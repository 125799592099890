import React from 'react'
import classNames from 'classnames'
import WelcomeTemplate from './WelcomeTemplate'
import { ReactComponent as ICanFly } from '../../../files/iCanFly.svg'

const WelcomeEnds = ({ welcome, previousStep, nextStep }) => {
    const content = () => {
        return (
            <div className="h-full flex flex-col sm:justify-between">
                <div className="flex flex-col space-y-4">
                    <div></div>
                    <div>
                        Vous pouvez maintenant créer{' '}
                        <span className="font-bold">
                            vos premiers transports
                        </span>{' '}
                        et{' '}
                        <span className="font-bold">
                            gérer votre régulation
                        </span>{' '}
                        !
                    </div>
                </div>
                <div className="flex justify-center">
                    <ICanFly />
                </div>
                <div className="pb-4">
                    Si vous avez des questions, vous pouvez nous contacter par
                    téléphone au <b>05 40 16 24 40</b> ou par mail à l'adresse :{' '}
                    <b>contact@heroad.io.</b>
                </div>
            </div>
        )
    }
    return (
        <WelcomeTemplate
            nextStep={nextStep}
            label="Accéder à l'application"
            previousStep={previousStep}
            content={content()}
            title="Le tutoriel est maintenant terminé ! 🎉"
            lastView={true}
        />
    )
}

export default WelcomeEnds
