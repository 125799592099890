import React from 'react'
import Select from 'react-select'

const ClassicInputV2 = ({
    isDisabled = false,
    icon,
    label,
    error,
    placeholder,
    value,
    setValue,
    required = true,
    type,
    id,
    ghostLabel,
}) => {
    return (
        <div className={`relative flex w-full flex-col text-lg`}>
            <div className="flex items-center space-x-2 pb-2">
                {icon && (
                    <i
                        className={`fa ml-2 ${icon} min-w-icon lg:fa-lg text-base`}
                    />
                )}
                {label && (
                    <label className="block text-lg font-bold uppercase tracking-wide text-gray-700 lg:text-xs">
                        {label}
                    </label>
                )}
            </div>
            <input
                className={`block h-14 w-full appearance-none rounded border pl-4 leading-tight text-gray-700 shadow-sm hover:border-lighter-blue-green focus:border-lighter-blue-green focus:outline-none lg:h-10
                  ${error ? 'border-red-500' : ''}`}
                id={id}
                type={type}
                // pattern={pattern}
                placeholder={placeholder}
                value={value}
                onChange={setValue}
                required={required}
                // min={min}
                // max={max}
                // maxLength={maxLength}
                disabled={isDisabled}
            />
            {ghostLabel ? (
                <div className="pointer-events-none  absolute  right-10 top-1 flex h-full  items-center pl-4 text-gray-700">
                    {ghostLabel}
                </div>
            ) : null}
        </div>
    )
}

export default ClassicInputV2
