import API from './api'
import { SERIES_ACTIONS } from './Reducers/SeriesReducer'
import {
    createDropDownTeamsValues,
    findRunTypeFromRunAttributes,
} from '../tools/Utility'
import * as dayjs from 'dayjs'
import RunTypes from './RunTypes'
import { ADDRESS_ACTIONS } from './Reducers/AddressAutoCompleteReducer'
import { PATIENT_ACTIONS } from './Reducers/PatientReducer'
import { DATE_ACTIONS } from './Reducers/DateReducer'
import { sortWithNewTimes } from '../tools/Sort'
import { PATIENT_FORM_ACTIONS } from '../components/App/Regulation/Reducers/PatientFormReducer'
import { PRESCRIPTION_FORM_ACTIONS } from '../components/App/Regulation/Reducers/PrescriptionFormReducer'
import {
    formatCaisseOptions,
    formatPractitionerOptions,
} from '../components/App/Regulation/Forms/PrescriptionForm'
import { PAYING_CENTER_FORM_ACTIONS } from '../components/App/Regulation/Reducers/PayingCenterFormReducer'
import { PRACTITIONER_FORM_ACTIONS } from '../components/App/Regulation/Reducers/PractitionerFormReducer'

export const fetchRunObjects = async (dispatch) => {
    const response = await API.RunObject.list()
    const data = await response.json()
    const runObjectList = data['hydra:member'].map((runObject) => {
        return {
            ...runObject,
            value: runObject.label,
        }
    })
    dispatch({
        type: SERIES_ACTIONS.SET_RUN_OBJECTS,
        payload: runObjectList,
    })
}

const frequencies = [
    { value: 0, label: 'Toutes les semaines' },
    { value: 1, label: 'Toutes les 2 semaines' },
    { value: 2, label: 'Toutes les 3 semaines' },
]

export const fetchTeamParameters = async (dispatch) => {
    const response = await API.Parameter.get(localStorage.getItem('society'))
    const parameters = await response.json()
    return dispatch({
        type: SERIES_ACTIONS.SET_PARAMETERS,
        payload: parameters,
    })
}

export const fetchPractitioners = async (dispatch) => {
    const response = await API.Practitioner.list()
    if (response.ok) {
        const jsonData = await response.json()
        dispatch({
            type: PRESCRIPTION_FORM_ACTIONS.SET_PRACTITIONERS_OPTIONS,
            payload: formatPractitionerOptions(jsonData['hydra:member']),
        })
    }
}

export const fetchDefaultTeams = async (dispatch) => {
    const response = await API.DefaultMasterRuns.list()
    if (response.ok) {
        const jsonData = await response.json()

        dispatch({
            type: SERIES_ACTIONS.SET_TEAMS,
            payload: createDropDownTeamsValues(jsonData['hydra:member']),
        })
    }
}

const FetchSeriesInformations = {
    getSeries: async (
        id,
        series,
        dispatch,
        pickUpDispatch,
        pickUpAddress,
        depositAddress,
        depositDispatch,
        datePatientDispatch,
        prsDateDispatch,
        dateMaternityDispatch,
        workAccidentDateDispatch,
        priorApprovalDateDispatch
    ) => {
        Promise.all([
            await fetchRunObjects(dispatch),
            await fetchTeamParameters(dispatch),
            await fetchDefaultTeams(dispatch),
        ]).then(() => {
            if (id && parseInt(id) !== 0) {
                API.Series.get(id).then((response) => {
                    response.json().then((seriesData) => {
                        if (!seriesData.newSeries) {
                            FetchSeriesInformations.getOldSeries(
                                id,
                                series,
                                seriesData,
                                dispatch,
                                pickUpDispatch,
                                pickUpAddress,
                                depositAddress,
                                depositDispatch
                            )
                        } else {
                            dispatch({
                                type: PATIENT_FORM_ACTIONS.GET_PATIENT,
                                payload: seriesData.patient,
                            })
                            datePatientDispatch({
                                type: DATE_ACTIONS.SET_DATE,
                                payload: seriesData.patient.dateOfBirth,
                            })
                            pickUpDispatch({
                                type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                                payload: seriesData.days[0].pickUpLocation,
                            })
                            depositDispatch({
                                type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                                payload: seriesData.days[0].depositLocation,
                            })
                            dispatch({
                                type: PAYING_CENTER_FORM_ACTIONS.FETCH_PAYING_CENTER,
                                payload: seriesData.prescription?.payingCenter,
                            })
                            dispatch({
                                type: PRACTITIONER_FORM_ACTIONS.FETCH_PRACTITIONER,
                                payload: seriesData.prescription?.practitioner,
                            })
                            dispatch({
                                type: PRESCRIPTION_FORM_ACTIONS.FETCH_PRESCRIPTION,
                                payload: seriesData.prescription,
                            })
                            prsDateDispatch({
                                type: DATE_ACTIONS.SET_DATE,
                                payload: seriesData.prsDate
                                    ? seriesData.prsDate
                                    : null,
                            })
                            dateMaternityDispatch({
                                type: DATE_ACTIONS.SET_DATE,
                                payload: seriesData.prescription?.dateMaternity,
                            })
                            workAccidentDateDispatch({
                                type: DATE_ACTIONS.SET_DATE,
                                payload:
                                    seriesData.prescription?.workAccidentDate,
                            })
                            priorApprovalDateDispatch({
                                type: DATE_ACTIONS.SET_DATE,
                                payload:
                                    seriesData.prescription?.priorApprovalDate,
                            })

                            let gottenSeries = {
                                id: seriesData.id,
                                loading: false,
                                newSeries: seriesData.newSeries,
                                patient: seriesData.patient,
                                runObject: seriesData.runObject,
                                prsNumber: seriesData.prsNumber,
                                kilometer: seriesData.kilometer,
                                prsDate: dayjs(seriesData.prsDate)
                                    .local()
                                    .format('YYYY-MM-DD'),
                                comments: seriesData.description,
                                firstRunDate: new Date(seriesData.firstRunDate),
                                lastRunDate: new Date(seriesData.lastRunDate),
                                byIntervalDates: seriesData.byIntervalDates,
                                label: seriesData.label,
                                nbRuns: seriesData.byIntervalDates
                                    ? null
                                    : seriesData.nbRuns,
                                frequency: frequencies.find(
                                    (f) => f.value === seriesData.frequency
                                ),
                                cpamInvoices: seriesData.cpamInvoices,
                                days: seriesData.days.map((day) => {
                                    return {
                                        day: day.day,
                                        depositLocation: day.depositLocation,
                                        pickUpLocation: day.pickUpLocation,
                                        differentReturnDeposit:
                                            day.differentReturnDeposit,
                                        departureTime: new Date(
                                            day.departureTime
                                        ),
                                        arrivingTime: new Date(
                                            day.arrivingTime
                                        ),
                                        returnTime: new Date(day.returnTime),
                                        returnDepositLocation:
                                            day.differentReturnDeposit
                                                ? day.returnDepositLocation
                                                : null,
                                        comments: day.comments,
                                        isReturnPath: day.isReturnPath,
                                        waitingTime: day.waitingTime,
                                        isRoundTrip: day.isRoundTrip,
                                        isDirectReturn: day.isDirectReturn,
                                        runType: RunTypes.find(
                                            (runType) =>
                                                runType.value === day.runType
                                        ),
                                    }
                                }),
                                daysOptions: series.daysDefaultList.filter(
                                    (defaultDay) =>
                                        !seriesData.days.some(
                                            (day) =>
                                                day.day === defaultDay.value
                                        )
                                ),
                            }

                            API.Runs.listForSeriesManage(id).then(
                                (response) => {
                                    response.json().then((data) => {
                                        console.log('data', data)
                                        console.log(
                                            'series teams',
                                            series.teams
                                        )
                                        // keep only lead runs
                                        const existingLeadsRuns = data.runs
                                            .filter(
                                                (r) =>
                                                    !r.isRoundTrip ||
                                                    (r.isRoundTrip &&
                                                        !r.isReturnPath)
                                            )
                                            .sort(sortWithNewTimes)

                                        const type = {
                                            '@id': `/types/${existingLeadsRuns[0].type.label}`,
                                            label: existingLeadsRuns[0].type
                                                .label,
                                        }

                                        gottenSeries.globalRunInformations = {
                                            type: type,
                                            depositLocation: depositAddress,
                                            pickUpLocation: pickUpAddress,
                                            isGiven: null,
                                            amount: existingLeadsRuns[0].amount
                                                ? existingLeadsRuns[0].amount
                                                : null,
                                            showAmount:
                                                !!existingLeadsRuns[0].amount,
                                        }

                                        gottenSeries.firstRunDate = new Date(
                                            existingLeadsRuns[0].date
                                        )
                                        gottenSeries.lastRunDate = new Date(
                                            existingLeadsRuns[
                                                existingLeadsRuns.length - 1
                                            ].date
                                        )

                                        const runsToInsert = []

                                        let runKey = 1
                                        existingLeadsRuns.map((existingRun) => {
                                            let run = {
                                                key: runKey,
                                                id: existingRun.id,
                                                returnRunId:
                                                    existingRun.returnRunId,
                                                date: new Date(
                                                    existingRun.date
                                                ),
                                                dateString: new Date(
                                                    existingRun.date
                                                ).toLocaleDateString('fr-FR'),
                                                departureTime: new Date(
                                                    existingRun.departureTime
                                                ),
                                                arrivingTime: new Date(
                                                    existingRun.arrivingTime
                                                ),
                                                returnTime: existingRun.return
                                                    ? new Date(
                                                          existingRun.return.departureTime
                                                      )
                                                    : null,
                                                status: existingRun.status.id,
                                                returnStatus: existingRun.return
                                                    ? existingRun.return.status
                                                          .id
                                                    : null,
                                                returnAmount: existingRun.return
                                                    ? existingRun.return.amount
                                                    : null,
                                                returnKilometer:
                                                    existingRun.return
                                                        ? existingRun.return
                                                              .kilometer
                                                        : null,
                                                isReturnPath:
                                                    existingRun.isReturnPath,
                                                isRoundTrip:
                                                    existingRun.isRoundTrip,
                                                haveIndirectReturn:
                                                    existingRun.isRoundTrip &&
                                                    !existingRun.directReturn,
                                                haveDirectReturn:
                                                    existingRun.directReturn,
                                                waitingTime:
                                                    existingRun.waitingTime,
                                                runShared: existingRun.partner,
                                                runSharedAndGiven:
                                                    existingRun.partner &&
                                                    existingRun.isGiven,
                                                returnRunShared: !!(
                                                    existingRun.return &&
                                                    existingRun.return.partner
                                                ),
                                                returnRunSharedAndGiven: !!(
                                                    existingRun.return &&
                                                    existingRun.return
                                                        .partner &&
                                                    existingRun.return.isGiven
                                                ),
                                                kilometer:
                                                    existingRun.kilometer,
                                                amount: existingRun.amount,
                                                parentId:
                                                    existingRun.parentId ||
                                                    null,
                                                departureOrder:
                                                    existingRun.departureOrder,
                                                returnParentId:
                                                    (existingRun.return &&
                                                        existingRun.return
                                                            .parentId) ||
                                                    null,
                                                returnDepartureOrder:
                                                    existingRun.return &&
                                                    existingRun.return
                                                        .departureOrder,
                                            }

                                            if (existingRun.partner) {
                                                console.log(
                                                    existingRun.partner &&
                                                        existingRun.isGiven
                                                )
                                            }

                                            //for run default team or team
                                            if (
                                                existingRun.defaultTeam ||
                                                existingRun.team
                                            ) {
                                                if (existingRun.defaultTeam) {
                                                    run.team =
                                                        existingRun.defaultTeam
                                                    run.isTeamDefaultTeam = true
                                                    run.defaultMasterRunId =
                                                        existingRun.defaultTeam.value.split(
                                                            '/'
                                                        )[2]
                                                } else {
                                                    run.team = existingRun.team
                                                    run.masterRunId =
                                                        existingRun.team.value.split(
                                                            '/'
                                                        )[2]
                                                }
                                            }

                                            //for return run default team or team
                                            if (
                                                existingRun.return &&
                                                (existingRun.return
                                                    .defaultTeam ||
                                                    existingRun.return.team)
                                            ) {
                                                if (
                                                    existingRun.return
                                                        .defaultTeam
                                                ) {
                                                    run.returnRunTeam =
                                                        existingRun.return.defaultTeam
                                                    run.returnRunDefaultMasterRunId =
                                                        existingRun.return.defaultTeam.value.split(
                                                            '/'
                                                        )[2]
                                                } else {
                                                    run.returnRunTeam =
                                                        existingRun.return.team
                                                    run.returnRunMasterRunId =
                                                        existingRun.return.team.value.split(
                                                            '/'
                                                        )[2]
                                                }
                                            }

                                            runsToInsert.push(run)
                                            runKey += existingRun.isRoundTrip
                                                ? 2
                                                : 1
                                        })
                                        console.log(
                                            'existingLeadsRuns',
                                            existingLeadsRuns
                                        )
                                        console.log('FETCH ', runsToInsert)

                                        gottenSeries.runsToInsert = runsToInsert

                                        dispatch({
                                            type: SERIES_ACTIONS.SET_SERIES,
                                            payload: gottenSeries,
                                        })
                                    })
                                }
                            )
                        }
                    })
                })
            } else {
                dispatch({
                    type: SERIES_ACTIONS.SET_LOADING,
                    payload: false,
                })
            }
        })
    },
    getOldSeries: async (
        id,
        series,
        seriesData,
        dispatch,
        pickUpDispatch,
        pickUpAddress,
        depositAddress,
        depositDispatch
    ) => {
        API.Runs.listWithSeriesId(id).then((response) => {
            response.json().then((runsData) => {
                // keep only lead runs
                const existingLeadsRuns = runsData['hydra:member']
                    .filter(
                        (r) =>
                            !r.isRoundTrip || (r.isRoundTrip && !r.isReturnPath)
                    )
                    .sort(sortWithNewTimes)

                const seriesFirstRun = existingLeadsRuns[0]
                const seriesLastRun =
                    existingLeadsRuns[existingLeadsRuns.length - 1]

                dispatch({
                    type: PATIENT_ACTIONS.GET_PATIENT,
                    payload: seriesFirstRun.patient,
                })

                pickUpDispatch({
                    type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                    payload: seriesFirstRun.pickUpLocation,
                })
                depositDispatch({
                    type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                    payload: seriesFirstRun.depositLocation,
                })

                let gottenSeries = {
                    id: seriesData.id,
                    loading: false,
                    newSeries: seriesData.newSeries,
                    firstRunDate: new Date(seriesFirstRun.date),
                    lastRunDate: new Date(seriesLastRun.date),
                    byIntervalDates: seriesData.byIntervalDates,
                    label:
                        seriesData.label && seriesData.label !== ''
                            ? seriesData.label
                            : null,
                    nbRuns: seriesData.byIntervalDates
                        ? null
                        : seriesData.nbRuns,
                    frequency: frequencies.find(
                        (f) => f.value === seriesData.frequency
                    ),
                    comments: seriesData.description,
                    patient: seriesFirstRun.patient,
                    runObject: seriesFirstRun.runObject,
                    prsNumber: seriesFirstRun.prsNumber,
                    kilometer: seriesFirstRun.kilometer,
                    prsDate: seriesFirstRun.prsDate
                        ? dayjs(seriesFirstRun.prsDate)
                              .local()
                              .format('YYYY-MM-DD')
                        : null,
                    globalRunInformations: {
                        type: seriesFirstRun.type,
                        depositLocation: depositAddress,
                        pickUpLocation: pickUpAddress,
                        isGiven: null,
                        amount: seriesFirstRun.amount
                            ? seriesFirstRun.amount
                            : null,
                        showAmount: !!seriesFirstRun.amount,
                    },
                    days: seriesData.days.map((day) => {
                        return {
                            day: day,
                            depositLocation: seriesFirstRun.depositLocation,
                            pickUpLocation: seriesFirstRun.pickUpLocation,
                            differentReturnDeposit: false,
                            departureTime: new Date(
                                seriesFirstRun.departureTime
                            ),
                            arrivingTime: new Date(seriesFirstRun.arrivingTime),
                            returnTime: seriesFirstRun.return
                                ? new Date(seriesFirstRun.return.departureTime)
                                : null,
                            returnDepositLocation: null,
                            comments: seriesFirstRun.comments,
                            isReturnPath: seriesFirstRun.isReturnPath,
                            waitingTime: seriesFirstRun.waitingTime
                                ? seriesFirstRun.waitingTime
                                : null,
                            isRoundTrip: seriesFirstRun.isRoundTrip,
                            isDirectReturn: seriesFirstRun.directReturn,
                            runType: findRunTypeFromRunAttributes(
                                seriesFirstRun.isReturnPath,
                                seriesFirstRun.isRoundTrip,
                                seriesFirstRun.waitingTime
                            ),
                        }
                    }),
                    daysOptions: series.daysDefaultList.filter(
                        (defaultDay) =>
                            !seriesData.days.some(
                                (day) => day === defaultDay.value
                            )
                    ),
                }

                const runsToInsert = []

                let runKey = 1
                existingLeadsRuns.map((existingRun) => {
                    let run = {
                        key: runKey,
                        date: new Date(existingRun.date),
                        dateString: new Date(
                            existingRun.date
                        ).toLocaleDateString('fr-FR'),
                        departureTime: new Date(existingRun.departureTime),
                        arrivingTime: new Date(existingRun.arrivingTime),
                        returnTime: existingRun.return
                            ? new Date(existingRun.return.departureTime)
                            : null,
                        status: existingRun.status.id,
                        returnStatus: existingRun.return
                            ? existingRun.return.status.id
                            : null,
                        isReturnPath: existingRun.isReturnPath,
                        isRoundTrip: existingRun.isRoundTrip,
                        haveIndirectReturn:
                            existingRun && !existingRun.directReturn,
                        haveDirectReturn: existingRun.directReturn,
                        waitingTime: existingRun.waitingTime,
                    }

                    runsToInsert.push(run)
                    runKey += existingRun.isRoundTrip ? 2 : 1
                })

                gottenSeries.runsToInsert = runsToInsert

                dispatch({
                    type: SERIES_ACTIONS.SET_SERIES,
                    payload: gottenSeries,
                })
            })
        })
    },
}

export default FetchSeriesInformations
