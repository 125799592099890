import React, { useState } from 'react'
import Loader from '../../../../Commons/Loaders/Loader'
import ModalSkeleton from '../../../../Modal/ModalSkeleton'
import SeriesRunInvoiceDetailsFormModal from './SeriesRunInvoiceDetailsFormModal'
import { SERIES_CPAM_INVOICE_FORM_ACTION } from '../SeriesCpamInvoiceFormReducer'
import SeriesRunPricePanel from './SeriesRunPricePanel'
import SeriesInvoiceTotalPanel from './SeriesInvoiceTotalPanel'
import {
    handleInvoiceCpamModalForm,
    handleInvoiceStatusChange,
    handleSuspendedInvoice,
} from '../../../../../services/InvoiceUtils'
import { InvoiceFormDetails } from '../../../Regulation/Forms/Invoice/InvoiceFormDetails'
import { PaymentDetails } from '../../../Regulation/Forms/Invoice/PaymentDetails'
import dayjs from 'dayjs'
import { isRunAbleToBeInvoiced } from '../../../../../services/fetchRunsInformations'
import InvoiceRuns from './InvoiceRuns'
import ManageRunModal from '../../../../Modal/ManageRunModal'
import {
    useStickyDateState,
    useStickyEndDateState,
} from '../../../../../hooks/LocalStorageDatesHooks'
import { saveAs } from 'file-saver'
import InvoiceRejectionDetails from '../../../Regulation/Forms/Invoice/InvoiceRejectionDetails'
import { INVOICE_CPAM_ACTIONS } from '../../../Regulation/Reducers/InvoiceCpamReducer'
import API from '../../../../../services/api'
import { toast } from 'react-toastify'
import SelectDateModalForm from '../../../Invoice/Noemie/SelectDateModalForm'
import CpamInvoiceHeader from '../../../Regulation/Forms/Invoice/CpamInvoiceHeader'

const SeriesCpamInvoiceFormModal = ({
    invoiceForm,
    dispatch,
    prescription,
    series,
    handleCreateInvoice,
}) => {
    const { patient } = series
    const rate = prescription?.rate?.value
    const [showSelectDateModal, setShowSelectDateModal] = useState(false)
    const [statusDate, setStatusDate] = useState(new Date())
    const [statusAndType, setStatusAndType] = useState({})

    const handleShowModal = () => {
        dispatch({
            type: SERIES_CPAM_INVOICE_FORM_ACTION.SHOW_RUN_INVOICE_DETAILS_MODAL,
        })
    }

    const handleStatusChange = (type, status) => {
        setStatusAndType({ type: type, status: status })
        // if (status === 'Virement reçu') {
        setShowSelectDateModal(true)
        // } else {
        //     changeStatus()
        // }
    }

    const changeStatus = async () => {
        handleInvoiceStatusChange(
            statusAndType.type,
            statusAndType.status,
            statusDate,
            invoiceForm,
            dispatch,
            SERIES_CPAM_INVOICE_FORM_ACTION
        ).then()
        setShowSelectDateModal(false)
    }

    const handleRunInvoiceDetailsForm = () => {
        handleInvoiceCpamModalForm(
            invoiceForm,
            dispatch,
            SERIES_CPAM_INVOICE_FORM_ACTION,
            handleShowModal,
            rate
        )
    }

    const checkIfEachRunAreInvoiceAble = invoiceForm.runs.every((run) =>
        isRunAbleToBeInvoiced(run, patient)
    )

    const [openRunFormModal, setOpenRunFormModal] = React.useState(false)
    const [dateFrom, setDateFrom] = useStickyDateState(
        new Date(dayjs().add(1, 'day')),
        'storedDate'
    )
    const [dateTo, setDateTo] = useStickyEndDateState(
        new Date(),
        'storedDate',
        'storedEndDate'
    )
    const [runId, setRunId] = React.useState(null)

    const handleOpenRunModal = (run) => {
        setRunId(run.id)
        setOpenRunFormModal(true)
    }

    const checkPatientCondition =
        !patient.patientsAddresses[0]?.address.city ||
        !patient.socialNumber ||
        !patient.dateOfBirth ||
        !patient.patientsAddresses.length > 0

    const handleDateChange = (date) => {
        // verifier si la date n'est pas dans le futur sinon erreur
        if (date > new Date()) {
            toast.error('La date ne peut pas être dans le futur')
        } else {
            setStatusDate(date)
        }
    }

    const handleSuspendInvoiceCpam = () => {
        handleSuspendedInvoice(
            invoiceForm,
            dispatch,
            INVOICE_CPAM_ACTIONS
        ).then()
    }

    const handlePrintInvoice = async () => {
        dispatch({
            type: SERIES_CPAM_INVOICE_FORM_ACTION.IS_LOADING,
            payload: true,
        })
        const pdfName = `Facture_${invoiceForm.cpamInvoiceId}.pdf`
        try {
            const response = await API.CpamInvoice.print(invoiceForm.id)
            if (response.ok) {
                response.blob().then((blob) => {
                    saveAs(blob, pdfName)
                    toast.success('Pdf téléchargé avec succès')
                })
            }
        } catch (error) {
            if (error.json) {
                const data = await error.json()
                toast.error(data['hydra:description'])
            } else {
                toast.error('Erreur, veuillez contacter un administrateur')
            }
        }
        dispatch({
            type: SERIES_CPAM_INVOICE_FORM_ACTION.IS_LOADING,
            payload: false,
        })
    }

    return (
        <div className={'w-full'}>
            {invoiceForm.loading ? (
                <Loader />
            ) : (
                <>
                    {checkIfEachRunAreInvoiceAble || invoiceForm.id ? (
                        <div className={'flex flex-col'}>
                            <CpamInvoiceHeader
                                invoice={invoiceForm}
                                handleSuspendInvoiceCpam={
                                    handleSuspendInvoiceCpam
                                }
                                handlePrintInvoice={handlePrintInvoice}
                                showInvoiceAction={false}
                                patient={patient}
                                prescription={prescription}
                                ACTION={SERIES_CPAM_INVOICE_FORM_ACTION}
                                dispatch={dispatch}
                            />
                            {invoiceForm.id ? (
                                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
                                    <InvoiceFormDetails invoice={invoiceForm} />
                                    <PaymentDetails
                                        invoice={invoiceForm}
                                        rate={rate}
                                        dispatch={dispatch}
                                        INVOICE_CPAM_ACTIONS={
                                            SERIES_CPAM_INVOICE_FORM_ACTION
                                        }
                                        handleStatusChange={handleStatusChange}
                                        formModal={true}
                                    />
                                </div>
                            ) : null}
                            <div className="flex flex-col py-4 pb-10 ">
                                {invoiceForm.priceDetails ? (
                                    <div className="flex grid-cols-2 flex-col gap-8 space-y-2 sm:grid sm:space-y-0 ">
                                        <SeriesRunPricePanel
                                            totals={invoiceForm.totals}
                                            handleChangePrices={handleShowModal}
                                            invoice={invoiceForm}
                                        />
                                        <SeriesInvoiceTotalPanel
                                            totals={invoiceForm.totals}
                                            rate={rate}
                                        />
                                    </div>
                                ) : null}
                            </div>
                            {invoiceForm.showRunInvoiceDetailsModal ? (
                                <ModalSkeleton
                                    handleCloseModal={handleShowModal}
                                    handleForm={handleRunInvoiceDetailsForm}
                                    label={`Détail des ${invoiceForm.runs.length} transports `}
                                    componentProps={{
                                        priceBases: series.priceBases,
                                        allowedToChangePriceBasis:
                                            series.allowedToChangePriceBasis,
                                        invoiceForm,
                                        dispatch,
                                    }}
                                    Component={SeriesRunInvoiceDetailsFormModal}
                                />
                            ) : null}
                        </div>
                    ) : (
                        <div className={'flex flex-col p-4'}>
                            {checkPatientCondition && (
                                <div
                                    className={
                                        'flex flex-col rounded-lg border-2 p-4'
                                    }
                                >
                                    <div className={'text-2xl font-semibold'}>
                                        Patient
                                    </div>
                                    <div className={'flex flex-col'}>
                                        {!patient.patientsAddresses.length >
                                            0 && (
                                            <p className="font-bold text-red-500">
                                                Veuillez renseigner une adresse
                                                de domicile
                                            </p>
                                        )}
                                        {!patient.socialNumber && (
                                            <p className="font-bold text-red-500">
                                                Veuillez renseigner un numéro de
                                                sécurité sociale
                                            </p>
                                        )}
                                        {!patient.patientsAddresses[0]?.address
                                            .city && (
                                            <p className="font-bold text-red-500">
                                                Veuillez renseigner une addresse
                                                complète sur le domicile
                                            </p>
                                        )}
                                        {!patient.dateOfBirth && (
                                            <p className="font-bold text-red-500">
                                                Veuillez renseigner une date de
                                                naissance
                                            </p>
                                        )}
                                        {/*{}*/}
                                    </div>
                                </div>
                            )}
                            <InvoiceRuns
                                invoiceForm={invoiceForm}
                                handleOpenRunModal={handleOpenRunModal}
                            />
                            {openRunFormModal && (
                                <ManageRunModal
                                    setDateTo={setDateTo}
                                    setDateFrom={setDateFrom}
                                    dateFrom={dateFrom}
                                    setOpenRunManageModal={setOpenRunFormModal}
                                    setOpenNewRunManageModal={
                                        setOpenRunFormModal
                                    }
                                    reRender={handleCreateInvoice}
                                    runId={runId}
                                />
                            )}
                        </div>
                    )}
                </>
            )}
            {invoiceForm.showRejectHistory ? (
                <ModalSkeleton
                    label="Historique des rejets"
                    handleCloseModal={() =>
                        dispatch({
                            type: SERIES_CPAM_INVOICE_FORM_ACTION.SET_SHOW_REJECT_HISTORY,
                            payload: false,
                        })
                    }
                    Component={InvoiceRejectionDetails}
                    componentProps={{
                        cpamInvoiceRejectionDetails:
                            invoiceForm.cpamInvoiceRejectionDetails,
                    }}
                />
            ) : null}
            {showSelectDateModal ? (
                <SelectDateModalForm
                    date={statusDate}
                    handleCloseModal={() => setShowSelectDateModal(false)}
                    handleDateChange={handleDateChange}
                    handleForm={changeStatus}
                />
            ) : null}
        </div>
    )
}

export default SeriesCpamInvoiceFormModal
