import React from 'react'
import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'
import { Printer } from 'iconoir-react'
import * as dayjs from 'dayjs'

const NoemieHeader = ({ isReceived, noemie, handlePrintAction }) => (
    <div className={'flex items-center justify-between pb-5'}>
        {isReceived ? (
            <div className={'flex flex-col'}>
                <h1 className="text-2xl font-bold">
                    Retours noemie du{' '}
                    {dayjs(noemie.noemieReceivedAt)
                        .local()
                        .format('DD/MM/YYYY')}
                </h1>
                <p>Cliquez sur un lot pour voir le détail du retour</p>
            </div>
        ) : (
            <h1 className="text-2xl font-bold">Retour NOEMIE non reçu</h1>
        )}
        <PrimaryButton
            label={'Imprimer justificatifs'}
            customIconClass={'pr-2'}
            action={handlePrintAction}
            icon={<Printer />}
        />
    </div>
)

export default NoemieHeader
