import React, { useReducer, useRef } from 'react'
import { PATIENT_ACTIONS } from '../../../../services/Reducers/PatientReducer'
import FormDateInput from '../../../Commons/Inputs/FormDateInput'
import { ReactComponent as Chip } from '../../../../files/chip.svg'
import { UserSquare } from 'iconoir-react'
import { CakeIcon } from '@heroicons/react/solid'
import { openMaps } from '../../../../services/address'
import SelectDropDownListV2 from '../../../Commons/DropDownLists/SelectDropDownListV2'
import {
    getPecInitialState,
    PEC_ACTIONS,
    PecMotifsType,
    PecReducer,
    PecTransportType,
} from '../../../../services/Reducers/PecReducer'
import {
    getDateFromDateReducer,
    hasOneDateParts,
    isDateValid,
} from '../../../../services/DateService'
import { toast } from 'react-toastify'
import API from '../../../../services/api'
import dayjs from 'dayjs'
import { ReactComponent as InlineButtonLoader } from '../../../../files/inlineButtonLoader.svg'
import FormSwitch from '../../../Commons/Buttons/FormSwitch'
import {
    DateReducer,
    getDateInitialState,
} from '../../../../services/Reducers/DateReducer'
import PecResponse from './PecResponse'
import ClassicInputV2 from '../../../Commons/Inputs/ClassicInputV2'
import pd from 'pretty-data'
import { PecLoginModal } from './PecLoginModal'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'
import { PRESCRIPTION_FORM_ACTIONS } from '../../Regulation/Reducers/PrescriptionFormReducer'
import {
    Exonerations,
    NatureAssurances,
    Taux,
} from '../../../../services/Prescription'
import { RegimeList } from '../../../../services/RegimeList'

export const formatSocialNumber = (num) => {
    if (!num) return ''
    let numStr = String(num)
    let formattedNum = ''

    for (let i = 0; i < numStr.length; i++) {
        formattedNum += numStr[i]

        // Ajoute un espace après le 1er, 3ème, 5ème, 7ème, 10ème et 13ème chiffre
        if (i === 0 || i === 2 || i === 4 || i === 6 || i === 9 || i === 12) {
            formattedNum += ' '
        }
    }

    return formattedNum
}

const formatDateIntoCalendaire = (date) => {
    return dayjs(new Date(date.year, date.month - 1, date.day))
        .local()
        .format('YYYY-MM-DD')
}

const mapVehiclesArrayToNewArray = (array1, array2) => {
    let isVSL = array1.some((item) => item.label === 'VSL')
    let isAmbulance = array1.some((item) => item.label === 'Ambulance')
    let newArray = []
    array1.forEach((item1) => {
        array2.forEach((item2) => {
            if (item1.label === item2.label) {
                newArray.push(item2)
            }
        })
    })

    if (isAmbulance && isVSL) {
        let abaVSL = array2.find((item) => item.value === 'ABA/VSL')
        if (abaVSL) {
            newArray.push(abaVSL)
        }
    }

    return newArray
}

export const PecModal = ({
    dateOfBirth,
    patientDispatch,
    patient,
    prsDateDispatch,
    prsDateInput,
    realDateDispatch,
    realDateInput,
}) => {
    const vehicleType = PecTransportType.find((item) => item.label === 'Taxi')
    const requestRef = useRef(null)
    const responseRef = useRef(null)
    const [xmlRequest, setXmlRequest] = React.useState(null)
    const [xmlResponse, setXmlResponse] = React.useState(null)
    const [pec, pecDispatch] = useReducer(
        PecReducer,
        {
            vehicleType,
        },
        getPecInitialState
    )
    const [dateATMP, dateATMPDispatch] = useReducer(
        DateReducer,
        null,
        getDateInitialState
    )

    const checkErrors = () => {
        let msg = []
        if (hasOneDateParts(prsDateInput)) {
            if (!isDateValid(prsDateInput, true)) {
                msg.push(
                    'Veuillez renseigner une date de prescription correcte'
                )
            }
        } else {
            msg.push('Veuillez renseigner une date de prescription')
        }
        if (hasOneDateParts(realDateInput)) {
            if (!isDateValid(realDateInput, true)) {
                msg.push('Veuillez renseigner une date de réalisation correcte')
            }
        } else {
            msg.push('Veuillez renseigner une date de réalisation')
        }
        if (
            getDateFromDateReducer(prsDateInput) >
            getDateFromDateReducer(realDateInput)
        ) {
            msg.push(
                'La date de prescription ne peut pas être postérieure à la date de réalisation'
            )
        }
        if (
            getDateFromDateReducer(prsDateInput) <
                getDateFromDateReducer(dateOfBirth) ||
            getDateFromDateReducer(realDateInput) <
                getDateFromDateReducer(dateOfBirth)
        ) {
            msg.push(
                'La date de prescription et de réalisation ne peut pas être antérieure à la date de naissance'
            )
        }
        if (getDateFromDateReducer(realDateInput) > new Date()) {
            msg.push(
                'La date de réalisation ne peut pas être postérieure à la date du jour'
            )
        }
        if (pec.motif.value === 'AT' || pec.motif.value === 'MP') {
            if (hasOneDateParts(dateATMP)) {
                const atmpDate = getDateFromDateReducer(dateATMP)
                const birthDate = getDateFromDateReducer(dateOfBirth) // Assurez-vous d'avoir défini birthDateInput
                if (birthDate > atmpDate) {
                    msg.push(
                        'La date de naissance ne peut pas être postérieure à la date d’accident de travail'
                    )
                }
                if (getDateFromDateReducer(prsDateInput) < atmpDate) {
                    msg.push(
                        'La date de prescription ne peut pas être antérieure à la date d’accident de travail'
                    )
                }
                if (getDateFromDateReducer(realDateInput) < atmpDate) {
                    msg.push(
                        'La date de réalisation ne peut pas être antérieure à la date d’accident de travail'
                    )
                }
            }
            if (!hasOneDateParts(dateATMP) && !pec.atNumber) {
                msg.push(
                    "Veuillez renseigner une date d'AT/MP ou un numéro d'AT"
                )
            } else if (hasOneDateParts(dateATMP) && pec.atNumber) {
                msg.push(
                    "Veuillez renseigner soit un numéro AT soit une date d'AT/MP"
                )
            } else if (hasOneDateParts(dateATMP)) {
                if (!isDateValid(dateATMP)) {
                    msg.push("Veuillez renseigner une date d'AT/MP correcte")
                }
            }
        }
        if (msg.length > 0) {
            msg.forEach((e) => {
                toast.error(e)
            })
            return true
        }
        return false
    }

    const handleCopyClick = (ref) => {
        ref.current.select()
        document.execCommand('copy')
        toast.success(`Requête copiée 🌟`)
    }

    const handlePecSession = (pecSessionPwd, pecSessionId) => {
        const pecSessionStartedAt = dayjs(
            localStorage.getItem('pecSessionStartedAt')
        )
        // handle PEC Sessions
        // if (
        //     !pecSessionStartedAt ||
        //     dayjs().diff(pecSessionStartedAt, 'minute') >= 30 ||
        //     pec.wrongIds ||
        //     (!pecSessionId && !pecSessionPwd)
        // ) {
        //     pecDispatch({
        //         type: PEC_ACTIONS.OPEN_MODAL,
        //     })
        //     return true
        // }
        return false
    }

    const parsePecResponse = (data) => {
        let facture = data.FACTURE
        let couverture1 = facture.COUVERTURE
        let couverture2 = facture.COUVERTURE

        if (couverture1.length > 1) {
            couverture1 = facture.COUVERTURE[0]
            couverture2 = facture.COUVERTURE[1]
        }

        return {
            codeRegime: RegimeList.find(
                (item) => item.value === couverture1.GrandRegime
            ),
            organismeDestinataire: couverture1.Organisme,
            codeCentreGestionnaire: couverture1.EntiteGestion,
            natureAssurance: NatureAssurances?.find(
                (item) => item.value === couverture1.CONTRATS?.NatureAssurance
            ),
            RangBen: facture.BENEFICIAIRE.RangBen,
            CadreReglement: couverture2.CONTRATS?.CadreReglement,
            exoneration: Exonerations.find(
                (item) => item.value === facture.CADRE.EXECUTION.SERVICE.RMB.Exo
            ),
            rate: Taux.find(
                (item) =>
                    item.value === facture.CADRE.EXECUTION.SERVICE.RMB.Part.Tux
            ),
            isSanitary: facture.isSanitaryTransport,
        }
    }

    const handleForm = () => {
        const pecSessionId = localStorage.getItem('pecSessionId')
        const pecSessionPwd = localStorage.getItem('pecSessionPwd')
        if (handlePecSession(pecSessionId, pecSessionPwd)) return
        // clear data
        pecDispatch({
            type: PEC_ACTIONS.SET_RESULT,
            payload: null,
        })
        setXmlRequest(null)
        setXmlResponse(null)

        if (!checkErrors()) {
            pecDispatch({ type: PEC_ACTIONS.SET_LOADING, payload: true })
            patient.dateOfBirth = `${dateOfBirth.year}-${dateOfBirth.month}-${dateOfBirth.day}`
            const motifs = []
            if (pec.isPec100) motifs.push('CDP100')
            if (pec.motif?.value) motifs.push(pec.motif.value)
            const data = {
                patient: patient,
                regime: patient.payingCenter.codeRegime,
                transport: pec.transport.value,
                realDate: formatDateIntoCalendaire(realDateInput),
                prsDate: formatDateIntoCalendaire(prsDateInput),
                motifs: motifs,
                atmpDate:
                    (motifs.includes('AT') || motifs.includes('MP')) &&
                    !pec.atNumber
                        ? formatDateIntoCalendaire(dateATMP)
                        : null,
                atNumber:
                    motifs.includes('AT') && !hasOneDateParts(dateATMP)
                        ? pec.atNumber
                        : null,
                identifiant: pecSessionId,
                password: pecSessionPwd,
            }
            API.Pec.post(data)
                .then((res) => {
                    res.json().then((data) => {
                        const parseDataResponse = parsePecResponse(data.result)
                        pecDispatch({
                            type: PEC_ACTIONS.SET_RESULT,
                            payload: data.result,
                        })
                        patientDispatch({
                            type: PRESCRIPTION_FORM_ACTIONS.SET_PEC_RESPONSE,
                            payload: parseDataResponse,
                        })
                        setXmlRequest(pd.pd.xml(data.xmlRequest))
                        setXmlResponse(pd.pd.xml(data.xmlResponse))
                    })
                })
                .catch((error) => {
                    pecDispatch({
                        type: PEC_ACTIONS.SET_LOADING,
                        payload: false,
                    })
                    error.json().then((data) => {
                        if (data.fatal) {
                            return toast.error(
                                data.error.message || data.detail.message
                            )
                        }
                        if (
                            data.error.detail ===
                            'Identifiant ou mot de passe incorrect.'
                        ) {
                            pecDispatch({
                                type: PEC_ACTIONS.SET_WRONG_IDS,
                                payload: true,
                            })
                        }
                        toast.error(data.error.detail || data.error.message)
                        // setXmlRequest(pd.pd.xml(data.xmlRequest))
                        // setXmlResponse(pd.pd.xml(data.xmlResponse))
                    })
                })
        }
    }
    return (
        <div
            className={`modal  absolute bottom-0 left-0 flex h-full w-full items-center justify-center bg-gray-500 bg-opacity-50 text-gray-700 lg:py-20 `}
        >
            <div
                id="top"
                className="shadow-modal invisible-scrollbar lg:min-h-modal mx-auto flex h-screen w-full max-w-4xl flex-col overflow-y-auto rounded-xl bg-white lg:h-full "
            >
                <div className="flex flex-col items-start p-6 lg:p-8">
                    <div className="flex w-full items-center border-b pb-4 text-3xl">
                        <div className="flex space-x-4 text-center font-semibold ">
                            PEC+ Vérification des droits via PMT
                        </div>
                        <svg
                            className="ml-auto h-10 w-10 cursor-pointer fill-current text-gray-700"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 18 18"
                            onClick={() =>
                                patientDispatch({
                                    type: PATIENT_ACTIONS.SHOW_PEC_MODAL,
                                    payload: false,
                                })
                            }
                        >
                            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                        </svg>
                    </div>
                    <div
                        className={
                            'flex w-full flex-col justify-between pt-4 lg:flex-row'
                        }
                    >
                        <div className="flex w-full  pb-10 lg:w-1/2  lg:pb-5">
                            <FormDateInput
                                label="Date de prescription"
                                date={prsDateInput}
                                dispatch={prsDateDispatch}
                            />
                        </div>
                        <div className="flex w-full pb-10 lg:w-1/2 lg:pb-5">
                            <FormDateInput
                                label="Date de réalisation"
                                date={realDateInput}
                                dispatch={realDateDispatch}
                            />
                        </div>
                    </div>

                    <div className={'flex w-full flex-col lg:flex-row'}>
                        <div className="flex w-full  pr-10 lg:w-1/2">
                            <div className="flex w-full flex-col space-y-6">
                                <SelectDropDownListV2
                                    label={'Transport'}
                                    icon={'fa-ambulance'}
                                    placeholder={
                                        'Sélectionner un type de transport'
                                    }
                                    options={[]}
                                    value={pec.transport}
                                    handleOptionChange={(e) =>
                                        pecDispatch({
                                            type: PEC_ACTIONS.SET_TRANSPORT,
                                            payload: e,
                                        })
                                    }
                                    isClearable={false}
                                    isDisabled={true}
                                />
                                <SelectDropDownListV2
                                    label={'Context du transport'}
                                    icon={'fa-check-square'}
                                    placeholder={
                                        'Sélectionner un motif/context'
                                    }
                                    options={PecMotifsType}
                                    value={pec.motif}
                                    handleOptionChange={(e) =>
                                        pecDispatch({
                                            type: PEC_ACTIONS.SET_MOTIF,
                                            payload: e,
                                        })
                                    }
                                    isClearable={true}
                                />
                                {pec.motif.value === 'MP' ||
                                pec.motif.value === 'AT' ? (
                                    <FormDateInput
                                        label="Date de l'AT/MP"
                                        date={dateATMP}
                                        dispatch={dateATMPDispatch}
                                    />
                                ) : null}
                                {pec.motif.value === 'AT' && (
                                    <ClassicInputV2
                                        showLabel={true}
                                        label={'Numéro AT'}
                                        value={pec.atNumber}
                                        setValue={(e) =>
                                            pecDispatch({
                                                type: PEC_ACTIONS.SET_AT_NUMBER,
                                                payload: e.target.value,
                                            })
                                        }
                                    />
                                )}
                                <div className={'flex flex-col space-y-1'}>
                                    <label
                                        className={
                                            'pb-1 text-base font-semibold uppercase lg:text-xs'
                                        }
                                    >
                                        Prise en charge à 100% ?
                                    </label>
                                    <FormSwitch
                                        id="patient"
                                        valueLeft={pec.isPec100}
                                        valueRight={!pec.isPec100}
                                        onClickLeft={() =>
                                            pecDispatch({
                                                type: PEC_ACTIONS.IS_PEC_100,
                                                payload: true,
                                            })
                                        }
                                        onClickRight={() =>
                                            pecDispatch({
                                                type: PEC_ACTIONS.IS_PEC_100,
                                                payload: false,
                                            })
                                        }
                                        leftLabel={'Oui'}
                                        rightLabel={'Non'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full flex-col lg:w-1/2">
                            <h2 className={'py-2 text-xl font-semibold'}>
                                Informations du patient
                            </h2>
                            <div
                                id="card"
                                className="relative h-60 w-96 cursor-pointer overflow-hidden rounded-2xl font-mono text-white transition-all duration-500"
                            >
                                <div className="vital absolute left-0 top-0 z-20 flex h-full w-full flex-col px-6 pt-4 transition-all delay-200 duration-100">
                                    <div
                                        className={
                                            'flex items-center justify-between'
                                        }
                                    >
                                        <div className="flex flex-col">
                                            <h1
                                                className={
                                                    'pl-2 text-5xl font-bold text-[#F7C501]'
                                                }
                                            >
                                                Vitale
                                            </h1>
                                            <div
                                                className={'flex items-center'}
                                            >
                                                <Chip width="50" height="50" />
                                                <div className="flex items-center pl-3">
                                                    <CakeIcon
                                                        width="25"
                                                        height="25"
                                                        color="#111827"
                                                    />
                                                    <p className="pl-2 pt-1 font-semibold text-gray-900">
                                                        {dateOfBirth.day +
                                                            '/' +
                                                            dateOfBirth.month +
                                                            '/' +
                                                            dateOfBirth.year}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <UserSquare
                                            strokeWidth={0.8}
                                            width={100}
                                            height={100}
                                            color={'#111827'}
                                        />
                                    </div>
                                    <div className="ml-16 pt-5 text-lg">
                                        <div className="">
                                            <label
                                                className={
                                                    'font-bold text-gray-900'
                                                }
                                            >
                                                {formatSocialNumber(
                                                    patient.socialNumber
                                                )}
                                            </label>
                                        </div>

                                        <div className=" flex w-full flex-row justify-between">
                                            <div className="flex w-full flex-col">
                                                <p
                                                    className={
                                                        'font-semibold text-gray-900'
                                                    }
                                                >
                                                    {patient.firstname}
                                                </p>
                                                <p
                                                    className={
                                                        'font-semibold uppercase text-gray-900'
                                                    }
                                                >
                                                    {patient.lastname}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rotateY absolute left-0 top-0 z-10 flex h-full w-full flex-col bg-[#00823d] pt-4 transition-all">
                                    <div className="flex flex-col px-6 text-base text-gray-900">
                                        <div className="flex flex-col space-y-1 pb-3">
                                            <p className="font-bold">
                                                Téléphone
                                            </p>
                                            {patient.phoneNumber ? (
                                                <div className="flex items-center justify-between">
                                                    <a
                                                        href={`tel:${patient.phoneNumber}`}
                                                    >
                                                        {patient.phoneNumber}
                                                    </a>
                                                    <i className="fa fa-phone" />
                                                </div>
                                            ) : (
                                                <i className="fa fa-times pl-2 pt-2" />
                                            )}
                                        </div>
                                        {patient.patientsAddresses && (
                                            <div className="flex w-full flex-col space-y-4">
                                                {patient.patientsAddresses.map(
                                                    (savedAddress, key) => (
                                                        <div
                                                            key={key}
                                                            onClick={() =>
                                                                openMaps(
                                                                    savedAddress.address
                                                                )
                                                            }
                                                            className="flex flex-col space-y-1"
                                                        >
                                                            <p className="font-bold ">
                                                                {
                                                                    savedAddress.label
                                                                }
                                                            </p>
                                                            <p className="cursor-pointer">
                                                                {savedAddress
                                                                    .address
                                                                    .street
                                                                    ? savedAddress
                                                                          .address
                                                                          .street +
                                                                      ', '
                                                                    : ''}
                                                                {savedAddress
                                                                    .address
                                                                    .secondaryStreet
                                                                    ? savedAddress
                                                                          .address
                                                                          .secondaryStreet +
                                                                      ', '
                                                                    : ''}
                                                                <span className="underline">
                                                                    {
                                                                        savedAddress
                                                                            .address
                                                                            .city
                                                                    }
                                                                </span>
                                                            </p>
                                                            {savedAddress.phoneNumber && (
                                                                <div className="flex items-center justify-between">
                                                                    <a
                                                                        href={`tel:${savedAddress.phoneNumber}`}
                                                                    >
                                                                        {
                                                                            savedAddress.phoneNumber
                                                                        }
                                                                    </a>
                                                                    <i className="fa fa-phone" />
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'w-full py-10'}>
                        {pec.loading ? (
                            <p
                                className={
                                    'flex w-full flex-col items-center justify-center text-center text-lg text-light-dark-green'
                                }
                            >
                                Connexion au service d'aide à la prise en charge
                                de l'Assurance Maladie
                                <InlineButtonLoader />
                            </p>
                        ) : !pec.loading && pec.result ? (
                            <div className={'flex flex-col'}>
                                <PecResponse data={pec.result} />
                            </div>
                        ) : null}
                    </div>
                    {/*{xmlRequest && xmlResponse ? (*/}
                    {/*    <div className="flex w-full flex-col space-y-6 rounded bg-blue-100 p-4">*/}
                    {/*        <span className="text-xl">*/}
                    {/*            Affichage des requetes xml dans le cadre des*/}
                    {/*            tests de verification du CNDA*/}
                    {/*        </span>*/}
                    {/*        <details className="group">*/}
                    {/*            <summary className="flex cursor-pointer  items-center justify-between ">*/}
                    {/*                <p className="text-lg font-semibold">*/}
                    {/*                    Requête d'envoi*/}
                    {/*                    <button*/}
                    {/*                        title="Copier la requête dans le presse-papier"*/}
                    {/*                        onClick={() =>*/}
                    {/*                            handleCopyClick(requestRef)*/}
                    {/*                        }*/}
                    {/*                        className="far fa-clone ml-4 rounded-lg p-2 text-xl focus:outline-none"*/}
                    {/*                    />*/}
                    {/*                </p>*/}
                    {/*                <span className="transition group-open:rotate-180">*/}
                    {/*                    <svg*/}
                    {/*                        fill="none"*/}
                    {/*                        height="25"*/}
                    {/*                        shape-rendering="geometricPrecision"*/}
                    {/*                        stroke="currentColor"*/}
                    {/*                        stroke-linecap="round"*/}
                    {/*                        stroke-linejoin="round"*/}
                    {/*                        stroke-width="1.5"*/}
                    {/*                        viewBox="0 0 24 24"*/}
                    {/*                        width="25"*/}
                    {/*                    >*/}
                    {/*                        <path d="M6 9l6 6 6-6"></path>*/}
                    {/*                    </svg>*/}
                    {/*                </span>*/}
                    {/*            </summary>*/}
                    {/*            <div className="group-open:animate-fadeIn mt-3 ">*/}
                    {/*                <SyntaxHighlighter*/}
                    {/*                    language="xml"*/}
                    {/*                    style={solarizedlight}*/}
                    {/*                    showLineNumbers={true}*/}
                    {/*                >*/}
                    {/*                    {xmlRequest}*/}
                    {/*                </SyntaxHighlighter>*/}
                    {/*            </div>*/}
                    {/*        </details>*/}
                    {/*        <details className="group ">*/}
                    {/*            <summary className="flex cursor-pointer  items-center justify-between ">*/}
                    {/*                <p className="text-lg font-semibold">*/}
                    {/*                    Réponse PEC+*/}
                    {/*                    <button*/}
                    {/*                        title="copier la requête dans le presse-papier"*/}
                    {/*                        onClick={() =>*/}
                    {/*                            handleCopyClick(responseRef)*/}
                    {/*                        }*/}
                    {/*                        className="far fa-clone ml-4 rounded-lg p-2 text-xl focus:outline-none"*/}
                    {/*                    />*/}
                    {/*                </p>*/}
                    {/*                <span className="transition group-open:rotate-180">*/}
                    {/*                    <svg*/}
                    {/*                        fill="none"*/}
                    {/*                        height="25"*/}
                    {/*                        shape-rendering="geometricPrecision"*/}
                    {/*                        stroke="currentColor"*/}
                    {/*                        stroke-linecap="round"*/}
                    {/*                        stroke-linejoin="round"*/}
                    {/*                        stroke-width="1.5"*/}
                    {/*                        viewBox="0 0 24 24"*/}
                    {/*                        width="25"*/}
                    {/*                    >*/}
                    {/*                        <path d="M6 9l6 6 6-6"></path>*/}
                    {/*                    </svg>*/}
                    {/*                </span>*/}
                    {/*            </summary>*/}
                    {/*            <div className="group-open:animate-fadeIn mt-3 ">*/}
                    {/*                <SyntaxHighlighter*/}
                    {/*                    language="xml"*/}
                    {/*                    style={solarizedlight}*/}
                    {/*                    showLineNumbers={true}*/}
                    {/*                >*/}
                    {/*                    {xmlResponse}*/}
                    {/*                </SyntaxHighlighter>*/}
                    {/*            </div>*/}
                    {/*        </details>*/}
                    {/*    </div>*/}
                    {/*) : null}*/}
                    <p className="w-full border-t pt-4">
                        Attention, le présent taux de prise en charge n’est
                        applicable que si le transport est en lien avec une
                        situation permettant son remboursement par l’Assurance
                        Maladie
                    </p>
                    <div className={'mt-top flex w-full justify-between pt-10'}>
                        <SecondaryButton
                            label="Annuler"
                            title="Annuler"
                            action={() =>
                                patientDispatch({
                                    type: PATIENT_ACTIONS.SHOW_PEC_MODAL,
                                    payload: false,
                                })
                            }
                        />
                        <PrimaryButton
                            label={pec.result ? 'Renvoyer' : 'Consulter'}
                            title={pec.result ? 'Renvoyer' : 'Consulter'}
                            action={handleForm}
                        />
                    </div>
                </div>
            </div>

            <div>
                <textarea
                    className="copy"
                    autoCorrect="off"
                    autoCapitalize="off"
                    readOnly
                    tabIndex="-1"
                    autoComplete="off"
                    ref={requestRef}
                    value={xmlRequest}
                />
                <textarea
                    className="copy"
                    autoCorrect="off"
                    autoCapitalize="off"
                    readOnly
                    tabIndex="-1"
                    autoComplete="off"
                    ref={responseRef}
                    value={xmlResponse}
                />
            </div>
            {pec.showLoginModal && (
                <PecLoginModal pec={pec} dispatch={pecDispatch} />
            )}
        </div>
    )
}
