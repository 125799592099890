import { apiClient } from '../apiClient'
import { formatHydraData } from '../../api'

const PATH_KEY = '/health_complementaries'

export const HealthComplementaries = {
    fetchAll: async () => {
        let path = PATH_KEY + '?pagination=false'
        return formatHydraData(await apiClient.get(path))
    },
}
