import React, { useCallback, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useLongPress } from 'use-long-press'
import TOOLS from '../../../services/tools'
import MobileOption from '../../Commons/MobileOption'
import DefaultOptions from '../../Commons/DefaultOptions'
import Loader from '../../Commons/Loaders/Loader'
import * as dayjs from 'dayjs'

const InvoiceList = ({ invoices, loading, totalItems }) => {
    let history = useNavigate()
    const location = useLocation()
    const [showMobileOptions, setShowMobileOptions] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [selectedRowId, setSelectedRowId] = useState(null)

    const historyPush = (id) => {
        history(location.pathname + '/consultation/' + id)
    }

    const historyPushUpdate = (id) => {
        history(location.pathname + '/' + id)
    }

    const optionsRef = useRef()

    TOOLS.useOnClickOutside(optionsRef, () => {
        setShowOptions(false)
    })

    const callback = useCallback((event) => {}, [])

    const bind = useLongPress(callback, {
        onStart: (event) => {
            setSelectedRowId(parseInt(event.currentTarget.id))
        },
        onFinish: (event) => setShowMobileOptions(true),
        onCancel: (event) => {
            setSelectedRowId(null)
            setShowMobileOptions(false)
        },
        onMove: (event) => console.log('Detected mouse or touch movement'),
        threshold: 300,
        captureEvent: true,
        cancelOnMovement: true,
        detect: 'touch',
    })

    return (
        <div className="px-2 xl:pt-5">
            <div className="flex py-2">
                <div className="ml-auto text-xs font-bold">
                    {totalItems} Factures
                </div>
            </div>
            {loading ? (
                <Loader />
            ) : (
                <div className="flex w-full flex-col space-y-2 lg:space-y-0 ">
                    {invoices.map((invoice) => (
                        <div
                            className="parents relative flex w-full pb-2"
                            key={invoice.id}
                            {...bind}
                            onClick={() => historyPush(invoice.id)}
                        >
                            <div
                                className={`grid w-full  cursor-pointer select-none rounded bg-white shadow-mobile lg:select-auto`}
                            >
                                <div
                                    className={`parents row-span-1 flex grid-rows-1  flex-wrap items-center justify-between px-2 py-2  text-sm lg:text-base`}
                                >
                                    <div className="w-1/2 truncate sm:w-1/4 ">
                                        {dayjs(invoice.date)
                                            .local()
                                            .format('DD/MM/YYYY')}
                                    </div>
                                    <div className="w-1/2 truncate font-bold sm:w-1/4 ">
                                        {invoice.patient.lastname}{' '}
                                        {invoice.patient.firstname}
                                    </div>
                                    <div className="w-1/2 truncate text-center sm:w-1/4">
                                        <div>
                                            {dayjs(invoice.dateFrom)
                                                .local()
                                                .format('DD/MM/YY')}{' '}
                                            au{' '}
                                            {dayjs(invoice.dateTo)
                                                .local()
                                                .format('DD/MM/YY')}
                                        </div>
                                        <span className="font-semibold">
                                            {invoice.totalIncludingTaxes.toFixed(
                                                2
                                            )}{' '}
                                            €
                                        </span>
                                    </div>
                                    <div className=" w-1/2 truncate text-center sm:w-1/4 ">
                                        <span
                                            className={`flex inline-flex w-32 max-w-full justify-center rounded-full px-2 text-base font-semibold leading-5 text-white ${
                                                invoice.status.label ===
                                                'Réglée'
                                                    ? 'bg-purple-500 '
                                                    : invoice.status.label ===
                                                      'Créée'
                                                    ? 'bg-gray-400 '
                                                    : invoice.status.label ===
                                                      'Transmise'
                                                    ? 'bg-blue-400  '
                                                    : 'bg-orange-400 '
                                            }`}
                                        >
                                            {invoice.status.label}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`absolute right-0 hidden h-10 cursor-pointer rounded pt-2 xl:block   ${
                                    invoice.id === selectedRowId
                                        ? null
                                        : 'options '
                                } `}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowOptions(true)
                                    setSelectedRowId(invoice.id)
                                }}
                            >
                                <i
                                    className={`fas fa-ellipsis-h w-20 pr-4 pt-1 text-right ${
                                        showOptions ? 'hidden' : ''
                                    } `}
                                />
                                {invoice.id === selectedRowId && showOptions ? (
                                    <DefaultOptions
                                        setShowMobileOptions={setShowOptions}
                                        rowId={selectedRowId}
                                        optionsRef={optionsRef}
                                        historyPushUpdate={historyPushUpdate}
                                        historyPush={historyPush}
                                        view={true}
                                    />
                                ) : null}
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {showMobileOptions ? (
                <MobileOption
                    setShowMobileOptions={setShowMobileOptions}
                    rowId={selectedRowId}
                    historyPush={historyPush}
                    view={true}
                />
            ) : null}
        </div>
    )
}

export default InvoiceList
