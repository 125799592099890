import React from 'react'
import TimeField from 'react-simple-timefield'

const ClassicIntervalTimePickers = ({
    departureTime,
    handleDepartureTime,
    arrivingTime,
    handleArrivingTime,
    showIcon,
    showLabel,
    error,
    smSize,
}) => {
    return (
        <div className="flex w-full ">
            <div className="inline-block ">
                <label
                    className={`block text-xs font-bold uppercase tracking-wide text-gray-700 ${
                        showLabel ? '' : 'hidden'
                    }`}
                >
                    Heure de départ
                </label>
                <TimeField
                    value={departureTime.toLocaleTimeString('fr-FR')}
                    onChange={(event, value) => handleDepartureTime(value)}
                    input={
                        <input
                            className={`${
                                smSize ? 'w-20' : 'w-32'
                            } block flex h-10 appearance-none border-b-2 text-center leading-tight text-gray-700 focus:outline-none ${
                                error
                                    ? 'border-red-500'
                                    : 'border-lighter-blue-green'
                            }`}
                        />
                    }
                    colon=":"
                />
            </div>
            <div className="flex items-center justify-center px-3 pt-3 ">:</div>
            <div className="">
                <label
                    className={`block text-xs font-bold uppercase tracking-wide text-gray-700 ${
                        showLabel ? '' : 'hidden'
                    }`}
                >
                    Heure d'arrivée
                </label>
                <TimeField
                    value={arrivingTime.toLocaleTimeString('fr-FR')}
                    input={
                        <input
                            className={`${
                                smSize ? 'w-20' : 'w-32'
                            } block flex h-10 appearance-none border-b-2 text-center leading-tight text-gray-700 focus:outline-none ${
                                error
                                    ? 'border-red-500'
                                    : 'border-lighter-blue-green'
                            }`}
                        />
                    }
                    onChange={(event, value) => handleArrivingTime(value)}
                    colon=":"
                />
            </div>
        </div>
    )
}

export default ClassicIntervalTimePickers
