export const NatureAssurances = [
    { value: '10', label: '10 Maladie' },
    { value: '13', label: '13 Maladie régime local (Alsace-Moselle)' },
    { value: '30', label: '30 Maternité' },
    { value: '41', label: '41 Accident du travail' },
    { value: '90', label: '90 Prévention maladie' },
]

export const Exonerations = [
    { value: '0', label: '0 Pas d’exonération' },
    {
        value: '1',
        label: '1 Soins en rapport avec un K ou un KC = ou > 50 (NGAP)',
    },
    {
        value: '2',
        label: '2 Soins relatifs à un séjour > 30 jours (réservé à un établissement)',
    },
    { value: '3', label: '3 Soins particuliers exonérés' },
    { value: '4', label: '4 Soins conformes au protocole ALD' },
    {
        value: '5',
        label: '5 Assuré ou bénéficiaire exonéré (régime exonérant)',
    },
]

export const Taux = [
    { value: '100', label: '100%' },
    { value: '65', label: '65%' },
    { value: '55', label: '55%' },
]

export const getTauxFromDate = (date) => {
    const changementDate = new Date('2023-08-01')
    // on check si la date est valide sinon on retourne les taux par défaut
    if (!date) {
        return Taux
    }
    // si la date est inférieur au 1 aout 2023 alors les taux possibles sont 100% et 65%
    if (date < changementDate) {
        return [
            { value: '100', label: '100%' },
            { value: '65', label: '65%' },
        ]
    } else {
        return [
            { value: '100', label: '100%' },
            { value: '55', label: '55%' },
        ]
    }
}
