import React from 'react'
import dayjs from 'dayjs'

const Times = ({ row, isComputer }) => {
    return (
        <div
            className={`flex w-1/6 items-center align-middle justify-between ${
                isComputer ? 'px-2 text-lg' : 'text-base pt-2 '
            } ${row.approximateTime && row.isReturnPath && 'italic'}`}
        >
            <div
                className={`flex justify-center w-full ${
                    isComputer ? 'flex-row' : 'flex-col'
                }`}
            >
                <div className="flex justify-center">
                    {dayjs(row.departureTime)
                        .local()
                        .format('HH:mm')
                        .replace(':', 'h')}
                </div>
                <div className={`${isComputer ? 'block' : 'hidden'}`}>-</div>
                <div className="flex justify-center">
                    {dayjs(row.arrivingTime)
                        .local()
                        .format('HH:mm')
                        .replace(':', 'h')}
                </div>
                <div className="flex items-center">
                    {row.userComments && row.isUserCommentsRed ? (
                        <i
                            className={`fas fa-comment-slash pl-2 ${
                                isComputer ? 'block' : 'hidden'
                            }`}
                        />
                    ) : row.userComments && !row.isUserCommentsRed ? (
                        <i
                            className={`far fa-comment pl-2 ${
                                isComputer ? 'block' : 'hidden'
                            }`}
                        />
                    ) : (
                        <span className="w-6 sm:hidden" />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Times
