import UppercaseLabel from '../../../Commons/UppercaseLabel'
import FormSwitch from '../../../Commons/Buttons/FormSwitch'
import { SERIES_ACTIONS } from '../../../../services/Reducers/SeriesReducer'
import CalendarDatePicker from '../../../Commons/CalendarDatePickers/CalendarDatePicker'
import { ArrowDown, ArrowRight, Plus, RefreshDouble } from 'iconoir-react'
import FormInput from '../../../Commons/Inputs/FormInput'
import { onlyNumbers } from '../../../../tools/Utility'
import React, { useRef } from 'react'
import SelectDropDownListV2 from '../../../Commons/DropDownLists/SelectDropDownListV2'
import * as dayjs from 'dayjs'
import DaysConfigurator from './DaysConfigurator'
import CardSeriesRun from './CardSeriesRun'
import classNames from 'classnames'
import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'

const frequencies = [
    { value: 0, label: 'Toutes les semaines' },
    { value: 1, label: 'Toutes les 2 semaines' },
    { value: 2, label: 'Toutes les 3 semaines' },
]

const SeriesConfigurator = ({
    series,
    dispatch,
    pickUpAddress,
    depositAddress,
}) => {
    const addMenuRef = useRef()
    const handleOpenSelectStartDatePicker = (value) => {
        dispatch({
            type: SERIES_ACTIONS.SET_OPEN_SELECT_START_DATE_PICKER,
            payload: value,
        })
    }
    const handleOpenSelectEndDatePicker = (value) => {
        dispatch({
            type: SERIES_ACTIONS.SET_OPEN_SELECT_END_DATE_PICKER,
            payload: value,
        })
    }

    const addDayToCollection = () => {
        if (series.days.length < 7) {
            //addNewRun
            const previousDay = series.days[series.days.length - 1]

            const newDay = {
                day: series.daysOptions[0].value,
                departureTime: previousDay.departureTime,
                arrivingTime: previousDay.arrivingTime,
                returnTime: previousDay.returnTime,
                depositLocation: previousDay.depositLocation,
                pickUpLocation: previousDay.pickUpLocation,
                differentReturnDeposit: false,
                returnDepositLocation: null,
                comments: previousDay.comments,
                isReturnPath: previousDay.isReturnPath,
                waitingTime: previousDay.waitingTime,
                isRoundTrip: previousDay.isRoundTrip,
                isDirectReturn: previousDay.isDirectReturn,
                runType: previousDay.runType,
            }
            dispatch({
                type: SERIES_ACTIONS.ADD_DAY,
                payload: newDay,
            })
        }
    }

    const handleStartDate = (date) => {
        const days = series.days.map((d, key) => {
            if (key === 0) {
                d.day = dayjs(date).weekday()
            } else if (d.day === dayjs(date).weekday()) {
                d.day = series.daysOptions[0].value
            }
            return d
        })

        const daysOptions = series.daysDefaultList.filter(
            (dd) => !days.some((d) => d.day === dd.value)
        )

        dispatch({
            type: SERIES_ACTIONS.SET_START_DATE,
            payload: { date: date, days: days, daysOptions: daysOptions },
        })
    }

    // Order days
    // First is start date.
    const orderDays = () => {
        const runToSort = series.days
        const startDateDayOfWeek = dayjs(series.firstRunDate).weekday()
        const objectDayOfWeek = series.daysDefaultList.find(
            (d) => d.value === startDateDayOfWeek
        )

        const sortedList = series.daysDefaultList
            .slice(objectDayOfWeek.value)
            .concat(series.daysDefaultList.slice(0, objectDayOfWeek.value))

        return runToSort.sort(function (a, b) {
            return sortedList.findIndex((el) => el.value === a.day) >
                sortedList.findIndex((el) => el.value === b.day)
                ? 1
                : -1
        })
    }

    const newTime = (date, time) => {
        const newDate = new Date(date)
        newDate.setHours(time.getHours())
        newDate.setMinutes(time.getMinutes())
        return newDate
    }

    const generateRunsToInsert = () => {
        const runs = []

        // sort days
        const sortedDays = orderDays()

        //add by dates intervals
        let incrementalDate = new Date(series.firstRunDate)

        let runKey = 1
        let i = 0

        while (
            series.byIntervalDates
                ? incrementalDate.getTime() <= series.lastRunDate.getTime()
                : runKey <= series.nbRuns
        ) {
            const day = sortedDays[i]
            let newDepartureTime = newTime(incrementalDate, day.departureTime)
            let newArrivingTime = newTime(incrementalDate, day.arrivingTime)
            let newReturnTime = day.returnTime
                ? newTime(incrementalDate, day.returnTime)
                : null

            let correspondingRun = null
            let correspondingRunIsLocked = false
            if (series.id) {
                //update
                correspondingRun = series.runsToInsert.find(
                    (run) =>
                        run.dateString ===
                        incrementalDate.toLocaleDateString('fr-FR')
                )

                correspondingRunIsLocked =
                    correspondingRun && correspondingRun.status !== 'todo'

                if (correspondingRun && correspondingRunIsLocked) {
                    newDepartureTime = newTime(
                        correspondingRun.date,
                        correspondingRun.departureTime
                    )
                    newArrivingTime = newTime(
                        correspondingRun.date,
                        correspondingRun.arrivingTime
                    )
                    if (correspondingRun.returnTime) {
                        newReturnTime = newTime(
                            correspondingRun.date,
                            correspondingRun.returnTime
                        )
                    }
                }
            }
            const run = {
                key: runKey,
                id: correspondingRun?.id,
                returnRunId: correspondingRun?.returnRunId,
                date: incrementalDate,
                dateString: incrementalDate.toLocaleDateString('fr-FR'),
                departureTime: newDepartureTime,
                arrivingTime: newArrivingTime,
                status:
                    (correspondingRunIsLocked && correspondingRun?.status) ||
                    'todo',
                returnStatus:
                    (correspondingRunIsLocked &&
                        correspondingRun?.returnStatus) ||
                    'todo',
                returnTime: newReturnTime,
                team:
                    (correspondingRunIsLocked && correspondingRun?.team) ||
                    null,
                defaultMasterRunId:
                    (correspondingRunIsLocked &&
                        correspondingRun?.defaultMasterRunId) ||
                    null,
                isReturnPath: false,
                isRoundTrip: false,
                returnRunSharedAndGiven:
                    correspondingRun?.returnRunSharedAndGiven,
                departureOrder: correspondingRun?.departureOrder,
                parentId: correspondingRun?.parentId,
                returnDepartureOrder: correspondingRun?.returnDepartureOrder,
                returnParentId: correspondingRun?.returnParentId,
            }

            if (day.isRoundTrip) {
                run.isRoundTrip = true
                run.haveIndirectReturn = !day.isDirectReturn
                run.haveDirectReturn = day.isDirectReturn

                run.returnTimeError = newReturnTime <= newArrivingTime
                run.waitingTime = day.waitingTime
            }

            if (!day.isRoundTrip && day.isReturnPath) {
                //simple return run
                run.isReturnPath = true
            }

            runs.push(run)

            if (
                !sortedDays.some(
                    (d) => d.day > dayjs(incrementalDate).weekday()
                )
            ) {
                //skip weeks if needed
                incrementalDate = new Date(
                    dayjs(incrementalDate).add(addRepetitionsDays(), 'day')
                )
            }

            i += 1
            if (i >= sortedDays.length) {
                i = 0
            }

            incrementalDate = getDayOfTheWeek(
                series.daysDefaultList.find(
                    (d) => d.value === sortedDays[i].day
                ).enLabel,
                true,
                incrementalDate
            )

            runKey += day.isRoundTrip ? 2 : 1
        }

        dispatch({
            type: SERIES_ACTIONS.SET_RUNS_TO_INSERT,
            payload: runs,
        })
    }

    const getDayOfTheWeek = (
        dayName,
        excludeToday = true,
        date = series.firstRunDate
    ) => {
        const refDate = new Date(date)
        const dayOfWeek = [
            'sun',
            'mon',
            'tue',
            'wed',
            'thu',
            'fri',
            'sat',
        ].indexOf(dayName.slice(0, 3).toLowerCase())
        if (dayOfWeek < 0) return
        refDate.setHours(0, 0, 0, 0)
        refDate.setDate(
            refDate.getDate() +
                +excludeToday +
                ((dayOfWeek + 7 - refDate.getDay() - excludeToday) % 7)
        )
        return refDate
    }

    const addRepetitionsDays = () => {
        return series.frequency.value * 7
    }

    const addNewRunToInsert = (runTypeValue) => {
        /**
         * Add new runs to insert - All run types
         */

        let key = 1
        let date = new Date(
            dayjs(new Date(series.days[0].date)).add('1', 'day')
        )
        let dateString = date.toLocaleDateString('fr-FR')
        let departureTime = new Date(
            dayjs(new Date(series.days[0].departureTime)).add('1', 'day')
        )
        let arrivingTime = new Date(
            dayjs(new Date(series.days[0].arrivingTime)).add('1', 'day')
        )

        if (series.runsToInsert.length > 0) {
            const previousRun =
                series.runsToInsert[series.runsToInsert.length - 1]

            key = previousRun.key + 1
            date = new Date(dayjs(new Date(previousRun.date)).add('1', 'day'))
            dateString = date.toLocaleDateString('fr-FR')
            departureTime = new Date(
                dayjs(new Date(previousRun.departureTime)).add('1', 'day')
            )
            arrivingTime = new Date(
                dayjs(new Date(previousRun.arrivingTime)).add('1', 'day')
            )
        }

        const newRun = {
            key: key,
            date: date,
            dateString: dateString,
            departureTime: departureTime,
            arrivingTime: arrivingTime,
            returnTime: null,
            isRoundTrip: false,
            haveIndirectReturn: false,
            haveDirectReturn: false,
            isReturnPath: runTypeValue === 2,
            status: 'todo',
        }

        if (runTypeValue === 3) {
            newRun.haveIndirectReturn = false
            newRun.haveDirectReturn = true
            newRun.isRoundTrip = true

            newRun.waitingTime = series.days[0].waitingTime
        } else if (runTypeValue === 4) {
            newRun.haveIndirectReturn = true
            newRun.haveDirectReturn = false
            newRun.isRoundTrip = true

            let newReturnTime = new Date(
                dayjs(new Date(arrivingTime)).add('1', 'hour')
            )

            newRun.returnTime = newReturnTime
        }

        dispatch({
            type: SERIES_ACTIONS.ADD_NEW_RUN_TO_INSERT,
            payload: newRun,
        })
    }

    const someRunAreLocked = series.runsToInsert.some(
        (run) => run.status !== 'todo'
    )

    return (
        <div className="min-h-row-date flex w-full flex-col flex-wrap space-y-5 rounded bg-white p-2 pb-10 text-lg shadow-md lg:p-5 lg:text-base">
            <div className="flex flex-col space-y-4 lg:flex-row lg:space-y-0">
                <div className="flex w-full flex-col space-y-4 lg:w-1/3">
                    <UppercaseLabel label="Type d'ajout des transports" />
                    <FormSwitch
                        leftLabel="Dates"
                        rightLabel="Quantité"
                        valueLeft={series.byIntervalDates}
                        valueRight={!series.byIntervalDates}
                        onClickLeft={() =>
                            dispatch({
                                type: SERIES_ACTIONS.SET_BY_DATES_INTERVAL,
                                payload: true,
                            })
                        }
                        onClickRight={() =>
                            dispatch({
                                type: SERIES_ACTIONS.SET_BY_DATES_INTERVAL,
                                payload: false,
                            })
                        }
                    />
                </div>
                <div className="w-full">
                    {series.byIntervalDates ? (
                        <div className="flex flex-col space-y-4">
                            <UppercaseLabel label="Intervalle" />
                            <div className="flex flex-row items-center space-x-2">
                                <CalendarDatePicker
                                    openSelectDatePicker={
                                        series.openSelectStartDatePicker
                                    }
                                    setOpenSelectDatePicker={
                                        handleOpenSelectStartDatePicker
                                    }
                                    date={series.firstRunDate}
                                    setDate={(date) => handleStartDate(date)}
                                    icon={false}
                                    fontSize="text-lg lg:text-sm"
                                    customDisplay="D MMM YYYY"
                                    disabled={someRunAreLocked}
                                />
                                <ArrowRight />

                                <CalendarDatePicker
                                    openSelectDatePicker={
                                        series.openSelectEndDatePicker
                                    }
                                    setOpenSelectDatePicker={
                                        handleOpenSelectEndDatePicker
                                    }
                                    date={series.lastRunDate}
                                    fontSize="text-lg lg:text-sm"
                                    setDate={(date) =>
                                        dispatch({
                                            type: SERIES_ACTIONS.SET_END_DATE,
                                            payload: date,
                                        })
                                    }
                                    icon={false}
                                    customDisplay="D MMM YYYY"
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="flex w-full flex-row space-x-10 lg:w-2/3">
                            <div className="flex flex-col space-y-4">
                                <UppercaseLabel label="Premier transport" />
                                <CalendarDatePicker
                                    openSelectDatePicker={
                                        series.openSelectStartDatePicker
                                    }
                                    setOpenSelectDatePicker={
                                        handleOpenSelectStartDatePicker
                                    }
                                    date={series.firstRunDate}
                                    fontSize="text-lg lg:text-sm"
                                    setDate={(date) => handleStartDate(date)}
                                    icon={false}
                                    customDisplay="D MMM YYYY"
                                    disabled={someRunAreLocked}
                                />
                            </div>
                            <div className="flex flex-col space-y-4">
                                <UppercaseLabel label="Nombre de transports" />
                                <div className="w-16">
                                    <FormInput
                                        value={series.nbRuns}
                                        inputMode={'numeric'}
                                        onChange={(e) =>
                                            dispatch({
                                                type: SERIES_ACTIONS.SET_NB_RUNS,
                                                payload: onlyNumbers(
                                                    e.target.value
                                                ),
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex flex-col space-y-2">
                <UppercaseLabel label="Fréquence" />
                <div className="w-full sm:w-1/2 lg:w-1/4">
                    <SelectDropDownListV2
                        options={frequencies}
                        value={series.frequency}
                        isClearable={false}
                        handleOptionChange={(e) =>
                            dispatch({
                                type: SERIES_ACTIONS.SET_FREQUENCY,
                                payload: e,
                            })
                        }
                        showLabel={true}
                        error={null}
                        isSearchable={false}
                    />
                </div>
            </div>
            <DaysConfigurator
                series={series}
                dispatch={dispatch}
                pickUpAddress={pickUpAddress}
                depositAddress={depositAddress}
            />
            <div className="pl-2">
                <PrimaryButton
                    label="Ajouter un jour"
                    title="Ajouter un jour"
                    action={addDayToCollection}
                    icon={<Plus className="text-xl" />}
                    hiddenLabelOnMobile={true}
                />
            </div>
            <div className="flex flex-col rounded-xl py-10">
                <div className="flex items-center justify-between">
                    <div className="flex w-full items-center justify-between space-x-2 pt-2">
                        <div className="flex items-center">
                            <ArrowDown className="text-2xl" />
                            <h2 className="pr-2 text-2xl">
                                Détails des transports
                            </h2>
                        </div>
                        <div
                            className={classNames('flex justify-center', {
                                'animate-bounce': series.needsRefresh,
                            })}
                        >
                            <PrimaryButton
                                label="Actualiser"
                                title="Actualiser"
                                action={generateRunsToInsert}
                                icon={<RefreshDouble />}
                                hiddenLabelOnMobile={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {series.runsToInsert.length > 0 ? (
                <div>
                    <div className="grid w-full gap-4 px-2 py-5 lg:grid-cols-2">
                        {series.runsToInsert.map((run, index) => (
                            <CardSeriesRun
                                run={run}
                                teams={series.teams}
                                index={index}
                                dispatch={dispatch}
                            />
                        ))}
                    </div>
                    <div className="px-2 py-5">
                        <div
                            className="group relative flex w-fit text-left"
                            tabIndex="-1"
                        >
                            <span className="rounded-md shadow-sm">
                                <PrimaryButton
                                    label="Ajouter"
                                    title="Ajouter un transport"
                                    action={() =>
                                        dispatch({
                                            type: SERIES_ACTIONS.SET_OPEN_ADD_MENU,
                                            payload: true,
                                        })
                                    }
                                />
                            </span>
                            {series.openAddMenu && (
                                <div className="invisible origin-top-right -translate-y-2 scale-95 transform opacity-0 transition-all duration-300 group-focus-within:visible group-focus-within:translate-y-0 group-focus-within:scale-100 group-focus-within:opacity-100">
                                    <div
                                        className={`letf-0 absolute ml-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none`}
                                        role="menu"
                                    >
                                        <button
                                            role="menuitem"
                                            onClick={() => addNewRunToInsert(1)}
                                            className="flex w-full justify-between px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:bg-gray-100 focus-visible:outline-1 focus-visible:outline-light-blue-green"
                                        >
                                            Aller simple
                                        </button>
                                        <button
                                            onClick={() => addNewRunToInsert(2)}
                                            className="flex w-full justify-between px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:bg-gray-100 focus-visible:outline-1 focus-visible:outline-blue-500"
                                            role="menuitem"
                                        >
                                            Retour simple
                                        </button>
                                        <button
                                            onClick={() => addNewRunToInsert(3)}
                                            className="flex w-full justify-between px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:bg-gray-100 focus-visible:outline-1 focus-visible:outline-blue-500"
                                            role="menuitem"
                                        >
                                            Aller-retour direct
                                        </button>
                                        <button
                                            onClick={() => addNewRunToInsert(4)}
                                            className="flex w-full justify-between px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:bg-gray-100 focus-visible:outline-1 focus-visible:outline-blue-500"
                                            role="menuitem"
                                        >
                                            Aller-retour indirect
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div>Aucun transport, veuillez actualiser.</div>
            )}
        </div>
    )
}

export default SeriesConfigurator
