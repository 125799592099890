import React, { useEffect, useReducer, useState } from 'react'
import ClassicInput from '../../Commons/Inputs/ClassicInput'
import API from '../../../services/api'
import { useNavigate, useParams } from 'react-router-dom'
import ManageTitle from '../../Commons/ManageTitle'
import SendFormButtons from '../../Commons/Buttons/SendFormButtons'
import Loader from '../../Commons/Loaders/Loader'
import useConfiguratorStore from '../../../stores/Configurator'
import ClassicTextarea from '../../Commons/Inputs/ClassicTextarea'
import {
    ACTIONS,
    PartnerReducer,
    getInitialState,
} from '../../../services/Reducers/PartnerReducer'
import { ErrorReducer } from '../../../services/Reducers/ErrorReducer'
import Error from '../../Commons/Error'
import { toast } from 'react-toastify'

const Manage = () => {
    const [errors, dispatchError] = useReducer(ErrorReducer, [])
    const [partner, dispatch] = useReducer(PartnerReducer, [], getInitialState)

    const setFilterPartner = useConfiguratorStore(
        (state) => state.setFilterPartner
    )
    const setFilterPatient = useConfiguratorStore(
        (state) => state.setFilterPatient
    )
    let history = useNavigate()
    let { id } = useParams()
    id = parseInt(id)

    const checkErrors = () => {
        let error = false
        if (partner.firstname.trim() === '') {
            toast.error('Veuillez renseigner un prénom')
            error = true
        }
        if (partner.lastname.trim() === '') {
            toast.error('Veuillez renseigner un nom.')
            error = true
        }
        if (partner.email && partner.email.trim() !== '') {
            if (!partner.email.includes('@')) {
                toast.error('Veuillez renseigner un email valide.')
                error = true
            }
        }

        if (partner.phoneNumber.trim() === '') {
            toast.error('Veuillez renseigner un numéro de téléphoneNumber.')
            error = true
        } else if (partner.phoneNumber.trim().length !== 10) {
            toast.error(
                'Veuillez renseigner un numéro de téléphoneNumber valide.'
            )
            error = true
        }
        return error
    }

    const handleForm = () => {
        if (!checkErrors()) {
            handleLoading(true)
            const data = {
                firstname: partner.firstname,
                lastname: partner.lastname,
                email: partner.email,
                phoneNumber: partner.phoneNumber,
                description: partner.description,
            }

            if (id === 0) {
                API.Partners.addPartner(data)
                    .then((response) => {
                        toast.success('Partenaire ajouté.')
                        historyPush()
                    })
                    .catch((error) => {
                        handleLoading(false)
                    })
            } else {
                API.Partners.put(id, partner)
                    .then((response) => {
                        toast.success('Partenaire modifié.')
                        historyPush()
                    })
                    .catch((error) => {
                        handleLoading(false)
                    })
            }
        }
    }

    const historyPush = () => {
        history(-1)
    }

    const getPartner = () => {
        API.Partners.get(id).then((response) => {
            response.json().then((data) => {
                const partner = {
                    firstname: data.firstname,
                    lastname: data.lastname,
                    email: data.email,
                    description: data.description,
                    phoneNumber: data.phoneNumber
                        ? data.phoneNumber.replace(/\s+/g, '')
                        : '',
                    loading: false,
                }
                handlePartner(partner)
            })
        })
    }

    useEffect(() => {
        if (id !== 0) {
            getPartner()
        } else {
            handleLoading(false)
        }
    }, [])

    function handleShowPartnersRuns() {
        setFilterPartner({
            value: '/ressources/partners/' + id,
            label: partner.firstname + partner.lastname,
        })
        setFilterPatient({})
        history('/transport')
    }

    const handlePartner = (partner) => {
        dispatch({ type: ACTIONS.SET_PARTNER, payload: partner })
    }

    const handleFirstname = (value) => {
        dispatch({ type: ACTIONS.SET_FIRSTNAME, payload: value })
    }

    const handleLastname = (value) => {
        dispatch({ type: ACTIONS.SET_LASTNAME, payload: value })
    }

    const handlePhone = (value) => {
        dispatch({ type: ACTIONS.SET_PHONE, payload: value })
    }
    const handleEmail = (value) => {
        dispatch({ type: ACTIONS.SET_EMAIL, payload: value })
    }
    const handleDescription = (value) => {
        dispatch({ type: ACTIONS.SET_DESCRIPTION, payload: value })
    }
    const handleLoading = (event) => {
        dispatch({ type: ACTIONS.SET_LOADING, payload: event })
    }

    return (
        <div className="min-height-90-x container mx-auto flex flex-col pb-20 pt-5 text-lg lg:pb-0 lg:text-sm">
            {partner.loading ? (
                <Loader />
            ) : (
                <div className="flex w-full flex-col rounded-xl bg-white px-5 py-10 shadow-md">
                    <ManageTitle label={partner.manageTitle} />
                    <div className="px-5 lg:px-20">
                        <div className="sm:grid sm:grid-cols-2 sm:gap-4 ">
                            <div className="col-span-1 py-2">
                                <ClassicInput
                                    label="Prénom *"
                                    value={partner.firstname}
                                    setValue={(e) =>
                                        handleFirstname(e.target.value)
                                    }
                                    id="firstname"
                                    placeholder="Jane"
                                    showLabel={true}
                                />
                            </div>
                            <div className="col-span-1 py-2">
                                <ClassicInput
                                    label="Nom de famille *"
                                    value={partner.lastname}
                                    setValue={(e) =>
                                        handleLastname(e.target.value)
                                    }
                                    id="lastname"
                                    placeholder="Dupont"
                                    showLabel={true}
                                />
                            </div>
                            <div className="col-span-1 py-2">
                                <ClassicInput
                                    label="Téléphone"
                                    type="tel"
                                    maxLength="10"
                                    value={partner.phoneNumber}
                                    setValue={(e) =>
                                        handlePhone(e.target.value)
                                    }
                                    id="phone"
                                    placeholder="XXXXXXXXX"
                                    showLabel={true}
                                />
                            </div>
                            <div className="col-span-1 py-2">
                                <ClassicInput
                                    label="Email"
                                    type="email"
                                    value={partner.email}
                                    setValue={(e) =>
                                        handleEmail(e.target.value)
                                    }
                                    id="Email"
                                    placeholder="email@"
                                    showLabel={true}
                                />
                            </div>
                            <div className="col-span-1 col-start-1 w-full pb-4 pt-2">
                                <ClassicTextarea
                                    value={partner.description}
                                    setValue={(e) =>
                                        handleDescription(e.target.value)
                                    }
                                    showLabel={true}
                                    label="Commentaire"
                                />
                            </div>
                            {id !== 0 && (
                                <div className="col-span-2 w-full pb-4">
                                    <button
                                        className="rounded bg-light-dark-green px-4 py-2 text-right font-bold text-white hover:bg-dark-green "
                                        onClick={handleShowPartnersRuns}
                                    >
                                        Courses du partenaire
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <SendFormButtons
                historyPush={historyPush}
                handleForm={handleForm}
            />
            <div className="fixed right-0 top-0 z-50 mr-5 flex flex flex-col space-y-2 text-gray-700">
                {errors.map((error) => {
                    return (
                        <Error
                            key={error.id}
                            error={error}
                            dispatch={dispatchError}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default Manage
