import React from 'react'

const PECBanner = ({ patientInfo, setShowBanner, showBanner }) => {
    return (
        <div
            className={`z-1000 fixed left-0 right-0 top-0 w-full transform cursor-pointer transition-all duration-500 ease-in-out ${
                showBanner
                    ? 'translate-y-0 opacity-100'
                    : '-translate-y-full opacity-0'
            } lg:left-5`}
            onClick={() => setShowBanner(false)}
        >
            <div className="hover:scale-102 mx-auto mt-4 max-w-sm overflow-hidden rounded-lg bg-secondary-color shadow-lg transition-transform duration-300">
                <div className="relative p-5">
                    <div className="flex flex-col space-y-2 text-white">
                        <p className="text-lg">
                            <strong className="font-bold">Caisse:</strong>{' '}
                            {patientInfo?.payingCenter.label}
                        </p>
                        <p className="text-lg">
                            <strong className="font-bold">ALD:</strong>{' '}
                            {patientInfo?.ald ? 'Oui' : 'Non'}
                        </p>
                        {patientInfo?.cmu && (
                            <p className="text-lg">Bénéficiaire de la CMU-C</p>
                        )}
                    </div>
                    <button
                        onClick={() => setShowBanner(false)}
                        className="absolute right-2 top-2 rounded-full p-1 transition-colors duration-200 hover:bg-white/20"
                    >
                        <svg
                            className="h-6 w-6 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PECBanner
