import React, { useRef, useState } from 'react'
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { ADDRESS_ACTIONS } from '../../services/Reducers/AddressAutoCompleteReducer'
import TOOLS from '../../services/tools'
import LoaderModal from '../Commons/Loaders/LoaderModal'
import { getAddressInfo } from '../../services/address'

import { toast } from 'react-toastify'
import { Cancel, NavArrowDown } from 'iconoir-react'

export const GoogleAutoComplete = ({
    savedAddresses = [],
    address,
    dispatch,
    label,
    ref,
    icon,
}) => {
    const [show, setShow] = useState(false)
    const [visible, setVisible] = useState('hidden')
    const isMobile = /iPhone|Android/i.test(navigator.userAgent)
    ref = useRef(ref)
    const targetElementRef = useRef(null)

    TOOLS.useOnClickOutside(ref, () => {
        dispatch({
            type: ADDRESS_ACTIONS.SHOW_PREDICTIONS,
            payload: false,
        })
    })
    const handleButtonFocus = () => {
        // if (isMobile) {
        // // Calcule la position en Y de l'élément cible
        // const targetElementPosition =
        //     targetElementRef.current.getBoundingClientRect().top +
        //     window.pageYOffset
        // console.log(targetElementPosition)
        // // Scroll vers l'élément cible pour le positionner en haut de l'écran mobile
        // window.scrollTo(targetElementPosition, 0)
        // }
    }
    const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
        useGoogle({
            apiKey: 'AIzaSyD6BE4lXa9Wlbg3VdlXarIXTBzMOCJT-nk',
            options: {
                componentRestrictions: {
                    country: ['fr', 'ch', 'be', 'es', 'mq'],
                },
                language: 'fr',
                region: 'fr',
            },
        })
    const addresses = [...placePredictions, ...savedAddresses]

    const handleAddressChange = (evt) => {
        getPlacePredictions({ input: evt.target.value })
        if (evt.target.value === '' || evt.target.value === null) {
            dispatch({
                type: ADDRESS_ACTIONS.DELETE_ADDRESS,
                payload: evt.target.value,
            })
        }
        dispatch({
            type: ADDRESS_ACTIONS.UPDATE_ADDRESS_FIELD,
            payload: evt.target.value,
        })
    }

    const handleClickOnPrediction = (prediction) => {
        getPlacePredictions('')
        prediction =
            prediction['@type'] === 'PatientsAddresses'
                ? prediction.address
                : prediction
        if (!prediction['@type']) {
            getAddressInfo(prediction).then((result) => {
                dispatch({
                    type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                    payload: result,
                })
            })
        } else {
            dispatch({
                type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                payload: prediction,
            })
        }
    }

    const displayAddress = (address) => {
        if (!address['@type']) {
            return address.description
        }
        const { label, street, secondaryStreet, city, zipCode } = address
        return [label, street, secondaryStreet, city, zipCode]
            .filter(Boolean)
            .join(', ')
    }

    const showAddressFields = () => {
        if (!show) {
            setVisible('')
            setTimeout(() => {
                setShow(!show)
            }, 1)
        } else {
            setShow(!show)
            setTimeout(() => {
                setVisible('hidden')
            }, 300)
        }
    }

    const checkErrors = () => {
        let error = false
        if (!address.city) {
            toast.error('Veuillez renseigner une ville', { autoClose: false })
            error = true
        }
        if (address.addressHasChanged) {
            toast.error(
                'Veuillez séléctionner une adresse ou utiliser les champs complémentaire'
            )
            error = true
        }

        return error
    }

    return (
        <div className="flex w-full flex-col" ref={targetElementRef}>
            <div className="relative flex">
                <div className="relative w-full pr-2 ">
                    <div className="flex space-x-2 ">
                        {icon && (
                            <i className="fas  fa-map-marker-alt fa-lg min-w-icon fa-lg" />
                        )}
                        <label className="mb-2 block text-base font-bold uppercase tracking-wide text-gray-700 lg:text-xs">
                            {label}
                        </label>
                    </div>
                    <div
                        className={`flex ${
                            show
                                ? 'bg-gray-200'
                                : 'bg-white focus-within:border-lighter-blue-green hover:border-lighter-blue-green'
                        } block w-full items-center rounded-md border px-3 leading-tight text-gray-700  `}
                    >
                        <input
                            className={`h-14 w-full appearance-none text-lg focus:outline-none lg:h-10 lg:text-sm ${
                                show ? 'bg-gray-200' : 'bg-white'
                            }`}
                            type="text"
                            placeholder="Rechercher une adresse..."
                            value={address.inputValue}
                            onClick={() => {
                                dispatch({
                                    type: ADDRESS_ACTIONS.SHOW_PREDICTIONS,
                                    payload: true,
                                })
                            }}
                            disabled={show}
                            onFocus={handleButtonFocus}
                            onChange={handleAddressChange}
                        />
                        {isPlacePredictionsLoading ? (
                            <LoaderModal
                                customWidth={'w-4'}
                                customHeight="h-4"
                            />
                        ) : (
                            <Cancel
                                className="fa fa-times my-2 ml-2 flex cursor-pointer justify-center text-gray-400 hover:text-gray-600"
                                onClick={() => {
                                    dispatch({
                                        type: ADDRESS_ACTIONS.DELETE_ADDRESS,
                                    })
                                }}
                            />
                        )}
                    </div>
                    {address.showPredictions ? (
                        <div
                            className=" absolute z-50 mr-4 mt-1 w-full "
                            ref={ref}
                        >
                            <div className="max-h-addresses invisible-scrollbar z-50 flex w-[95vw] flex-col overflow-y-scroll rounded-xl border bg-white shadow-md sm:w-auto lg:mr-4 ">
                                {addresses.map((address, index) => (
                                    <div
                                        className="flex cursor-pointer py-4 pl-4 text-lg hover:bg-gray-100 hover:font-semibold lg:py-2 lg:text-sm "
                                        key={address.description + index}
                                        onClick={() =>
                                            handleClickOnPrediction(address)
                                        }
                                    >
                                        {address.label && (
                                            <div className="pr-1 font-semibold">
                                                {address.label + '-'}
                                            </div>
                                        )}
                                        <div>
                                            {/* si l'address provient de PatientsAddresses*/}
                                            {displayAddress(
                                                address.label
                                                    ? address.address
                                                    : address
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}
                </div>
                <button
                    className="align-center mt-auto  flex items-center justify-center rounded-md border bg-white  hover:border-lighter-blue-green"
                    onClick={showAddressFields}
                >
                    <div className="flex h-14 items-center p-3 lg:h-10 ">
                        <NavArrowDown
                            className={`dropdown-label transform text-gray-400 ${
                                show
                                    ? 'rotate-180 duration-200 ease-in'
                                    : 'duration-200 ease-out'
                            }`}
                        />
                    </div>
                </button>
            </div>
            <div className={`mx-auto w-full ${visible}`}>
                <div
                    className={`mt-3 transform opacity-0 ${
                        show
                            ? 'translate-y-3 opacity-100 duration-300 ease-in-out'
                            : '-translate-y-3 duration-300'
                    } `}
                >
                    <div className="flex flex-wrap text-base lg:text-xs">
                        <div className="mb-3 w-full md:mb-0  md:w-1/2 md:pr-2">
                            <label
                                className="mb-2 block font-bold  uppercase  tracking-wide text-gray-700"
                                htmlFor="grid-address"
                            >
                                Numéro - libellé de la voie
                            </label>
                            <input
                                className="block h-14 w-full appearance-none rounded border border-gray-200 bg-white px-3 text-lg leading-tight text-gray-700  hover:border-lighter-blue-green focus:border-lighter-blue-green focus:outline-none md:mb-3 lg:h-10 lg:text-sm"
                                id="grid-address"
                                type="text"
                                placeholder="Avenue du Maréchal Leclerc"
                                value={address.street}
                                onChange={(e) =>
                                    dispatch({
                                        type: ADDRESS_ACTIONS.UPDATE_STREET,
                                        payload: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="mb-3 w-full md:mb-0  md:w-1/2 md:px-2">
                            <label
                                className="mb-2 block font-bold  uppercase tracking-wide text-gray-700"
                                htmlFor="grid-apt"
                            >
                                Complément d'adresse
                            </label>
                            <input
                                className="block h-14 w-full appearance-none rounded border border-gray-200 bg-white px-3 text-lg leading-tight text-gray-700  hover:border-lighter-blue-green focus:border-lighter-blue-green focus:outline-none lg:h-10 lg:text-sm"
                                id="grid-apt"
                                type="text"
                                placeholder="Batiment C"
                                value={address.secondaryStreet}
                                onChange={(e) =>
                                    dispatch({
                                        type: ADDRESS_ACTIONS.UPDATE_SECONDARY_STREET,
                                        payload: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="mb-3 w-full md:mb-0  md:w-1/2 md:pr-2">
                            <label
                                className="mb-2 block font-bold  uppercase tracking-wide text-gray-700"
                                htmlFor="grid-city"
                            >
                                Ville
                            </label>
                            <input
                                className="block h-14 w-full appearance-none rounded border border-gray-200 bg-white px-3 text-lg leading-tight text-gray-700  hover:border-lighter-blue-green focus:border-lighter-blue-green focus:outline-none md:mb-3 lg:h-10 lg:text-sm"
                                id="grid-city"
                                type="text"
                                placeholder="Bordeaux "
                                value={address.city}
                                onChange={(e) =>
                                    dispatch({
                                        type: ADDRESS_ACTIONS.UPDATE_CITY,
                                        payload: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="mb-3 w-full md:mb-0  md:w-1/2 md:px-2">
                            <label
                                className="mb-2 block font-bold  uppercase tracking-wide text-gray-700"
                                htmlFor="grid-state"
                            >
                                Département
                            </label>
                            <input
                                className="block h-14 w-full appearance-none rounded border border-gray-200 bg-white px-3 text-lg leading-tight text-gray-700  hover:border-lighter-blue-green focus:border-lighter-blue-green focus:outline-none lg:h-10 lg:text-sm"
                                id="grid-state"
                                type="text"
                                placeholder="Gironde"
                                value={address.county}
                                onChange={(e) =>
                                    dispatch({
                                        type: ADDRESS_ACTIONS.UPDATE_COUNTY,
                                        payload: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="mb-3 w-full md:mb-0  md:w-1/2 md:pr-2">
                            <label
                                className="mb-2 block font-bold  uppercase tracking-wide text-gray-700"
                                htmlFor="grid-zip"
                            >
                                Code postal
                            </label>
                            <input
                                className="block h-14 w-full appearance-none rounded border border-gray-200 bg-white px-3 text-lg leading-tight text-gray-700  hover:border-lighter-blue-green focus:border-lighter-blue-green focus:outline-none md:mb-3 lg:h-10 lg:text-sm"
                                id="grid-zip"
                                type="text"
                                placeholder="33000"
                                value={address.zipCode}
                                onChange={(e) =>
                                    dispatch({
                                        type: ADDRESS_ACTIONS.UPDATE_ZIP_CODE,
                                        payload: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="mb-3 w-full md:mb-0  md:w-1/2 md:px-2">
                            <label
                                className="mb-2 block font-bold  uppercase tracking-wide text-gray-700"
                                htmlFor="grid-country"
                            >
                                Label / établissement
                            </label>
                            <input
                                className="block h-14 w-full appearance-none rounded border border-gray-200 bg-white px-3 text-lg leading-tight text-gray-700  hover:border-lighter-blue-green focus:border-lighter-blue-green focus:outline-none lg:h-10 lg:text-sm"
                                id="grid-country"
                                type="text"
                                placeholder="CHU"
                                value={address.label}
                                onChange={(e) =>
                                    dispatch({
                                        type: ADDRESS_ACTIONS.UPDATE_LABEL,
                                        payload: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="mb-3 w-full md:mb-0 md:pr-2">
                            <label
                                className="mb-2 block font-bold  uppercase tracking-wide text-gray-700"
                                htmlFor="grid-zip"
                            >
                                Code insee
                            </label>
                            <input
                                className="block h-14 w-full appearance-none rounded border border-gray-200 bg-white px-3 text-lg leading-tight text-gray-700  hover:border-lighter-blue-green focus:border-lighter-blue-green focus:outline-none md:mb-3 lg:h-10 lg:text-sm"
                                id="grid-insee"
                                type="text"
                                placeholder="33000"
                                value={address.codeInsee}
                                onChange={(e) =>
                                    dispatch({
                                        type: ADDRESS_ACTIONS.UPDATE_INSEE,
                                        payload: e.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
