import React from 'react'
import { ACTIONS } from '../../../services/Reducers/RunReducer'
import { PATIENT_ACTIONS } from '../../../services/Reducers/PatientReducer'
import FormInput from '../../Commons/Inputs/FormInput'
import TextAreaForms from '../../Commons/FormTextArea'
import API from '../../../services/api'
import PatientAddresses from '../../Commons/PatientAdresses'
import FormSwitch from '../../Commons/Buttons/FormSwitch'
import PatientNotifications from '../../Commons/PatientNotifications'
import { toast } from 'react-toastify'
import FormDateInput from '../../Commons/Inputs/FormDateInput'
import {
    checkLunarDate,
    hasAllDateParts,
    hasOneDateParts,
    isDateValid,
} from '../../../services/DateService'
import dayjs from 'dayjs'
import { OpenInWindow } from 'iconoir-react'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import { formatSocialNumber, PecModal } from '../Patient/PEC/PecModal'
import SelectDropDownListV2 from '../../Commons/DropDownLists/SelectDropDownListV2'
import { RegimeList } from '../../../services/RegimeList'
import { BirthRank } from '../../../services/BirthRank'
import ClassicSwitch from '../../Commons/Inputs/ClassicSwitch'

export const checkPECErrors = (patient, date, notif = true) => {
    let msg = []
    if (hasOneDateParts(date)) {
        if (!isDateValid(date, !patient.isLunarDate, patient.isLunarDate)) {
            msg.push('Veuillez renseigner une date de naissance correcte')
        }
    } else {
        msg.push('Veuillez renseigner une date de naissance ')
    }
    if (!patient.socialNumber || patient.socialNumber.length !== 15) {
        msg.push('Veuillez renseigner un numéro de sécurité sociale valide.')
    }
    if (!patient.birthRank) {
        msg.push('Veuillez renseigner un rang de naissance.')
    }
    if (!patient.payingCenter) {
        msg.push('Veuillez renseigner une caisse.')
    }
    if (msg.length > 0) {
        msg.forEach((e) => {
            toast.error(e, { autoClose: false })
        })
        return true
    }
    return false
}

const PatientModal = ({
    show,
    runDispatch,
    runState,
    patient,
    dispatch,
    date,
    dateDispatch,
    prsDateDispatch,
    prsDateInput,
    realDateInput,
    realDateDispatch,
}) => {
    const checkErrors = () => {
        console.log('check from patient modal')
        let msg = []
        if (!patient.firstname) {
            msg.push('Veuillez renseigner un prénom.')
        }
        if (!patient.lastname) {
            msg.push('Veuillez renseigner un nom.')
        }
        if (hasOneDateParts(date)) {
            if (!isDateValid(date, !patient.isLunarDate, patient.isLunarDate)) {
                msg.push('Veuillez renseigner une date de naissance correcte')
            }
        }
        if (
            patient.patientsAddresses.some(
                (address) => !address.address.city || !address.label
            )
        ) {
            msg.push('Veuillez renseigner une adresse complète.')
        }
        const checkIfAtLeastOneDefaultAddressIsChecked =
            patient.patientsAddresses.filter(
                (address) => address.defaultAddress === true
            )
        if (
            patient.patientsAddresses.length > 0 &&
            checkIfAtLeastOneDefaultAddressIsChecked.length !== 1
        ) {
            msg.push('Veuillez renseigner une adresse par défaut')
        }
        if (
            patient.patientsContacts.some(
                (address) =>
                    address.label === '' || address.phoneNumber.length < 14
            )
        ) {
            msg.push('Veuillez renseigner un contact valide')
        }
        if (msg.length > 0) {
            msg.forEach((e) => {
                toast.error(e)
            })
            return true
        }
        return false
    }

    const handleOnChange = () => {
        dispatch({
            type: PATIENT_ACTIONS.SET_HAS_CHANGED,
        })
    }

    const setPatientDateOfBirth = (patient, date, checkLunarDate) => {
        if (hasAllDateParts(date)) {
            console.log(date)
            if (checkLunarDate(date)) {
                console.log('date LUNAIRE')
                patient.stringDateOfBirth = `${date.day}/${date.month}/${date.year}`
                patient.dateOfBirth = null
            } else {
                console.log('DATE NORMAL')
                patient.dateOfBirth = hasAllDateParts(date)
                    ? dayjs(new Date(date.year, date.month - 1, date.day))
                          .local()
                          .format('YYYY-MM-DD')
                    : null
                patient.stringDateOfBirth = null
            }
        } else {
            patient.dateOfBirth = null
        }

        return patient
    }

    const handleForm = ({ fromPec = false }) => {
        if (!checkErrors()) {
            // Check date lunaire
            console.log('check lunar date handle')
            patient = setPatientDateOfBirth(patient, date, checkLunarDate)

            if (patient.id) {
                dispatch({
                    type: PATIENT_ACTIONS.EDIT_PATIENT,
                    payload: patient,
                })
                runDispatch({
                    type: ACTIONS.UPDATE_PATIENT,
                    payload: patient,
                })

                setTimeout(() => {
                    if (!fromPec) {
                        toast.success('Fiche modifiée avec succès.')
                        runDispatch({
                            type: ACTIONS.SHOW_PATIENT_MODAL,
                            payload: false,
                        })
                    } else {
                        if (!checkPECErrors(patient, date)) {
                            dispatch({
                                type: PATIENT_ACTIONS.SHOW_PEC_MODAL,
                                payload: true,
                            })
                        }
                    }
                    dispatch({
                        type: PATIENT_ACTIONS.REMOVE_LOADING,
                    })
                }, 500)
            } else {
                patient.regime = patient.regime?.value
                patient.birthRank = patient.birthRank?.value
                API.Patients.addPatient(patient)
                    .then((res) => {
                        res.json().then((data) => {
                            dispatch({
                                type: PATIENT_ACTIONS.GET_PATIENT,
                                payload: {
                                    ...data,
                                    id: parseInt(
                                        data['@id'].split('/').reverse()[0]
                                    ),
                                    ['@id']: data['@id'],
                                    patientsAddresses: data.patientsAddresses,
                                    pickUpLocation: !runState.pickUpLocation
                                        ? data.patientsAddresses[0]
                                            ? data.patientsAddresses[0]
                                            : null
                                        : null,
                                },
                            })
                            runDispatch({
                                type: ACTIONS.UPDATE_PATIENT,
                                payload: {
                                    ...patient,
                                    id: parseInt(
                                        data['@id'].split('/').reverse()[0]
                                    ),
                                    ['@id']: data['@id'],
                                    patientsAddresses: data.patientsAddresses,
                                    pickUpLocation: !runState.pickUpLocation
                                        ? data.patientsAddresses[0]
                                            ? data.patientsAddresses[0]
                                            : null
                                        : null,
                                },
                            })
                            if (!fromPec) {
                                setTimeout(() => {
                                    toast.success(
                                        'Nouvelle fiche ajoutée avec succès.'
                                    )

                                    runDispatch({
                                        type: ACTIONS.SHOW_PATIENT_MODAL,
                                        payload: false,
                                    })
                                }, 500)
                            } else {
                                if (!checkPECErrors(patient, date)) {
                                    dispatch({
                                        type: PATIENT_ACTIONS.SHOW_PEC_MODAL,
                                        payload: true,
                                    })
                                }
                            }
                        })
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    }

    const handlePECRequest = () => {
        let fromPec = true
        handleForm({ fromPec })
    }

    return (
        <div
            className={`${
                !show && 'hidden'
            } modal fixed bottom-0 left-0 flex h-full w-full items-center justify-center bg-gray-500 bg-opacity-50 text-gray-700 lg:py-20 `}
        >
            <div
                id="top"
                className="shadow-modal invisible-scrollbar lg:min-h-modal mx-auto flex h-screen w-full max-w-4xl flex-col overflow-y-auto rounded-xl bg-white lg:h-full "
            >
                <div className="flex flex-col items-start p-6 lg:p-8">
                    <div className="flex w-full items-center border-b pb-4 text-3xl">
                        <div className="flex space-x-4 text-center font-semibold ">
                            {runState.patient ? 'Modifier' : 'Ajouter'} une
                            fiche
                        </div>
                        <svg
                            className="ml-auto h-10 w-10 cursor-pointer fill-current text-gray-700"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 18 18"
                            onClick={() =>
                                runDispatch({
                                    type: ACTIONS.SHOW_PATIENT_MODAL,
                                    payload: false,
                                })
                            }
                        >
                            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                        </svg>
                    </div>
                    <div className="flex w-full flex-col  space-y-8 pt-5 text-xl lg:space-y-4 lg:text-base ">
                        <FormSwitch
                            label="Type de fiche"
                            id="patient"
                            valueLeft={patient.isPatient}
                            valueRight={!patient.isPatient}
                            onClickLeft={() =>
                                dispatch({
                                    type: PATIENT_ACTIONS.IS_PATIENT,
                                    payload: true,
                                })
                            }
                            onClickRight={() =>
                                dispatch({
                                    type: PATIENT_ACTIONS.IS_PATIENT,
                                    payload: false,
                                })
                            }
                        />
                        <FormInput
                            label="Nom"
                            type={'text'}
                            placeholder={'Nom'}
                            value={patient.lastname}
                            onChange={(e) =>
                                dispatch({
                                    type: PATIENT_ACTIONS.SET_LASTNAME,
                                    payload: e.target.value,
                                })
                            }
                        />
                        <FormInput
                            label="Prénom"
                            type={'text'}
                            placeholder={'Prénom'}
                            value={patient.firstname}
                            onChange={(e) =>
                                dispatch({
                                    type: PATIENT_ACTIONS.SET_FIRSTNAME,
                                    payload: e.target.value,
                                })
                            }
                        />
                        <FormInput
                            label="Téléphone"
                            type={'tel'}
                            placeholder={'Téléphone'}
                            value={patient.phoneNumber}
                            pattern={'[0-9\\s]{14}'}
                            onChange={(e) =>
                                dispatch({
                                    type: PATIENT_ACTIONS.SET_PHONE_NUMBER,
                                    payload: e.target.value,
                                })
                            }
                            infoLabel={'Format: 06 06 06 06 06'}
                        />
                        {patient.isPatient && (
                            <>
                                <div className="flex flex-col lg:flex-row">
                                    <div
                                        className={`flex w-full items-center justify-between pb-2 font-semibold lg:w-1/3`}
                                    >
                                        <label>Date de naissance</label>
                                    </div>
                                    <div className={'w-full lg:w-1/3'}>
                                        <FormDateInput
                                            date={date}
                                            dispatch={dateDispatch}
                                            isBirthDate={true}
                                            isLunarDate={patient.isLunarDate}
                                            handleOnChange={handleOnChange}
                                        />
                                    </div>
                                    <div
                                        className={
                                            'flex w-full  lg:w-1/3 lg:justify-end'
                                        }
                                    >
                                        <ClassicSwitch
                                            label="Date étrangère"
                                            value={patient.isLunarDate}
                                            setValue={() =>
                                                dispatch({
                                                    type: PATIENT_ACTIONS.SET_IS_LUNAR_DATE,
                                                    payload:
                                                        !patient.isLunarDate,
                                                })
                                            }
                                            info={true}
                                            infoLabel={
                                                'Permet de rentrer des dates non classique'
                                            }
                                        />
                                    </div>
                                </div>
                                <FormInput
                                    label="N° de sécurité sociale"
                                    type={'text'}
                                    placeholder={'Numéro de sécurité sociale'}
                                    value={formatSocialNumber(
                                        patient.socialNumber
                                    )}
                                    pattern="[A-Za-z0-9]{1,20}"
                                    onChange={(e) =>
                                        dispatch({
                                            type: PATIENT_ACTIONS.SET_SOCIAL_NUMBER,
                                            payload: e.target.value,
                                        })
                                    }
                                    infoLabel={
                                        'Format: 16004253114576, peut contenir des lettres en majuscules et des chiffres'
                                    }
                                />
                                <div className="flex flex-col lg:flex-row lg:items-center">
                                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0 ">
                                        Régime
                                    </label>
                                    <div className="lg:w-2/3">
                                        <SelectDropDownListV2
                                            placeholder={
                                                'Sélectionner un régime'
                                            }
                                            options={RegimeList}
                                            value={patient.regime}
                                            handleOptionChange={(e) =>
                                                dispatch({
                                                    type: PATIENT_ACTIONS.SET_REGIME,
                                                    payload: e,
                                                })
                                            }
                                            isClearable={false}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col lg:flex-row lg:items-center">
                                    <label className="pb-2   font-bold lg:w-1/3 lg:p-0  ">
                                        Rang de naissance
                                    </label>
                                    <div className="lg:w-2/3">
                                        <SelectDropDownListV2
                                            placeholder={'Sélectionner un rang'}
                                            options={BirthRank}
                                            value={patient.birthRank}
                                            handleOptionChange={(e) =>
                                                dispatch({
                                                    type: PATIENT_ACTIONS.SET_BIRTH_RANK,
                                                    payload: e,
                                                })
                                            }
                                            isClearable={false}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        <TextAreaForms
                            label="Description"
                            type={'text'}
                            placeholder={'Description'}
                            value={patient.description}
                            onChange={(e) =>
                                dispatch({
                                    type: PATIENT_ACTIONS.SET_DESCRIPTION,
                                    payload: e.target.value,
                                })
                            }
                        />
                        <div className="w-full py-2 lg:w-1/3">
                            <button
                                onClick={handlePECRequest}
                                className="transition-color flex w-full  items-center justify-center rounded border border-light-dark-green bg-light-dark-green px-4 py-2 text-center font-bold text-white duration-200 hover:bg-dark-green"
                                title="PEC"
                            >
                                Consulter les droits PEC+
                                <OpenInWindow className={'ml-auto'} />
                            </button>
                        </div>
                        <PatientAddresses
                            dispatch={dispatch}
                            PATIENT_ACTIONS={PATIENT_ACTIONS}
                            patient={patient}
                        />
                        <PatientNotifications
                            dispatch={dispatch}
                            PATIENT_ACTIONS={PATIENT_ACTIONS}
                            patient={patient}
                        />
                    </div>
                </div>
                {patient.hasChanged && (
                    <div className="mt-auto flex w-full justify-between px-8 pb-8 text-2xl lg:text-base">
                        <SecondaryButton
                            title="Annuler"
                            label="Annuler"
                            hiddenLabelOnMobile={false}
                            action={() =>
                                runDispatch({
                                    type: ACTIONS.SHOW_PATIENT_MODAL,
                                    payload: false,
                                })
                            }
                            disabled={patient.loading}
                        />
                        <PrimaryButton
                            title="Sauvegarder"
                            label={runState.patient ? 'Modifier' : 'Ajouter'}
                            hiddenLabelOnMobile={false}
                            action={() => handleForm({ fromPec: false })}
                            disabled={patient.loading}
                            loader={patient.loading}
                        />
                    </div>
                )}
            </div>
            {patient.showPecModal && (
                <PecModal
                    dateOfBirth={date}
                    patientDispatch={dispatch}
                    patient={patient}
                    prsDateDispatch={prsDateDispatch}
                    prsDateInput={prsDateInput}
                    realDateDispatch={realDateDispatch}
                    realDateInput={realDateInput}
                />
            )}
        </div>
    )
}

export default PatientModal
