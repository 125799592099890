import React, { useEffect, useState } from 'react'
import API from '../../../../services/api'
import Patient from '../../Regulation/RunListComponents/Patient'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import Configurator from './Configurator'
import { toast } from 'react-toastify'
import { Computer, EditPencil, Erase, SmartphoneDevice } from 'iconoir-react'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'
import TabButton from '../../../Commons/Buttons/TabButton'

const ConfiguratorContainer = ({
    computerConfigurator,
    mobileConfigurator,
    setComputerConfigurator,
    setMobileConfigurator,
    selected,
    hover,
    setLoading,
}) => {
    const location = useLocation()
    const history = useNavigate()
    const [previewLoading, setPreviewLoading] = useState(false)

    const fakeRow = {
        fullname: 'Dupont Georges',
        address: 'Roubineau, 47120 Soumensac',
        runObject: {
            label: 'Consultation',
        },
        date: new Date(),
        type: {
            label: 'VSL',
        },
        depositLocation: {
            label: 'Hopital Pellegrin',
        },
        status: {
            id: 'done',
            label: 'Fait',
        },
        updateStatusAt: new Date(),
        departureTime: new Date(),
        arrivingTime: new Date(),
        masterRun: {
            users: [
                {
                    firstname: 'Antoine',
                    lastname: 'Lejeune',
                },
                {
                    firstname: 'Maria',
                    lastname: 'Paille',
                },
            ],
        },
        isReturnPath: true,
        series: {
            nbRuns: 12,
        },
        seriesIndex: 3,
        departureOrder: 1,
    }

    const [configuratorComputerElements, setConfiguratorComputerElements] =
        useState({
            elements: {
                patient: {
                    id: 'patient',
                    content: 'Patient',
                    isChecked: false,
                },
                depositLocation: {
                    id: 'depositLocation',
                    content: 'Lieu de rendez-vous',
                    isChecked: false,
                },
                objectAndDateFirstFormat: {
                    id: 'objectAndDateFirstFormat',
                    content: 'Objet de la course et date format 01/01/1970',
                    isChecked: false,
                },
                objectAndDateSecondFormat: {
                    id: 'objectAndDateSecondFormat',
                    content: 'Objet de la course et date format Jeu. 01 Jan.',
                    isChecked: false,
                },
                vehicle: {
                    id: 'vehicle',
                    content: 'Type de véhicule',
                    isChecked: false,
                },
                timesAndTimeValidation: {
                    id: 'timesAndTimeValidation',
                    content: 'Heure de validation',
                    description:
                        "Lorsque la course est validée, l'heure de validation apparait à la place des horaires de la courses.",
                    isChecked: false,
                },
                timesOnly: {
                    id: 'timesOnly',
                    content: 'Horaires de la course uniquement',
                    isChecked: false,
                },
                status: {
                    id: 'status',
                    content: 'Statut de la course',
                    isChecked: false,
                },
                team: {
                    id: 'team',
                    content: 'Chauffeurs',
                    isChecked: false,
                },
                return: {
                    id: 'return',
                    content: 'Afficher si la course est un retour',
                    isChecked: false,
                },
                runNumberInSeries: {
                    id: 'runNumberInSeries',
                    content:
                        "Afficher l'index de la course correspondant à sa série",
                    description:
                        "Afficher l'index de la course par rapport au nombre de course de la série. Exemple : 5/11",
                    isChecked: false,
                },
                departureOrder: {
                    id: 'departureOrder',
                    content: "Afficher l'ordre d'une course groupée",
                    isChecked: false,
                },
            },
            elementIds: [],
        })

    const [configuratorMobileElements, setConfiguratorMobileElements] =
        useState({
            elements: {
                patient: {
                    id: 'patient',
                    content: 'Patient',
                    isChecked: false,
                },
                depositLocation: {
                    id: 'depositLocation',
                    content: 'Lieu de rendez-vous',
                    isChecked: false,
                },
                dateFirstFormat: {
                    id: 'dateFirstFormat',
                    content: 'Date format 01/01/1970',
                    isChecked: false,
                },
                dateSecondFormat: {
                    id: 'dateSecondFormat',
                    content: 'Date format Jeu. 01 Jan.',
                    isChecked: false,
                },
                vehicle: {
                    id: 'vehicle',
                    content: 'Type de véhicule',
                    isChecked: false,
                },
                timesAndTimeValidation: {
                    id: 'timesAndTimeValidation',
                    content: 'Heure de validation',
                    description:
                        "Lorsque la course est validée, l'heure de validation apparait à la place des horaires de la courses.",
                    isChecked: false,
                },
                timesOnly: {
                    id: 'timesOnly',
                    content: 'Horaires de la course uniquement',
                    isChecked: false,
                },
                status: {
                    id: 'status',
                    content: 'Statut de la course',
                    isChecked: false,
                },
                team: {
                    id: 'team',
                    content: 'Chauffeurs',
                    isChecked: false,
                },
                return: {
                    id: 'return',
                    content: 'Afficher si la course est un retour',
                    isChecked: false,
                },
                object: {
                    id: 'object',
                    content: 'Objet de la course',
                    isChecked: false,
                },
                runNumberInSeries: {
                    id: 'runNumberInSeries',
                    content:
                        "Afficher l'index de la course correspondant à sa série",
                    description:
                        "Afficher l'index de la course par rapport au nombre de course de la série. Exemple : 5/11",
                    isChecked: false,
                },
                departureOrder: {
                    id: 'departureOrder',
                    content: "Afficher l'ordre d'une course groupée",
                    isChecked: false,
                },
            },
            elementIds: [],
        })

    useEffect(() => {
        init()
    }, [])

    const init = () => {
        //COMPUTER CONFIGURATOR
        let newComputerElements = {
            ...configuratorComputerElements.elements,
        }
        let computerElementIds = []
        Object.entries(computerConfigurator.custom).map(([key, element]) => {
            newComputerElements[element] = {
                ...configuratorComputerElements.elements[element],
                isChecked: true,
            }
            computerElementIds.push(element)
        })

        const newComputerState = {
            ...configuratorComputerElements,
            elements: newComputerElements,
            elementIds: computerElementIds,
        }
        setConfiguratorComputerElements(newComputerState)

        //MOBILE CONFIGURATOR
        let newMobileElements = {
            ...configuratorMobileElements.elements,
        }
        let mobileElementIds = []
        Object.entries(mobileConfigurator.custom).map(([key, element]) => {
            newMobileElements[element] = {
                ...configuratorMobileElements.elements[element],
                isChecked: true,
            }
            mobileElementIds.push(element)
        })

        const newMobileState = {
            ...configuratorMobileElements,
            elements: newMobileElements,
            elementIds: mobileElementIds,
        }
        setPreviewLoading(false)
        setConfiguratorMobileElements(newMobileState)
    }

    const saveChanges = () => {
        if (configuratorComputerElements.elementIds.length <= 1) {
            toast.error(
                "Veuillez ajouter plus d'un élément dans votre configurateur pc."
            )
        }
        if (configuratorMobileElements.elementIds.length <= 1) {
            toast.error(
                "Veuillez ajouter plus d'un élément dans votre configurateur mobile."
            )
        }
        if (
            configuratorComputerElements.elementIds.length > 1 &&
            configuratorMobileElements.elementIds.length > 1
        ) {
            setLoading(true)
            const society = localStorage.getItem('society')
                ? localStorage.getItem('society')
                : null
            let data = {
                computerConfigurator: {
                    custom: {},
                    default: computerConfigurator.default,
                },
                mobileConfigurator: {
                    custom: {},
                    default: mobileConfigurator.default,
                },
            }
            configuratorComputerElements.elementIds.forEach(
                (element, index) => {
                    data.computerConfigurator.custom[index] = element
                }
            )
            configuratorMobileElements.elementIds.forEach((element, index) => {
                data.mobileConfigurator.custom[index] = element
            })

            API.Parameter.put(society, data).then((response) => {
                response.json().then((data) => {
                    toast.success('Votre configuration a été sauvegardée.')
                    setComputerConfigurator(data.computerConfigurator)
                    setMobileConfigurator(data.mobileConfigurator)
                    setLoading(false)
                })
            })
        }
    }

    const resetConfigurator = (configuratorName) => {
        setPreviewLoading(true)
        let data
        if (configuratorName === 'computer') {
            data = {
                computerConfigurator: {
                    custom: computerConfigurator.default,
                    default: computerConfigurator.default,
                },
            }
        } else {
            data = {
                mobileConfigurator: {
                    custom: mobileConfigurator.default,
                    default: mobileConfigurator.default,
                },
            }
        }

        const society = localStorage.getItem('society')
            ? localStorage.getItem('society')
            : null
        API.Parameter.put(society, data).then((response) => {
            response.json().then((data) => {
                setComputerConfigurator(data.computerConfigurator)
                setMobileConfigurator(data.mobileConfigurator)
                init()
                toast.success(
                    `Votre configuration ${
                        configuratorName === 'computer' ? 'pc' : 'mobile'
                    } a été réinitialisée.`
                )
            })
        })
    }

    const reloading = () => {
        setPreviewLoading(true)
        init()
        toast.success('Vos modifications ont été annulées.')
    }

    return (
        <div className="px-4 pb-10">
            <div className="flex text-lg font-semibold text-gray-700 lg:text-base">
                <div className="flex h-10 w-full cursor-pointer flex-wrap space-x-4 rounded-xl border-dark-green lg:w-auto">
                    <TabButton
                        value={
                            location.pathname === '/parametres/configurateur'
                        }
                        action={() => history('/parametres/configurateur')}
                        label="Pc"
                        icon={<Computer />}
                    />
                    <TabButton
                        value={location.pathname.startsWith(
                            '/parametres/configurateur/mobile'
                        )}
                        action={() =>
                            history('/parametres/configurateur/mobile')
                        }
                        label="Mobile"
                        icon={<SmartphoneDevice />}
                    />
                </div>
            </div>
            <div className="flex w-full flex-col">
                <Routes>
                    <Route
                        path="/"
                        element={
                            <Configurator
                                fakeRow={fakeRow}
                                configuratorElements={
                                    configuratorComputerElements
                                }
                                setConfiguratorElements={
                                    setConfiguratorComputerElements
                                }
                                resetConfigurator={resetConfigurator}
                                previewLoading={previewLoading}
                                setPreviewLoading={setPreviewLoading}
                                configuratorName="computer"
                            />
                        }
                    />
                    <Route
                        path="/mobile"
                        element={
                            <Configurator
                                fakeRow={fakeRow}
                                configuratorElements={
                                    configuratorMobileElements
                                }
                                setConfiguratorElements={
                                    setConfiguratorMobileElements
                                }
                                previewLoading={previewLoading}
                                setPreviewLoading={setPreviewLoading}
                                resetConfigurator={resetConfigurator}
                                configuratorName="mobile"
                            />
                        }
                    />
                </Routes>

                <div className="mt-auto pt-5 text-xl lg:text-base">
                    <div className="pb-2 italic">
                        N'hésitez pas à nous contacter si vous souhaitez des
                        options non proposées actuellement.
                    </div>
                    <hr className="pb-5" />
                    <div className="flex justify-between">
                        <SecondaryButton
                            label="Annuler"
                            title="Annuler"
                            action={reloading}
                        />
                        <PrimaryButton
                            label="Valider"
                            title="Valider"
                            action={saveChanges}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfiguratorContainer
