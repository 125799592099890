export const BENEFICIARY_ACTIONS = {
    SET_BENEFICIARY: 'SET_BENEFICIARY',
    SET_BENEFICIARY_SEARCH_INPUT: 'SET_BENEFICIARY_SEARCH_INPUT',
}

export const BeneficiaryFormReducer = (state, action) => {
    switch (action.type) {
        case BENEFICIARY_ACTIONS.SET_BENEFICIARY:
            return {
                ...state,
                beneficiary: action.payload,
            }
        case BENEFICIARY_ACTIONS.SET_BENEFICIARY_SEARCH_INPUT:
            return {
                ...state,
                beneficiaries: action.payload,
            }
        default:
            return state
    }
}

export const getBeneficiaryInitialState = ({ beneficiary }) => {
    if (beneficiary) {
        return {
            ...beneficiary,
            beneficiarySearchInput: '',
            beneficiaries: [],
        }
    } else {
        return {
            beneficiarySearchInput: '',
            beneficiaries: [],
        }
    }
}
