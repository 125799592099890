import * as dayjs from 'dayjs'

export const INVOICE_FILTER_ACTIONS = {
    SET_DATE_TO: 'SET_DATE_TO',
    SET_DATE_FROM: 'SET_DATE_FROM',
    GET_OBJECT_LIST: 'GET_OBJECTS',
    SET_OBJECT: 'SET_OBJECT',
    GET_PATIENT_LIST: 'GET_PATIENT_LIST',
    SET_PATIENT: 'SET_PATIENT',
    GET_SERIES_LIST: 'GET_SERIES_LIST',
    SET_SERIES: 'SET_SERIES',
    SWITCH_STEP: 'SWITCH_TO_STEP',
    SET_ANNEXES: 'SET_ANNEXES',
    REMOVE_ANNEXE: 'REMOVE_ANNEXE',
}

export const InvoiceFilterReducer = (state, action) => {
    switch (action.type) {
        case INVOICE_FILTER_ACTIONS.SET_DATE_TO:
            return { ...state, dateTo: action.payload }
        case INVOICE_FILTER_ACTIONS.SET_DATE_FROM:
            return { ...state, dateFrom: action.payload }
        case INVOICE_FILTER_ACTIONS.GET_OBJECT_LIST:
            return { ...state, objectList: action.payload }
        case INVOICE_FILTER_ACTIONS.SET_OBJECT:
            return { ...state, object: action.payload }
        case INVOICE_FILTER_ACTIONS.GET_PATIENT_LIST:
            return { ...state, patientList: action.payload }
        case INVOICE_FILTER_ACTIONS.SET_PATIENT:
            return { ...state, patient: action.payload }
        case INVOICE_FILTER_ACTIONS.GET_SERIES_LIST:
            return { ...state, seriesList: action.payload }
        case INVOICE_FILTER_ACTIONS.SET_SERIES:
            return { ...state, series: action.payload }
        case INVOICE_FILTER_ACTIONS.SWITCH_STEP:
            return { ...state, step: action.payload }
        case INVOICE_FILTER_ACTIONS.SET_ANNEXES:
            return { ...state, annexes: action.payload, loading: false }
        case INVOICE_FILTER_ACTIONS.REMOVE_ANNEXE:
            return {
                ...state,
                annexes: state.annexes.filter(
                    (line) => line.id !== action.payload.id
                ),
            }
        default:
            throw new Error()
    }
}

export const InvoiceFilterInitialState = ({ dateFrom, dateTo }) => {
    return {
        dateFrom: dateFrom,
        dateTo: dateTo,
        step: 1,
        firstStep: true,
        secondStep: false,
        annexes: [],
        patientList: [],
        patient: null,
        object: null,
        objectList: [],
        series: null,
        seriesList: [],
        loading: true,
    }
}
