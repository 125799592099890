import React from 'react'
import * as dayjs from 'dayjs'

const ShareView = ({ history, run }) => {
    return (
        <div className="flex h-full  w-full flex-col space-y-6 py-2 text-lg lg:text-base">
            <div className="flex w-full items-center justify-between py-5 text-2xl text-gray-700 ">
                <h2 className="font-bold">Fiche partenaire</h2>
                <button
                    className="transition-color rounded bg-light-dark-green px-4 py-2 text-right text-base font-bold text-white duration-200 hover:bg-dark-green "
                    title="Modifier le partenaire"
                    onClick={() =>
                        history(`/ressources/partenaires/${run.partner.id}`)
                    }
                >
                    <span className="hidden lg:flex">Modifier</span>
                    <i className="fa fa-edit lg:hidden" />
                </button>
            </div>
            <div className="flex flex-col lg:flex-row">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">Prénom</label>
                <p>{run.partner.firstname}</p>
            </div>
            <div className="flex flex-col lg:flex-row">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">Nom</label>
                <p className=" ">{run.partner.lastname}</p>
            </div>
            <div className="flex flex-col lg:flex-row">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                    Téléphone
                </label>
                <p>{run.partner.phoneNumber}</p>
            </div>
            <div className="flex flex-col lg:flex-row">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">Email</label>
                <p>{run.partner.email}</p>
            </div>
            <div className="flex flex-col lg:flex-row">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                    Note de partage
                </label>
                <p>{run.note}</p>
            </div>
            <div className="flex flex-col lg:flex-row">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                    Partagé par
                </label>
                <p>
                    {run.sharingOption
                        ? run.sharingOption.label
                        : 'Ne pas notifier'}
                </p>
            </div>
            <div className="flex flex-col lg:flex-row">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                    Partagé le
                </label>
                <p className="capitalize">
                    {dayjs(run.sharedAt)
                        .local()
                        .format('ddd DD MMMM - HH : mm')}
                </p>
            </div>
        </div>
    )
}

export default ShareView
