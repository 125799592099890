import {
    getTotalsFromInvoiceFormDetails,
    handleInvoiceCpamModalForm,
    InitCalculatedPriceFromRunInvoiceDetails,
    getRunInvoiceFormDetailsFromRuns,
    updateCalculatedPriceFromRunInvoiceDetailsForms,
    handleSuspendedInvoice,
    handleInvoiceStatusChange,
} from '../../../../services/InvoiceUtils'
import { INVOICE_CPAM_ACTIONS } from '../Reducers/InvoiceCpamReducer'
import {
    checkInvoiceForm,
    handleInvoiceForm,
} from '../FormHandlers/handleInvoiceForm'
import { saveAs } from 'file-saver'
import React, { useEffect, useState } from 'react'
import { TRANSPORT_ACTIONS } from '../Reducers/TransportFormReducer'
import { checkPECErrors } from '../PatientModal'
import { dateStringToObject } from '../../../../services/DateService'
import InvoiceTotalPanel from './Invoice/InvoiceTotalPanel'

import { InvoiceFormDetails } from './Invoice/InvoiceFormDetails'
import { PaymentDetails } from './Invoice/PaymentDetails'
import InvoiceCpamModalForm from './InvoiceCpamModalForm'
import ModalSkeleton from '../../../Modal/ModalSkeleton'
import RunPricePanel from './Invoice/RunPricePanel'
import InvoiceRejectionDetails from './Invoice/InvoiceRejectionDetails'
import API from '../../../../services/api'
import { toast } from 'react-toastify'
import SelectDateModalForm from '../../Invoice/Noemie/SelectDateModalForm'
import CpamInvoiceHeader from './Invoice/CpamInvoiceHeader'

const TransportCpamInvoiceForm = ({
    invoice,
    run,
    prescription,
    handleRunFormValidation,
    dispatch,
    patient,
    pickUpAddress,
    depositAddress,
}) => {
    const rate = prescription?.rate?.value
    const runs =
        run.linkedRun && !run.linkedRun?.isGiven ? [run, run.linkedRun] : [run]

    const [showSelectDateModal, setShowSelectDateModal] = useState(false)
    const [statusDate, setStatusDate] = useState(new Date())
    const [statusAndType, setStatusAndType] = useState({})
    const updatePriceDetails = () => {
        if (invoice.runsInvoiceFormsDetails[0].id) {
            const updatedPrices =
                updateCalculatedPriceFromRunInvoiceDetailsForms(
                    invoice.runsInvoiceFormsDetails
                )

            dispatch({
                type: INVOICE_CPAM_ACTIONS.UPDATE_ALL_RUNS_INVOICE_DETAILS,
                payload: updatedPrices,
            })

            const totals = getTotalsFromInvoiceFormDetails(updatedPrices, rate)
            dispatch({
                type: INVOICE_CPAM_ACTIONS.INIT_PRICE_BASIS,
                payload: updatedPrices[0].priceBasis,
            })
            dispatch({
                type: INVOICE_CPAM_ACTIONS.SET_TOTALS,
                payload: totals,
            })
        } else {
            const priceInit = InitCalculatedPriceFromRunInvoiceDetails(
                invoice,
                run,
                prescription.isTPMR
            )

            dispatch({
                type: INVOICE_CPAM_ACTIONS.UPDATE_ALL_RUNS_INVOICE_DETAILS,
                payload: priceInit,
            })

            const totals = getTotalsFromInvoiceFormDetails(priceInit, rate)

            dispatch({
                type: INVOICE_CPAM_ACTIONS.SET_TOTALS,
                payload: totals,
            })

            dispatch({
                type: INVOICE_CPAM_ACTIONS.INIT_PRICE_BASIS,
                payload: priceInit[0].priceBasis,
            })
        }
    }

    useEffect(() => {
        updatePriceDetails()
    }, [])

    const handleInvoiceModalForm = () => {
        handleInvoiceCpamModalForm(
            invoice,
            dispatch,
            INVOICE_CPAM_ACTIONS,
            handleCloseInvoiceModal,
            rate
        )
    }

    const handleStatusChange = (type, status) => {
        setStatusAndType({ type: type, status: status })
        setShowSelectDateModal(true)
    }

    const changeStatus = async () => {
        handleInvoiceStatusChange(
            statusAndType.type,
            statusAndType.status,
            statusDate,
            invoice,
            dispatch,
            INVOICE_CPAM_ACTIONS
        ).then()
        setShowSelectDateModal(false)
    }

    const handleSuspendInvoiceCpam = () => {
        handleSuspendedInvoice(invoice, dispatch, INVOICE_CPAM_ACTIONS).then()
    }

    const handleDateChange = async (date) => {
        // verifier si la date n'est pas dans le futur sinon erreur
        if (date > new Date()) {
            toast.error('La date ne peut pas être dans le futur')
        } else {
            setStatusDate(date)
        }
    }

    const checkAndHandleInvoiceForm = async () => {
        const patientToCheck = patient.parent ? patient.parent : patient
        if (
            !checkInvoiceForm(
                patient,
                invoice,
                prescription,
                run,
                pickUpAddress,
                depositAddress
            ) &&
            !checkPECErrors(
                patientToCheck,
                dateStringToObject(patient.dateOfBirth)
            )
        ) {
            dispatch({
                type: TRANSPORT_ACTIONS.SET_LOADING,
                payload: true,
            })

            // false => without redirection
            const response = await handleRunFormValidation(false)
            console.log(response)
            if (response) {
                const formResponse = await handleInvoiceForm(
                    invoice,
                    run,
                    prescription,
                    patient
                )
                if (formResponse.success) {
                    // Add new ids to forms
                    const runInvoiceDetails = getRunInvoiceFormDetailsFromRuns(
                        formResponse.data.runs
                    )

                    const invoiceDetails = {
                        ...formResponse.data,
                        runsInvoiceFormsDetails: runInvoiceDetails,
                    }
                    dispatch({
                        type: INVOICE_CPAM_ACTIONS.FETCH_INVOICE_CPAM,
                        payload: invoiceDetails,
                    })
                    // change run status for frontEnd
                    dispatch({
                        type: TRANSPORT_ACTIONS.UPDATE_STATUS,
                        payload: 'Facturé',
                    })
                }
                dispatch({
                    type: TRANSPORT_ACTIONS.SET_LOADING,
                    payload: false,
                })
            } else {
                dispatch({
                    type: TRANSPORT_ACTIONS.SET_LOADING,
                    payload: false,
                })
            }
        }
    }

    const handleCloseInvoiceModal = () => {
        dispatch({
            type: INVOICE_CPAM_ACTIONS.SET_SHOW_INVOICE_MODAL_FORM,
        })
    }

    const handlePrintInvoice = async () => {
        dispatch({
            type: TRANSPORT_ACTIONS.SET_LOADING,
            payload: true,
        })
        let pdfName = `Facture_${invoice.cpamInvoiceId}.pdf`
        try {
            const response = await API.CpamInvoice.print(invoice.id)
            if (response.ok) {
                response.blob().then((blob) => {
                    saveAs(blob, pdfName)
                    toast.success('Pdf téléchargé avec succès')
                })
            }
        } catch (error) {
            if (error.json) {
                const data = await error.json()
                toast.error(data['hydra:description'])
            } else {
                toast.error('Erreur, veuillez contacter un administrateur')
            }
        }
        if (
            invoice.healthComplementaryAmount > 0 &&
            !invoice.healthComplementary
        ) {
            const labelCourrierPdf = `Courrier_${invoice.cpamInvoiceId}.pdf`
            try {
                const response = await API.CpamInvoice.printPatientLetter(
                    invoice.id
                )
                if (response.ok) {
                    response.blob().then((blob) => {
                        saveAs(blob, labelCourrierPdf)
                        toast.success('Pdf téléchargé avec succès')
                    })
                }
            } catch (error) {
                if (error.json) {
                    const data = await error.json()
                    toast.error(data['hydra:description'])
                } else {
                    toast.error('Erreur, veuillez contacter un administrateur')
                }
            }
        }
        dispatch({
            type: TRANSPORT_ACTIONS.SET_LOADING,
            payload: false,
        })
    }

    return (
        <div className={'flex flex-col'}>
            <div className="flex w-full flex-col rounded-xl">
                <CpamInvoiceHeader
                    invoice={invoice}
                    handleSuspendInvoiceCpam={handleSuspendInvoiceCpam}
                    showInvoiceAction={true}
                    patient={patient}
                    checkAndHandleInvoiceForm={checkAndHandleInvoiceForm}
                    handlePrintInvoice={handlePrintInvoice}
                    prescription={prescription}
                    run={run}
                    ACTION={INVOICE_CPAM_ACTIONS}
                    dispatch={dispatch}
                />
                {invoice.id ? (
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
                        <InvoiceFormDetails invoice={invoice} />
                        <PaymentDetails
                            invoice={invoice}
                            rate={rate}
                            dispatch={dispatch}
                            INVOICE_CPAM_ACTIONS={INVOICE_CPAM_ACTIONS}
                            handleStatusChange={handleStatusChange}
                        />
                    </div>
                ) : null}
                <div className="flex flex-col py-4 pb-10 ">
                    {invoice.priceDetails ? (
                        <div className="flex grid-cols-2 flex-col gap-8 space-y-2 sm:grid sm:space-y-0 ">
                            {invoice.runsInvoiceFormsDetails
                                .sort((a, b) => {
                                    if (a.isReturnPath !== b.isReturnPath) {
                                        return a.isReturnPath ? 1 : -1 // false va venir avant true
                                    }
                                    return a.runId - b.runId
                                })
                                .map((runInvoiceDetails, index) => (
                                    <>
                                        <div key={runInvoiceDetails.runId}>
                                            <RunPricePanel
                                                runInvoiceDetails={
                                                    runInvoiceDetails
                                                }
                                                invoice={invoice}
                                                dispatch={dispatch}
                                                index={index}
                                            />
                                        </div>
                                        {run.isRoundTrip &&
                                        invoice.runsInvoiceFormsDetails
                                            .length === 1 ? (
                                            <div className="stripeBg grid grid-cols-2 gap-4 rounded-lg border-2 px-8 py-4 shadow-md">
                                                <div
                                                    className={
                                                        'col-span-2 flex h-14 items-center justify-between border-b py-2'
                                                    }
                                                >
                                                    <h2
                                                        className={
                                                            'text-xl font-semibold'
                                                        }
                                                    >
                                                        Transport{' '}
                                                        {runInvoiceDetails.isReturnPath
                                                            ? 'aller '
                                                            : 'retour '}
                                                        donné à un partenaire
                                                    </h2>
                                                </div>
                                            </div>
                                        ) : null}
                                    </>
                                ))}
                            <InvoiceTotalPanel
                                totals={invoice.totals}
                                rate={rate}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            {invoice.showInvoiceModalForm ? (
                <ModalSkeleton
                    label="Modifier le calcul"
                    handleCloseModal={handleCloseInvoiceModal}
                    handleForm={handleInvoiceModalForm}
                    Component={InvoiceCpamModalForm}
                    componentProps={{
                        priceBases: run.priceBases,
                        allowedToChangePriceBasis:
                            run.allowedToChangePriceBasis,
                        runs: runs,
                        invoiceCpamForm: invoice,
                        dispatch,
                        updatePriceDetails,
                    }}
                />
            ) : null}
            {invoice.showRejectHistory ? (
                <ModalSkeleton
                    label="Historique des rejets"
                    handleCloseModal={() =>
                        dispatch({
                            type: INVOICE_CPAM_ACTIONS.SET_SHOW_REJECT_HISTORY,
                            payload: false,
                        })
                    }
                    Component={InvoiceRejectionDetails}
                    componentProps={{
                        cpamInvoiceRejectionDetails:
                            invoice.cpamInvoiceRejectionDetails,
                    }}
                />
            ) : null}
            {showSelectDateModal ? (
                <SelectDateModalForm
                    date={statusDate}
                    handleCloseModal={() => setShowSelectDateModal(false)}
                    handleDateChange={handleDateChange}
                    handleForm={changeStatus}
                />
            ) : null}
        </div>
    )
}

export default TransportCpamInvoiceForm
