import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import navMenu from '../../services/menu'
import { ReactComponent as Logo } from '../../files/HEROAD_gray.svg'
import { BuildVersion } from '../../BuildVersion'
import TOOLS from '../../services/tools'
import classNames from 'classnames'
import { FastArrowLeft, MoreVert, NavArrowDown } from 'iconoir-react'
import { toast } from 'react-toastify'

const Navigator = ({
    isActive,
    toggleClass,
    isUser,
    setIsMenuIconActive,
    setSocketNotification,
    socketNotification,
    openNotifDropDown,
    setOpenNotifDropDown,
    loading,
    reloadNotif,
    setReloadNotif,
    notifications,
    openUserMenu,
    setOpenUserMenu,
}) => {
    const location = useLocation()
    const isUserAbleToInvoice = JSON.parse(localStorage.getItem('isInvoicer'))

    const [categories, setCategories] = useState(navMenu)
    const isPremium = localStorage.getItem('premium') !== 'false'
    const isFreeTrialEnded = localStorage.getItem('isFreeTrialEnded') === 'true'

    const actualLocation = (locate) => {
        return location.pathname.startsWith(locate)
    }

    let history = useNavigate()

    const menuRef = useRef()
    TOOLS.useOnClickOutside(menuRef, () => {
        setIsMenuIconActive(false)
    })

    useEffect(() => {
        const newCategories = categories.map((category) => {
            return {
                ...category,
                menu: category.menu.map((menu) => {
                    return {
                        ...menu,
                        active: actualLocation(menu.fatherUrl),
                        deploySubMenu:
                            menu.subMenu.length > 0 && actualLocation(menu.url),
                        subMenu: menu.subMenu.map((subMenu) => {
                            return {
                                ...subMenu,
                                active: location.pathname.includes(subMenu.url),
                            }
                        }),
                    }
                }),
            }
        })
        setCategories(newCategories)
    }, [location.pathname])

    const handleSelectedMenu = (
        event,
        menuId,
        url,
        subMenu,
        openSubMenuByDefault,
        externalLink
    ) => {
        if (subMenu.length > 0) {
            event.stopPropagation()
        }
        if (!externalLink) {
            const newCategories = categories.map((category) => {
                return {
                    ...category,
                    menu: category.menu.map((menu) => {
                        if (menu.id === menuId) {
                            return {
                                ...menu,
                                active: !menu.active,
                                deploySubMenu: subMenu.length > 0,
                                subMenu: menu.subMenu.map((subMenu) => {
                                    return {
                                        ...subMenu,
                                        active:
                                            subMenu.url === url ||
                                            location.pathname.includes(
                                                subMenu.url
                                            ),
                                    }
                                }),
                            }
                        } else {
                            const categoryWithMenu = categories.find(
                                (category) =>
                                    category.menu.some(
                                        (menu) => menu.id === menuId
                                    )
                            )

                            const newMenuSelected = categoryWithMenu.menu.find(
                                (menu) => menu.id === menuId
                            )

                            return {
                                ...menu,
                                // active: false,
                                active:
                                    newMenuSelected.subMenu.length > 0 &&
                                    !newMenuSelected.subMenu.some(
                                        (subMenu) => subMenu.active
                                    ) &&
                                    !newMenuSelected.openSubMenuByDefault
                                        ? menu.active
                                        : false,
                                deploySubMenu: false,
                                subMenu: menu.subMenu.map((subMenu) => {
                                    return {
                                        ...subMenu,
                                        active:
                                            newMenuSelected.subMenu.length ===
                                                0 ||
                                            newMenuSelected.openSubMenuByDefault
                                                ? false
                                                : subMenu.active,
                                    }
                                }),
                            }
                        }
                    }),
                }
            })
            setCategories(newCategories)
            if (!(subMenu.length > 0 && !openSubMenuByDefault)) {
                history(url)
            }
        } else {
            window.open(url, '_blank', 'noreferrer')
        }
    }

    const handleSelectedSubMenu = (subMenuId, subMenuUrl, menuId, isCpam) => {
        const newCategories = categories.map((category) => {
            return {
                ...category,
                menu: category.menu.map((menu) => {
                    if (menuId === menu.id) {
                        return {
                            ...menu,
                            subMenu: menu.subMenu.map((subMenu) => {
                                if (subMenu.id === subMenuId) {
                                    return {
                                        ...subMenu,
                                        active: true,
                                    }
                                } else {
                                    return {
                                        ...subMenu,
                                        active: false,
                                    }
                                }
                            }),
                        }
                    } else {
                        return {
                            ...menu,
                            active: false,
                            subMenu: menu.subMenu.map((subMenu) => {
                                return {
                                    ...subMenu,
                                    active: false,
                                }
                            }),
                        }
                    }
                }),
            }
        })
        if (!isCpam || isUserAbleToInvoice) {
            setCategories(newCategories)
            history(subMenuUrl)
        } else {
            toast.info('Cette fonctionnalité est en cours de développement')
        }
    }

    return (
        <div
            className={`sidenav h-screen max-h-screen justify-between text-xl font-medium lg:text-sm ${
                isActive ? 'active' : null
            }`}
            onClick={toggleClass}
            ref={menuRef}
        >
            <div className="flex w-full">
                <div className="flex h-screen max-h-screen w-2/3 flex-col overflow-y-auto bg-white lg:w-full">
                    <div className="flex items-center justify-between pr-4 pt-3 xl:pr-0">
                        <div className="logo px-4">
                            <Logo />
                        </div>
                        <div>
                            <button
                                className="mr-2 rounded-lg hover:bg-gray-300"
                                onClick={(event) => {
                                    event.stopPropagation()
                                    setSocketNotification(false)
                                    setReloadNotif(!reloadNotif) // TODO LE TEMPS DE FIX LA DECONNEXION DES WEBSOCKETS
                                    setOpenNotifDropDown(!openNotifDropDown)
                                }}
                            >
                                <div className="fas fa-bell relative px-2 text-3xl text-secondary-color lg:text-2xl">
                                    {!loading &&
                                    notifications.length > 0 &&
                                    notifications.some(
                                        (notification) => !notification.seen
                                    ) ? (
                                        <div>
                                            {/*<div className="absolute top-0 right-0 w-2 h-2 rounded-full bg-red-600 animate-ping" />*/}
                                            <div className="absolute right-0 top-0 h-2 w-2 rounded-full bg-red-600" />
                                        </div>
                                    ) : null}
                                </div>
                            </button>
                        </div>
                        <div className="sidenav__close-icon block xl:hidden">
                            <FastArrowLeft />
                        </div>
                    </div>
                    <div></div>
                    <div className="align-center items-center rounded-full px-6 pt-1">
                        <p className="align-center mt-auto text-secondary-color">
                            v{BuildVersion}
                        </p>
                    </div>
                    {process.env.REACT_APP_ENV !== 'prod' ? (
                        <div className="align-center items-center rounded-full px-6 pt-1">
                            <p className="align-center justify-center rounded-lg bg-purple-200 text-center font-bold text-purple-600">
                                {process.env.REACT_APP_ENV}
                            </p>
                        </div>
                    ) : null}
                    <div className="flex h-full flex-col pt-4">
                        <div className="flex flex-col">
                            {categories.map(({ id, menu }) => (
                                <div className="" key={id}>
                                    <div className="py-2 pl-4 pt-4 text-secondary-color">
                                        {id}
                                    </div>
                                    {menu.map(
                                        ({
                                            id: menuId,
                                            icon,
                                            url,
                                            openSubMenuByDefault,
                                            subMenu,
                                            active,
                                            externalLink,
                                            premiumOnly,
                                        }) => (
                                            <div
                                                key={menuId}
                                                className="flex flex-col py-1 pl-2"
                                            >
                                                <button
                                                    key={menuId}
                                                    onClick={(event) => {
                                                        handleSelectedMenu(
                                                            event,
                                                            menuId,
                                                            url,
                                                            subMenu,
                                                            openSubMenuByDefault,
                                                            externalLink
                                                        )
                                                    }}
                                                    className={classNames(
                                                        'flex w-72 items-center justify-between rounded-lg px-4 py-2 hover:bg-secondary-color hover:text-white lg:w-56',
                                                        {
                                                            'bg-secondary-color font-bold text-white':
                                                                active &&
                                                                subMenu.length ===
                                                                    0,
                                                            'bg-white font-bold text-secondary-color':
                                                                (active &&
                                                                    subMenu.length >
                                                                        0) ||
                                                                (!active &&
                                                                    subMenu.some(
                                                                        (
                                                                            subMenu
                                                                        ) =>
                                                                            subMenu.active
                                                                    )),
                                                        }
                                                    )}
                                                >
                                                    <div className="flex w-full space-x-2">
                                                        {(premiumOnly &&
                                                            !isPremium) ||
                                                        isFreeTrialEnded ? (
                                                            <i className="fa fa-lock px-2" />
                                                        ) : (
                                                            icon
                                                        )}
                                                        <span className="text-md">
                                                            {menuId}
                                                        </span>
                                                    </div>
                                                    {subMenu.length > 0 && (
                                                        <div>
                                                            {active ? (
                                                                <NavArrowDown className="rotate-180" />
                                                            ) : (
                                                                <NavArrowDown />
                                                            )}
                                                        </div>
                                                    )}
                                                </button>
                                                <div className="relative flex flex-col">
                                                    <span className="absolute ml-6 h-full border-l-2 border-secondary-color opacity-20" />
                                                    {active &&
                                                        subMenu.map(
                                                            ({
                                                                id: subMenuId,
                                                                url: subMenuUrl,
                                                                active: activeSubMenu,
                                                                isCpam: isCpam,
                                                            }) => (
                                                                <button
                                                                    key={
                                                                        subMenuId
                                                                    }
                                                                    onClick={() =>
                                                                        handleSelectedSubMenu(
                                                                            subMenuId,
                                                                            subMenuUrl,
                                                                            menuId,
                                                                            isCpam
                                                                        )
                                                                    }
                                                                    className={classNames(
                                                                        'z-10 mt-1 flex w-72 rounded-xl px-4 py-2 hover:bg-secondary-color hover:text-white lg:w-56',
                                                                        {
                                                                            'bg-secondary-color font-bold text-white':
                                                                                activeSubMenu,
                                                                            'text-gray-500':
                                                                                isCpam &&
                                                                                !isUserAbleToInvoice, // Grise le texte si isCpam est true
                                                                        }
                                                                    )}
                                                                >
                                                                    <span className="ml-7">
                                                                        {
                                                                            subMenuId
                                                                        }
                                                                    </span>
                                                                </button>
                                                            )
                                                        )}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className="flex h-full flex-col justify-center">
                            <div className="flex space-x-2 pb-4 pl-2 ">
                                <button
                                    className="flex w-72 cursor-pointer items-center justify-between rounded-full bg-gray-100 p-2 text-base hover:shadow-md lg:w-56"
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        setOpenUserMenu(!openUserMenu)
                                    }}
                                >
                                    <p>
                                        {`👋 Bonjour ${localStorage.getItem(
                                            'firstname'
                                        )}`}
                                    </p>
                                    <MoreVert className="text-lg hover:text-secondary-color" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="menu-clic-space w-1/3 sm:w-full "></div>
            </div>
        </div>
    )
}

export default Navigator
