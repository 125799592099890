import * as dayjs from 'dayjs'

export const StatusNotification = ({ statusNotification }) => {
    return (
        <div className="flex w-full items-center ">
            {statusNotification.type === 'status_ongoing' ? (
                <i className="fas fa-car-side min-w-2-5 text-blue-500" />
            ) : (
                <i className="fa fa-check-circle min-w-2-5  text-green-500" />
            )}
            <div className="flex flex-col pl-2 pr-1">
                <div className="wrapper text-notif flex text-left text-gray-700 ">
                    <a className={'font-bold capitalize'}>
                        {statusNotification.notifier.fullName}
                    </a>
                    <a>
                        {statusNotification.type === 'status_ongoing'
                            ? ' a pris en charge le transport de '
                            : ' a finalisé le transport de '}
                    </a>
                    <a className="capitalize underline">
                        {statusNotification.run.patient.fullName}
                    </a>
                </div>
                <p className="text-left text-xs">
                    {dayjs(statusNotification.createdAt).fromNow()}
                </p>
            </div>
            {!statusNotification.seen && (
                <div className="ml-auto">
                    <div className={`h-2 w-2 rounded-full bg-blue-500 `} />
                </div>
            )}
        </div>
    )
}
