// RunCard.jsx
import React, { useEffect, useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import dayjs from 'dayjs'
import RunInvoiceDetails from './RunInvoiceDetails'

const RunCard = ({
    run,
    runInvoiceDetails,
    dispatch,
    isSimpleRun,
    index,
    ACTION,
}) => {
    const [openedCardId, setOpenedCardId] = useState(
        isSimpleRun || index === 0 ? run.id : null
    )
    return (
        <div key={run.id}>
            <div
                className="parents relative flex w-full "
                onClick={() =>
                    setOpenedCardId(openedCardId === run.id ? null : run.id)
                }
            >
                <div
                    className={
                        '  flex w-full cursor-pointer select-none items-center justify-between space-x-4 rounded-lg border bg-white shadow-mobile sm:p-2'
                    }
                >
                    <div>{dayjs(run.date).local().format('DD/MM/YYYY')}</div>
                    <div>
                        {dayjs(run.departureTime).local().format('HH:mm')}-{' '}
                        {dayjs(run.arrivingTime).local().format('HH:mm')}
                    </div>
                    <div>
                        {run.directReturn ? 'Direct ' : null}
                        {run.isReturnPath ? 'Retour' : 'Aller'}{' '}
                    </div>
                    <div>{run.amount}€</div>
                    <div>{run.kilometer} km</div>
                    <div>
                        {run.pickUpLocation.city} - {run.depositLocation.city}
                    </div>
                    <div
                        className={`-left-14 top-0 hidden h-10  w-10 cursor-pointer rounded sm:absolute sm:block`}
                        onClick={() =>
                            setOpenedCardId(
                                openedCardId === run.id ? null : run.id
                            )
                        }
                    >
                        {openedCardId === run.id ? (
                            <ChevronUpIcon className="h-10 w-10" />
                        ) : (
                            <ChevronDownIcon className="h-10 w-10" />
                        )}
                    </div>
                    <div className={'absolute -left-6 -top-2'}>
                        <div className={'flex'}>
                            <span
                                className={
                                    ' h-5 w-5 rounded-full border bg-white text-center text-sm font-bold'
                                }
                            >
                                {index + 1}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'border-l-1 sm:border-l-4 sm:pl-3'}>
                <RunInvoiceDetails
                    runInvoiceDetails={runInvoiceDetails}
                    dispatch={dispatch}
                    runId={run.id}
                    index={index}
                    openedCardId={openedCardId}
                    ACTION={ACTION}
                />
            </div>
        </div>
    )
}

export default RunCard
