import React from 'react'
import {
    AddCircle,
    CheckCircle,
    Clock,
    Community,
    Group,
    Lock,
    PinAlt,
} from 'iconoir-react'
import { formatEuro } from '../../../Invoice/Manage'
import PrimaryButton from '../../../../Commons/Buttons/PrimaryButton'
import { findColorByTarifType } from '../../../../../services/InvoiceUtils'
import { INVOICE_CPAM_ACTIONS } from '../../Reducers/InvoiceCpamReducer'

const RunPricePanel = ({ runInvoiceDetails, invoice, dispatch, index }) => {
    const handleChangePrices = () => {
        dispatch({
            type: INVOICE_CPAM_ACTIONS.SET_SHOW_INVOICE_MODAL_FORM,
        })
    }

    const tarifs = [
        {
            name: 'compteur',
            icon: <Clock />,
        },
        {
            name: 'kilométrique',
            icon: <PinAlt />,
        },
        {
            name: 'forfaitaire',
            icon: <Lock />,
        },
    ]

    const getTarif = () => {
        if (
            runInvoiceDetails.isHorokilometrique &&
            !runInvoiceDetails.isForfait &&
            runInvoiceDetails.amount
        ) {
            return tarifs.find((tarif) => tarif.name === 'compteur')
        } else if (runInvoiceDetails.isForfait) {
            return tarifs.find((tarif) => tarif.name === 'forfaitaire')
        } else {
            return tarifs.find((tarif) => tarif.name === 'kilométrique')
        }
    }

    const getConditionsDisplay = (runInvoiceDetails) => {
        if (
            runInvoiceDetails.isHorokilometrique &&
            !runInvoiceDetails.isForfait &&
            !runInvoiceDetails.nbPeople > 1
        ) {
            return 'Compteur'
        } else if (runInvoiceDetails.isForfait) {
            return 'Forfaitaire'
        } else {
            return 'Kilométrique'
        }
    }

    const currentTarif = getTarif()

    const tarifLabel = (
        <div className={'flex items-center space-x-2'}>
            <span>{currentTarif.icon}</span>
            <p>{`Tarif ${runInvoiceDetails.priceBasis?.label}  ${currentTarif.name}`}</p>
        </div>
    )

    return (
        <div className="grid grid-cols-2 gap-4 rounded-lg border-2 bg-white px-8 py-4 shadow-md">
            <div
                className={
                    'col-span-2 flex h-14 items-center justify-between border-b py-2'
                }
            >
                <h2 className={'text-xl font-semibold'}>
                    Transport{' '}
                    {runInvoiceDetails.isReturnPath ? 'retour' : 'aller'}
                </h2>
                {runInvoiceDetails.nbPeople === 2 ? (
                    <Group className="mr-2 text-xl" />
                ) : runInvoiceDetails.nbPeople > 2 ? (
                    <Community className="mr-2 text-xl" />
                ) : null}
                {(!invoice.id && index === 0) ||
                (invoice?.status?.label === 'Créée' && index === 0) ? (
                    <div className={''}>
                        <PrimaryButton
                            label={'Modifier le calcul'}
                            action={handleChangePrices}
                        />
                    </div>
                ) : null}
            </div>
            <div className="col-span-2 pr-2 font-semibold">{tarifLabel}</div>
            {runInvoiceDetails.isHorokilometrique &&
            !runInvoiceDetails.isForfait &&
            runInvoiceDetails.amount ? (
                <HorokilometriquePrice runInvoiceDetails={runInvoiceDetails} />
            ) : runInvoiceDetails.isForfait ? (
                <ForfaitPrice runInvoiceDetails={runInvoiceDetails} />
            ) : (
                <RegularPrice runInvoiceDetails={runInvoiceDetails} />
            )}
            <>
                {runInvoiceDetails.runInvoiceSupplements.length > 0 ? (
                    <>
                        <div className="col-span-2 border-t pt-4 font-semibold">
                            Supplément remboursables
                        </div>
                        <div
                            className={
                                'col-span-2 w-full flex-col space-y-4 pl-2'
                            }
                        >
                            {runInvoiceDetails.runInvoiceSupplements.map(
                                (supplement, index) => (
                                    <div
                                        key={index}
                                        className={
                                            'flex w-full justify-between'
                                        }
                                    >
                                        <p className={'flex items-center '}>
                                            <AddCircle className={'mr-2'} />
                                            {supplement.label}
                                        </p>
                                        <p>{formatEuro(supplement.price)}</p>
                                    </div>
                                )
                            )}
                        </div>
                    </>
                ) : null}
                {runInvoiceDetails.pecAmount ? (
                    <div className="col-span-2 flex justify-between border-t pt-4">
                        <p className="font-semibold">Coût de prise en charge</p>
                        <div>{formatEuro(runInvoiceDetails.pecAmount)}</div>
                    </div>
                ) : null}
                {!runInvoiceDetails.isMinimumPerception &&
                !runInvoiceDetails.isForfait ? (
                    <>
                        <div className="col-span-2 border-t pr-2 pt-4 font-bold">
                            Remise
                        </div>
                        <div className={'col-span-1'}>
                            {runInvoiceDetails?.discount}%
                        </div>

                        <div className={'col-span-1 text-right'}>
                            -{formatEuro(runInvoiceDetails.totalDiscount)}
                        </div>
                    </>
                ) : null}
                {runInvoiceDetails.isMinimumPerception ? (
                    <div className="col-span-2 flex items-center justify-between border-t pt-4">
                        <p className="font-semibold"> Minimum de perception</p>
                        <CheckCircle className={'text-green-500'} />
                    </div>
                ) : null}
                {runInvoiceDetails.abatement?.value > 0 ? (
                    <>
                        <div className="col-span-2 flex justify-between border-t pt-4">
                            <p className={'font-bold'}>
                                Total avant abattement
                            </p>
                            <p>
                                {formatEuro(
                                    runInvoiceDetails.totalBeforeAbatement
                                )}
                            </p>
                        </div>
                        <div className="col-span-2 flex justify-between">
                            <div className={'flex items-center'}>
                                <p className={''}>
                                    Abattement de{' '}
                                    {runInvoiceDetails.abatement?.value}%
                                </p>
                                <i
                                    className="far fa-question-circle fa-lg cursor-pointer pl-2"
                                    title={
                                        'L’abattement est appliqué uniquement sur les montants remisés, les péages et autres suppléments non remisés ne sont pas concernés.'
                                    }
                                />
                            </div>
                            <p>
                                -{' '}
                                {formatEuro(
                                    runInvoiceDetails.totalBeforeAbatement -
                                        runInvoiceDetails.totalAmount
                                )}{' '}
                            </p>
                        </div>
                    </>
                ) : null}
                <div className="col-span-2 border-t pt-4 font-semibold">
                    <div className="-mx-4 flex justify-between rounded-lg bg-gray-100  p-4">
                        <p>Total du calcul </p>
                        <p>{formatEuro(runInvoiceDetails.totalAmount)}</p>
                    </div>
                </div>
            </>
        </div>
    )
}

export default RunPricePanel
const HorokilometriquePrice = ({ runInvoiceDetails }) => (
    <div className={'col-span-2 flex w-full items-center'}>
        <div className={'flex w-full flex-col space-y-4'}>
            {runInvoiceDetails.details?.map((detail) =>
                detail.kilometer !== 0 ? (
                    <div
                        key={detail.tarifType}
                        className={'flex w-full justify-between'}
                    >
                        <div className="flex space-x-2">
                            <div
                                className={`h-6 w-6 rounded-full border text-center ${findColorByTarifType(
                                    detail.tarifType
                                )}`}
                            >
                                {detail.tarifType}
                            </div>
                            <div>{detail.kilometer} km</div>
                        </div>
                    </div>
                ) : null
            )}
        </div>
        <div className={'font-semibold'}>
            {formatEuro(runInvoiceDetails.amount)}
        </div>
    </div>
)

const RegularPrice = ({ runInvoiceDetails }) => (
    <div className={'col-span-2 w-full flex-col space-y-4 '}>
        {runInvoiceDetails.details?.map((detail) =>
            detail.kilometer !== 0 ? (
                <div
                    key={detail.tarifType}
                    className={'flex w-full justify-between'}
                >
                    <div className="flex space-x-2">
                        <div
                            className={`h-6 w-6 rounded-full border text-center ${findColorByTarifType(
                                detail.tarifType
                            )}`}
                        >
                            {detail.tarifType}
                        </div>
                        <div>{detail.kilometer} km</div>
                        <div>X</div>
                        <div>{formatEuro(detail.price)}</div>
                    </div>
                    <div>{formatEuro(detail.total)}</div>
                </div>
            ) : null
        )}
    </div>
)

const ForfaitPrice = ({ runInvoiceDetails }) => (
    <div className={'col-span-2 w-full flex-col space-y-4'}>
        {runInvoiceDetails.details?.map((detail) =>
            detail.kilometer !== 0 ? (
                <div
                    key={detail.tarifType}
                    className={'flex w-full justify-between'}
                >
                    <div className="flex space-x-2">
                        <div
                            className={`h-6 w-6 rounded-full border text-center ${findColorByTarifType(
                                detail.tarifType
                            )}`}
                        >
                            {detail.tarifType}
                        </div>
                        <div>{detail.kilometer} km</div>
                    </div>
                    <div>FORFAIT</div>
                </div>
            ) : null
        )}
    </div>
)
