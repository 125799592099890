import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Manage from './Manage'
import InvoiceFilter from './InvoiceFilter'
import {
    useStickyDateState,
    useStickyEndDateState,
} from '../../../hooks/LocalStorageDatesHooks'
import Invoice from './Invoice'
import InvoiceConsult from './InvoiceConsult'

const InvoiceContainer = () => {
    const [search, setSearch] = useState('')
    const [archived, setArchived] = useState(false)

    const [dateFrom, setDateFrom] = useStickyDateState(new Date(), 'storedDate')
    const [dateTo, setDateTo] = useStickyEndDateState(
        new Date(),
        'storedDate',
        'storedEndDate'
    )
    const [openFilterModal, setOpenFilterModal] = useState(false)

    return (
        <Routes>
            <Route path="consultation/:id" element={<InvoiceConsult />} />
            <Route
                path=":id"
                element={
                    <Manage
                        setDateFrom={setDateFrom}
                        dateFrom={dateFrom}
                        setDateTo={setDateTo}
                        dateTo={dateTo}
                    />
                }
            />

            <Route
                path="/"
                element={
                    <div className="pb-10">
                        <InvoiceFilter
                            search={search}
                            setSearch={setSearch}
                            dateFrom={dateFrom}
                            setDateFrom={setDateFrom}
                            setDateTo={setDateTo}
                            dateTo={dateTo}
                            setArchived={setArchived}
                            archived={archived}
                            setOpenFilterModal={setOpenFilterModal}
                        />
                        <Invoice
                            search={search}
                            dateFrom={dateFrom}
                            setDateFrom={setDateFrom}
                            setDateTo={setDateTo}
                            dateTo={dateTo}
                            archived={archived}
                            setOpenFilterModal={setOpenFilterModal}
                            openFilterModal={openFilterModal}
                        />
                    </div>
                }
            />
        </Routes>
    )
}

export default InvoiceContainer
