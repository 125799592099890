// Actions
export const HEALTH_COMPLEMENTARY_ACTIONS = {
    SET_LABEL: 'SET_COMPLEMENTARY_LABEL',
    SET_NUM_ID: 'SET_NUM_ID',
    SET_ADDRESS: 'SET_ADDRESS',
    GET_RESULT: 'GET_RESULT',
    CLEAR_INPUT: 'CLEAR_INPUT',
    SET_IS_AME: 'SET_IS_AME',
    SET_IS_CSS: 'SET_IS_CSS',
    SET_IS_SPECIAL: 'SET_IS_SPECIAL',
}

// Initial state
export const getComplementaryInitialState = ({ initHealthComplementary }) => {
    if (initHealthComplementary) {
        return {
            ...initHealthComplementary,
        }
    } else {
        return {
            label: '',
            numId: '',
            address: null,
            isSpecial: false,
            isCSS: false,
            isAME: false,
        }
    }
}

// Reducer
export const healthComplementaryFormReducer = (state, action) => {
    switch (action.type) {
        case HEALTH_COMPLEMENTARY_ACTIONS.SET_LABEL:
            return {
                ...state,
                label: action.payload,
            }
        case HEALTH_COMPLEMENTARY_ACTIONS.SET_NUM_ID:
            return {
                ...state,
                numId: action.payload,
            }
        case HEALTH_COMPLEMENTARY_ACTIONS.SET_ADDRESS:
            return {
                ...state,
                address: {
                    ...state.address,
                    ...action.payload,
                },
            }
        case HEALTH_COMPLEMENTARY_ACTIONS.GET_RESULT:
            console.log(action.payload)
            return {
                ...state,
                ...action.payload,
            }
        case HEALTH_COMPLEMENTARY_ACTIONS.CLEAR_INPUT:
            return {
                label: '',
                numId: '',
                address: null,
            }
        case HEALTH_COMPLEMENTARY_ACTIONS.SET_IS_AME:
            return {
                ...state,
                isAME: action.payload,
                isCSS: false,
            }
        case HEALTH_COMPLEMENTARY_ACTIONS.SET_IS_CSS:
            return {
                ...state,
                isCSS: action.payload,
                isAME: false,
            }
        case HEALTH_COMPLEMENTARY_ACTIONS.SET_IS_SPECIAL:
            return {
                ...state,
                isSpecial: action.payload,
                isCSS: false,
                isAME: false,
            }
        default:
            return state
    }
}
