import React from 'react'
import { INVOICE_ACTIONS } from '../../../services/Reducers/InvoiceReducer'
import { formatRunData } from '../../../services/FormatRuns'

export default function Annexes({ dispatch, invoice }) {
    return (
        <div
            className={`flex items-center justify-center fixed left-0 bottom-0 pb-20 w-full h-full bg-gray-500 bg-opacity-50 text-gray-700 modal px-5`}
        >
            <div className="flex flex-col bg-white rounded-lg w-full max-w-7xl mx-auto shadow-modal">
                <div className="flex flex-col items-start lg:p-8 p-4">
                    <div className="flex items-center w-full pb-4 text-xl border-b">
                        <div className="flex space-x-4 font-semibold text-center ">
                            Annexes
                        </div>
                        <svg
                            className="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 18 18"
                            onClick={() => {
                                dispatch({
                                    type: INVOICE_ACTIONS.SHOW_ANNEXES,
                                    payload: false,
                                })
                            }}
                        >
                            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                        </svg>
                    </div>
                    <div className="flex flex-col items-start space-y-2 lg:p-4 p-2 w-full overflow-y-auto overflow-x-auto invisible-scrollbar min-h-modal">
                        {formatRunData(invoice.annexes).map((annexe) => (
                            <div
                                key={annexe.id}
                                className="grid lg:grid-cols-7 grid-cols-2 lg:grid-flow-col w-full gap-4  border-b lg:p-4 p-2 "
                            >
                                <div>{annexe.date}</div>
                                <div>{annexe.patient}</div>
                                <div>{annexe.horaire}</div>
                                <div className="flex flex-col">
                                    <div>{annexe.equipe}</div>
                                    <div>{annexe.vehicle}</div>
                                </div>
                                <div className="flex flex-col">
                                    <div>{annexe.pickUpLocation}.</div>
                                    <div>{annexe.depositLocation}.</div>
                                </div>
                                <div className="flex flex-col lg:text-center">
                                    <div>{annexe.objet}</div>
                                    <div>{annexe.isReturnPath}</div>
                                </div>
                                <div>{annexe.commentaire}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
