import dayjs from 'dayjs'
import {
    formatVehicleOptions,
    roundingMinutesToQuarter,
} from '../../../../tools/Utility'
import { BirthRank } from '../../../../services/BirthRank'
import RunTypes from '../../../../services/RunTypes'
import { TarifList } from '../../../../services/TarifList'
import { SharingOptionsByType } from '../../../../services/SharingOptions'

export const TRANSPORT_ACTIONS = {
    SET_DATE: 'setDate',
    SET_DEPARTURE_TIME: 'setDepartureTime',
    SET_ARRIVING_TIME: 'setArrivingTime',
    SET_WAITING_TIME: 'setWaitingTime',
    SHOW_MARGIN: 'showMargin',
    SET_MARGIN_TIME: 'setMarginTime',
    SET_RETURN_TIME: 'setReturnTime',
    SET_RUN_TYPE: 'setRunType',
    SHOW_PATIENT: 'showPatient',
    UPDATE_PATIENT: 'updatePatient',
    SET_PATIENT: 'setPatient',
    SET_PATIENT_SEARCH_INPUT: 'setPatientSearchInput',
    SET_RUN_OBJECT: 'setRunObject',
    SET_RUN_OBJECTS: 'setRunObjects',
    SHOW_PATIENT_MODAL: 'showPatientModal',
    SET_VEHICLE_TYPE: 'setVehicleType',
    SET_RUN: 'setRun',
    SET_PARAMETERS: 'setParameters',
    SHOW_AMOUNT: 'showAmount',
    SET_AMOUNT: 'setAmount',
    SET_TEAM: 'setTeam',
    SET_TEAMS: 'setTeams',
    SET_COMMENTS: 'setComments',
    SHOW_SHARING_OPTIONS: 'showSharingOptions',
    SHOW_PRESCRIPTION_DETAILS: 'showPrescriptionDetails',
    REMOVE_SHARE: 'removeShare',
    SET_PARTNER: 'setPartner',
    SET_PARTNERS: 'setPartners',
    IS_GIVEN_BY_PARTNER: 'isGivenByPartner',
    SET_SHARING_OPTIONS: 'setSharingOptions',
    SET_SHARING_OPTION: 'setSharingOption',
    SET_NOTE: 'setNote',
    SET_ERRORS: 'setErrors',
    POP_UP_MODAL: 'popUpModal',
    SET_LOADING: 'setLoading',
    UPDATE_ADDRESS_LABEL: 'updateAddressLabel',
    DELETE_ADDRESS: 'deleteAddress',
    APPROXIMATE_TIME: 'approximateTime',
    IS_CPAM_INVOICE: 'isCpamInvoice',
    SET_PRESCRIPTION_NUMBER: 'setPrescriptionNumber',
    SET_PRESCRIPTION_DATE: 'setPrescriptionDate',
    SET_KILOMETER: 'setKilometer',
    SET_DISTANCE_NOT_FOUND: 'setDistanceNotFound',
    SET_DISABLE_BUTTONS: 'setDisableButtons',
    UPDATE_STATUS: 'updateStatus',
    SET_DOCUMENTS: 'setDocuments',
    SET_EFFECTIVE_ARRIVING_TIME: 'setEffectiveArrivingTime',
    SET_EFFECTIVE_DEPARTURE_TIME: 'setEffectiveDepartureTime',
    SET_USER_COMMENTS: 'setUserComments',
}

export const getTransportInitialState = ({ date }) => {
    return {
        date: date,
        dateString: dayjs(date).local().format('YYYY-MM-DD'),
        departureTime: roundingMinutesToQuarter(date).toDate(),
        departureTimeError: false,
        arrivingTime: roundingMinutesToQuarter(date).add(1, 'hour').toDate(),
        arrivingTimeError: false,
        returnTime: roundingMinutesToQuarter(date).add(2, 'hour').toDate(),
        waitingTime: 20,
        showMargin: false,
        marginTime: 15,
        parametersMarginTime: 15,
        showPatientModal: false,
        isRoundTrip: false,
        isRoundTripReturn: false,
        patients: [],
        patientSearchInput: '',
        patient: null,
        runType: RunTypes[3],
        oldRunType: null,
        type: '',
        runObjects: [],
        runObject: {
            label: 'Consultation',
            value: 'Consultation',
            ['@id']: '/run_objects/Consultation',
        },
        showAmount: false,
        amount: null,
        loading: true,
        pickUpLocation: null,
        depositLocation: null,
        teams: [],
        team: null,
        comments: '',
        partners: [],
        partner: null,
        sharingOptions: [],
        sharingOption: SharingOptionsByType.whatsapp,
        note: '',
        isGiven: true,
        sharedAt: false,
        leadId: null,
        isDisabled: false,
        firstRunId: null,
        isReturnPath: false,
        status: null,
        approximateTime: false,
        timesChanged: false,
        showPrescriptionDetails: false,
        isCPAMInvoice: true,
        prsNumber: null,
        prsDate: date,
        prsTypeOfDeparture: null,
        prsTypeOfArriving: null,
        distanceNotFound: false,
        disableButtons: false,
        tarif: TarifList[0],
        couple: null,
        documents: null,
        priceBases: [],
        effectiveDepartureTime: null,
        effectiveArrivingTime: null,
        userComments: null,
    }
}

export const TransportFormReducer = (state, action) => {
    switch (action.type) {
        case TRANSPORT_ACTIONS.SET_DATE:
            let returnTimeHours
            let formatDate = dayjs(action.payload).local().format('YYYY-MM-DD')
            let departureTimeHours = dayjs(state.departureTime)
                .local()
                .format('HH:mm')
            let arrivingTimeHours = dayjs(state.arrivingTime)
                .local()
                .format('HH:mm')
            if (state.runType.value === 4) {
                returnTimeHours = dayjs(state.returnTime)
                    .local()
                    .format('HH:mm')
            }
            window.localStorage.setItem('storedDate', action.payload)
            window.localStorage.setItem('storedEndDate', action.payload)
            return {
                ...state,
                date: action.payload,
                team: null,
                dateString: formatDate,
                departureTime: dayjs(formatDate + departureTimeHours).toDate(),
                arrivingTime: dayjs(formatDate + arrivingTimeHours).toDate(),
                returnTime:
                    state.runType.value === 3 // Retour Direct
                        ? dayjs(formatDate + returnTimeHours).toDate()
                        : state.runType.value === 4 // Retour Indirect
                        ? dayjs(formatDate + returnTimeHours).toDate()
                        : null,
            }
        case TRANSPORT_ACTIONS.SET_DEPARTURE_TIME:
            return {
                ...state,
                departureTime: new Date(
                    dayjs(state.dateString + action.payload)
                ),
                timesChanged: true,
            }
        case TRANSPORT_ACTIONS.SET_ARRIVING_TIME:
            return {
                ...state,
                arrivingTime: new Date(
                    dayjs(state.dateString + action.payload)
                ),
                timesChanged: true,
            }
        case TRANSPORT_ACTIONS.SET_WAITING_TIME: {
            return {
                ...state,
                waitingTime: action.payload ? action.payload : 0,
                timesChanged: true,
            }
        }
        case TRANSPORT_ACTIONS.SET_RETURN_TIME: {
            return {
                ...state,
                returnTime: new Date(dayjs(state.dateString + action.payload)),
                timesChanged: true,
            }
        }
        case TRANSPORT_ACTIONS.SHOW_MARGIN: {
            return {
                ...state,
                showMargin: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.SET_MARGIN_TIME: {
            return {
                ...state,
                marginTime: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.SET_RUN_TYPE: {
            return {
                ...state,
                runType: action.payload,
                isReturnPath: action.payload.value === 2,
            }
        }
        case TRANSPORT_ACTIONS.SHOW_PATIENT: {
            return {
                ...state,
                showPatient: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.SET_PATIENT: {
            return {
                ...state,
                patient: action.payload ? action.payload.patient : null,
                birthRank: BirthRank.find(
                    (item) => item.value === action.payload?.birthRank
                ),
                runObject: action.payload?.patient.defaultRunObject
                    ? action.payload.patient.defaultRunObject
                    : state.runObject,
                vehicleType: action.payload?.patient.defaultType
                    ? action.payload.patient.defaultType
                    : state.vehicleType,
            }
        }
        case TRANSPORT_ACTIONS.SET_PATIENT_SEARCH_INPUT: {
            return {
                ...state,
                patients: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.SHOW_PATIENT_MODAL: {
            return {
                ...state,
                showPatientModal: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.SET_VEHICLE_TYPE: {
            return {
                ...state,
                vehicleType: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.UPDATE_PATIENT: {
            return {
                ...state,
                patient: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.SET_RUN:
            return {
                ...state,
                ...action.payload,
            }
        case TRANSPORT_ACTIONS.SET_PARAMETERS: {
            let runObject =
                action.payload.defaultVehicleType.label === 'VTC'
                    ? {
                          label: 'Course payante',
                          value: 'Course payante',
                          ['@id']: 'run_objects/Course payante',
                      }
                    : state.runObject
            return {
                ...state,
                waitingTime: action.payload.departureWaitingMargin,
                marginTime: action.payload.arrivingMargin,
                parametersMarginTime: action.payload.arrivingMargin,
                vehicleType: {
                    value: action.payload.defaultVehicleType.label,
                    label: action.payload.defaultVehicleType.label,
                    ['@id']: action.payload.defaultVehicleType['@id'],
                },
                vehiclesTypes: formatVehicleOptions(
                    action.payload.defaultVehiclesTypes
                ),
                runObject: runObject,
                priceBases: action.payload.priceBases,
                allowedToChangePriceBasis:
                    action.payload.allowedToChangePriceBasis,
                allowedToInvoiceWithoutAMC:
                    action.payload.allowedToInvoiceWithoutAMC,
            }
        }
        case TRANSPORT_ACTIONS.SET_RUN_OBJECTS: {
            return {
                ...state,
                runObjects: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.SET_RUN_OBJECT: {
            return {
                ...state,
                runObject: action.payload,
                showAmount: action.payload.label.includes('payant'),
            }
        }
        case TRANSPORT_ACTIONS.SHOW_AMOUNT: {
            return {
                ...state,
                showAmount: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.SET_AMOUNT: {
            return {
                ...state,
                amount: action.payload.replace(/[^0-9.]+/g, ''),
            }
        }
        case TRANSPORT_ACTIONS.SET_TEAM: {
            return {
                ...state,
                team: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.SET_TEAMS: {
            return {
                ...state,
                teams: action.payload,
                team:
                    action.payload.length > 0
                        ? state.team?.value === 0
                            ? null
                            : state.team
                        : { label: 'Aucune équipe disponible', value: 0 },
            }
        }
        case TRANSPORT_ACTIONS.SET_COMMENTS: {
            return {
                ...state,
                comments: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.SET_PARTNERS: {
            return {
                ...state,
                partners: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.SET_PARTNER: {
            return {
                ...state,
                partner: action.payload ? action.payload.partner : null,
            }
        }
        case TRANSPORT_ACTIONS.SHOW_SHARING_OPTIONS: {
            return {
                ...state,
                showSharingOptions: action.payload,
            }
        }

        case TRANSPORT_ACTIONS.SET_SHARING_OPTIONS: {
            return {
                ...state,
                sharingOptions: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.SET_SHARING_OPTION: {
            return {
                ...state,
                sharingOption: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.SHOW_PRESCRIPTION_DETAILS: {
            return {
                ...state,
                showPrescriptionDetails: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.IS_GIVEN_BY_PARTNER: {
            return {
                ...state,
                isGiven: action.payload,
                sharingOption: !action.payload ? null : state.sharingOption,
            }
        }
        case TRANSPORT_ACTIONS.SET_NOTE: {
            return {
                ...state,
                note: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.SET_ERRORS: {
            return {
                ...state,
                errors: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.POP_UP_MODAL: {
            return {
                ...state,
                popUpModal: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.SET_LOADING: {
            return {
                ...state,
                loading: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.APPROXIMATE_TIME: {
            return {
                ...state,
                approximateTime: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.IS_CPAM_INVOICE: {
            return {
                ...state,
                isCPAMInvoice: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.SET_PRESCRIPTION_NUMBER: {
            return {
                ...state,
                prsNumber: action.payload.substring(0, 15).trim(),
            }
        }
        case TRANSPORT_ACTIONS.SET_PRESCRIPTION_DATE: {
            return {
                ...state,
                prsDate: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.SET_KILOMETER: {
            return {
                ...state,
                kilometer: action.payload.replace(/[^0-9]+/g, ''),
                distanceNotFound: false,
            }
        }
        case TRANSPORT_ACTIONS.SET_DISTANCE_NOT_FOUND: {
            return {
                ...state,
                distanceNotFound: true,
            }
        }
        case TRANSPORT_ACTIONS.SET_DISABLE_BUTTONS: {
            return {
                ...state,
                disableButtons: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.UPDATE_STATUS: {
            return {
                ...state,
                status: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.REMOVE_SHARE: {
            return {
                ...state,
                sharingOption: null,
                note: null,
                partner: null,
                isGiven: true,
                sharedAt: undefined,
            }
        }
        case TRANSPORT_ACTIONS.SET_DOCUMENTS: {
            return {
                ...state,
                documents: action.payload,
            }
        }
        case TRANSPORT_ACTIONS.SET_EFFECTIVE_ARRIVING_TIME: {
            return {
                ...state,
                effectiveArrivingTime: new Date(
                    dayjs(state.dateString + action.payload)
                ),
            }
        }
        case TRANSPORT_ACTIONS.SET_EFFECTIVE_DEPARTURE_TIME: {
            return {
                ...state,
                effectiveDepartureTime: new Date(
                    dayjs(state.dateString + action.payload)
                ),
            }
        }
        case TRANSPORT_ACTIONS.SET_USER_COMMENTS: {
            return {
                ...state,
                userComments: action.payload,
            }
        }
        default:
            return state
    }
}
