export const RunStatus = [
    {
        value: 'todo',
        label: 'A faire',
        ['@id']: '/statuses/todo',
        description: 'Statut initial lors de la création du transport',
    },
    {
        value: 'canceled',
        label: 'Annulé',
        ['@id']: '/statuses/canceled',
        description: 'Transport annulé',
    },
    {
        value: 'ongoing',
        label: 'En cours',
        ['@id']: '/statuses/ongoing',
        description:
            'Statut affecté lors de la prise en charge par un chauffeur',
    },
    {
        value: 'done',
        label: 'Fait',
        ['@id']: '/statuses/done',
        description:
            'Statut affecté lors de la finalisation du transport par le chauffeur',
    },
    {
        value: 'charged',
        label: 'Facturé',
        ['@id']: '/statuses/charged',
        description:
            'Statut affecté lorsque le transport possède un facture associée',
    },
    {
        value: 'transmitted',
        label: 'Transmis',
        ['@id']: '/statuses/transmitted',
        description:
            'Statut affecté lorsque le transport a été transmis au logiciel de facturation',
    },
    {
        value: 'closed',
        label: 'Cloturé',
        ['@id']: '/statuses/closed',
        description:
            'Statut indiquant que la facture a été payée et que les lots ont été clôturés',
    },
]

export const getBorderColor = (status, position = null) => {
    //tailwind doesn't accept ${}

    if (position === 'right') {
        switch (status) {
            case 'done':
                return 'border-r-green-500'
            case 'todo':
                return 'border-r-gray-500'
            case 'closed':
                return 'border-r-purple-500'
            case 'charged':
                return 'border-r-brown'
            case 'transmitted':
                return 'border-r-fuchsia-400'
            case 'canceled':
                return 'border-r-orange-500'
            case 'ongoing':
                return 'border-r-blue-500'
            default:
                return 'border-r-blue-500'
        }
    } else if (position === 'left') {
        switch (status) {
            case 'done':
                return 'border-l-green-500'
            case 'todo':
                return 'border-l-gray-500'
            case 'closed':
                return 'border-l-purple-500'
            case 'charged':
                return 'border-l-brown'
            case 'transmitted':
                return 'border-l-fuchsia-400'
            case 'canceled':
                return 'border-l-orange-500'
            case 'ongoing':
                return 'border-l-blue-500'
            default:
                return 'border-l-blue-500'
        }
    } else {
        switch (status) {
            case 'done':
                return 'border-green-500'
            case 'todo':
                return 'border-gray-500'
            case 'closed':
                return 'border-purple-500'
            case 'charged':
                return 'border-brown'
            case 'transmitted':
                return 'border-fuchsia-400'
            case 'canceled':
                return 'border-orange-500'
            case 'ongoing':
                return 'border-blue-500'
            default:
                return 'border-blue-500'
        }
    }
}

export const getTextAndBackgroundColor = (status) => {
    switch (status.toLowerCase()) {
        case 'done':
        case 'fait':
            return 'text-green-500 bg-green-100'
        case 'todo':
        case 'a faire':
            return 'text-gray-500 bg-gray-100'
        case 'charged':
        case 'facturé':
            return 'text-brown bg-light-brown'
        case 'transmitted':
        case 'transmis':
            return 'text-fuchsia-500 bg-fuchsia-100'
        case 'canceled':
        case 'annulé':
            return 'text-orange-500 bg-orange-100'
        case 'ongoing':
        case 'en cours':
            return 'text-blue-500 bg-blue-100'
        case 'closed':
        case 'cloturé':
            return 'text-purple-500 bg-purple-100'
        default:
            return 'text-gray-500 bg-gray-100'
    }
}

export const hoverColorMap = {
    todo: 'hover:bg-gray-100',
    done: 'hover:bg-green-100',
    charged: 'hover:bg-light-brown',
    transmitted: 'hover:bg-fuchsia-100',
    canceled: 'hover:bg-orange-100',
    ongoing: 'hover:bg-blue-100',
    closed: 'hover:bg-purple-100',
}

export const getBgFromStatusLabel = (status) => {
    switch (status) {
        case 'Fait':
            return 'text-green-500 bg-green-500'
        case 'A faire':
            return 'text-gray-500 bg-gray-500'
        case 'Facturé':
            return 'text-brown bg-brown'
        case 'Transmis':
            return 'text-fuchsia-500 bg-fuchsia-500'
        case 'Annulé':
            return 'text-orange-500 bg-orange-500'
        case 'En cours':
            return 'text-blue-500 bg-blue-500'
        case 'Cloturé':
            return 'text-purple-500 bg-purple-500'
        default:
            return 'text-gray-500 bg-gray-500'
    }
}

export const getTextAndBackgroundColorWithBorders = (status) => {
    switch (status.toLowerCase()) {
        case 'done':
        case 'fait':
            return 'text-green-500 bg-green-100 border-green-500'
        case 'todo':
        case 'a faire':
            return 'text-gray-500 bg-gray-100 border-gray-500'
        case 'charged':
        case 'facturé':
            return 'text-brown bg-light-brown border-brown'
        case 'transmitted':
        case 'transmis':
            return 'text-fuchsia-500 bg-fuchsia-100 border-fuchsia-500'
        case 'canceled':
        case 'annulé':
            return 'text-orange-500 bg-orange-100 border-orange-500'
        case 'ongoing':
        case 'en cours':
            return 'text-blue-500 bg-blue-100 border-blue-500'
        case 'closed':
        case 'cloturé':
            return 'text-purple-500 bg-purple-100 border-purple-500'
        default:
            return 'text-gray-500 bg-gray-100 border-gray-500'
    }
}
