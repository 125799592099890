import { OpenInWindow } from 'iconoir-react'
import FormDateInput from '../../../Commons/Inputs/FormDateInput'
import SelectDropDownListV2 from '../../../Commons/DropDownLists/SelectDropDownListV2'
import React from 'react'
import {
    Exonerations,
    NatureAssurances,
} from '../../../../services/Prescription'
import { PRESCRIPTION_FORM_ACTIONS } from '../Reducers/PrescriptionFormReducer'
import { PRACTITIONER_FORM_ACTIONS } from '../Reducers/PractitionerFormReducer'
import { formatRunObject } from '../../../../tools/Utility'
import { checkPECErrors } from '../PatientModal'
import { PATIENT_FORM_ACTIONS } from '../Reducers/PatientFormReducer'
import ModalSkeleton from '../../../Modal/ModalSkeleton'
import PractitionerForm from './PractitionerForm'
import { PecModal } from '../../Patient/PEC/PecModal'
import ClassicInputV2 from '../../../Commons/Inputs/ClassicInputV2'
import ClassicSwitch from '../../../Commons/Inputs/ClassicSwitch'
import { DATE_ACTIONS } from '../../../../services/Reducers/DateReducer'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import { checkAndPrintErrorIfRunIsAbleToBeTransfered } from '../../../../services/fetchRunsInformations'
import { formatCaisseLabel } from '../../Patient/PatientFormContent'
import { useMutation } from '@tanstack/react-query'
import { handlePractitionerForm } from '../../../../services/API/Entities/practitioner'

const formatPractitionerLabel = (practitioner) => {
    if (practitioner?.label || practitioner?.lastname) {
        // check if the practitioner has a label or not
        let label = practitioner?.label
            ? practitioner?.label
            : `${practitioner.lastname}  ${practitioner.firstname}`
        return {
            label: label, // ${practitioner.address}
            value: practitioner['@id'],
            object: practitioner,
            ['@id']: practitioner['@id'],
        }
    }
}

export const formatPractitionerOptions = (list) => {
    const options = []
    list.forEach((option) => {
        const data = formatPractitionerLabel(option)
        options.push(data)
    })
    return options
}

export const formatCaisseOptions = (list) => {
    const options = []
    list.forEach((option) => {
        const data = formatCaisseLabel(option)
        options.push(data)
    })
    return options
}

const PrescriptionForm = ({
    prescription,
    runForm,
    dispatch,
    prsDateInput,
    prsDateDispatch,
    realDateDispatch,
    realDateInput,
    workAccidentDate,
    workAccidentDateDispatch,
    dateMaternity,
    dateMaternityDispatch,
    patientForm,
    birthDate,
    practitionerForm,
    priorApprovalDate,
    priorApprovalDateDispatch,
}) => {
    const handleClosePractitionerModal = () => {
        dispatch({
            type: PRACTITIONER_FORM_ACTIONS.SET_SHOW_MODAL,
            payload: false,
        })
    }

    const practitionerMutation = useMutation({
        mutationFn: handlePractitionerForm,
        onSuccess: (data) => {
            handleClosePractitionerModal()
            dispatch({
                type: PRACTITIONER_FORM_ACTIONS.FETCH_PRACTITIONER,
                payload: data,
            })
            dispatch({
                type: PRESCRIPTION_FORM_ACTIONS.SET_PRACTITIONER,
                payload: data,
            })
        },
    })

    const handlePractitionerModalForm = async () => {
        practitionerMutation.mutate(practitionerForm)
    }

    const handleChangeNatureAssurance = (e) => {
        dispatch({
            type: PRESCRIPTION_FORM_ACTIONS.SET_NATURE_ASSURANCE,
            payload: e,
        })
        if (e.value !== '41') {
            dateMaternityDispatch({
                type: DATE_ACTIONS.ERASE_DATE,
            })
        } else if (e.value !== '30') {
            workAccidentDateDispatch({
                type: DATE_ACTIONS.ERASE_DATE,
            })
            dispatch({
                type: PRESCRIPTION_FORM_ACTIONS.SET_WORK_ACCIDENT_NUMBER,
                payload: null,
            })
        }
    }

    return (
        <div className="flex flex-col space-y-4 pb-10">
            <div className="flex w-full items-center justify-between px-4 lg:px-0">
                <h1 className="py-10 text-2xl font-semibold ">
                    Détails de la prescription
                </h1>
                <div>
                    <button
                        onClick={() => {
                            if (!checkPECErrors(patientForm, birthDate, true)) {
                                dispatch({
                                    type: PATIENT_FORM_ACTIONS.SHOW_PEC_MODAL,
                                    payload: true,
                                })
                            }
                        }}
                        className="transition-color flex w-full  items-center justify-center rounded border border-light-dark-green bg-light-dark-green px-4 py-2 text-center font-bold text-white duration-200 hover:bg-dark-green"
                        title="PEC"
                    >
                        Consulter les droits PEC+
                        <OpenInWindow className={'ml-auto '} />
                    </button>
                </div>
            </div>
            <div
                className={`min-h-row-date flex w-full flex-wrap items-center rounded-xl bg-white p-2 pb-10 pt-10 shadow-md lg:pt-5`}
            >
                <div className="min-h-row-date flex w-full flex-col items-center space-y-5 px-2 lg:flex-row lg:space-x-10 lg:space-y-0 lg:px-5 lg:pb-3 lg:pt-5">
                    <div className="flex w-full pb-10 lg:w-1/2 lg:pb-5">
                        <FormDateInput
                            label="Date de prescription *"
                            date={prsDateInput}
                            dispatch={prsDateDispatch}
                        />
                    </div>
                </div>
                <div className="min-h-row-date flex w-full flex-col items-center space-y-5 px-2 lg:flex-row lg:space-x-10 lg:space-y-0 lg:px-5">
                    <div className="flex w-full pb-10 lg:w-1/2 lg:pb-5 ">
                        <SelectDropDownListV2
                            label="Nature d'assurance (Maladie, AT, etc.)"
                            value={formatRunObject(
                                prescription.natureAssurance
                            )}
                            handleOptionChange={handleChangeNatureAssurance}
                            id="assurance"
                            options={NatureAssurances}
                            placeholder="Maladie"
                            showLabel={true}
                        />
                    </div>
                    <div className="flex w-full pb-10 lg:w-1/2 lg:pb-5 ">
                        <SelectDropDownListV2
                            label="Taux applicable"
                            value={prescription.rate}
                            handleOptionChange={(e) =>
                                dispatch({
                                    type: PRESCRIPTION_FORM_ACTIONS.SET_RATE,
                                    payload: e,
                                })
                            }
                            options={prescription.rateOptions}
                            id="taux"
                            placeholder="55%"
                            showLabel={true}
                        />
                    </div>
                </div>
                {prescription.natureAssurance?.value === '41' && (
                    <div className="min-h-row-date flex w-full flex-col items-center justify-between space-y-5 px-2 lg:flex-row lg:space-x-10 lg:space-y-0 lg:px-5">
                        <div className="flex w-full pb-10 lg:w-1/3 lg:pb-5 ">
                            <FormDateInput
                                label="Date de l'AT"
                                date={workAccidentDate}
                                dispatch={workAccidentDateDispatch}
                            />
                        </div>
                        <span className={'justify-center text-center lg:w-1/3'}>
                            OU
                        </span>
                        <div className="flex w-full pb-10 lg:w-1/3 lg:pb-5 ">
                            <ClassicInputV2
                                showLabel={true}
                                label={'Numéro AT'}
                                value={prescription.workAccidentNumber}
                                setValue={(e) =>
                                    dispatch({
                                        type: PRESCRIPTION_FORM_ACTIONS.SET_WORK_ACCIDENT_NUMBER,
                                        payload: e.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                )}
                {prescription.natureAssurance?.value === '30' && (
                    <div className="min-h-row-date flex w-full flex-col items-center space-y-5 px-2 lg:flex-row lg:space-x-10 lg:space-y-0 lg:px-5">
                        <div className="flex w-full pb-10 lg:w-1/2 lg:pb-5 ">
                            <FormDateInput
                                label="Date maternité"
                                date={dateMaternity}
                                dispatch={dateMaternityDispatch}
                            />
                        </div>
                    </div>
                )}
                <div className="min-h-row-date flex w-full flex-col items-center space-y-5 px-2 lg:flex-row lg:space-x-10 lg:space-y-0 lg:px-5">
                    <div className="flex w-full pb-10 lg:w-1/2 lg:pb-5 ">
                        <SelectDropDownListV2
                            label="Praticien / établissement"
                            options={prescription.practitionersOptions}
                            value={formatPractitionerLabel(
                                prescription.practitioner
                            )}
                            handleOptionChange={(e) => {
                                dispatch({
                                    type: PRESCRIPTION_FORM_ACTIONS.SET_PRACTITIONER,
                                    payload: e?.object,
                                })
                                dispatch({
                                    type: PRACTITIONER_FORM_ACTIONS.FETCH_PRACTITIONER,
                                    payload: e?.object,
                                })
                            }}
                            addOption={() =>
                                dispatch({
                                    type: PRACTITIONER_FORM_ACTIONS.SET_SHOW_MODAL,
                                    payload: true,
                                })
                            }
                            isClearable={true}
                            placeholder="Docteur Dupont"
                            showLabel={true}
                        />
                    </div>
                    <div className="flex w-full pb-10 lg:w-1/2 lg:pb-5 ">
                        <SelectDropDownListV2
                            label="Exo ticket modérateur"
                            value={prescription.exoTicketModerateur}
                            handleOptionChange={(e) =>
                                dispatch({
                                    type: PRESCRIPTION_FORM_ACTIONS.SET_EXONERATION,
                                    payload: e,
                                })
                            }
                            isSearchable={false}
                            options={Exonerations}
                            id="prescriptionNumber"
                            placeholder="Pas d'exonération"
                            showLabel={true}
                        />
                    </div>
                </div>
                <div className="min-h-row-date flex w-full flex-col space-y-5 px-2 lg:flex-row lg:items-center lg:space-x-10 lg:space-y-0 lg:px-5">
                    <div className="flex items-center justify-between pb-4 lg:w-1/2">
                        <div className={'flex w-3/4 flex-col lg:w-2/3'}>
                            <p className="text-lg font-semibold">
                                Accord préalable
                            </p>
                            <p className="">
                                Est-ce que le transport fait l'objet d'un accord
                                préalable avec l'AMO ?
                            </p>
                        </div>
                        <div className={'w-1/4 lg:w-1/3'}>
                            <ClassicSwitch
                                setValue={() => {
                                    dispatch({
                                        type: PRESCRIPTION_FORM_ACTIONS.SET_IS_PRIOR_APPROVAL,
                                        payload: !prescription.isPriorApproval,
                                    })
                                }}
                                value={prescription.isPriorApproval}
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-between pb-4 lg:w-1/2">
                        <div className={'flex w-3/4 flex-col lg:w-2/3'}>
                            <p className="text-lg font-semibold">
                                Transport à mobilité réduite
                            </p>
                            <p className="">
                                Est-ce que le transport est un TPMR ?
                            </p>
                        </div>
                        <div className={'w-1/4 lg:w-1/3'}>
                            <ClassicSwitch
                                setValue={() =>
                                    dispatch({
                                        type: PRESCRIPTION_FORM_ACTIONS.SET_IS_TPMR,
                                        payload: !prescription.isTPMR,
                                    })
                                }
                                value={prescription.isTPMR}
                            />
                        </div>
                    </div>
                </div>
                {prescription.isPriorApproval && (
                    <div className="min-h-row-date flex w-full flex-col items-center space-y-5 px-2 lg:flex-row lg:space-x-10 lg:space-y-0 lg:px-5">
                        <div className="flex w-full pb-10 lg:w-1/2 lg:pb-5 ">
                            <FormDateInput
                                label="Date d'accord préalable"
                                date={priorApprovalDate}
                                dispatch={priorApprovalDateDispatch}
                            />
                        </div>
                    </div>
                )}
                {runForm?.id !== '0' && runForm !== undefined ? (
                    <div className="flex w-full flex-col items-center px-2 pt-5 lg:flex-row  lg:space-y-0 lg:px-5 ">
                        <div className={'flex w-full'}>
                            <SecondaryButton
                                action={() =>
                                    checkAndPrintErrorIfRunIsAbleToBeTransfered(
                                        runForm,
                                        patientForm,
                                        prsDateInput
                                    )
                                }
                                label="Vérification CPAM"
                                title="Vérification CPAM"
                                hiddenLabelOnMobile={false}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
            {practitionerForm.showModal ? (
                <ModalSkeleton
                    handleCloseModal={handleClosePractitionerModal}
                    label={`${
                        practitionerForm['@id'] ? 'Modifier' : 'Ajouter'
                    } établissement/praticien`}
                    buttonLabel={
                        practitionerForm['@id'] ? 'Modifier' : 'Ajouter'
                    }
                    Component={PractitionerForm}
                    componentProps={{
                        practitioner: practitionerForm,
                        dispatch: dispatch,
                    }}
                    handleForm={handlePractitionerModalForm}
                    disabledSubmit={practitionerMutation.isLoading}
                />
            ) : null}
            {patientForm.showPecModal && (
                <PecModal
                    dateOfBirth={birthDate}
                    patientDispatch={dispatch}
                    patient={patientForm}
                    prsDateDispatch={prsDateDispatch}
                    prsDateInput={prsDateInput}
                    realDateDispatch={realDateDispatch}
                    realDateInput={realDateInput}
                />
            )}
        </div>
    )
}

export default PrescriptionForm
