import TimesAndType from './TimesAndType'
import AddressesInline from '../../../Commons/AddressesInline'
import { Minus, Trash } from 'iconoir-react'
import React, { useEffect, useReducer, useRef } from 'react'
import { SERIES_ACTIONS } from '../../../../services/Reducers/SeriesReducer'
import {
    ADDRESS_ACTIONS,
    AddressAutoCompleteReducer,
    getAddressInitialState,
} from '../../../../services/Reducers/AddressAutoCompleteReducer'
import { GoogleAutoComplete } from '../../GoogleAutoComplete'
import ClassicSwitch from '../../../Commons/Inputs/ClassicSwitch'
import ClassicTextarea from '../../../Commons/Inputs/ClassicTextarea'
import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'
import DeleteButton from '../../../Commons/Buttons/DeleteButton'

const DayConfigurator = ({
    series,
    dispatch,
    day,
    index,
    globalRunDepositAddress,
    globalRunPickUpAddress,
}) => {
    const removeDayFromCollection = (dayNumber) => {
        dispatch({
            type: SERIES_ACTIONS.REMOVE_DAY,
            payload: dayNumber,
        })
    }

    const [pickUpAddress, pickUpDispatch] = useReducer(
        AddressAutoCompleteReducer,
        { undefined },
        getAddressInitialState
    )

    const [depositAddress, depositDispatch] = useReducer(
        AddressAutoCompleteReducer,
        { undefined },
        getAddressInitialState
    )

    const [returnDepositAddress, returnDepositDispatch] = useReducer(
        AddressAutoCompleteReducer,
        { undefined },
        getAddressInitialState
    )

    const inputPickUp = useRef()
    const inputDeposit = useRef()

    const setDefaultAddresses = () => {
        //return deposit
        if (day.returnDepositLocation) {
            returnDepositDispatch({
                type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                payload: day.returnDepositLocation,
            })
        }

        //set pickup ADDRESS ACTION
        //if address missing set global pickUpLocation
        if (day.pickUpLocation && day.pickUpLocation.city) {
            pickUpDispatch({
                type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                payload: day.pickUpLocation,
            })
        } else {
            pickUpDispatch({
                type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                payload: globalRunPickUpAddress,
            })
        }

        //set pickup ADDRESS ACTION
        //if address missing set global depositLocation
        if (day.depositLocation && day.depositLocation.city) {
            depositDispatch({
                type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                payload: day.depositLocation,
            })
        } else {
            depositDispatch({
                type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                payload: globalRunDepositAddress,
            })
        }
    }

    const setCommentsToFirstDay = () => {
        dispatch({
            type: SERIES_ACTIONS.SET_DAY_COMMENTS,
            payload: {
                day: day.day,
                comments: series.comments,
            },
        })
    }

    useEffect(() => {
        setDefaultAddresses()
        if (index === 0 && !day.comments) {
            setCommentsToFirstDay()
        }
    }, [])

    useEffect(() => {
        day.pickUpLocation = pickUpAddress
    }, [pickUpAddress])

    useEffect(() => {
        day.depositLocation = depositAddress
    }, [depositAddress])

    useEffect(() => {
        day.returnDepositLocation = returnDepositAddress
    }, [returnDepositAddress])

    return (
        <div className="p flex flex-col space-y-4">
            <TimesAndType
                series={series}
                day={day}
                dispatch={dispatch}
                index={index}
            />
            <div className="min-h-row-date flex w-full">
                <AddressesInline
                    depositAddress={depositAddress}
                    depositDispatch={depositDispatch}
                    pickUpDispatch={pickUpDispatch}
                    inputDeposit={inputDeposit}
                    series={series}
                    pickUpAddress={pickUpAddress}
                    inputPickUp={inputPickUp}
                />
            </div>
            {(day.runType.value === 3 || day.runType.value === 4) && (
                <div className="flex w-full flex-col space-y-4 lg:flex-row lg:justify-between lg:space-y-0">
                    <div className="flex max-h-16">
                        <ClassicSwitch
                            label="Lieu de retour différent"
                            value={day.differentReturnDeposit}
                            setValue={() =>
                                dispatch({
                                    type: SERIES_ACTIONS.SET_DAY_IS_DIFFERENT_RETURN_DEPOSIT,
                                    payload: {
                                        day: day.day,
                                        differentReturnDeposit:
                                            !day.differentReturnDeposit,
                                    },
                                })
                            }
                            info={true}
                            infoLabel={
                                "Permet d'ajouter un lieu de retour différent du lieu de prise en charge"
                            }
                        />
                    </div>
                    {day.differentReturnDeposit && (
                        <div className="w-return-deposit">
                            <GoogleAutoComplete
                                dispatch={returnDepositDispatch}
                                address={returnDepositAddress}
                                savedAddresses={
                                    series.globalRunInformations.patient
                                        ?.patientsAddresses
                                        ? series.globalRunInformations.patient
                                              ?.patientsAddresses
                                        : []
                                }
                                label="Lieu de retour"
                                ref={inputPickUp}
                            />
                        </div>
                    )}
                </div>
            )}
            <div className="flex lg:w-1/2">
                <ClassicTextarea
                    setValue={(e) =>
                        dispatch({
                            type: SERIES_ACTIONS.SET_DAY_COMMENTS,
                            payload: {
                                day: day.day,
                                comments: e.target.value,
                            },
                        })
                    }
                    value={day.comments}
                    label={'Commentaire'}
                />
            </div>
            {index !== 0 && (
                <div className="flex justify-end">
                    <DeleteButton
                        label="Supprimer"
                        title="Supprimer le jour"
                        action={() => removeDayFromCollection(day.day)}
                        hiddenLabelOnMobile={true}
                        icon={<Trash />}
                    />
                </div>
            )}
        </div>
    )
}

export default DayConfigurator
