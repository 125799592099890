import ClassicSwitch from '../../../../Commons/Inputs/ClassicSwitch'
import ClassicInputV2 from '../../../../Commons/Inputs/ClassicInputV2'
import SelectDropDownListV2 from '../../../../Commons/DropDownLists/SelectDropDownListV2'
import { ABATEMENT } from '../../../../../services/InvoiceUtils'
import React from 'react'
import { RUN_INVOICE_FORM_DETAILS_ACTIONS } from './RunInvoiceFormDetailsReducer'

const SharedRunSection = ({ dispatch, invoiceCpamForm }) => {
    return (
        <>
            <div className="flex items-center justify-between space-x-2 pb-4">
                <div className={'flex w-4/5 flex-col'}>
                    <p className="text-lg font-semibold">Transport partagé</p>
                    <p className="">
                        Est-ce que le transport est a été partagé avec un autre
                        patient ?
                    </p>
                </div>
                <div className={'w-1/5'}>
                    <ClassicSwitch
                        setValue={() =>
                            dispatch({
                                type: RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_SHARED_RUN,
                                payload: !invoiceCpamForm.isShared,
                            })
                        }
                        value={invoiceCpamForm.isShared}
                    />
                </div>
            </div>
            {invoiceCpamForm.isShared ? (
                <div className={'pb-4'}>
                    <div className="col-span-2 flex justify-between space-x-5">
                        <ClassicInputV2
                            label="Nb de personnes"
                            className="w-full"
                            value={invoiceCpamForm.nbPeople}
                            setValue={(e) =>
                                dispatch({
                                    type: RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_NB_PEOPLE,
                                    payload: e.target.value,
                                })
                            }
                            placeholder="0"
                            showLabel={true}
                        />
                        <SelectDropDownListV2
                            label="Abattement %"
                            className="w-full"
                            value={
                                invoiceCpamForm.abatement
                                    ? invoiceCpamForm.abatement
                                    : ABATEMENT[0]
                            }
                            options={ABATEMENT}
                            handleOptionChange={(e) =>
                                dispatch({
                                    type: RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_ABATEMENT,
                                    payload: e,
                                })
                            }
                            placeholder="%"
                            showLabel={true}
                        />
                    </div>
                </div>
            ) : null}
        </>
    )
}

export default SharedRunSection
