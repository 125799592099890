export const VehicleTypes = [
    {
        value: 1,
        label: 'VSL',
        ['@id']: '/types/VSL',
    },
    {
        value: 2,
        label: 'Ambulance',
        ['@id']: '/types/Ambulance',
    },
    {
        value: 3,
        label: 'Taxi',
        ['@id']: '/types/Taxi',
    },
    {
        value: 4,
        label: 'VTC',
        ['@id']: '/types/VTC',
    },
    {
        value: 5,
        label: 'VTM',
        ['@id']: '/types/VTM',
    },
    {
        value: 6,
        label: 'Autocar',
        ['@id']: '/types/Autocar',
    },
]

export default VehicleTypes
