import React, { useState } from 'react'
import NoData from '../App/NoData'
import { useNavigate, useLocation } from 'react-router-dom'
import Loader from '../Commons/Loaders/Loader'
import { DriverLegendModal } from '../Modal/DriverLegendModal'
import { ChatLines } from 'iconoir-react'

const ScheduleRunsList = ({
    onlyUserRuns,
    showUnassignedRun,
    rows,
    userColor,
    loading,
    uniqueMasterRuns,
    isHidedPlanning,
}) => {
    const location = useLocation()
    let history = useNavigate()
    const [showLegendModal, setShowLegendModal] = useState(false)

    const historyPlanningPush = (id) => {
        history(location.pathname + '/run/' + id)
    }

    return (
        <div className="flex flex-col">
            {loading ? (
                <Loader />
            ) : (
                <div className="p-2 pb-10 text-xl text-gray-600">
                    <div className="px-2⎄ flex w-full items-center justify-between text-lg font-semibold">
                        <button
                            className={`flex items-center`}
                            onClick={() => setShowLegendModal(true)}
                        >
                            <p className="pr-2 font-bold underline underline-offset-4">
                                Légende
                            </p>
                            <i className="far fa-question-circle fa-lg cursor-pointer pr-2" />
                        </button>
                        <h3 className="">
                            Validé(s) :
                            {` ${
                                rows.filter(
                                    (r) => r.status && r.status.id === 'done'
                                ).length
                            } / ${rows.length}`}
                        </h3>
                    </div>
                    {isHidedPlanning() ? (
                        <NoData message="Vous n'avez pas encore accès à ce planning" />
                    ) : rows.length > 0 ? (
                        <div className="flex w-full flex-col pb-4">
                            {rows.map((row) => (
                                <div
                                    key={row.id}
                                    onClick={() => historyPlanningPush(row.id)}
                                    className={`relative flex h-28 min-h-full w-full cursor-pointer  items-center overflow-hidden rounded border-l-8 bg-white p-2 shadow-mobile hover:bg-gray-100
                                   ${
                                       row.isUserRun && row.status.id === 'done'
                                           ? 'border-green-500'
                                           : row.isUserRun &&
                                             row.status.id === 'charged'
                                           ? 'border-purple-500'
                                           : row.isUserRun
                                           ? 'border-blue-500'
                                           : 'border-gray-500'
                                   }

                                    ${
                                        !onlyUserRuns &&
                                        (row.isUserRun
                                            ? 'border-green-500'
                                            : row.isNotAssignedRun
                                            ? 'border-gray-500'
                                            : 'border-blue-400')
                                    }
                                    ${
                                        !row.departureOrder
                                            ? 'mt-4'
                                            : row.departureOrder === 1
                                            ? 'mt-4'
                                            : ''
                                    }

                                    ${
                                        row.status.id === 'canceled'
                                            ? 'stripeBg_canceled'
                                            : 'bg-white'
                                    }
                               `}
                                    style={{
                                        borderColor:
                                            (!onlyUserRuns &&
                                                row.isNotAssignedRun) ||
                                            !row.masterRun
                                                ? 'border-gray-500'
                                                : row.masterRun.vehicle.color,
                                    }}
                                >
                                    <div
                                        className={`flex w-2/12 flex-col items-center space-y-1 font-semibold lg:text-lg ${
                                            row.approximateTime &&
                                            row.isReturnPath &&
                                            'italic'
                                        } `}
                                    >
                                        <span className="px-2 tracking-wide ">
                                            {row.startHour}
                                        </span>
                                        {(!onlyUserRuns &&
                                            row.isNotAssignedRun) ||
                                        !row.masterRun ? (
                                            <span>-</span>
                                        ) : (
                                            <span
                                                className={
                                                    'rounded-full p-1 text-sm uppercase text-white'
                                                }
                                                style={{
                                                    backgroundColor:
                                                        (!onlyUserRuns &&
                                                            row.isNotAssignedRun) ||
                                                        !row.masterRun
                                                            ? 'border-gray-500'
                                                            : row.masterRun
                                                                  .vehicle
                                                                  .color,
                                                }}
                                            >
                                                {row.firstUserInitial}
                                            </span>
                                        )}
                                        <span className="px-2 tracking-wide">
                                            {row.endHour}
                                        </span>
                                    </div>
                                    <div className="flex w-8/12 flex-col space-y-1 pl-2">
                                        <p className="truncate  font-semibold">
                                            {row.fullname}
                                        </p>
                                        {row.pickUpLocation.label &&
                                        row.pickUpLocation.label !==
                                            row.pickUpLocation.city ? (
                                            <p className="truncate">
                                                {row.pickUpLocation.label}
                                            </p>
                                        ) : (
                                            <p className="truncate">
                                                {row.pickUpLocation.street
                                                    ? row.pickUpLocation
                                                          .street +
                                                      (row.pickUpLocation
                                                          .secondaryStreet
                                                          ? ', ' +
                                                            row.pickUpLocation
                                                                .secondaryStreet
                                                          : '') +
                                                      ' - ' +
                                                      row.pickUpLocation.city
                                                    : row.pickUpLocation.city}
                                            </p>
                                        )}
                                        {row.depositLocation.label &&
                                        row.depositLocation.label !==
                                            row.depositLocation.city ? (
                                            <p className="truncate">
                                                {row.depositLocation.label}
                                            </p>
                                        ) : (
                                            <p className="truncate">
                                                {row.depositLocation.street
                                                    ? row.depositLocation
                                                          .street +
                                                      (row.depositLocation
                                                          .secondaryStreet
                                                          ? ', ' +
                                                            row.depositLocation
                                                                .secondaryStreet
                                                          : '') +
                                                      ' - ' +
                                                      row.depositLocation.city
                                                    : row.depositLocation.city}
                                            </p>
                                        )}
                                    </div>
                                    <div className="flex w-1/12 items-center justify-center">
                                        {row.departureOrder && (
                                            <span className=" inline-block text-center font-bold text-gray-800 ">
                                                {row.departureOrder}
                                            </span>
                                        )}
                                    </div>
                                    <div className="flex w-1/12 items-center justify-center">
                                        {row.status &&
                                            row.status.id === 'done' && (
                                                <span className="inline-block h-6 w-6 rounded bg-green-500 text-center font-bold text-white">
                                                    <i className="fa fa-check text-white " />
                                                </span>
                                            )}
                                    </div>
                                    <span className="absolute right-0 top-0 flex flex-col space-y-2 p-2">
                                        {row.approximateTime &&
                                            row.isReturnPath && (
                                                <i className="far fa-question-circle pr-2" />
                                            )}
                                        {row.isReturnPath && (
                                            <i className="far fa-registered" />
                                        )}
                                        {row.comments && (
                                            <ChatLines className="text-sm" />
                                        )}
                                    </span>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <NoData message="Aucune course disponible" />
                    )}
                </div>
            )}
            {showLegendModal && (
                <DriverLegendModal
                    setShowLegendModal={setShowLegendModal}
                    userColor={userColor}
                    uniqueMasterRuns={uniqueMasterRuns}
                    onlyUserRuns={onlyUserRuns}
                />
            )}
        </div>
    )
}

export default ScheduleRunsList
