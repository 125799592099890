import { Route, Routes } from 'react-router-dom'
import React from 'react'
import RunInformation from './RunInformation'
import ScheduleRunsContainer from './ScheduleRunsContainer'

const MobileUserContainer = () => {
    return (
        <Routes>
            <Route path="/run/:id" element={<RunInformation />} />
            <Route path="/" element={<ScheduleRunsContainer />} />
        </Routes>
    )
}

export default MobileUserContainer
