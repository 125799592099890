import React from 'react'
import dayjs from 'dayjs'

const DateMobileFirstFormat = ({ row }) => {
    return (
        <div className="flex w-28 justify-center">
            {dayjs(row.date).local().format('DD/MM/YYYY')}
        </div>
    )
}

export default DateMobileFirstFormat
