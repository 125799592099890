import React from 'react'
import dayjs from 'dayjs'

const TimesAndTimeValidation = ({ row, isComputer }) => {
    return (
        <div
            className={`flex w-1/6 items-center align-middle justify-between ${
                isComputer ? 'lg:text-lg lg:px-2' : 'text-base pt-2 '
            } ${row.approximateTime && row.isReturnPath && 'italic'}`}
        >
            {row.status.id === 'done' ? (
                <div
                    className={
                        row.status.id === 'done'
                            ? 'flex font-semibold justify-center items-center text-center w-full'
                            : ''
                    }
                >
                    {dayjs(row.updateStatusAt)
                        .local()
                        .format('HH:mm')
                        .replace(':', 'h')}
                    {row.userComments && row.isUserCommentsRed ? (
                        <i
                            className={`fas fa-comment-slash pl-2 ${
                                isComputer ? 'block' : 'hidden'
                            }`}
                        />
                    ) : row.userComments && !row.isUserCommentsRed ? (
                        <i
                            className={`far fa-comment pl-2 ${
                                isComputer ? 'block' : 'hidden'
                            }`}
                        />
                    ) : (
                        <span className="w-6 sm:hidden" />
                    )}
                </div>
            ) : (
                <div
                    className={`flex ${
                        isComputer ? 'flex-row' : 'flex-col'
                    } justify-center w-full`}
                >
                    <div className="flex justify-center">
                        {dayjs(row.departureTime)
                            .local()
                            .format('HH:mm')
                            .replace(':', 'h')}
                    </div>
                    <div className={`${isComputer ? 'block' : 'hidden'}`}>
                        -
                    </div>
                    <div className="flex justify-center">
                        {dayjs(row.arrivingTime)
                            .local()
                            .format('HH:mm')
                            .replace(':', 'h')}{' '}
                    </div>
                    <div className="flex items-center">
                        {row.userComments && row.isUserCommentsRed ? (
                            <i
                                className={`fas fa-comment-slash pl-2 ${
                                    isComputer ? 'block' : 'hidden'
                                }`}
                            />
                        ) : row.userComments && !row.isUserCommentsRed ? (
                            <i
                                className={`far fa-comment pl-2 ${
                                    isComputer ? 'block' : 'hidden'
                                }`}
                            />
                        ) : (
                            <span
                                className={`w-6 ${!isComputer && 'hidden'}`}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default TimesAndTimeValidation
