import React, { useEffect, useRef, useState } from 'react'
import API from '../../services/api'
import jwt from 'jwt-decode'
import Heroad from '../../files/HEROAD2_gray.svg'
import { useNavigate } from 'react-router-dom'
import { BuildVersion } from '../../BuildVersion'
import Gleap from 'gleap'
import { HmacSHA256 } from 'crypto-js'
import { useUser } from '../../context/UserContext'
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import { SignIn } from './SignIn'
import { SignInFirstConnection } from './SignInFirstConnection'
import { DeviceManager } from '../../DeviceManager'

export const gleapLogin = async (userId, firstName, email, phone, society) => {
    if (userId) {
        const hmac = HmacSHA256(
            userId.toString(),
            process.env.REACT_APP_GLEAP_PRIVATE_KEY
        )
        const userHash = hmac.toString() // Convert the hash to a hex string
        const deviceId = localStorage.getItem('deviceId')
        // if exist get deviceId
        // Now use the hash for the user identification
        Gleap.identify(
            userId.toString(),
            {
                name: firstName,
                email: email,
                phone: phone,
                companyName: society,
            },
            userHash
        )
    }
}

const SignInContainer = (key, value) => {
    const [username, setUsername] = useState('')
    const usernameRef = React.useRef(username)
    const [password, setPassword] = useState('')
    const passwordRef = React.useRef(password)
    const [error, setError] = useState('')
    const [passwordShown, setPasswordShown] = useState(false)
    const [loading, setLoading] = useState(false)
    let history = useNavigate()
    const hasRedirected = useRef(false)
    const { login } = useUser()
    const isMobile = /iPhone|Android/i.test(navigator.userAgent)

    const [firstConnection, setFirstConnection] = useState(false)

    const getUser = async () => {
        if (hasRedirected.current) return
        try {
            const response = await API.Authentication.getUser()
            const data = await response.json()

            await gleapLogin(
                data.id,
                data.firstname,
                data.email,
                data.phoneNumber,
                data.society
            )

            const newPath =
                data.roles.find((r) => r === 'ROLE_ADMIN') && !isMobile
                    ? '/transport'
                    : '/chauffeur'

            history(newPath)
        } catch (error) {
            console.error('Error fetching user data:', error)
            // Handle the error appropriately
        }
    }

    const handleLogin = async () => {
        setLoading(true)
        try {
            let userToken = await login(
                usernameRef.current,
                passwordRef.current
            )
            document.cookie = 'token=' + userToken.token + '; path=/'
            localStorage.setItem('token', userToken.token)

            const user = jwt(userToken.token)

            try {
                await AsyncLocalStorage.setItem('@token', userToken.token)
            } catch (e) {
                // error
            }

            // Store user data in local storage
            const userData = {
                id: user.id,
                email: user.email,
                phone: user.phoneNumber,
                firstname: user.firstname,
                premium: user.premium,
                unlimitedSms: user.unlimitedSms,
                societyWelcomePresentationPassed:
                    user.societyWelcomePresentationPassed,
                isAbleToInvoice: user.isAbleToInvoice,
                isAbleToTransfer: user.isAbleToTransfer,
                role: user.roles.some((r) => r.includes('ADMIN'))
                    ? 'ADMIN'
                    : 'USER',
                isInvoicer: user.roles.some((r) => r.includes('ROLE_INVOICER')),
                isMaintainer: user.roles.some((r) =>
                    r.includes('ROLE_MAINTAINER')
                ),
                society: user.society,
            }

            Object.entries(userData).forEach(([key, value]) => {
                if (value !== undefined) {
                    localStorage.setItem(key, value)
                }
            })

            if (user.preferences?.planningView) {
                localStorage.setItem(
                    'planningView',
                    user.preferences.planningView
                )
            }
            DeviceManager.getDeviceId()

            await gleapLogin(
                user.id,
                user.firstname,
                user.email,
                user.phoneNumber,
                user.society
            )

            let redirectUrl = '/chauffeur'
            if (user.registrated) {
                if (!isMobile && localStorage.getItem('role') !== 'USER') {
                    redirectUrl = '/transport'
                }
            } else {
                redirectUrl = !user.society
                    ? '/inscription/societe'
                    : !user.plan
                    ? '/inscription/plans'
                    : '/inscription/recapitulatif'
            }
            history(redirectUrl)
        } catch (error) {
            setError('Vérifier votre identifiant / mot de passe')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getUser()
        const keyDownListener = (event) => {
            if (event.keyCode === 13) {
                handleLogin()
            }
        }
        document.addEventListener('keydown', keyDownListener, false)
        return () => {
            document.removeEventListener('keydown', keyDownListener, false)
        }
    }, [])

    return (
        <div className="flex h-screen flex-col">
            <div className="mx-auto flex w-full max-w-md pt-24 text-gray-800 lg:max-w-lg lg:pt-20 xl:max-w-xl">
                <div className="mx-2 flex w-full flex-col">
                    <div className="mr-auto">
                        <img
                            alt="typo"
                            src={Heroad}
                            className="w-60 pb-10 pl-2"
                        />
                    </div>
                    <div className="login-shadow w-full rounded-lg bg-white p-8 sm:p-16">
                        {firstConnection ? (
                            <SignInFirstConnection
                                username={username}
                                setUsername={setUsername}
                                usernameRef={usernameRef}
                                password={password}
                                setPassword={setPassword}
                                passwordRef={passwordRef}
                                passwordShown={passwordShown}
                                setPasswordShown={setPasswordShown}
                                error={error}
                                handleLogin={handleLogin}
                                loading={loading}
                                setError={setError}
                                setFirstConnection={setFirstConnection}
                            />
                        ) : (
                            <SignIn
                                username={username}
                                setUsername={setUsername}
                                usernameRef={usernameRef}
                                passwordRef={passwordRef}
                                password={password}
                                setPassword={setPassword}
                                passwordShown={passwordShown}
                                setPasswordShown={setPasswordShown}
                                error={error}
                                handleLogin={handleLogin}
                                loading={loading}
                            />
                        )}
                    </div>
                    {!firstConnection && (
                        <div className="mr-auto flex pl-2 pt-5 text-xl lg:text-base">
                            <button
                                className="cursor-pointer underline hover:font-semibold hover:text-dark-green"
                                onClick={() => {
                                    setFirstConnection(true)
                                    setError('')
                                }}
                            >
                                Première connexion ?
                            </button>
                        </div>
                    )}
                    <div className="mr-auto flex flex-wrap pl-2 pt-5 text-xl lg:text-base">
                        <div className="pr-1">Vous n'avez pas de compte ?</div>

                        <button
                            className="cursor-pointer underline hover:font-semibold hover:text-dark-green"
                            onClick={() =>
                                (window.location.href =
                                    'https://heroad.io#demo')
                            }
                        >
                            Contactez-nous
                        </button>
                        {/*<span*/}
                        {/*    className="cursor-pointer hover:font-semibold hover:text-dark-green"*/}
                        {/*    onClick={() =>*/}
                        {/*        (window.location.href = '/inscription')*/}
                        {/*    }*/}
                        {/*>*/}
                        {/*    S'inscrire*/}
                        {/*</span>*/}
                    </div>
                </div>
            </div>
            <div className="mx-auto mb-5 mt-auto flex w-full max-w-md pl-4 lg:max-w-lg xl:max-w-xl">
                Heroad © 2024 - contact@heroad.io - v{BuildVersion}
            </div>
        </div>
    )
}

export default SignInContainer
