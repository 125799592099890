import React, { useCallback, useRef, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { HealthComplementaries } from '../../../../services/API/Entities/healthComplementaries'
import SelectDropDownListV3 from '../../../Commons/DropDownLists/SelectDropDownListV3'
import { formatOptions } from '../../../../tools/Utility'
import { PayingCenters } from '../../../../services/API/Entities/payingCenters'
import { Patient } from '../../../../services/API/Entities/patient'
import { debounce } from 'lodash'
import ClassicSwitch from '../../../Commons/Inputs/ClassicSwitch'

const CpamInvoiceFilterModal = ({
    patient,
    setPatient,
    setPayingCenter,
    payingCenter,
    healthComplementary,
    setHealthComplementary,
    outstandingBalance,
    setOutstandingBalance,
}) => {
    const [patientSearch, setPatientSearch] = useState('')

    const debouncedSearchRef = useRef(
        debounce((searchTerm) => {
            setPatientSearch(searchTerm)
        }, 600)
    ).current

    const {
        data: healthComplementaries = [],
        isLoading: isLoadingHealthComplementaries,
    } = useQuery({
        queryKey: ['healthComplementaries'],
        queryFn: () => HealthComplementaries.fetchAll(),
    })

    const { data: payingCenters = [], isLoading: isLoadingPayingCenters } =
        useQuery({
            queryKey: ['payingCenters'],
            queryFn: () => PayingCenters.fetchAll(),
        })

    const { data: patients = [], isLoading: isLoadingPatients } = useQuery({
        queryKey: ['patients', patientSearch],
        queryFn: () => Patient.fetchAllForDebounceSearch(patientSearch),
        enabled: patientSearch.trim() !== '', // Désactivez le fetch automatique
        keepPreviousData: true,
    })

    // Fonction de debounce pour la recherche de patients
    const handlePatientSearchChange = useCallback(
        (inputValue) => {
            debouncedSearchRef(inputValue)
        },
        [debouncedSearchRef]
    )

    return (
        <div className={'grid w-full grid-cols-2 gap-4 py-5'}>
            <SelectDropDownListV3
                label="Complémentaire santé"
                options={formatOptions(healthComplementaries)}
                isLoading={isLoadingHealthComplementaries}
                placeholder="Sélectionnez une complémentaire santé"
                isSearchable={true}
                value={healthComplementary}
                isClearable={true}
                // Ajoutez d'autres props nécessaires ici
                handleOptionChange={(selectedOption) => {
                    setHealthComplementary(selectedOption)
                }}
            />
            <SelectDropDownListV3
                label="Centre payeur"
                options={formatOptions(payingCenters)}
                isLoading={isLoadingPayingCenters}
                placeholder="Sélectionnez un centre payeur"
                isSearchable={true}
                isClearable={true}
                value={payingCenter}
                // Ajoutez d'autres props nécessaires ici
                handleOptionChange={(selectedOption) => {
                    setPayingCenter(selectedOption)
                }}
            />
            <SelectDropDownListV3
                label="Patient"
                options={patients}
                isLoading={isLoadingPatients}
                placeholder="Sélectionnez un patient"
                isClearable={true}
                isSearchable={true}
                value={patient}
                handleOptionChange={(selectedOption) => {
                    setPatient(selectedOption)
                }}
                handleSearchInputPatientChange={handlePatientSearchChange}
            />
            <ClassicSwitch
                label="Reste en dû"
                value={outstandingBalance}
                setValue={() => {
                    setOutstandingBalance(!outstandingBalance)
                }}
                info={true}
                infoLabel={'Afficher les factures impayées'}
            />
        </div>
    )
}

export default CpamInvoiceFilterModal
