import React, { useEffect, useState } from 'react'
import Series from './Series'
import SeriesFilter from './SeriesFilter'
import { Route, Routes } from 'react-router-dom'
import { useStickyDateState } from '../../../hooks/LocalStorageDatesHooks'
import { SERIES_ACTIONS } from '../../../services/Reducers/SeriesReducer'
import API from '../../../services/api'
import { useDebounce } from '../../../hooks/useDebounce'
import { Queue } from 'workbox-background-sync'
import SeriesForm from './Manage/Forms/SeriesForm'

const formatData = (series) => {
    const formattedSeries = series.map((s) => {
        if (s.runs.length > 0) {
            if (s.newSeries) {
                const patientName = `${s.patient.lastname} ${s.patient.firstname}`
                const runObject = s.runObject.label
                const firstRunDate = s.firstRunDate
                const lastRunDate = s.lastRunDate
                return {
                    ...s,
                    patientName: patientName,
                    runObject: runObject,
                    firstRunDate: new Date(firstRunDate),
                    lastRunDate: new Date(lastRunDate),
                    nbRuns: s.runs.length,
                    nbRunsChargedOrDone: s.runs.filter(
                        (run) =>
                            run.status.id === 'done' ||
                            run.status.id === 'charged' ||
                            run.status.id === 'transmitted'
                    ).length,
                    newSeries: s.newSeries,
                }
            } else {
                const patientName = `${s.runs[0].patient.lastname} ${s.runs[0].patient.firstname}`
                const runObject = s.runs[0].runObject.label
                const sortedRuns = s.runs.sort(function (a, b) {
                    return new Date(a.date) - new Date(b.date)
                })
                const firstRunDate = new Date(sortedRuns[0].date)
                const firstRunId =
                    sortedRuns[0].isRoundTrip && sortedRuns[0].isReturnPath
                        ? sortedRuns[0].lead.id
                        : sortedRuns[0].id

                const lastRunDate = new Date(sortedRuns.reverse()[0].date)

                return {
                    ...s,
                    patientName: patientName,
                    runObject: runObject,
                    firstRunDate: firstRunDate,
                    lastRunDate: lastRunDate,
                    nbRuns: s.runs.length,
                    nbRunsChargedOrDone: s.runs.filter(
                        (run) =>
                            run.status.id === 'done' ||
                            run.status.id === 'charged' ||
                            run.status.id === 'transmitted'
                    ).length,
                    firstRunId: firstRunId,
                    newSeries: s.newSeries,
                }
            }
        }
    })
    return formattedSeries.filter((f) => f)
}

const SeriesContainer = (props) => {
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [archived, setArchived] = useState(false)
    const [dateFrom, setDateFrom] = useStickyDateState(new Date(), 'storedDate')
    const [needsRefresh, setNeedsRefresh] = useState(false)

    const [rows, setRows] = useState([])
    const [totalItems, setTotalItems] = useState(0)
    const [loading, setLoading] = useState(true)
    const [firstLoading, setFirstLoading] = useState(true)

    const fetchRunsSeriesList = () => {
        setLoading(true)
        API.Series.list(search, null, null, archived).then((response) => {
            response.json().then((data) => {
                setRows(formatData(data['hydra:member']))
                setTotalItems(data['hydra:totalItems'])
                setFirstLoading(false)
                setLoading(false)
                setNeedsRefresh(false)
            })
        })
    }

    useDebounce(() => fetchRunsSeriesList(), 1000, [search])

    useEffect(() => {
        fetchRunsSeriesList()
    }, [archived])

    return (
        <div className="h-full w-full">
            <Routes>
                <Route
                    path="/"
                    element={
                        <div>
                            <SeriesFilter
                                {...props}
                                search={search}
                                setSearch={setSearch}
                                setPage={setPage}
                                archives={archived}
                                setArchives={setArchived}
                                needsRefresh={needsRefresh}
                                setNeedsRefresh={setNeedsRefresh}
                            />
                            <Series
                                {...props}
                                search={search}
                                setPage={setPage}
                                page={page}
                                archived={archived}
                                rows={rows}
                                fetchRunsSeriesList={fetchRunsSeriesList}
                                firstLoading={firstLoading}
                                totalItems={totalItems}
                                loading={loading}
                            />
                        </div>
                    }
                />
                <Route
                    path=":id"
                    element={
                        <SeriesForm
                            date={dateFrom}
                            ACTIONS={SERIES_ACTIONS}
                            fetchRunsSeriesList={fetchRunsSeriesList}
                        />
                    }
                />
            </Routes>
        </div>
    )
}

export default SeriesContainer
