import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import PrimaryButton from '../Commons/Buttons/PrimaryButton'
import { Plus } from 'iconoir-react'

const NoData = ({
    handleAction,
    labelAction,
    message,
    action,
    setAction,
    add,
    link,
    icon,
}) => {
    let history = useNavigate()
    return (
        <div className="align-center mt-20 flex flex-col justify-center  px-4 text-center text-3xl text-light-dark-green">
            <div>{message}</div>
            {handleAction && (
                <div
                    onClick={() => {
                        handleAction()
                        setAction(!action)
                    }}
                    className="flex cursor-pointer items-center justify-center  pt-2 underline "
                >
                    <div>{labelAction}</div>
                </div>
            )}

            {add ? (
                <div className="flex flex-col justify-center">
                    <div className="py-4">Ou</div>
                    <div className="flex  justify-center text-xl">
                        <PrimaryButton
                            action={() => history('/transport/0')}
                            label="Ajouter un nouveau transport"
                            icon={<Plus className="text-xl" />}
                            hiddenLabelOnMobile={true}
                        />
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default NoData
