import API from '../api'
import { formatSavedAddresses } from '../address'
import dayjs from 'dayjs'
import { RegimeList } from '../RegimeList'
import { BirthRank } from '../BirthRank'

export const PATIENT_ACTIONS = {
    SET_FIRSTNAME: 'setFirstname',
    SET_LASTNAME: 'setLastname',
    SET_BIRTHDATE: 'setBirthdate',
    SET_PHONE_NUMBER: 'setPhoneNumber',
    SET_DESCRIPTION: 'setDescription',
    SET_SOCIAL_NUMBER: 'setSocialNumber',
    IS_PATIENT: 'isPatient',
    SET_DEFAULT_PICK_UP_ADDRESS: 'setDefaultPickUpAddress',
    SET_DEFAULT_DEPOSIT_ADDRESS: 'setDefaultDepositAddress',
    ALLOW_ADD_PATIENT: 'addPatient',
    EDIT_PATIENT: 'editPatient',
    GET_PATIENT: 'getPatient',
    CLEAR_PATIENT: 'CLEAR_PATIENT',
    SET_ERROR: 'setError',
    UPDATE_ADDRESS: 'updateAddress',
    UPDATE_ADDRESS_LABEL: 'updateAddressLabel',
    UPDATE_ADDRESS_INFO: 'updateAddressInfo',
    ADD_ADDRESS: 'addAddress',
    REMOVE_ADDRESS: 'removeAddress',
    DELETE_ADDRESS: 'deleteAddress',
    ADD_CONTACT: 'addContact',
    REMOVE_CONTACT: 'removeContact',
    UPDATE_CONTACT_INFO: 'updateContactInfo',
    DELETE_CONTACT: 'deleteContact',
    CLEAR_ID_FROM_ADDRESSES: 'clearIdFromAddresses',
    REMOVE_LOADING: 'removeLoading',
    SHOW_PEC_MODAL: 'showPecModal',
    SET_BIRTH_RANK: 'setBirthRank',
    SET_IS_LUNAR_DATE: 'setIsLunarDate',
    SET_HAS_CHANGED: 'setHasChanged',
    SET_HEALTH_COMPLEMENTARY: 'setHealthComplementary',
    SHOW_HEALTH_COMPLEMENTARY_MODAL: 'showHealthComplementaryModal',
    SET_HEALTH_COMPLEMENTARY_OPTIONS: 'SET_HEALTH_COMPLEMENTARY_OPTIONS',
    SET_HEALTH_COMPLEMENTARY_NUM_ID: 'SET_HEALTH_COMPLEMENTARY_NUM_ID',
}

export const getPatientInitialState = ({ run }) => {
    if (run) {
        return {
            id: run.patient && run.patient.id,
            firstname: (run.patient && run.patient.firstname) || '',
            lastname: (run.patient && run.patient.lastname) || '',
            phoneNumber: (run.patient && run.patient.phoneNumber) || '',
            dateOfBirth:
                (run.patient?.dateOfBirth &&
                    dayjs(run.patient.dateOfBirth)
                        .local()
                        .format('YYYY-MM-DD')) ||
                null,
            description: (run.patient && run.patient.description) || '',
            socialNumber: (run.patient && run.patient.socialNumber) || '',
            hasChanged: false,
            loading: false,
            error: '',
            create: false,
            showPecModal: false,
            isPatient: run.patient ? run.patient.isPatient : true,
            patientsAddresses: run.patient
                ? formatSavedAddresses(run.patient.patientsAddresses).sort(
                      (a, b) => {
                          return a.id - b.id
                      }
                  )
                : [],
            patientsContacts: run.patient
                ? run.patient.patientsContacts.sort((a, b) => {
                      return a.id - b.id
                  })
                : [],
            regime: RegimeList[0],
            birthRank: BirthRank[0],
            isLunarDate: !!run.patient?.stringDateOfBirth,
            healthComplementary: run.patient?.healthComplementary,
            healthComplementaryOptions: [],
            membershipNumber: run.patient?.membershipNumber,
        }
    }
}

export const PatientReducer = (state, action) => {
    switch (action.type) {
        case PATIENT_ACTIONS.GET_PATIENT:
            const address = action.payload.patientsAddresses
            const sortedAddresses = address?.sort((a, b) => {
                return a.id - b.id
            })
            const defaultAddress = address.find((a) => a.defaultAddress)
            return {
                ...state,
                ['@id']: action.payload['@id'],
                id: action.payload.id,
                firstname: action.payload.firstname,
                lastname: action.payload.lastname,
                dateOfBirth: action.payload.dateOfBirth
                    ? dayjs(action.payload.dateOfBirth)
                          .local()
                          .format('YYYY-MM-DD')
                    : null,
                phoneNumber: action.payload.phoneNumber,
                description: action.payload.description,
                socialNumber: action.payload.socialNumber,
                isPatient: action.payload.isPatient,
                defaultAddress: defaultAddress,
                patientsAddresses: sortedAddresses,
                regime: RegimeList.find(
                    (item) => item.value === action.payload.regime
                ),
                birthRank: BirthRank.find(
                    (item) => item.value === action.payload.birthRank
                ),
                patientsContacts: action.payload.patientsContacts,
                loading: false,
                showPecModal: false,
                isLunarDate: !!action.payload.stringDateOfBirth,
                healthComplementary: action.payload.healthComplementary,
                payingCenter: action.payload.payingCenter,
                membershipNumber: action.payload.membershipNumber,
            }
        case PATIENT_ACTIONS.SET_FIRSTNAME:
            return {
                ...state,
                firstname: action.payload,
                hasChanged: true,
            }
        case PATIENT_ACTIONS.SET_LASTNAME:
            return {
                ...state,
                lastname: action.payload,
                hasChanged: true,
            }
        case PATIENT_ACTIONS.SET_BIRTHDATE:
            return {
                ...state,
                dateOfBirth: action.payload ? action.payload : null,
                hasChanged: true,
            }
        case PATIENT_ACTIONS.SET_PHONE_NUMBER:
            return {
                ...state,
                phoneNumber: action.payload
                    .replace(/[^\dA-Z]/g, '')
                    .replace(/(.{2})/g, '$1 ')
                    .substring(0, 15)
                    .trim(),

                hasChanged: true,
            }
        case PATIENT_ACTIONS.SET_DESCRIPTION:
            return {
                ...state,
                description: action.payload,
                hasChanged: true,
            }
        case PATIENT_ACTIONS.SET_SOCIAL_NUMBER:
            return {
                ...state,
                socialNumber: action.payload.replace(/[^\dA-Z]/g, ''),
                hasChanged: true,
            }
        case PATIENT_ACTIONS.SET_DEFAULT_PICK_UP_ADDRESS:
            return {
                ...state,
                defaultPickUpAddress: action.payload,
                hasChanged: true,
            }
        case PATIENT_ACTIONS.IS_PATIENT: {
            return {
                ...state,
                isPatient: action.payload,
                hasChanged: true,
            }
        }
        case PATIENT_ACTIONS.SET_DEFAULT_DEPOSIT_ADDRESS:
            return {
                ...state,
                defaultDepositAddress: action.payload,
                hasChanged: true,
            }
        case PATIENT_ACTIONS.ALLOW_ADD_PATIENT:
            return {
                ...state,
                create: true,
                loading: true,
            }
        case PATIENT_ACTIONS.ADD_ADDRESS:
            return {
                ...state,
                patientsAddresses: [
                    ...state.patientsAddresses,
                    newAddressLine(
                        action.payload,
                        state.patientsAddresses.length + 1
                    ),
                ],
            }
        case PATIENT_ACTIONS.REMOVE_ADDRESS:
            return {
                ...state,
                patientsAddresses: state.patientsAddresses.filter(
                    (address) => address['@id'] !== action.payload
                ),
                hasChanged: true,
            }

        case PATIENT_ACTIONS.CLEAR_PATIENT: {
            return {
                isPatient: true,
                patientsAddresses: [],
                patientsContacts: [],
            }
        }

        case PATIENT_ACTIONS.UPDATE_ADDRESS:
            return {
                ...state,
                patientsAddresses: [
                    ...state.patientsAddresses.map((address) => {
                        if (address['@id'] === action.payload['@id']) {
                            return {
                                ...address,
                                ...action.payload,
                            }
                        } else {
                            return address
                        }
                    }),
                ],
                hasChanged: true,
            }

        case PATIENT_ACTIONS.DELETE_ADDRESS: // delete only the address info
            return {
                ...state,
                patientsAddresses: [
                    ...state.patientsAddresses.map((address) => {
                        if (address['@id'] === action.payload['@id']) {
                            return {
                                ...action.payload,
                                label: address.label,
                                phoneNumber: address.phoneNumber,
                            }
                        } else {
                            return address
                        }
                    }),
                ],
            }

        case PATIENT_ACTIONS.UPDATE_ADDRESS_LABEL:
            return {
                ...state,
                patientsAddresses: [
                    ...state.patientsAddresses.map((address) => {
                        if (address['@id'] === action.payload['@id']) {
                            return {
                                ...address,
                                description: action.payload.description,
                            }
                        } else {
                            return address
                        }
                    }),
                ],
                hasChanged: true,
            }
        case PATIENT_ACTIONS.UPDATE_ADDRESS_INFO:
            return {
                ...state,
                patientsAddresses: [
                    ...state.patientsAddresses.map((address) => {
                        if (address['@id'] === action.payload['@id']) {
                            return {
                                ...action.payload,
                                phoneNumber: action.payload.phoneNumber
                                    ?.replace(/[^\dA-Z]/g, '')
                                    .replace(/(.{2})/g, '$1 ')
                                    .substring(0, 15)
                                    .trim(),
                                defaultHasChanged: false,
                            }
                        } else {
                            return {
                                ...address,
                                defaultAddress: action.payload.defaultHasChanged
                                    ? false
                                    : address.defaultAddress,
                            }
                        }
                    }),
                ],
                hasChanged: true,
            }
        case PATIENT_ACTIONS.ADD_CONTACT:
            return {
                ...state,
                patientsContacts: [
                    ...state.patientsContacts,
                    newContactLine(
                        action.payload,
                        state.patientsContacts.length + 1
                    ),
                ],
                hasChanged: true,
            }
        case PATIENT_ACTIONS.REMOVE_CONTACT:
            return {
                ...state,
                patientsContacts: state.patientsContacts.filter(
                    (address) => address['@id'] !== action.payload
                ),
                hasChanged: true,
            }

        case PATIENT_ACTIONS.DELETE_CONTACT:
            return {
                ...state,
                patientsContacts: [
                    ...state.patientsContacts.map((address) => {
                        if (address['@id'] === action.payload['@id']) {
                            return {
                                ...action.payload,
                                label: address.label,
                                phoneNumber: address.phoneNumber
                                    .replace(/[^\dA-Z]/g, '')
                                    .replace(/(.{2})/g, '$1 ')
                                    .substring(0, 15)
                                    .trim(),
                            }
                        } else {
                            return address
                        }
                    }),
                ],
                hasChanged: true,
            }
        case PATIENT_ACTIONS.UPDATE_CONTACT_INFO:
            return {
                ...state,
                patientsContacts: [
                    ...state.patientsContacts.map((address) => {
                        if (address['@id'] === action.payload['@id']) {
                            return {
                                ...action.payload,
                                phoneNumber: action.payload.phoneNumber
                                    .replace(/[^\dA-Z]/g, '')
                                    .replace(/(.{2})/g, '$1 ')
                                    .substring(0, 15)
                                    .trim(),
                            }
                        } else {
                            return address
                        }
                    }),
                ],
                hasChanged: true,
            }

        case PATIENT_ACTIONS.CLEAR_ID_FROM_ADDRESSES:
            action.payload.patientsAddresses?.forEach((obj, i) => {
                delete action.payload.patientsAddresses[i].address['@id']
                delete action.payload.patientsAddresses[i].address.id
            })
            return {
                ...state,
                patientsAddresses: action.payload.patientsAddresses,
            }

        case PATIENT_ACTIONS.EDIT_PATIENT:
            action.payload.patientsAddresses?.forEach((obj, i) => {
                delete action.payload.patientsAddresses[i].address['@id']
                delete action.payload.patientsAddresses[i].address.id
            })
            const regime = action.payload.regime
            const birthRank = action.payload.birthRank
            action.payload.regime = action.payload.regime?.value
            action.payload.birthRank = action.payload.birthRank?.value
            API.Patients.put(action.payload.id, action.payload)
                .then((res) => {
                    console.log(res)
                })
                .catch((err) => {
                    console.log(err)
                })
            return {
                ...state,
                loading: true,
                regime: regime,
                birthRank: birthRank,
            }
        case PATIENT_ACTIONS.SET_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case PATIENT_ACTIONS.SHOW_PEC_MODAL:
            return {
                ...state,
                showPecModal: action.payload,
            }
        case PATIENT_ACTIONS.REMOVE_LOADING:
            return {
                ...state,
                loading: false,
            }
        case PATIENT_ACTIONS.SET_REGIME:
            return {
                ...state,
                hasChanged: true,
                regime: action.payload,
            }
        case PATIENT_ACTIONS.SET_BIRTH_RANK:
            return {
                ...state,
                hasChanged: true,
                birthRank: action.payload,
            }
        case PATIENT_ACTIONS.SET_IS_LUNAR_DATE:
            return {
                ...state,
                isLunarDate: action.payload,
                hasChanged: true,
            }

        case PATIENT_ACTIONS.SET_HAS_CHANGED:
            return {
                ...state,
                hasChanged: true,
            }
        case PATIENT_ACTIONS.SET_HEALTH_COMPLEMENTARY:
            return {
                ...state,
                healthComplementary: action.payload,
                hasChanged: true,
            }
        case PATIENT_ACTIONS.SHOW_HEALTH_COMPLEMENTARY_MODAL:
            return {
                ...state,
                showHealthComplementaryModal: action.payload,
            }
        case PATIENT_ACTIONS.SET_HEALTH_COMPLEMENTARY_OPTIONS:
            return {
                ...state,
                healthComplementaryOptions: action.payload,
            }
        default:
            throw new Error('Action non reconnue')
    }
}

const newAddressLine = (payload, i) => {
    return {
        ['@id']: 'new_address' + i,
        address: '',
        label: '',
        new: true,
        description: '',
        phoneNumber: '',
        defaultAddress: i === 1,
        defaultHasChanged: false,
    }
}

const newContactLine = (payload, i) => {
    return {
        ['@id']: 'new_address' + i,
        contact: '',
        label: '',
        new: true,
        phoneNumber: '',
    }
}
