import { createContext, useContext, useState } from 'react'
import API from '../services/api'
import { useUser } from './UserContext'

export const ParametersContext = createContext(null)

export const ParametersProvider = ({ children }) => {
    const [parameters, setParameters] = useState(null)
    const { user } = useUser()
    const fetchParameters = async (society) => {
        try {
            const response = await API.Parameter.get(society)
            const parametersData = await response.json()
            setParameters(parametersData) // Update parameters state here
            return parametersData
        } catch (error) {
            console.error('Fetch parameters failed:', error)
            throw error
        }
    }

    const value = {
        parameters, // Use the state variable parameters here
        fetchParameters,
    }

    return (
        <ParametersContext.Provider value={value}>
            {children}
        </ParametersContext.Provider>
    )
}

export const useParameters = () => useContext(ParametersContext)
