import React from 'react'
import Error from '../components/Commons/Error'
import { geocodeByPlaceId } from 'react-google-places-autocomplete'

export const formatSavedAddresses = (savedAddresses) => {
    return savedAddresses
        .filter((address) => !address.new)
        .map((addressInfo) => {
            return {
                ...addressInfo,
                ...addressInfo.address?.googlePrediction.value,
                description: addressInfo.address?.googlePrediction.label,
            }
        })
}

export const openMaps = (address) => {
    if (
        /Android/i.test(navigator.userAgent) ||
        /iPhone/i.test(navigator.userAgent) ||
        /iPad/i.test(navigator.userAgent)
    )
        window.open(
            `waze://?q=${
                address.street ? address.street.replace(' ', '+') + ',' : ''
            }${address.zipCode},${address.city.replace(' ', '+')}`
        )
    else
        window.open(
            `https://maps.google.com/?q=${
                address.street ? address.street.replace(' ', '+') + ',' : ''
            }${address.zipCode},${address.city.replace(' ', '+')}`
        )
}

const ADDRESS_GPT3 = {
    GoogleFormat: (address) => {
        // Create a map of address types to short names
        const shortNames = new Map(
            address.map((a) => [a.types[0], a.short_name])
        )

        const longNames = new Map(address.map((a) => [a.types[0], a.long_name]))
        // Use the map to lookup short names for each address type
        const streetNumber = shortNames.get('street_number') || ''
        const street = shortNames.get('route') || ''
        const neighborhood = shortNames.get('neighborhood') || ''
        const finalStreet = street
            ? `${streetNumber ? streetNumber : ''} ${street}`
            : neighborhood
        const zipCode = shortNames.get('postal_code') || ''
        const city = shortNames.get('locality') || ''
        const county = longNames.get('administrative_area_level_2') || ''
        const country = longNames.get('country') || ''
        const countryCode = shortNames.get('country') || ''
        const administrative =
            shortNames.get('administrative_area_level_1') || ''

        return {
            finalStreet: finalStreet !== ' ' ? finalStreet : '',
            zipCode: zipCode,
            city: city,
            county: county,
            country: country,
            countryCode: countryCode,
            administrative: administrative,
        }
    },
}

export const getAddressInfo = async (googlePrediction, id) => {
    // Use async/await to simplify the code and improve readability
    try {
        const results = await geocodeByPlaceId(googlePrediction.place_id)
        const formatAddress = ADDRESS_GPT3.GoogleFormat(
            results[0].address_components
        )
        const isHealthEstablishment = googlePrediction.types.includes('health')

        let newLocation = {
            city: formatAddress.city,
            country: formatAddress.country,
            street: formatAddress.finalStreet,
            zipCode: formatAddress.zipCode,
            countryCode: formatAddress.countryCode,
            administrative: formatAddress.administrative,
            county: formatAddress.county,
            secondaryStreet: '',
            label:
                isHealthEstablishment || !formatAddress.finalStreet
                    ? googlePrediction.structured_formatting.main_text
                    : null,
            googlePrediction: {
                label: googlePrediction.description,
                value: googlePrediction,
            },
            coordinates: results[0].geometry.location.toJSON(),
        }

        if (id) {
            newLocation['@id'] = id
        }

        return newLocation
    } catch (error) {
        throw new Error()
    }
}
