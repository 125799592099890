import {
    Bank,
    EmojiBall,
    EmojiSad,
    EmojiSatisfied,
    Healthcare,
    NavArrowDown,
    SendEuros,
} from 'iconoir-react'
import dayjs from 'dayjs'
import { formatEuro } from '../../../Invoice/Manage'
import { findColorByStatus } from '../../../../../services/InvoiceUtils'
import { ReactComponent as Cpam } from '../../../../../files/cpam.svg'

export const PaymentDetails = ({
    invoice,
    dispatch,
    INVOICE_CPAM_ACTIONS,
    handleStatusChange,
    rate,
    formModal = false,
}) => {
    return (
        <div
            className={
                ' grid grid-rows-2 gap-2 divide-y-2 rounded-lg border-2 bg-white p-4 shadow-md'
            }
        >
            <div className={'align-center grid grid-cols-3 justify-center'}>
                <div className={'flex items-center space-x-3'}>
                    <Cpam width={40} height={40} />
                    <div className={'flex flex-col justify-center '}>
                        <div>{invoice.payingCenter?.label}</div>
                        {invoice.payingCenterStatus === 'Virement reçu' ? (
                            <div className={'flex items-center '}>
                                <SendEuros />
                                <Bank className={'mr-2'} />
                                <p className={'underline'}>
                                    {dayjs(invoice.payingCenterStatusPaidAt)
                                        .local()
                                        .format('DD/MM/YY')}
                                </p>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className={'flex items-center justify-center'}>
                    {formatEuro(invoice.payingCenterAmount)}
                </div>
                <div
                    tabIndex="-1"
                    className={
                        ' group relative flex w-fit items-center justify-between text-right'
                    }
                >
                    <button
                        className={`text-md align-center flex w-fit justify-center rounded-full border-2  px-4 py-1 font-bold  hover:scale-[1.02]
                                           ${findColorByStatus(
                                               invoice.payingCenterStatus
                                           )} `}
                        onClick={() =>
                            dispatch({
                                type: INVOICE_CPAM_ACTIONS.OPEN_AMO_STATUS_LIST,
                                payload: true,
                            })
                        }
                    >
                        <p>{invoice.payingCenterStatus}</p>
                        <NavArrowDown className={'ml-auto flex'} />
                    </button>
                    {invoice.openAMOStatusList && (
                        <div className="invisible z-50 origin-top-right -translate-y-2 scale-95 transform opacity-0 transition-all duration-300 group-focus-within:visible group-focus-within:translate-y-0 group-focus-within:scale-100 group-focus-within:opacity-100">
                            <div
                                className={`absolute right-0 top-8  w-56 origin-top-right divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none`}
                                role="menu"
                            >
                                <button
                                    role="menuitem"
                                    className="flex w-full px-4 py-2 text-left  leading-5 text-gray-700 hover:bg-gray-100 focus-visible:outline-1 focus-visible:outline-light-blue-green"
                                    onClick={() =>
                                        handleStatusChange(
                                            'AMO',
                                            'Virement reçu'
                                        )
                                    }
                                >
                                    <EmojiSatisfied className={'mr-2'} />
                                    <p
                                        className={
                                            'font-semibold text-green-500'
                                        }
                                    >
                                        Virement reçu
                                    </p>
                                </button>
                                <button
                                    role="menuitem"
                                    className="flex w-full px-4 py-2 text-left  leading-5 text-gray-700 hover:bg-gray-100 focus-visible:outline-1 focus-visible:outline-light-blue-green"
                                    onClick={() =>
                                        handleStatusChange('AMO', 'En attente')
                                    }
                                >
                                    <EmojiBall className={'mr-2'} />
                                    <p className="font-semibold text-gray-500">
                                        En attente
                                    </p>
                                </button>
                                <button
                                    role="menuitem"
                                    className="flex w-full px-4 py-2 text-left  leading-5 text-gray-700 hover:bg-gray-100 focus-visible:outline-1 focus-visible:outline-light-blue-green"
                                    onClick={() =>
                                        handleStatusChange('AMO', 'Annulé')
                                    }
                                >
                                    <EmojiSad className={'mr-2'} />
                                    <p className="font-semibold text-orange-500">
                                        Annulé
                                    </p>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {rate !== '100' ? (
                <div
                    className={
                        'align-center grid grid-cols-3 justify-center pt-4'
                    }
                >
                    <div className={'flex items-center space-x-3'}>
                        <Healthcare width={40} height={40} />
                        <div className={'flex flex-col justify-center'}>
                            <div>
                                {invoice.healthComplementary
                                    ? invoice.healthComplementary?.label
                                    : 'Assuré'}
                            </div>
                            {invoice.healthComplementaryStatus ===
                            'Virement reçu' ? (
                                <div className={'flex items-center'}>
                                    <SendEuros />
                                    <Bank className={'mr-2'} />
                                    <p className={'underline'}>
                                        {dayjs(
                                            invoice.healthComplementaryStatusPaidAt
                                        )
                                            .local()
                                            .format('DD/MM/YY')}
                                    </p>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className={'flex items-center justify-center'}>
                        {formatEuro(invoice.healthComplementaryAmount)}
                    </div>
                    <div
                        tabIndex="-1"
                        className={
                            ' group relative flex w-fit items-center justify-between text-right'
                        }
                    >
                        <button
                            className={`text-md align-center flex w-fit justify-center rounded-full border-2   px-4 py-1 font-bold  hover:scale-[1.02]
                                             ${findColorByStatus(
                                                 invoice.healthComplementaryStatus
                                             )}  `}
                            onClick={() =>
                                dispatch({
                                    type: INVOICE_CPAM_ACTIONS.OPEN_AMC_STATUS_LIST,
                                    payload: true,
                                })
                            }
                        >
                            <p>{invoice.healthComplementaryStatus}</p>
                            <NavArrowDown className={'ml-auto flex'} />
                        </button>
                        {invoice.openAMCStatusList && (
                            <div className="invisible origin-top-right -translate-y-2 scale-95 transform opacity-0 transition-all duration-300 group-focus-within:visible group-focus-within:translate-y-0 group-focus-within:scale-100 group-focus-within:opacity-100">
                                <div
                                    className={`absolute right-0 top-8 w-56 origin-top-right divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none`}
                                    role="menu"
                                >
                                    <button
                                        role="menuitem"
                                        className="flex w-full px-4 py-2 text-left  leading-5 text-gray-700 hover:bg-gray-100 focus-visible:outline-1 focus-visible:outline-light-blue-green"
                                        onClick={() =>
                                            handleStatusChange(
                                                'AMC',
                                                'Virement reçu'
                                            )
                                        }
                                    >
                                        <EmojiSatisfied className={'mr-2'} />
                                        <p
                                            className={
                                                'font-semibold text-green-500'
                                            }
                                        >
                                            Virement reçu
                                        </p>
                                    </button>
                                    <button
                                        role="menuitem"
                                        className="flex w-full px-4 py-2 text-left  leading-5 text-gray-700 hover:bg-gray-100 focus-visible:outline-1 focus-visible:outline-light-blue-green"
                                        onClick={() =>
                                            handleStatusChange(
                                                'AMC',
                                                'En attente'
                                            )
                                        }
                                    >
                                        <EmojiBall className={'mr-2'} />
                                        <p className="font-semibold text-gray-500">
                                            En attente
                                        </p>
                                    </button>
                                    <button
                                        role="menuitem"
                                        className="flex w-full px-4 py-2 text-left  leading-5 text-gray-700 hover:bg-gray-100 focus-visible:outline-1 focus-visible:outline-light-blue-green"
                                        onClick={() =>
                                            handleStatusChange('AMC', 'Annulé')
                                        }
                                    >
                                        <EmojiSad className={'mr-2'} />
                                        <p className="font-semibold text-orange-500">
                                            Annulé
                                        </p>
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    )
}
