import CalendarDatePicker from '../../../Commons/CalendarDatePickers/CalendarDatePicker'
import TimeField from 'react-simple-timefield'
import dayjs from 'dayjs'
import SelectDropDownListV2 from '../../../Commons/DropDownLists/SelectDropDownListV2'
import RunTypes from '../../../../services/RunTypes'
import WaitingTimeInput from '../../../Commons/Inputs/WaitingTimeInput'
import { formatName, formatRunObject } from '../../../../tools/Utility'
import { GoogleAutoComplete } from '../../GoogleAutoComplete'
import { ADDRESS_ACTIONS } from '../../../../services/Reducers/AddressAutoCompleteReducer'
import ClassicTextarea from '../../../Commons/Inputs/ClassicTextarea'
import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'
import React, { useState } from 'react'
import { TRANSPORT_ACTIONS } from '../Reducers/TransportFormReducer'
import {
    handlePatientChange,
    searchPatient,
} from '../../../../services/patient'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import { AddUser, Search } from 'iconoir-react'
import ClassicInputV2 from '../../../Commons/Inputs/ClassicInputV2'

const TransportFormContent = ({
    run,
    dispatch,
    setOpenRunManageModal,
    setOpenNewRunManageModal,
    dateDispatch,
    setSeriesId,
    setOpenSeriesManageModal,
    pickUpDispatch,
    pickUpAddress,
    depositAddress,
    depositDispatch,
    isMobile,
    fromModal,
    fetchDistanceData,
    handleChangeKilometer,
    handleChangeAmount,
    navigate,
    setRunId,
    prescriptionForm,
}) => {
    const [openSelectDatePicker, setOpenSelectDatePicker] = useState(false)
    const title = run.id ? 'Modifier le transport' : 'Ajouter un transport'
    const isRunCreate = !run.id
    const isRunTypeNotOne = run.runType.value !== 1
    const hasReturnIdOrLeadId = run.returnId || run.leadId
    const shouldShowPrimaryButton = isRunTypeNotOne && hasReturnIdOrLeadId
    const buttonLabel = run.leadId ? 'Consulter aller' : 'Consulter retour'
    const inputDepositRef = React.createRef()
    const inputPickUpRef = React.createRef()

    const goTo = (id) => {
        // clean address to prevent label loading errors
        pickUpDispatch({
            type: ADDRESS_ACTIONS.DELETE_ADDRESS,
        })
        depositDispatch({
            type: ADDRESS_ACTIONS.DELETE_ADDRESS,
        })
        if (fromModal) {
            setRunId(id)
        } else {
            navigate(`../${id}`, { state: id })
        }
    }

    return (
        <div className="flex flex-col ">
            <div className="flex w-full items-center justify-between px-4 lg:px-0 ">
                <h1 className="py-10 text-2xl font-semibold">{title}</h1>
                {shouldShowPrimaryButton ? (
                    <PrimaryButton
                        label={buttonLabel}
                        action={() => goTo(run.leadId || run.returnId)}
                    />
                ) : null}
            </div>

            <div
                className={`min-h-row-date flex flex-wrap items-center space-x-2 space-y-10 rounded-xl bg-white p-5  shadow-md lg:justify-around lg:space-x-10 lg:space-y-1 lg:p-2`}
            >
                <div className="flex w-full justify-center lg:w-auto">
                    <CalendarDatePicker
                        openSelectDatePicker={openSelectDatePicker}
                        setOpenSelectDatePicker={setOpenSelectDatePicker}
                        date={run.date}
                        setDate={(e) =>
                            dispatch({
                                type: TRANSPORT_ACTIONS.SET_DATE,
                                payload: e,
                            })
                        }
                        customDisplay={'dddd D MMM YYYY'}
                        fullWidth={true}
                        fontSize={'text-md'}
                        mobileLeftWidth={true}
                    />
                </div>
                <div className="flex">
                    <div className="inline-block w-1/2 text-center">
                        <label className="block text-base font-bold uppercase tracking-wide text-gray-700 lg:text-xs">
                            Départ
                        </label>
                        <TimeField
                            value={dayjs(run.departureTime)
                                .local()
                                .format('HH:mm')}
                            onChange={(e) =>
                                dispatch({
                                    type: TRANSPORT_ACTIONS.SET_DEPARTURE_TIME,
                                    payload: e.target.value,
                                })
                            }
                            input={
                                <input
                                    className={`manage-max-w-hours h-10 w-full border-b-2 text-center leading-tight focus:outline-none ${
                                        run.departureTimeError
                                            ? 'border-red-500'
                                            : 'border-gray-300 focus:border-lighter-blue-green'
                                    }
                                               `}
                                />
                            }
                            colon=":"
                        />
                    </div>
                    <div className="flex items-center justify-center px-3 pt-3 ">
                        <i className="fa fa-arrow-right fa-lg " />
                    </div>
                    <div className="inline-block w-1/2 text-center">
                        <label className="block text-base font-bold uppercase tracking-wide text-gray-700 lg:text-xs">
                            Arrivée
                        </label>
                        <TimeField
                            value={dayjs(run.arrivingTime)
                                .local()
                                .format('HH:mm')}
                            onChange={(e) =>
                                dispatch({
                                    type: TRANSPORT_ACTIONS.SET_ARRIVING_TIME,
                                    payload: e.target.value,
                                })
                            }
                            input={
                                <input
                                    className={`manage-max-w-hours h-10 w-full border-b-2 text-center leading-tight focus:outline-none ${
                                        run.arrivingTimeError
                                            ? 'border-red-500'
                                            : 'border-gray-300 focus:border-lighter-blue-green'
                                    }
                                               `}
                                />
                            }
                            colon=":"
                        />
                    </div>
                </div>
                <div className="min-w-select-types flex w-full lg:w-auto">
                    <SelectDropDownListV2
                        options={RunTypes}
                        value={run.runType}
                        label="Type de transport"
                        handleOptionChange={(e) =>
                            dispatch({
                                type: TRANSPORT_ACTIONS.SET_RUN_TYPE,
                                payload: e,
                            })
                        }
                        isDisabled={run.isDisabled}
                        isClearable={false}
                        isSearchable={false}
                    />
                </div>
                {run.runType.value === 4 && !run.isDisabled && (
                    <div className="inline-block w-full text-center lg:w-auto">
                        <label className="block text-base font-bold uppercase tracking-wide text-gray-700 lg:text-xs">
                            Retour
                        </label>
                        <TimeField
                            value={dayjs(run.returnTime)
                                .local()
                                .format('HH:mm')}
                            onChange={(e) =>
                                dispatch({
                                    type: TRANSPORT_ACTIONS.SET_RETURN_TIME,
                                    payload: e.target.value,
                                })
                            }
                            input={
                                <input
                                    className={`manage-max-w-hours h-10 w-full border-b-2 text-center leading-tight focus:outline-none ${
                                        run.arrivingTimeError
                                            ? 'border-red-500'
                                            : 'border-gray-300 focus:border-lighter-blue-green'
                                    }
                                               `}
                                />
                            }
                            colon=":"
                        />
                    </div>
                )}
                {run.runType.value === 3 && !run.isDisabled && (
                    <div className="inline-block w-full text-center lg:w-auto">
                        <WaitingTimeInput
                            label="Temps D'attente"
                            value={run.waitingTime}
                            type="number"
                            required={true}
                            setValue={(e) =>
                                dispatch({
                                    type: TRANSPORT_ACTIONS.SET_WAITING_TIME,
                                    payload: parseInt(e.target.value),
                                })
                            }
                            placeholder="20"
                            id="waitingtime"
                            showLabel={true}
                            min={0}
                        />
                    </div>
                )}
            </div>
            <div className="ml-auto py-4 pr-5">
                {run.runType.value !== 1 && (
                    <div
                        onClick={(e) =>
                            dispatch({
                                type: TRANSPORT_ACTIONS.APPROXIMATE_TIME,
                                payload: !run.approximateTime,
                            })
                        }
                    >
                        <label
                            htmlFor="recevoir"
                            className="mb-2 pr-2 text-base font-bold uppercase text-gray-700 lg:text-xs"
                        >
                            Heure de retour approximative
                        </label>
                        <input
                            type="checkbox"
                            id="approx"
                            value="aproximatif"
                            name="aproximatif"
                            checked={run.approximateTime}
                            onChange={(e) =>
                                dispatch({
                                    type: TRANSPORT_ACTIONS.APPROXIMATE_TIME,
                                    payload: !run.approximateTime,
                                })
                            }
                        />
                    </div>
                )}
            </div>

            <div
                className={`min-h-row-date flex w-full flex-wrap items-center space-y-10 rounded-xl bg-white p-2  shadow-md lg:space-y-5 `}
            >
                <div className="min-h-row-date flex w-full flex-col items-center space-y-10 px-2 pt-8 lg:flex-row lg:space-y-0 lg:px-5 lg:pt-4">
                    <div className="flex w-full items-center lg:w-1/2 lg:pr-5">
                        <div className="flex w-full items-center">
                            <SelectDropDownListV2
                                label={'Personne à transporter'}
                                showLabel={true}
                                placeholder={
                                    'Sélectionner une personne à transporter'
                                }
                                icon={'fa-user'}
                                addIcon={<AddUser />}
                                value={formatName(run.patient)}
                                handleOptionChange={(e) =>
                                    handlePatientChange(
                                        e,
                                        dispatch,
                                        dateDispatch,
                                        pickUpDispatch,
                                        depositDispatch,
                                        run,
                                        prescriptionForm
                                    )
                                }
                                isDisabled={run.isDisabled || run.series}
                                options={run.patients}
                                handleSearchInputPatientChange={(e) =>
                                    searchPatient(e, dispatch)
                                }
                                isClearable={true}
                                isSearchable={true}
                                addOption={() =>
                                    dispatch({
                                        type: TRANSPORT_ACTIONS.SHOW_PATIENT_MODAL,
                                        payload: true,
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="flex w-full items-center lg:w-1/2 lg:pl-5">
                        <div className="flex w-full items-center">
                            <SelectDropDownListV2
                                label={'Transport'}
                                icon={'fa-ambulance'}
                                placeholder={
                                    'Sélectionner un type de transport'
                                }
                                options={run.vehiclesTypes}
                                value={run.vehicleType}
                                handleOptionChange={(e) =>
                                    dispatch({
                                        type: TRANSPORT_ACTIONS.SET_VEHICLE_TYPE,
                                        payload: e,
                                    })
                                }
                                isDisabled={run.isDisabled}
                                isClearable={false}
                                isSearchable={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="min-h-row-date flex w-full flex-col items-center space-y-5 px-2 lg:flex-row lg:space-y-0 lg:px-5">
                    <div className="flex w-full items-center lg:w-1/2 lg:pr-5">
                        <div className="flex w-full items-center">
                            <SelectDropDownListV2
                                label={'Objet du transport'}
                                placeholder={
                                    'Sélectionner une objet de transport'
                                }
                                showLabel={true}
                                icon={'fa-bullseye'}
                                options={run.runObjects}
                                value={formatRunObject(run.runObject)}
                                handleOptionChange={(e) =>
                                    dispatch({
                                        type: TRANSPORT_ACTIONS.SET_RUN_OBJECT,
                                        payload: e,
                                    })
                                }
                                isDisabled={run.isDisabled}
                                isSearchable={true}
                            />
                        </div>
                    </div>
                    <div className="flex w-full lg:w-1/2 lg:pl-5">
                        <div className="flex w-full">
                            <SelectDropDownListV2
                                label={'Affecter'}
                                placeholder={'Sélectionner une équipe'}
                                icon={'fa-id-badge'}
                                options={run.teams}
                                value={run.team}
                                handleOptionChange={(e) =>
                                    dispatch({
                                        type: TRANSPORT_ACTIONS.SET_TEAM,
                                        payload: e,
                                    })
                                }
                                isDisabled={run.isRoundTripReturn}
                                isClearable={true}
                                isSearchable={!isMobile}
                            />
                        </div>
                    </div>
                </div>
                <div className="min-h-row-date flex w-full px-2 pb-5 lg:px-5">
                    <div className="flex w-full flex-col lg:flex-row">
                        <GoogleAutoComplete
                            dispatch={pickUpDispatch}
                            address={pickUpAddress}
                            savedAddresses={
                                run.patient?.patientsAddresses
                                    ? run.patient?.patientsAddresses
                                    : []
                            }
                            label="Départ"
                            ref={inputPickUpRef}
                        />
                        <div className={'flex justify-center'}>
                            <i
                                className="fas fa-exchange-alt align-center  -mb-4 h-0 rotate-90 cursor-pointer items-center justify-center pt-10 text-center text-3xl hover:text-light-dark-green lg:mb-0 lg:block lg:rotate-0 lg:px-6 lg:pt-7 lg:text-2xl  "
                                onClick={() => {
                                    pickUpDispatch({
                                        type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                                        payload: depositAddress,
                                    })
                                    depositDispatch({
                                        type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                                        payload: pickUpAddress,
                                    })
                                }}
                            />
                        </div>
                        <GoogleAutoComplete
                            dispatch={depositDispatch}
                            address={depositAddress}
                            savedAddresses={
                                run.patient?.patientsAddresses
                                    ? run.patient?.patientsAddresses
                                    : []
                            }
                            label={'Destination'}
                            ref={inputDepositRef}
                        />
                    </div>
                </div>
                <div className="min-h-row-date flex w-full flex-col space-y-5  px-2 lg:flex-row lg:space-y-0 lg:px-5">
                    <div className="flex w-full  lg:w-1/2 lg:pr-5 lg:pt-0">
                        <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-2">
                            <ClassicInputV2
                                label="Montant / compteur"
                                type="text"
                                icon="fa-euro-sign"
                                value={run.amount}
                                required={true}
                                setValue={handleChangeAmount}
                                placeholder="0"
                                id="amount"
                                showLabel={true}
                            />
                            <div className="flex w-full items-center lg:items-start">
                                <ClassicInputV2
                                    label="Kilomètrage"
                                    type="text"
                                    icon="fa-road"
                                    value={run.kilometer}
                                    required={true}
                                    setValue={handleChangeKilometer}
                                    placeholder="0"
                                    id="kilometer"
                                    showLabel={true}
                                />
                                <div
                                    className={`flex pl-2 pt-8 ${
                                        run.distanceNotFound && 'flex-col'
                                    } `}
                                >
                                    <SecondaryButton
                                        title="Rechercher le trajet dans l'historique"
                                        action={() => fetchDistanceData(true)}
                                        icon={<Search />}
                                    />
                                    {run.distanceNotFound && (
                                        <p className={'text-xs text-red-500'}>
                                            Trajet non repertorié
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full lg:w-1/2 lg:pl-5 lg:pt-0">
                        <div className="flex  w-full">
                            <ClassicTextarea
                                setValue={(e) =>
                                    dispatch({
                                        type: TRANSPORT_ACTIONS.SET_COMMENTS,
                                        payload: e.target.value,
                                    })
                                }
                                value={run.comments}
                                label={'Commentaire'}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex w-full items-center justify-between px-2 pb-5 lg:px-5">
                    {run.series ? (
                        <div>
                            <PrimaryButton
                                action={() => {
                                    if (fromModal) {
                                        setOpenRunManageModal(false)
                                        setOpenNewRunManageModal(false)
                                        setSeriesId(run.series.id)
                                        setOpenSeriesManageModal(true)
                                    } else {
                                        navigate(`/series/${run.series.id}`)
                                    }
                                }}
                                label="Voir la série"
                                title="Voir la série"
                                hiddenLabelOnMobile={false}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default TransportFormContent
