export const ACTIONS = {
    SET_PARTNER: 'setPartner',
    SET_FIRSTNAME: 'setFirstname',
    SET_LASTNAME: 'setLastname',
    SET_PHONE: 'setPhone',
    SET_EMAIL: 'setEmail',
    SET_LOADING: 'setLoading',
    SET_DESCRIPTION: 'setDescription',
}

export const getInitialState = () => {
    return {
        id: null,
        firstname: '',
        lastname: '',
        phoneNumber: '',
        email: '',
        description: '',
        loading: true,
        manageTitle: "Création d'un nouveau partenaire",
    }
}

export const PartnerReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_PARTNER:
            return {
                ...state,
                loading: false,
                manageTitle: `Modification du partenaire ${action.payload.lastname} ${action.payload.firstname}`,
                firstname: action.payload.firstname,
                lastname: action.payload.lastname,
                email: action.payload.email,
                phoneNumber: action.payload.phoneNumber,
                description: action.payload.description,
            }
        case ACTIONS.SET_FIRSTNAME:
            return {
                ...state,
                firstname: action.payload,
            }
        case ACTIONS.SET_LASTNAME:
            return {
                ...state,
                lastname: action.payload,
            }
        case ACTIONS.SET_PHONE:
            return {
                ...state,
                phoneNumber: action.payload,
            }
        case ACTIONS.SET_EMAIL:
            return {
                ...state,
                email: action.payload,
            }
        case ACTIONS.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        case ACTIONS.SET_DESCRIPTION:
            return {
                ...state,
                description: action.payload,
            }
        default:
            throw new Error('Action non reconnue')
    }
}
