import React from 'react'
import ClassicInput from '../../Commons/Inputs/ClassicInput'
import ClassicSwitch from '../../Commons/Inputs/ClassicSwitch'
import { useNavigate } from 'react-router-dom'
import { Plus, Search } from 'iconoir-react'
import classNames from 'classnames'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import { ReactComponent as InlineButtonLoader } from '../../../files/inlineButtonLoader.svg'
import InputWithLoader from '../../Commons/Inputs/InputWithLoader'

const SeriesFilter = ({
    search,
    setSearch,
    setPage,
    archives,
    setArchives,
    needsRefresh,
    setNeedsRefresh,
}) => {
    let history = useNavigate()

    return (
        <div className="flex items-center justify-between pl-2 pt-2">
            <div className="min-w-date-input flex items-center space-x-2 lg:max-w-none">
                <InputWithLoader
                    value={search}
                    onChange={(e) => {
                        setPage(1)
                        setSearch(e.target.value)
                        setNeedsRefresh(true)
                    }}
                    icon={<Search />}
                    refreshing={needsRefresh}
                    placeholder="Rechercher"
                />
            </div>
            <PrimaryButton
                label="Série"
                title="Ajouter une série"
                hiddenLabelOnMobile={true}
                action={() => history('/series/0')}
                icon={<Plus className="text-xl" />}
            />

            <div className="inline-block">
                <ClassicSwitch
                    label="Archives"
                    value={archives}
                    setValue={() => {
                        setPage(1)
                        setArchives(!archives)
                    }}
                    info={true}
                    infoLabel={'Afficher la liste des séries terminées'}
                />
            </div>
        </div>
    )
}

export default SeriesFilter
