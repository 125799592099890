import * as dayjs from 'dayjs'
import VehicleTypes from '../services/VehicleTypes'
import RunTypes from '../services/RunTypes'
import { toast } from 'react-toastify'

export const checkIfTeamRunOnExactSameTimes = (
    teamRuns,
    runId,
    departureTime,
    arrivingTime
) => {
    let isTeamRunOnExactSameTime = false
    const run = teamRuns.find((r) => r.id === runId)
    // !run signifie que le transport vient des transports non assignés.
    // Le run n'est donc pas présent dans les runs de l'équipe
    if (!run || (run.departureOrder && run.parent))
        teamRuns.forEach((r) => {
            if (
                runId !== r.id &&
                dayjs(departureTime).local().format('HH:mm') ===
                    dayjs(r.departureTime).local().format('HH:mm') &&
                dayjs(arrivingTime).local().format('HH:mm') ===
                    dayjs(r.arrivingTime).local().format('HH:mm')
            ) {
                //check if runs are on same group
                if (!run || !checkSameGroup(run, r)) {
                    isTeamRunOnExactSameTime = true
                }
            }
        })
    return isTeamRunOnExactSameTime
}

export const formatPrice = (number, field) => {
    if (number === '') {
        return 0
    }
    return number.match(/^[\d.]+$/) ? number : field
}

export const formatOptions = (list) => {
    if (list !== undefined) {
        const options = []
        list.forEach((option) => {
            const data = {
                value: option.label,
                label: option.label,
                ['@id']: option['@id'],
            }

            options.push(data)
        })
        console.log(options)
        return options
    }
}

export const formatObjectOptions = (list) => {
    const options = []
    list.forEach((option) => {
        const data = {
            value: option.label,
            label: option.label,
            ['@id']: option['@id'],
            object: option,
        }

        options.push(data)
    })
    return options
}

export const formatSpreadObjectOptions = (list) => {
    const options = []
    list.forEach((option) => {
        const data = {
            value: option.label,
            label: option.label,
            ...option,
        }

        options.push(data)
    })
    return options
}

export const emailIsValid = (email) => {
    return /\S+@\S+\.\S+/.test(email)
}

export const formatName = (data) => {
    if (data && data.firstname && data.lastname) {
        return {
            value: data['@id'],
            label: `${data.firstname} ${data.lastname}`,
        }
    }
}

export const formatRunObject = (data) => {
    if (data) {
        return {
            value: data.label,
            label: data.label,
            ['@id']: data['@id'],
        }
    }
}

export const formatPriceBasis = (data) => {
    if (data) {
        return {
            value: data.label,
            label: data.label,
            ...data,
        }
    }
}

export const formatItemForSelect = (data, Enum) => {
    if (data) {
        if (typeof data === 'object') {
            data = data.value
        }
        const enumData = Enum.find((item) => data === item.value)
        return {
            value: enumData.value,
            label: enumData.label,
        }
    }
}

export const formatVehicleOptions = (list) => {
    const options = []
    if (list === undefined || list === null) {
        list = VehicleTypes
    }
    list.forEach((option) => {
        const data = {
            value: option.value,
            label: option.label,
            ['@id']: option['@id'],
        }

        options.push(data)
    })
    return options
}

export const onlyNumbers = (value) => {
    return value.replace(/\D/g, '')
}
export const roundingMinutesToQuarter = (date) => {
    let dateHour = new Date()
    return dayjs(
        dateHour.setMinutes(Math.round(new Date().getMinutes() / 15) * 15)
    )
        .set('date', date.getDate())
        .set('month', date.getMonth())
        .set('year', date.getFullYear())
}

export const sortedRuns = (runs) => {
    return runs.sort(function (a, b) {
        return (
            new Date(a.departureTime) - new Date(b.departureTime) ||
            new Date(a.arrivingTime) - new Date(b.arrivingTime)
        )
    })
}

export const findRunTypeFromRunAttributes = (
    isReturnPath,
    isRoundTrip,
    waitingTime
) => {
    if (!isRoundTrip && !isReturnPath) {
        return RunTypes.find((runType) => runType.value === 1)
    } else if (!isRoundTrip && isReturnPath) {
        return RunTypes.find((runType) => runType.value === 2)
    } else if (isRoundTrip && waitingTime) {
        return RunTypes.find((runType) => runType.value === 3)
    } else {
        return RunTypes.find((runType) => runType.value === 4)
    }
}

export const findRunTypeFromRunToInsert = (runToInsert) => {
    if (!runToInsert.isRoundTrip && !runToInsert.isReturnPath) {
        return RunTypes.find((runType) => runType.value === 1)
    } else if (!runToInsert.isRoundTrip && runToInsert.isReturnPath) {
        return RunTypes.find((runType) => runType.value === 2)
    } else if (runToInsert.isRoundTrip && runToInsert.haveDirectReturn) {
        return RunTypes.find((runType) => runType.value === 3)
    } else {
        return RunTypes.find((runType) => runType.value === 4)
    }
}

export const returnVehicleTypeColorFromVehicleType = (vehicleType) => {
    return `bg-${
        vehicleType === 'VSL'
            ? 'blue-100 text-blue-800'
            : vehicleType === 'Ambulance'
            ? 'red-100 text-red-600'
            : 'orange-100 text-orange-600'
    }`
}

export const checkSameGroup = (run1, run2) => {
    if (!run1.departureOrder || !run2.departureOrder) {
        return false
    } else if (run1.departureOrder === 1 && !run1.parent) {
        return run2.parent && run2.parent.id === run1.id
    } else if (run2.departureOrder === 1 && !run2.parent) {
        return run1.parent && run1.parent.id === run2.id
    } else {
        return (
            (run1.parent && run1.parent.id) === (run2.parent && run2.parent.id)
        )
    }
}

export const parseStringBooleanToBoolean = (value) => {
    return value === 'true'
}

export function verifyKeyType1And2(numeroAvecCle) {
    let numero = numeroAvecCle.toString().slice(0, 8) // Prendre les 8 premiers chiffres
    let clePotentielle = parseInt(numeroAvecCle.toString().slice(8, 9), 10) // Prendre le 9e chiffre comme clé potentielle
    let somme = 0

    // Convertir le numéro en tableau de chiffres
    let chiffres = numero.split('').map(Number).reverse()

    for (let i = 1; i < chiffres.length; i++) {
        if (i % 2 === 0) {
            somme += chiffres[i]
        } else {
            somme += chiffres[i] * 2 > 9 ? chiffres[i] * 2 - 9 : chiffres[i] * 2
        }
    }

    // Calculer la clé (complément à 10 du dernier chiffre de la somme)
    let cleCalculee = (10 - (somme % 10)) % 10

    toast.info('Clé probable : ' + cleCalculee)
    // Vérifier si la clé calculée correspond à la clé potentielle
    return cleCalculee === clePotentielle
}

export function verifyKeyType97(numero) {
    const immatriculation = numero.substring(0, 13) // On prend les 13 premiers caractères
    const cle = parseInt(numero.substring(13), 10) // La clé est composée des caractères restants

    // On calcule le reste de la division par 97
    let reste = parseInt(immatriculation, 10) % 97

    // On calcule le complément à 97
    let complement = 97 - reste

    if (complement !== cle) {
        toast.info('Clé probable : ' + complement)
    }

    // La clé est correcte si le complément est égal à la clé fournie
    return complement === cle
}

export function getKeyType(numero) {
    const immatriculation = numero.substring(0, 13) // On prend les 13 premiers caractères
    const cle = parseInt(numero.substring(13), 10) // La clé est composée des caractères restants

    // On calcule le reste de la division par 97
    let reste = parseInt(immatriculation, 10) % 97

    // On calcule le complément à 97
    return 97 - reste
}

export function createDropDownTeamsValues(teams) {
    return teams.map((team) => {
        const usersName = team.users.map(
            (u, index) => `${u.firstname} ${u.lastname}`
        )

        return {
            value: team['@id'],
            label: usersName.join(', '),
        }
    })
}

export function transformObjectsIntoIris(object) {
    if (object.hasOwnProperty(['@id'])) {
        return object['@id']
    } else {
        return object
    }
}

export const formatNameForSelect = (data) => {
    if (data && data.firstname && data.lastname && data.id) {
        console.log(data)
        return {
            value: data['@id'],
            label: `${data.firstname} ${data.lastname}`,
            id: data.id,
        }
    }
}
