import React, { useReducer, useState } from 'react'
import ClassicInput from '../../Commons/Inputs/ClassicInput'
import dayjs from 'dayjs'
import RegulationPopUpFilter from './RegulationPopUpFilter'
import SelectDateRangePicker from '../../Commons/SelectDateRangePicker'
import API from '../../../services/api'
import { saveAs } from 'file-saver'
import { useNavigate, useLocation } from 'react-router-dom'
import { RunStatus } from '../../../services/RunStatus'
import { ErrorReducer } from '../../../services/Reducers/ErrorReducer'
import Error from '../../Commons/Error'
import { toast } from 'react-toastify'
import DownloadButton from '../../Commons/Buttons/DownloadButton'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import {
    Cancel,
    Filter,
    OneFingerSelectHandGesture,
    Plus,
    Search,
    SendDiagonal,
} from 'iconoir-react'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import InputWithLoader from '../../Commons/Inputs/InputWithLoader'

const RegulationFilter = ({
    search,
    setSearch,
    setPage,
    page,
    setDateFrom,
    setDateTo,
    dateFrom,
    dateTo,
    type,
    id,
    setType,
    patient,
    setPatient,
    partner,
    setPartner,
    action,
    setAction,
    setObject,
    object,
    handleErase,
    setStatus,
    status,
    setFirstLoading,
    user,
    setUser,
    setShareFilter,
    shareFilter,
    transferLabel,
    setTransferLabel,
    transferGroup,
    setAddToTransferGroup,
    setSelectAll,
    excludeStatus,
    setExcludeStatus,
    setRefreshing,
    refreshing,
}) => {
    const history = useNavigate()
    const location = useLocation()
    const isTransferActive = location.search.includes('?transfer=')
    const isAbleToTransfer = localStorage.getItem('isAbleToTransfer') === 'true'
    const [showPopUpFilter, setShowPopUpFilter] = useState(false)
    const [loading, setLoading] = useState(false)
    const refFrom = React.createRef()
    const refTo = React.createRef()
    const ymdFrom = dayjs(dateFrom).local().format('YYYY-MM-DD')
    const ymdTo = dayjs(dateTo).local().format('YYYY-MM-DD')
    const [openSelectDatePicker, setOpenSelectDatePicker] = useState(false)

    const [errors, dispatchError] = useReducer(ErrorReducer, [])

    const handleTransfer = () => {
        setTransferLabel('Sélection')
        if (isTransferActive && transferGroup.length > 0) {
            const formattedRuns = transferGroup.map((run) => run['@id'])
            API.Transfer.addTransports(formattedRuns)
                .then((response) => {
                    response.json().then(() => {
                        toast.success('Les transports ont bien été transférés.')

                        setAction(!action)
                    })
                })
                .catch((error) => {
                    if (error.json) {
                        error.json().then((data) => {
                            toast.error(data.error)
                        })
                    }
                })
        } else {
            setStatus(RunStatus.find((item) => item.value === 'done'))
            history({
                pathname: '/transport',
                search: '?transfer=true',
            })
            setAction(!action)
        }
    }

    const exportCsv = () => {
        const between = {
            strictlyBefore: ymdTo,
            strictlyAfter: ymdFrom,
        }
        setLoading(true)
        // TODO exlude status
        API.Export.getRuns(
            search,
            null,
            patient != null ? patient.value : '',
            between,
            type != null ? type.value : '',
            status != null ? status.value : '',
            object != null ? object.value : '',
            user != null ? user.value : '',
            partner != null ? partner.value : '',
            excludeStatus != null ? excludeStatus.value : ''
        )
            .then((response) => {
                return response.blob()
            })
            .then((blob) => {
                saveAs(
                    blob,
                    `heroad-export-${new Date().toLocaleDateString(
                        'fr-FR'
                    )}.csv`
                )
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const activeFilter =
        patient ||
        object ||
        partner ||
        status ||
        excludeStatus ||
        user ||
        dateTo === null ||
        ymdTo !== ymdFrom ||
        type

    return (
        <div className="grid grid-cols-2 justify-between gap-4 px-2 pb-2 pt-2 lg:grid-cols-4 ">
            <div className="col-span-1 flex">
                <div className="flex items-center">
                    <SelectDateRangePicker
                        openSelectDatePicker={openSelectDatePicker}
                        setOpenSelectDatePicker={setOpenSelectDatePicker}
                        startDate={dateFrom}
                        endDate={dateTo}
                        setStartDate={setDateFrom}
                        setEndDate={setDateTo}
                        setPage={setPage}
                        page={page}
                        setAction={setAction}
                        action={action}
                    />
                </div>
            </div>
            <div className="col-span-1 flex items-center justify-center space-x-2">
                <InputWithLoader
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value)
                        setPage(1)
                        setRefreshing(true)
                    }}
                    icon={<Search />}
                    refreshing={refreshing}
                    placeholder="Rechercher"
                />
            </div>

            <div className="col-span-2 flex w-full justify-between pl-4 ">
                <div className="flex px-2 ">
                    <SecondaryButton
                        icon={<Filter />}
                        action={() => {
                            setShowPopUpFilter(true)
                        }}
                        label="Filtrer"
                        active={activeFilter}
                    />
                    <button
                        className={`fa fa-times cursor-pointer pl-2 ${
                            activeFilter ? null : 'hidden'
                        } `}
                        onClick={handleErase}
                    />
                </div>

                <div className="flex">
                    {transferGroup.length > 0 &&
                    isAbleToTransfer &&
                    isTransferActive ? (
                        <PrimaryButton
                            icon={<SendDiagonal />}
                            action={handleTransfer}
                            label="Valider et transférer"
                            hiddenLabelOnMobile={true}
                        />
                    ) : (
                        isAbleToTransfer && (
                            <SecondaryButton
                                icon={
                                    isTransferActive ? (
                                        <OneFingerSelectHandGesture />
                                    ) : (
                                        <SendDiagonal />
                                    )
                                }
                                action={handleTransfer}
                                label={transferLabel}
                                active={isTransferActive}
                                hiddenLabelOnMobile={true}
                            />
                        )
                    )}
                    {isTransferActive && (
                        <button
                            onClick={() => {
                                setAddToTransferGroup([])
                                setStatus(null)
                                setAction(!action)
                                setSelectAll(false)
                                setTransferLabel('Télétransmettre')
                                history({
                                    pathname: '/transport',
                                })
                            }}
                            className={`fa fa-times cursor-pointer pl-2`}
                        />
                    )}
                </div>
                <div className="flex space-x-4">
                    <PrimaryButton
                        action={() => history('/transport/0')}
                        icon={<Plus className="text-xl" />}
                        label="Transport"
                        hiddenLabelOnMobile={true}
                    />
                    <DownloadButton loading={loading} action={exportCsv} />
                </div>
            </div>
            <div className="fixed right-0 top-0 z-50 mr-5 flex flex-col space-y-2 text-gray-700">
                {errors.map((error) => {
                    return (
                        <Error
                            key={error.id}
                            error={error}
                            dispatch={dispatchError}
                        />
                    )
                })}
            </div>
            {showPopUpFilter ? (
                <RegulationPopUpFilter
                    setShowPopUpFilter={setShowPopUpFilter}
                    patient={patient}
                    setPartner={setPartner}
                    partner={partner}
                    action={action}
                    setAction={setAction}
                    setPatient={setPatient}
                    setType={setType}
                    setPage={setPage}
                    type={type}
                    id={id}
                    setDateFrom={setDateFrom}
                    dateFrom={dateFrom}
                    refFrom={refFrom}
                    setDateTo={setDateTo}
                    dateTo={dateTo}
                    refTo={refTo}
                    object={object}
                    setObject={setObject}
                    handleErase={handleErase}
                    setStatus={setStatus}
                    status={status}
                    ymdTo={ymdTo}
                    ymdFrom={ymdFrom}
                    setFirstLoading={setFirstLoading}
                    user={user}
                    setUser={setUser}
                    setShareFilter={setShareFilter}
                    shareFilter={shareFilter}
                    excludeStatus={excludeStatus}
                    setExcludeStatus={setExcludeStatus}
                />
            ) : null}
        </div>
    )
}

export default RegulationFilter
