import React, { useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import { Calendar } from 'react-date-range'
import { fr } from 'date-fns/locale'
import classNames from 'classnames'
dayjs.locale('fr')
const useOnClickUserMenuOutside = (ref, outsideHandler, clickHandler) => {
    useEffect(() => {
        const listener = (event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                // clickHandler(event)
            } else {
                outsideHandler(event)
            }
        }

        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [ref, outsideHandler, clickHandler])
}

const CalendarDatePicker = ({
    openSelectDatePicker,
    setOpenSelectDatePicker,
    date,
    setDate,
    customDisplay,
    icon,
    label,
    fullWidth,
    fontSize = 'text-sm',
    reversedOpening,
    mobileLeftWidth = false,
    disabled = false,
}) => {
    const handleSelect = (d) => {
        setDate(d)
        setOpenSelectDatePicker(false)
    }

    const isMobile = /iPhone|Android/i.test(navigator.userAgent)

    const datePickerRef = useRef()

    useOnClickUserMenuOutside(
        datePickerRef,
        () => setOpenSelectDatePicker(false),
        () => setOpenSelectDatePicker(true)
    )

    return (
        <div
            className={` relative flex flex-col text-gray-600 ${
                mobileLeftWidth && isMobile ? ' -ml-2 w-full ' : ''
            } `}
        >
            {label && (
                <label
                    className={
                        'block pb-2 text-sm font-bold uppercase tracking-wide text-gray-700 lg:text-xs'
                    }
                >
                    {label}
                </label>
            )}
            {openSelectDatePicker && (
                <div
                    className={classNames('absolute z-50 ', {
                        'bottom-0': reversedOpening,
                    })}
                    ref={datePickerRef}
                >
                    <Calendar
                        date={date}
                        onChange={handleSelect}
                        className="mobileRdrCalendarWrapper"
                        locale={fr}
                        color={'#8aafb0'}
                    />
                </div>
            )}
            <button
                className={classNames(
                    `flex min-w-12 items-center justify-center rounded-lg
                    border p-2 font-semibold hover:border-lighter-blue-green ${fontSize}
                    space-x-2 bg-white shadow-md  `,
                    {
                        'min-w-14': customDisplay && fullWidth,
                        'min-w-17vh': customDisplay && !fullWidth,
                        'cursor-not-allowed': disabled,
                        'cursor-pointer': !disabled,
                    }
                )}
                disabled={disabled}
                onClick={() => setOpenSelectDatePicker(!openSelectDatePicker)}
            >
                {icon !== false && (
                    <i className="fas fa-calendar-alt fa-lg pr-5 text-gray-600" />
                )}
                <p className="capitalize">
                    {dayjs(date)
                        .local()
                        .format(customDisplay ? customDisplay : 'ddd D MMM')}
                </p>
            </button>
        </div>
    )
}

export default CalendarDatePicker
