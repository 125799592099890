import * as dayjs from 'dayjs'

export const CommentNotification = ({ commentNotification }) => {
    return (
        <div className="flex w-full items-center ">
            {commentNotification.type === 'edit_comment' ? (
                <i className="far fa-edit min-w-2-5 text-yellow-500" />
            ) : (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="min-w-2-5 h-6 w-6  text-blue-500"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                    />
                </svg>
            )}
            <div className="flex flex-col pl-2 pr-1 ">
                <div className="text-notif wrapper flex text-left text-gray-700 ">
                    <a className={'font-bold capitalize'}>
                        {commentNotification.notifier.fullName}
                    </a>
                    <a>
                        {commentNotification.type === 'edit_comment'
                            ? ' a modifié un commentaire sur le transport de '
                            : ' a ajouté un commentaire sur le transport de '}
                    </a>
                    <a className="pr-1 capitalize underline">
                        {commentNotification.run.patient.fullName}
                    </a>
                    <p>« {commentNotification.run.userComments} »</p>
                </div>
                <p className="text-left text-xs">
                    {dayjs(commentNotification.createdAt).fromNow()}
                </p>
            </div>
            {!commentNotification.seen && (
                <div className="ml-auto">
                    <div className={`h-2 w-2 rounded-full bg-blue-500 `} />
                </div>
            )}
        </div>
    )
}
