import * as dayjs from 'dayjs'

export const MultipleNotification = ({ multiNotif }) => {
    return (
        <div className="flex w-full items-center ">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="min-w-2-5 h-6 w-6  text-green-500"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                />
            </svg>
            <div className="flex flex-col pl-2 pr-1">
                <div className="text-notif wrapper flex text-left text-gray-700 ">
                    <a className={'font-bold capitalize'}>
                        {multiNotif.notifier.fullName}
                    </a>
                    <a>
                        {multiNotif.type === 'both_ongoing_and_add_comment'
                            ? ' a pris en charge le transport de'
                            : ' a finalisé le transport de '}
                    </a>
                    <a className="capitalize underline">
                        {multiNotif.run.patient.fullName + ' '}
                    </a>
                    <p>
                        et a ajouté un commentaire. «{' '}
                        {multiNotif.run.userComments}»
                    </p>
                </div>
                <p className="text-left text-xs">
                    {dayjs(multiNotif.createdAt).fromNow()}
                </p>
            </div>
            {!multiNotif.seen && (
                <div className="ml-auto">
                    <div className={`h-2 w-2 rounded-full bg-blue-500 `} />
                </div>
            )}
        </div>
    )
}
