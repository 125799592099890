import React, { useEffect, useLayoutEffect, useReducer, useRef } from 'react'
import API from '../../services/api'
import * as dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../Commons/Loaders/Loader'
import SecondaryButton from '../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../Commons/Buttons/PrimaryButton'
import {
    getInitialState,
    RUNINFO_ACTIONS,
    RunInformationReducer,
} from '../../services/Reducers/RunInformationReducer'
import FetchRunsInformations from '../../services/fetchRunsInformations'
import { toast } from 'react-toastify'
import { formatAddressLabel } from '../../services/Reducers/AddressAutoCompleteReducer'
import { ArrowLeft, Group, UserCircle, UserSquare } from 'iconoir-react'
import { openMaps } from '../../services/address'
import DeleteButton from '../Commons/Buttons/DeleteButton'
import animationData from '../../files/animations/taxicar.json'
import Lottie from 'lottie-react'

const PartnerConsult = ({ token }) => {
    let history = useNavigate()
    const ref = useRef(null)
    const param = useParams()

    const [runInfo, dispatch] = useReducer(
        RunInformationReducer,
        undefined,
        getInitialState
    )

    const fetchInformations = async () => {
        await FetchRunsInformations.getPartnerRunInfo(
            param.id,
            dispatch,
            runInfo,
            RUNINFO_ACTIONS,
            token
        )
    }

    const handleCopyClick = (ref, pickUp = true) => {
        let msg = ''
        if (pickUp) {
            msg = 'Addresse de départ'
        } else {
            msg = "Addresse d'arrivée"
        }
        ref?.current?.select()
        document.execCommand('copy')
        toast.success(`${msg} copiée 🌟`)
    }

    const pickUpRef = useRef(null)
    const depositRef = useRef(null)

    useLayoutEffect(() => {
        if (!runInfo.loading) {
            dispatch({
                type: RUNINFO_ACTIONS.SET_WIDTH,
                payload: ref?.current?.offsetWidth,
            })
        }
    }, [ref, runInfo.loading])

    useEffect(() => {
        fetchInformations()
    }, [])

    const handleForm = (status = 'ongoing', notify = true) => {
        let message =
            status === 'done'
                ? 'Transport finalisé'
                : 'Prise en charge confirmée.'

        message = status === 'todo' ? 'Transport invalidé' : message

        dispatch({
            type: RUNINFO_ACTIONS.SET_LOADING,
            payload: true,
        })
        let data = {
            status: `/statuses/${status}`,
        }

        API.Partners.putRun(token, param.id, data)
            .then((data) => {
                toast.success(message)
                dispatch({
                    type: RUNINFO_ACTIONS.SET_STATUS,
                    payload: data.status,
                })
                dispatch({
                    type: RUNINFO_ACTIONS.SET_LOADING,
                    payload: false,
                })
            })
            .catch((error) => {
                dispatch({
                    type: RUNINFO_ACTIONS.SET_LOADING,
                    payload: false,
                })
            })
    }

    const onScroll = (() => {
        let ticking = false
        let lastScrollLeft = 0
        return (e) => {
            if (!ticking) {
                window.requestAnimationFrame(function () {
                    let documentScrollLeft = e.target.scrollLeft
                    if (lastScrollLeft !== documentScrollLeft) {
                        dispatch({
                            type: RUNINFO_ACTIONS.SET_NAVIGATION_SCROLL,
                            payload: {
                                documentScrollLeft,
                                lastScrollLeft,
                            },
                        })
                        lastScrollLeft = documentScrollLeft
                    }
                    ticking = false
                })
                ticking = true
            }
        }
    })()

    return (
        <div className="mx-auto  h-full w-full text-2xl text-gray-700">
            {runInfo.loading ? (
                <Loader />
            ) : !runInfo.errorForPartner ? (
                <div className="h-full">
                    <div className="mx-5 flex items-center py-4 text-center text-sm">
                        <div className={'flex'}>
                            <SecondaryButton
                                title={'Retour'}
                                icon={<ArrowLeft />}
                                action={() => history('..')}
                            />
                        </div>

                        <div
                            className={
                                'mr-14 flex w-full items-center justify-center'
                            }
                        >
                            <i
                                className={`fa fa-circle fa-lg ${runInfo.runInfoColor} pr-2`}
                            />
                            <i
                                className={`fa fa-circle fa-lg ${runInfo.patientInfoColor} pr-2`}
                            />
                        </div>
                    </div>
                    {runInfo.status.id === 'canceled' && (
                        <div className="flex justify-center pb-4">
                            <p className="flex items-center font-bold text-orange-500">
                                <i className="fas fa-exclamation-circle pr-2 " />
                                <p>Transport annulé</p>
                            </p>
                        </div>
                    )}

                    <div
                        onScroll={onScroll}
                        ref={ref}
                        className="hide-scroll mx-auto flex w-full snap-x snap-mandatory overflow-scroll"
                    >
                        <div className="mx-5 mb-5 flex w-11/12 shrink-0 snap-center snap-always flex-col overflow-y-scroll rounded-xl bg-white p-5 shadow-mobile">
                            <div className="flex w-full items-center  justify-between pb-5 ">
                                <p className="font-bold text-gray-600">
                                    Transport
                                </p>
                                <i className="fas fa-car fa-lg " />
                                <p className="font-bold text-gray-600">
                                    {runInfo.isRoundTrip ? 'A/R' : ''}
                                    {runInfo.isReturnPath
                                        ? ' Retour'
                                        : ' Aller'}
                                </p>
                            </div>
                            <div>
                                <textarea
                                    className="copy"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    readOnly
                                    tabIndex="-1"
                                    autoComplete="off"
                                    ref={pickUpRef}
                                    value={formatAddressLabel(
                                        runInfo.pickUpLocation
                                    )}
                                />
                                <textarea
                                    className="copy"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    readOnly
                                    tabIndex="-1"
                                    autoComplete="off"
                                    ref={depositRef}
                                    value={formatAddressLabel(
                                        runInfo.depositLocation
                                    )}
                                />
                            </div>
                            {runInfo.status.id === 'todo' ? (
                                <div className="flex justify-center py-4">
                                    <PrimaryButton
                                        label={
                                            !runInfo.isTakenInCharge
                                                ? 'Confirmer la prise en charge'
                                                : 'Renvoyer la confirmation'
                                        }
                                        title={
                                            !runInfo.isTakenInCharge
                                                ? 'Confirmer la prise en charge'
                                                : 'Renvoyer la confirmation'
                                        }
                                        action={() => {
                                            handleForm('ongoing')
                                        }}
                                        mobileSize={true}
                                    />
                                </div>
                            ) : runInfo.status.id === 'canceled' ? null : (
                                <div className="flex items-center justify-center py-4">
                                    <span
                                        className={` flex  items-center justify-center rounded-full bg-green-200 px-2 py-1 text-xl font-bold text-green-600`}
                                    >
                                        Prise en charge confirmée
                                    </span>
                                </div>
                            )}
                            <div className="flex w-full justify-between border-b py-4">
                                <div className="flex flex-col items-center  ">
                                    <p className="text-lg font-bold text-gray-600">
                                        Heure de départ
                                    </p>
                                    <p className="text-center">
                                        {dayjs(runInfo.departureTime)
                                            .local()
                                            .format('HH:mm')}
                                    </p>
                                </div>
                                <div className="pr-2 pt-8 font-bold text-gray-600">
                                    ->
                                </div>
                                <div className="flex flex-col items-center ">
                                    <p className="text-lg font-bold text-gray-600">
                                        Heure d'arrivée
                                    </p>
                                    <p className="text-center">
                                        {dayjs(runInfo.arrivingTime)
                                            .local()
                                            .format('HH:mm')}
                                    </p>
                                </div>
                            </div>
                            <div className={'mt-4 flex pt-2'}>
                                <UserCircle className={'mr-2'} />
                                <p>
                                    {runInfo.patient.lastname} -{' '}
                                    {runInfo.patient.firstname}{' '}
                                </p>
                            </div>
                            <div className="flex flex-col py-4">
                                <div
                                    className={
                                        'flex items-center justify-between'
                                    }
                                >
                                    <p className="font-bold text-gray-600">
                                        Lieu de départ
                                    </p>
                                    <button
                                        onClick={() =>
                                            handleCopyClick(pickUpRef)
                                        }
                                        className="far fa-clone rounded-lg p-2 text-3xl text-secondary-color focus:outline-none"
                                    />
                                </div>
                                <p className="cursor-pointer">
                                    {runInfo.pickUpLocation.street
                                        ? runInfo.pickUpLocation.street + ', '
                                        : ''}
                                    {runInfo.pickUpLocation.secondaryStreet
                                        ? runInfo.pickUpLocation
                                              .secondaryStreet + ', '
                                        : ''}
                                    <span className="underline">
                                        {runInfo.pickUpLocation.city}
                                    </span>
                                </p>

                                {runInfo.pickUpLocation.label &&
                                    runInfo.pickUpLocation.label !==
                                        runInfo.pickUpLocation.city && (
                                        <p>{runInfo.pickUpLocation.label} </p>
                                    )}
                                <div className="flex pt-4 ">
                                    <button
                                        className="w-full items-center rounded-lg border border-secondary-color py-2  text-xl text-secondary-color text-secondary-color"
                                        onClick={() =>
                                            openMaps(runInfo.pickUpLocation)
                                        }
                                    >
                                        <i
                                            className={
                                                'fab fa-waze pr-4 text-2xl  text-secondary-color'
                                            }
                                        />
                                        Voir l'itinéraire
                                    </button>
                                </div>
                            </div>
                            <div className="flex flex-col space-y-1 py-4">
                                <div
                                    className={
                                        'flex items-center justify-between'
                                    }
                                >
                                    <p className="font-bold text-gray-600">
                                        Lieu d'arrivée
                                    </p>
                                    <button
                                        onClick={() =>
                                            handleCopyClick(depositRef, false)
                                        }
                                        className="far fa-clone rounded-lg p-2 text-3xl text-secondary-color focus:outline-none"
                                    />
                                </div>

                                <p className="cursor-pointer">
                                    {runInfo.depositLocation.street
                                        ? runInfo.depositLocation.street + ', '
                                        : ''}
                                    {runInfo.depositLocation.secondaryStreet
                                        ? runInfo.depositLocation
                                              .secondaryStreet + ', '
                                        : ''}
                                    <span className="underline">
                                        {runInfo.depositLocation.city}
                                    </span>
                                </p>
                                {runInfo.depositLocation.label &&
                                    runInfo.depositLocation.label !==
                                        runInfo.depositLocation.city && (
                                        <p>{runInfo.depositLocation.label} </p>
                                    )}
                                <div className="flex pt-4 ">
                                    <button
                                        className="w-full items-center rounded-lg border border-secondary-color py-2 text-xl text-secondary-color"
                                        onClick={() =>
                                            openMaps(runInfo.depositLocation)
                                        }
                                    >
                                        <i
                                            className={
                                                'fab fa-waze pr-4 text-2xl'
                                            }
                                        />
                                        Voir l'itinéraire
                                    </button>
                                </div>
                            </div>

                            <div className="flex flex-col space-y-1 py-4">
                                <p className="font-bold text-gray-600">Objet</p>
                                <p>{runInfo.runObject.label}</p>
                            </div>
                            {runInfo.series && (
                                <div className="flex flex-col space-y-1">
                                    <div className="flex justify-between ">
                                        <p className="font-bold text-gray-600">
                                            Série
                                        </p>
                                        <p>
                                            {runInfo.seriesIndex}/
                                            {runInfo.series.nbRuns}
                                        </p>
                                    </div>
                                    <div> {runInfo.series.description}</div>
                                </div>
                            )}
                            {runInfo.comments && (
                                <div className="flex flex-col space-y-1 py-2">
                                    <p className="font-bold text-gray-600">
                                        Commentaire
                                    </p>
                                    <p>{runInfo.comments}</p>
                                </div>
                            )}
                        </div>
                        <div className="mx-5 mb-5 flex w-11/12 shrink-0 snap-center snap-always flex-col space-y-8 overflow-y-scroll rounded-xl bg-white p-5 shadow-mobile">
                            <div className="flex w-full items-center  justify-between pb-5 ">
                                <p className="font-bold text-gray-600">
                                    Fiche{' '}
                                    {runInfo.patient.isPatient
                                        ? 'patient'
                                        : 'client'}
                                </p>
                                <i className="fas fa-user fa-lg pl-4" />
                            </div>
                            <div className="flex w-full justify-between space-x-2">
                                <div className="min-w-12 flex flex-col">
                                    <p className="font-bold text-gray-600">
                                        Nom
                                    </p>
                                    <p>{runInfo.patient.lastname}</p>
                                </div>

                                <div className="flex flex-col ">
                                    <p className="font-bold text-gray-600">
                                        Prénom
                                    </p>
                                    <p>{runInfo.patient.firstname}</p>
                                </div>
                            </div>
                            <div className="flex flex-col space-y-1">
                                <p className="font-bold text-gray-600">
                                    Téléphone
                                </p>
                                {runInfo.patient.phoneNumber ? (
                                    <div className="flex items-center justify-between">
                                        <a
                                            href={`tel:${runInfo.patient.phoneNumber}`}
                                        >
                                            {runInfo.patient.phoneNumber}
                                        </a>
                                        <i className="fa fa-phone" />
                                    </div>
                                ) : (
                                    <i className="fa fa-times pl-2 pt-2" />
                                )}
                            </div>
                            <div className="flex flex-col space-y-1">
                                <p className="font-bold text-gray-600">
                                    Date de naissance
                                </p>
                                {runInfo.patient.dateOfBirth ? (
                                    <div className="flex items-center justify-between">
                                        <p>
                                            {runInfo.patient.dateOfBirth
                                                ? dayjs(
                                                      new Date(
                                                          runInfo.patient.dateOfBirth.split(
                                                              '+'
                                                          )[0]
                                                      )
                                                  ).format('DD/MM/YYYY')
                                                : null}
                                        </p>
                                        <i className="fa fa-calendar-alt" />
                                    </div>
                                ) : (
                                    <i className="fa fa-times pl-2 pt-2" />
                                )}
                            </div>
                            <div className="flex flex-col space-y-1">
                                <p className="font-bold text-gray-600">
                                    N° de sécu :
                                </p>
                                {runInfo.patient.socialNumber ? (
                                    <div className="flex items-center justify-between">
                                        <p>{runInfo.patient.socialNumber}</p>
                                        <UserSquare height={36} width={36} />
                                    </div>
                                ) : (
                                    <i className="fa fa-times pl-2 pt-2" />
                                )}
                            </div>
                            {runInfo.patient.description && (
                                <div className="flex flex-col space-y-1">
                                    <p className="font-bold text-gray-600">
                                        Description
                                    </p>
                                    <p>{runInfo.patient.description}</p>
                                </div>
                            )}
                            {runInfo.patientsAddresses && (
                                <div className="flex w-full flex-col space-y-8">
                                    {runInfo.patientsAddresses.map(
                                        (savedAddress, key) => (
                                            <div
                                                key={key}
                                                onClick={() =>
                                                    openMaps(
                                                        savedAddress.address
                                                    )
                                                }
                                                className="flex flex-col space-y-1"
                                            >
                                                <p className="font-bold text-gray-600">
                                                    {savedAddress.label}
                                                </p>
                                                <p className="cursor-pointer">
                                                    {savedAddress.address.street
                                                        ? savedAddress.address
                                                              .street + ', '
                                                        : ''}
                                                    {savedAddress.address
                                                        .secondaryStreet
                                                        ? savedAddress.address
                                                              .secondaryStreet +
                                                          ', '
                                                        : ''}
                                                    <span className="underline">
                                                        {
                                                            savedAddress.address
                                                                .city
                                                        }
                                                    </span>
                                                </p>
                                                {savedAddress.phoneNumber && (
                                                    <div className="flex items-center justify-between">
                                                        <a
                                                            href={`tel:${savedAddress.phoneNumber}`}
                                                        >
                                                            {
                                                                savedAddress.phoneNumber
                                                            }
                                                        </a>
                                                        <i className="fa fa-phone" />
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    )}
                                </div>
                            )}
                            {runInfo.patientsContacts?.length > 0 && (
                                <div className="flex w-full flex-col space-y-8">
                                    <div className="flex items-center justify-between">
                                        <p className="font-semibold text-gray-600">
                                            Contacts enregistrés
                                        </p>
                                        <Group />
                                    </div>
                                    <div className="flex w-full flex-col space-y-8">
                                        {runInfo.patientsContacts.map(
                                            (contact, key) => (
                                                <div
                                                    key={key}
                                                    className="flex flex-col space-y-1"
                                                >
                                                    <p className="font-semibold text-gray-600">
                                                        {contact.label}
                                                    </p>
                                                    <p className=""></p>
                                                    {contact.phoneNumber && (
                                                        <div className="flex items-center justify-between">
                                                            <a
                                                                href={`tel:${contact.phoneNumber}`}
                                                            >
                                                                {
                                                                    contact.phoneNumber
                                                                }
                                                            </a>
                                                            <i className="fa fa-phone" />
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {runInfo.status.id === 'todo' ||
                    runInfo.status.id === 'ongoing' ? (
                        <div className="flex w-full justify-center px-5 pb-20 pt-5">
                            <PrimaryButton
                                label="Valider le transport"
                                title="Valider le transport"
                                action={() => handleForm('done')}
                                mobileSize={true}
                            />
                        </div>
                    ) : runInfo.status.id === 'done' ? (
                        <div className="flex w-full justify-center px-5 pb-20 pt-5">
                            <DeleteButton
                                label="Invalider le transport"
                                title="Invalider le transport"
                                action={() => handleForm('todo')}
                                mobileSize={true}
                            />
                        </div>
                    ) : null}
                </div>
            ) : (
                <div className="container mx-auto flex h-full flex-col items-center justify-center">
                    <Lottie
                        animationData={animationData}
                        loop={true}
                        autoplay={true}
                        style={{ width: 200, height: 200 }}
                    />
                    <p className="text-center text-2xl font-semibold ">
                        Le transport que vous essayez de consulter
                        <a className={'pl-1 underline'}>
                            n'est plus accessible
                        </a>
                        , veuillez contacter votre partenaire pour plus
                        d'informations.
                    </p>
                    <div className={'flex pt-10'}>
                        <SecondaryButton
                            title={'Retour'}
                            icon={<ArrowLeft />}
                            action={() => history('..')}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default PartnerConsult
