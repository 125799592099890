import React, { useEffect, useState } from 'react'
import * as dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import dayjsPluginUTC from 'dayjs-plugin-utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import Manage from '../../Regulation/Manage'
import RunTabs from '../../Regulation/RunTabs'

dayjs.extend(weekday)
dayjs.extend(dayjsPluginUTC)
dayjs.extend(customParseFormat)

const ManageDashboardContainer = ({
    date,
    setDate,
    setDateTo,
    setOpenRunManageModal,
    setOpenNewRunManageModal,
    setOpenSeriesManageModal,
    reRender,
    id,
    setSeriesId,
}) => {
    const [runId, setRunId] = useState(id)
    return (
        <div className=" w-11/12">
            <RunTabs
                date={date}
                setDate={setDate}
                setDateTo={setDateTo}
                fromModal={true}
                setOpenRunManageModal={setOpenRunManageModal}
                setOpenNewRunManageModal={setOpenNewRunManageModal}
                setOpenSeriesManageModal={setOpenSeriesManageModal}
                reRender={reRender}
                runId={runId}
                setRunId={setRunId}
                setSeriesId={setSeriesId}
            />
        </div>
    )
}

export default ManageDashboardContainer
