import React from 'react'
import classNames from 'classnames'
import { InfoEmpty } from 'iconoir-react'
import { RunObjectColors } from '../../../../services/RunObjectColors'

const DashboardRunCard = ({
    run,
    style,
    onCardClick,
    onInfoClick,
    dashboard,
    showRunObjectColors,
}) => {
    return (
        <div
            key={run.id}
            style={style()}
            className={classNames(
                `hover-first-index relative my-3 flex cursor-pointer flex-col justify-between rounded-lg border-l-4 text-sm hover:scale-[1.02] hover:shadow-mobile ${run.statusBorderColor}`,
                {
                    'p-2': run.width >= 150,
                    'py-1 pl-2 pr-3': run.width < 150 && run.width > 100,
                    'p-1': run.width <= 100,
                    ['stripeBg_canceled']:
                        run.status.id === 'canceled' && !run.selected,
                    'shadow-mobile-return-card bg-mid-gray':
                        run.isReturnPath &&
                        !run.selected &&
                        !showRunObjectColors,
                    'shadow-mobile-aller-card bg-white':
                        !run.isReturnPath &&
                        !run.selected &&
                        !showRunObjectColors,
                    'bg-primary-color text-white': run.selected,
                    [`${RunObjectColors[run.runObject.color]}`]:
                        showRunObjectColors && !run.selected,
                }
            )}
            data-for={run.id.toString()}
            onClick={() => onCardClick(run)}
        >
            {run.width >= 110 ? (
                <div
                    className={classNames('flex h-full flex-col', {
                        'text-xsm': run.width <= 150 && run.width > 125,
                        'text-xs': run.width <= 125,
                    })}
                >
                    <div className="flex flex-row justify-between">
                        <div className="flex w-5/6 flex-col">
                            <div className="truncate font-semibold">
                                {run.patientName}
                            </div>
                            <div className="truncate">{run.pickUpAddress}</div>
                        </div>
                        <div
                            className="text-lg hover:scale-110"
                            onClick={(e) => {
                                e.stopPropagation()
                                onInfoClick(run)
                            }}
                        >
                            <InfoEmpty />
                        </div>
                    </div>

                    <div className="flex w-full justify-between pb-1"></div>
                    {run.userComments ? (
                        <div className="flex w-full justify-between pb-1">
                            <div className="flex w-3/4">
                                <span className="truncate font-semibold">
                                    {run.runObject.label}
                                </span>
                            </div>
                            <div className="flex w-1/4 items-center justify-end ">
                                <span className="text-right text-base">
                                    {run.isUserCommentsRed ? (
                                        <i className="fas fa-comment-slash" />
                                    ) : (
                                        <i className="far fa-comment" />
                                    )}
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div className="flex w-full pb-1">
                            <div className="flex font-semibold">
                                {run.runObject.label}
                            </div>
                        </div>
                    )}

                    <div className="mt-auto flex w-full justify-between font-bold">
                        <div
                            className={classNames('flex', {
                                'text-green-700':
                                    dashboard.showEffectiveHours &&
                                    run.effectiveDepartureTimeHour,
                                italic: run.isReturnPath && run.approximateTime,
                            })}
                        >
                            {dashboard.showEffectiveHours &&
                            run.effectiveDepartureTimeHour
                                ? run.effectiveDepartureTimeHour
                                : run.departureTimeHour}
                        </div>
                        <div
                            className={classNames('flex', {
                                'text-green-700':
                                    dashboard.showEffectiveHours &&
                                    run.effectiveArrivingTimeHour,
                                italic: run.isReturnPath && run.approximateTime,
                            })}
                        >
                            {dashboard.showEffectiveHours &&
                            run.effectiveArrivingTimeHour
                                ? run.effectiveArrivingTimeHour
                                : run.arrivingTimeHour}
                        </div>
                    </div>
                </div>
            ) : run.width <= 110 && run.width >= 75 ? (
                <div className="flex h-full flex-col justify-between">
                    <div className="text-xxs flex flex-row flex-wrap">
                        <div className="pr-1 font-bold uppercase">
                            {run.patient.lastname}
                        </div>
                        <div className="font-bold uppercase">
                            {run.patient.firstname}
                        </div>
                    </div>
                    <div className="text-xxs truncate">{run.pickUpAddress}</div>

                    <div className="text-xxs mt-auto flex w-full flex-row justify-between font-semibold">
                        <div
                            className={classNames('flex', {
                                'text-green-700':
                                    dashboard.showEffectiveHours &&
                                    run.effectiveArrivingTimeHour,
                                italic: run.isReturnPath && run.approximateTime,
                            })}
                        >
                            {dashboard.showEffectiveHours &&
                            run.effectiveDepartureTimeHour
                                ? run.effectiveDepartureTimeHour
                                : run.departureTimeHour}
                        </div>
                        <div
                            className={classNames('flex', {
                                'text-green-700':
                                    dashboard.showEffectiveHours &&
                                    run.effectiveArrivingTimeHour,
                                italic: run.isReturnPath && run.approximateTime,
                            })}
                        >
                            {dashboard.showEffectiveHours &&
                            run.effectiveArrivingTimeHour
                                ? run.effectiveArrivingTimeHour
                                : run.arrivingTimeHour}
                        </div>
                    </div>

                    <div className="flex justify-end text-lg">
                        <div
                            className="w-8 hover:scale-110"
                            onClick={(e) => {
                                e.stopPropagation()
                                onInfoClick(run)
                            }}
                        >
                            <InfoEmpty />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="text-xxs flex h-full flex-col justify-between pb-1">
                    <div className="w-full font-bold uppercase">
                        {run.patient.lastname.substring(0, 4)}.{' '}
                        {run.patient.firstname.substring(0, 4)}.
                    </div>

                    <div className="mt-auto flex w-full flex-col justify-between font-semibold">
                        <div
                            className={classNames('flex', {
                                'text-green-700':
                                    dashboard.showEffectiveHours &&
                                    run.effectiveArrivingTimeHour,
                                italic: run.isReturnPath && run.approximateTime,
                            })}
                        >
                            {dashboard.showEffectiveHours &&
                            run.effectiveDepartureTimeHour
                                ? run.effectiveDepartureTimeHour
                                : run.departureTimeHour}
                        </div>
                        <div
                            className={classNames('flex', {
                                'text-green-700':
                                    dashboard.showEffectiveHours &&
                                    run.effectiveArrivingTimeHour,
                                italic: run.isReturnPath && run.approximateTime,
                            })}
                        >
                            {dashboard.showEffectiveHours &&
                            run.effectiveArrivingTimeHour
                                ? run.effectiveArrivingTimeHour
                                : run.arrivingTimeHour}
                        </div>
                    </div>
                    <div className="flex justify-end text-lg">
                        <div
                            className="w-8 hover:scale-110"
                            onClick={(e) => {
                                e.stopPropagation()
                                onInfoClick(run)
                            }}
                        >
                            <InfoEmpty />
                        </div>
                    </div>
                </div>
            )}
            {/*<ReactTooltip*/}
            {/*    id={run.id.toString()}*/}
            {/*    aria-haspopup="true"*/}
            {/*    role="example"*/}
            {/*    delayShow={1000}*/}
            {/*    delayHide={0}*/}
            {/*    className="max-w-xl"*/}
            {/*    disable={isMobile}*/}
            {/*>*/}
            {/*    <div>{run.patientName}</div>*/}
            {/*    <div>{run.runObject.label}</div>*/}
            {/*    <div>Départ : {run.pickUpAddress}</div>*/}
            {/*    <div>Arrivée : {run.depositAddress}</div>*/}
            {/*    <div>Commentaires : {run.comments}</div>*/}
            {/*    <div>*/}
            {/*        Horaires : {run.departureTimeHour} - {run.arrivingTimeHour}*/}
            {/*    </div>*/}
            {/*</ReactTooltip>*/}
        </div>
    )
}

export default DashboardRunCard
