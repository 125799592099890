import React, { useEffect } from 'react'
import classNames from 'classnames'
import { Community, Group, InfoEmpty } from 'iconoir-react'

const DashboardGroupedRunCard = ({
    run,
    onInfoClick,
    onTeamCardClick,
    style,
    dashboard,
}) => {
    return (
        <div
            style={style()}
            className={classNames(
                `hover-first-index shadow-mobile-aller-card relative my-3 flex cursor-pointer flex-col rounded-lg border-l-4 p-2 text-sm shadow-mobile hover:scale-[1.02] hover:shadow-mobile ${run.statusBorderColor}`,
                {
                    'bg-primary-color text-white': run.selected,
                    'bg-white': !run.selected,
                }
            )}
            onClick={() => onTeamCardClick(run)}
        >
            <div className={`flex justify-between`}>
                <div className="group-card-names-width flex truncate">
                    {run.groupedRuns.length === 2 ? (
                        <Group className="mr-2 text-xl" />
                    ) : (
                        <Community className="mr-2 text-xl" />
                    )}
                    <div
                        className={`flex h-16 w-full flex-col flex-wrap gap-x-2 truncate ${
                            run.groupedRuns.length < 2 && 'space-y-1'
                        }`}
                    >
                        {run.groupedRuns.map((r, index) => (
                            <div key={index} className="truncate font-bold">
                                {r.patientName}
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    className="w-10 text-xl hover:scale-110"
                    onClick={(e) => {
                        e.stopPropagation()
                        onInfoClick(run)
                    }}
                >
                    <InfoEmpty />
                </div>
            </div>
            <div className="mt-auto flex w-full justify-between font-bold">
                <div
                    className={classNames('flex', {
                        'text-green-700':
                            dashboard.showEffectiveHours &&
                            run.effectiveDepartureTimeHour,
                    })}
                >
                    {dashboard.showEffectiveHours &&
                    run.effectiveDepartureTimeHour
                        ? run.effectiveDepartureTimeHour
                        : run.departureTimeHour}
                </div>
                <div
                    className={classNames('flex', {
                        'text-green-700':
                            dashboard.showEffectiveHours &&
                            run.effectiveArrivingTimeHour,
                    })}
                >
                    {dashboard.showEffectiveHours &&
                    run.effectiveArrivingTimeHour
                        ? run.effectiveArrivingTimeHour
                        : run.arrivingTimeHour}
                </div>
            </div>
        </div>
    )
}

export default DashboardGroupedRunCard
