import React, { useEffect, useState } from 'react'
import API from '../../../../services/api'
import Loader from '../../../Commons/Loaders/Loader'
import NoData from '../../NoData'
import NoemieListComponent from './NoemieListComponent'

const NoemieList = ({}) => {
    const [totalItems, setTotalItems] = useState(0)
    const [firstLoading, setFirstLoading] = useState(true)
    const [loading, setLoading] = useState(true)
    const [noemies, setNoemies] = useState([])

    const getNoemies = () => {
        setLoading(true)
        API.Noemie.list(false).then((response) => {
            response.json().then((data) => {
                setNoemies(data['hydra:member'])
                setTotalItems(data['hydra:totalItems'])
                setLoading(false)
                setFirstLoading(false)
            })
        })
    }

    useEffect(() => {
        getNoemies()
    }, [])

    return (
        <div>
            {firstLoading ? (
                <Loader />
            ) : noemies.length > 0 ? (
                <NoemieListComponent
                    noemies={noemies}
                    totalItems={totalItems}
                    loading={loading}
                />
            ) : (
                <NoData message="Aucun retour NOEMIE disponible" />
            )}
        </div>
    )
}

export default NoemieList
