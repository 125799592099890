import { GoogleAutoComplete } from '../App/GoogleAutoComplete'
import { ADDRESS_ACTIONS } from '../../services/Reducers/AddressAutoCompleteReducer'
import React from 'react'
import { DataTransferBoth } from 'iconoir-react'

const AddressesInline = ({
    series,
    pickUpDispatch,
    pickUpAddress,
    inputPickUp,
    depositAddress,
    depositDispatch,
    inputDeposit,
}) => {
    return (
        <div className="flex flex-col lg:flex-row w-full space-x-4 items-center lg:items-start">
            <GoogleAutoComplete
                dispatch={pickUpDispatch}
                address={pickUpAddress}
                savedAddresses={
                    series.patient?.patientsAddresses
                        ? series.patient?.patientsAddresses
                        : []
                }
                label="Départ"
                ref={inputPickUp}
            />
            <DataTransferBoth
                className="text-5xl rotate-90 cursor-pointer hover:text-light-dark-green mt-2"
                onClick={() => {
                    pickUpDispatch({
                        type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                        payload: depositAddress,
                    })
                    depositDispatch({
                        type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                        payload: pickUpAddress,
                    })
                }}
            />
            <GoogleAutoComplete
                dispatch={depositDispatch}
                address={depositAddress}
                savedAddresses={
                    series.patient?.patientsAddresses
                        ? series.patient?.patientsAddresses
                        : []
                }
                label={'Destination'}
                ref={inputDeposit}
            />
        </div>
    )
}

export default AddressesInline
