import React from 'react'
import { useNavigate } from 'react-router-dom'
import Cancel from '../../../Commons/Cancel'
import Success from '../../../Commons/Success'

const SubscriptionSuccess = () => {
    let history = useNavigate()

    const goTo = () => {
        window.location.href = '/transport'
        // history('/parametres/abonnement')
    }

    return <Success goTo={goTo} buttonLabel="Continuer" />
}

export default SubscriptionSuccess
