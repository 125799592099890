import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import MobilePagination from '../../Commons/MobilePagination'
import DefaultOptions from '../../Commons/DefaultOptions'
import { useLongPress } from 'use-long-press'
import TOOLS from '../../../services/tools'
import MobileOption from '../../Commons/MobileOption'
import CardPaginationForcePaged from '../../Commons/Pagination/CardPaginationForcePaged'
import Loader from '../../Commons/Loaders/Loader'
import ShareModal from '../../Modal/ShareModal'
import ActionModal from '../../Modal/ActionModal'
import ComputerConfiguratorDisplayed from '../../Commons/ComputerConfiguratorDisplayed'
import MobileConfiguratorDisplayed from '../../Commons/MobileConfiguratorDisplayed'
import * as dayjs from 'dayjs'
import AddToSeriesModal from '../../Modal/AddToSeriesModal'
import { isRunAbleToBeTransferred } from '../../../services/fetchRunsInformations'
import { getBorderColor } from '../../../services/RunStatus'
import { LegendModal } from '../../Modal/LegendModal'
import { toast } from 'react-toastify'
import { SharingOptionsByType } from '../../../services/SharingOptions'

const useWindowDimensions = () => {
    const hasWindow = typeof window !== 'undefined'

    function getWindowDimensions() {
        const screenWidth = hasWindow ? window.innerWidth : null
        const screenHeight = hasWindow ? window.innerHeight : null
        return {
            screenWidth,
            screenHeight,
        }
    }

    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    )

    useEffect(() => {
        if (hasWindow) {
            function handleResize() {
                setWindowDimensions(getWindowDimensions())
            }

            window.addEventListener('resize', handleResize)
            return () => window.removeEventListener('resize', handleResize)
        }
    }, [hasWindow])

    return windowDimensions
}

const RegulationListContainer = ({
    rows,
    setPage,
    totalItems,
    loading,
    page,
    lastPage,
    isSocietyTaxi,
    handleShareRun,
    handleRemoveShare,
    handleDeleteElement,
    computerConfigurator,
    setComputerConfigurator,
    mobileConfigurator,
    setAddToTransferGroup,
    transferGroup,
    setSelectAll,
    selectAll,
    fetchRunsList,
}) => {
    let history = useNavigate()
    const [itemPerPage] = useState(100)
    const [totalPages, setTotalPages] = useState(1)
    const [showMobileOptions, setShowMobileOptions] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [showLegendModal, setShowLegendModal] = useState(false)

    const [selectedRowId, setSelectedRowId] = useState(null)
    const [selectedRow, setSelectedRow] = useState(null)
    const [styleId, setStyleId] = useState(null)
    const [partner, setPartner] = useState()
    const [note, setNote] = useState('')
    const [shareModalOpen, setShareModalOpen] = useState(false)
    const [isClearablePartner, setIsClearablePartner] = useState(false)
    const [sharedRun, setSharedRun] = useState('')
    const [hasChanged, setHasChanged] = useState(false)
    const [shareOption, setShareOption] = useState(
        SharingOptionsByType.whatsapp
    )
    const [labelAction, setLabelAction] = useState('')
    const [actionButton, setActionButton] = useState('')
    const [warningLabel, setWarningLabel] = useState('')
    const [label, setLabel] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const { screenHeight, screenWidth } = useWindowDimensions()
    const [addToSeriesModalOpen, setAddToSeriesModalOpen] = useState(false)
    const [addToSeriesRun, setAddToSeriesRun] = useState(null)

    const location = useLocation()

    useEffect(() => {
        setTotalPages(Math.ceil(totalItems / itemPerPage))
    }, [totalItems, loading])

    const handlePageClick = ({ selected: selectedPage }) => {
        setPage(selectedPage + 1)
    }

    const historyPush = (id) => {
        history(location.pathname + '/informations/' + id)
    }

    const historyPushUpdate = (id) => {
        history(location.pathname + '/' + id)
    }

    const historyPushCopy = (id) => {
        history(`/transport/0?copy=${id}`)
    }

    const optionsRef = useRef()
    TOOLS.useOnClickOutside(optionsRef, () => {
        setShowOptions(false)
        setStyleId(null)
    })

    const callback = useCallback((event) => {}, [])

    const bind = useLongPress(callback, {
        onStart: (event) => {
            setSelectedRowId(parseInt(event.currentTarget.id))
            setSelectedRow(event.currentTarget)
            setStyleId(parseInt(event.currentTarget.id))
        },
        onFinish: (event) => setShowMobileOptions(true),
        onCancel: (event) => {
            setSelectedRowId(null)
            setStyleId(null)
            setShowMobileOptions(false)
        },
        onMove: (event) => console.log('Detected mouse or touch movement'),
        threshold: 300,
        captureEvent: true,
        cancelOnMovement: true,
        detect: 'touch',
    })

    const handleModalAddToSeries = (rowId) => {
        let row = rows.find((r) => r.id === rowId)
        setAddToSeriesRun(row)
        setAddToSeriesModalOpen(true)
        setShowMobileOptions(false)
    }

    const isLocationTransfer = location.search.includes('?transfer=')

    const getRunsCanBeAddToTransferGroup = (runs) => {
        return runs.filter((run) => isRunAbleToBeTransferred(run))
    }

    const handleModalShare = (rowId) => {
        let row = rows.find((r) => r.id === rowId)
        setSharedRun(row)
        let Ifpartner = null
        if (row.partner) {
            setIsClearablePartner(true)
            Ifpartner = {
                value: 'partners/' + row.partner.id,
                label: row.partner.firstname + ' ' + row.partner.lastname,
            }
        }
        setPartner(Ifpartner)
        setNote(row.note)
        setShowMobileOptions(false)
        setShareModalOpen(true)
    }

    const handleActionShareRun = () => {
        if (shareOption.value === 'mail' && !partner.email) {
            toast.error("Le partenaire n'a pas d'adresse mail")
            return
        }
        const data = {
            partner: partner != null ? partner.value : null,
            user: localStorage.getItem('email'),
            sharingOption: shareOption.value,
            note: note,
        }
        if (hasChanged && partner != null) {
            handleShareRun(selectedRowId, data)
        }
        setShareModalOpen(false)
    }

    const handleModalDelete = (rowId) => {
        let row = rows.find((r) => r.id === rowId)
        let label =
            'Êtes-vous sûr de vouloir supprimer la course ' +
            row.id +
            ' pour ' +
            row.fullname +
            ' prévue le ' +
            dayjs(row.date).local().format('DD/MM/YYYY') +
            ' entre ' +
            row.heure +
            ' ?'

        setShowMobileOptions(false)
        setLabel(label)
        setActionButton('Supprimer')
        setLabelAction('Supprimer la course')
        setModalOpen(true)
    }

    const handleAction = () => {
        setModalOpen(false)
        handleDeleteElement(selectedRowId)
    }

    const handleAddToTransferGroup = (run) => {
        if (transferGroup.some((row) => row.id === run.id)) {
            setAddToTransferGroup(
                transferGroup.filter((row) => row.id !== run.id)
            )
        } else {
            setAddToTransferGroup([...transferGroup, run])
        }
    }

    return (
        <div className="px-2  xl:pt-5">
            <div className="relative flex flex-row items-center justify-between pb-2 text-xs  lg:justify-start lg:text-base">
                <button
                    className={`flex items-center bg-transparent text-xs font-bold hover:text-light-dark-green `}
                    onClick={() => setShowLegendModal(true)}
                >
                    <p className=" pr-2 font-bold underline underline-offset-4">
                        Légende
                    </p>
                    <i className="far fa-question-circle fa-lg cursor-pointer pr-2" />
                </button>
                <div className="ml-auto text-xs font-bold">
                    {totalItems} Transports
                </div>
                {getRunsCanBeAddToTransferGroup(rows).length > 0 &&
                isLocationTransfer ? (
                    <div
                        className={`absolute left-0 -ml-10 hidden h-10 w-10 cursor-pointer rounded pb-1  text-center xl:block`}
                        onClick={() => {
                            if (!selectAll) {
                                setSelectAll(true)
                                setAddToTransferGroup(
                                    getRunsCanBeAddToTransferGroup(rows)
                                )
                            } else {
                                setSelectAll(false)
                                setAddToTransferGroup([])
                            }
                        }}
                    >
                        <button
                            className={`form-checkbox h-3 w-3 cursor-pointer rounded-sm border-dark-green text-dark-green ring-2 ring-offset-2 ${
                                selectAll
                                    ? 'bg-green-500 ring-green-500'
                                    : 'bg-white ring-gray-500'
                            }`}
                        />
                    </div>
                ) : null}
            </div>
            {loading ? (
                <Loader />
            ) : (
                <div className="min-height-70">
                    {rows.map((row) => (
                        <div
                            className="parents relative flex w-full pb-2"
                            key={row.id}
                        >
                            <div
                                className={`grid  ${
                                    isRunAbleToBeTransferred(row)
                                        ? 'shadow-transferable bg-white'
                                        : 'bg-white shadow-mobile'
                                }   w-full cursor-pointer select-none rounded border-l-4 lg:select-auto lg:border-l-8  ${
                                    row.id === styleId ? 'ring' : null
                                } ${getBorderColor(row.status.id)}
                                ${
                                    row.partner && row.isGiven
                                        ? ' stripeBg'
                                        : null
                                } ${
                                    row.status.id === 'canceled'
                                        ? ' stripeBg_canceled'
                                        : null
                                }
                                `}
                                id={row.id}
                                {...bind}
                                onClick={() => historyPushUpdate(row.id)}
                            >
                                {screenWidth < 1024 ? (
                                    <div
                                        className={`h-42 parents row-span-1 flex grid-rows-1 flex-wrap items-center justify-between px-2 py-1 text-sm sm:text-base`}
                                    >
                                        {Object.entries(mobileConfigurator).map(
                                            ([key, value]) => (
                                                <MobileConfiguratorDisplayed
                                                    row={row}
                                                    value={value}
                                                    key={key}
                                                    isSocietyTaxi={
                                                        isSocietyTaxi
                                                    }
                                                />
                                            )
                                        )}
                                    </div>
                                ) : (
                                    <div
                                        className={`h-42 parents row-span-1 flex grid-rows-1 flex-wrap items-center justify-between px-2 py-1 text-base`}
                                    >
                                        {Object.entries(
                                            computerConfigurator
                                        ).map(([key, value]) => (
                                            <ComputerConfiguratorDisplayed
                                                row={row}
                                                value={value}
                                                key={key}
                                                isSocietyTaxi={isSocietyTaxi}
                                            />
                                        ))}
                                        <div
                                            className={`absolute right-0 hidden h-10 cursor-pointer rounded pt-2 xl:block ${
                                                row.id === selectedRowId
                                                    ? null
                                                    : 'options '
                                            } `}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setShowOptions(true)
                                                setSelectedRowId(row.id)
                                                setSelectedRow(row)
                                                setStyleId(row.id)
                                            }}
                                        >
                                            <i
                                                className={`fas fa-ellipsis-h w-20 pr-4 pt-1 text-right ${
                                                    showOptions ? 'hidden' : ''
                                                } `}
                                            />
                                            {row.id === selectedRowId &&
                                            showOptions ? (
                                                <DefaultOptions
                                                    setShowMobileOptions={
                                                        setShowOptions
                                                    }
                                                    rowId={selectedRowId}
                                                    historyPush={historyPush}
                                                    historyPushCopy={
                                                        historyPushCopy
                                                    }
                                                    optionsRef={optionsRef}
                                                    handleModalShare={
                                                        handleModalShare
                                                    }
                                                    handleModalDelete={
                                                        handleModalDelete
                                                    }
                                                    historyPushUpdate={
                                                        historyPushUpdate
                                                    }
                                                    isSeriesRun={!!row.series}
                                                    handleModalAddToSeries={
                                                        handleModalAddToSeries
                                                    }
                                                    isPartnerRun={
                                                        !!selectedRow.partner
                                                    }
                                                />
                                            ) : null}
                                        </div>
                                        {isRunAbleToBeTransferred(row) &&
                                        isLocationTransfer ? (
                                            <div
                                                className={`absolute left-0 -ml-10 hidden h-10 w-10 cursor-pointer rounded pt-1 text-center xl:block
                                                `}
                                                onClick={(e) => {
                                                    handleAddToTransferGroup(
                                                        row
                                                    )
                                                    e.stopPropagation()
                                                }}
                                            >
                                                <button
                                                    className={`form-checkbox h-2 w-2 cursor-pointer rounded-sm border-dark-green text-dark-green ring-2 ring-offset-2 ${
                                                        transferGroup.some(
                                                            (run) =>
                                                                row.id ===
                                                                run.id
                                                        )
                                                            ? 'bg-green-500 ring-green-500'
                                                            : 'bg-white ring-gray-500'
                                                    }`}
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                    {totalItems >= itemPerPage && (
                        <MobilePagination
                            title={'Prises en charges'}
                            setPage={setPage}
                            page={page}
                            totalItems={totalItems}
                            lastPage={lastPage}
                        />
                    )}
                </div>
            )}
            {totalItems >= itemPerPage && (
                <CardPaginationForcePaged
                    totalPages={totalPages}
                    page={page}
                    handlePageClick={handlePageClick}
                    totalItems={totalItems}
                    title={'Prises en charges'}
                />
            )}
            {showMobileOptions ? (
                <MobileOption
                    setShowMobileOptions={setShowMobileOptions}
                    rowId={selectedRowId}
                    historyPush={historyPush}
                    historyPushCopy={historyPushCopy}
                    setStyleId={setStyleId}
                    handleModalShare={handleModalShare}
                    handleModalDelete={handleModalDelete}
                    historyPushUpdate={historyPushUpdate}
                    isSeriesRun={
                        !!rows.find((r) => r.id === selectedRowId).series
                    }
                    handleModalAddToSeries={handleModalAddToSeries}
                    view={true}
                    isPartnerRun={!!selectedRow.partner}
                />
            ) : null}
            {showLegendModal && (
                <LegendModal setShowLegendModal={setShowLegendModal} />
            )}
            {modalOpen ? (
                <ActionModal
                    setModalOpen={setModalOpen}
                    label={label}
                    warningLabel={warningLabel}
                    action={handleAction}
                    labelAction={labelAction}
                    actionButton={actionButton}
                />
            ) : null}
            {shareModalOpen ? (
                <ShareModal
                    setShareModalOpen={setShareModalOpen}
                    label={label}
                    handleActionShareRun={handleActionShareRun}
                    labelAction={labelAction}
                    actionButton={actionButton}
                    sharedRun={sharedRun}
                    note={note}
                    setNote={setNote}
                    setPartner={setPartner}
                    partner={partner}
                    isClearablePartner={isClearablePartner}
                    setIsClearablePartner={setIsClearablePartner}
                    setHasChanged={setHasChanged}
                    shareOption={shareOption}
                    setShareOption={setShareOption}
                    handleRemoveShare={handleRemoveShare}
                />
            ) : null}
            {addToSeriesModalOpen ? (
                <AddToSeriesModal
                    setAddToSeriesModalOpen={setAddToSeriesModalOpen}
                    run={addToSeriesRun}
                    setAddToSeriesRun={setAddToSeriesRun}
                    fetchRunsList={fetchRunsList}
                />
            ) : null}
        </div>
    )
}

export default RegulationListContainer
