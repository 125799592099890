import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import CpamInvoiceStatusChart from './CpamInvoiceStatusChart'
import TransportStatusChart from './TransportStatusChart'
import RevenueChart from './RevenueChart'
import CalendarDatePicker from '../../Commons/CalendarDatePickers/CalendarDatePicker'
import { ArrowRight } from 'iconoir-react'
import { formatDateToString } from '../../../services/DateService'
import { getHeaders } from '../../../services/api'
import { formatEuro } from '../Invoice/Manage'
import OutstandingBalanceChart from './OutstandingBalanceChart'
import { API_ENTRYPOINT } from '../../../config/entrypoint'

const fetchInvoiceChart = async (startDate, endDate) => {
    const headers = await getHeaders(true)
    const response = await fetch(
        `${API_ENTRYPOINT}/invoices/status?start_date=${formatDateToString(
            startDate
        )}&end_date=${formatDateToString(endDate)}`,
        {
            method: 'GET',
            headers,
        }
    )
    if (!response.ok) {
        throw new Error('Network response was not ok')
    }

    return await response.json()
}

const fetchOutstanding = async () => {
    const headers = await getHeaders(true)
    const response = await fetch(`${API_ENTRYPOINT}/invoices/outstanding`, {
        method: 'GET',
        headers,
    })
    if (!response.ok) {
        throw new Error('Network response was not ok')
    }

    return await response.json()
}

const fetchTransportChart = async (startDate, endDate) => {
    const headers = await getHeaders(true)
    const response = await fetch(
        `${API_ENTRYPOINT}/runs/status?start_date=${formatDateToString(
            startDate
        )}&end_date=${formatDateToString(endDate)}`,
        {
            method: 'GET',
            headers,
        }
    )
    if (!response.ok) {
        throw new Error('Network response was not ok')
    }

    return await response.json()
}
const fetchRevenueChart = async (startDate, endDate) => {
    const headers = await getHeaders(true)
    const response = await fetch(
        `${API_ENTRYPOINT}/invoices/monthly?start_date=${formatDateToString(
            startDate
        )}&end_date=${formatDateToString(endDate)}`,
        {
            method: 'GET',
            headers,
        }
    )
    if (!response.ok) {
        throw new Error('Network response was not ok')
    }

    return await response.json()
}

const fetchPaymentTable = async () => {
    // Appel API pour les données du tableau de paiements
}

const fetchDriverRevenue = async () => {
    // Appel API pour les données du CA par chauffeur
}

const StatisticsContainer = () => {
    const [startDate, setStartDate] = useState(
        new Date(new Date().getFullYear(), 0, 1)
    )
    const [endDate, setEndDate] = useState(
        new Date(new Date().getFullYear(), 11, 31)
    )

    const { data: invoiceData = [], isLoading: isLoadingInvoice } = useQuery({
        queryKey: ['invoiceChart', startDate, endDate],
        queryFn: () => fetchInvoiceChart(startDate, endDate),
    })

    const { data: transportData = [], isLoading: isLoadingTransport } =
        useQuery({
            queryKey: ['transportChart', startDate, endDate],
            queryFn: () => fetchTransportChart(startDate, endDate),
        })

    const { data: revenueData = [], isLoading: isLoadingRevenue } = useQuery({
        queryKey: ['revenueChart', startDate, endDate],
        queryFn: () => fetchRevenueChart(startDate, endDate),
    })

    const { data: outstandingBalance = [], isLoading: isLoadingOutstanding } =
        useQuery({
            queryKey: ['outstandingBalance'],
            queryFn: () => fetchOutstanding(),
        })

    const [openSelectStartDatePicker, setOpenSelectStartDatePicker] =
        useState(false)
    const [openSelectEndDatePicker, setOpenSelectEndDatePicker] =
        useState(false)

    return (
        <div className=" ">
            {/* En-tête et filtres */}
            <div className="mb-4 grid grid-cols-3 gap-4 pt-5">
                <div className={'col-span-2 flex flex-col'}>
                    <h2 className={'text-xl font-semibold'}>Période</h2>
                    <div className="flex items-center space-x-2">
                        <CalendarDatePicker
                            openSelectDatePicker={openSelectStartDatePicker}
                            setOpenSelectDatePicker={() =>
                                setOpenSelectStartDatePicker(
                                    !openSelectStartDatePicker
                                )
                            }
                            date={startDate}
                            setDate={(date) => setStartDate(date)}
                            icon={false}
                            fontSize="text-lg lg:text-sm"
                            customDisplay="D MMM YYYY"
                        />
                        <ArrowRight />
                        <CalendarDatePicker
                            openSelectDatePicker={openSelectEndDatePicker}
                            setOpenSelectDatePicker={() =>
                                setOpenSelectEndDatePicker(
                                    !openSelectEndDatePicker
                                )
                            }
                            date={endDate}
                            fontSize="text-lg lg:text-sm"
                            setDate={(date) => setEndDate(date)}
                            icon={false}
                            customDisplay="D MMM YYYY"
                        />
                    </div>
                </div>
            </div>
            {/* Graphiques */}
            <div className="mb-4 grid grid-cols-2 gap-4">
                <div>
                    <div className={'flex flex-col pb-2'}>
                        <h2 className={' text-xl font-semibold'}>Factures</h2>
                        <p className={''}>
                            {!isLoadingInvoice ? invoiceData.total : null}
                        </p>
                    </div>
                    {isLoadingInvoice ? (
                        <LoadingSpinner />
                    ) : (
                        <CpamInvoiceStatusChart data={invoiceData} />
                    )}
                </div>
                <div>
                    <div className={'flex flex-col pb-2'}>
                        <h2 className={' text-xl font-semibold'}>Transports</h2>
                        <p className={''}>
                            {!isLoadingTransport && transportData.total}
                        </p>
                    </div>
                    {isLoadingTransport ? (
                        <LoadingSpinner />
                    ) : (
                        <TransportStatusChart data={transportData} />
                    )}
                </div>
            </div>
            <div className="mb-4 grid grid-cols-2 gap-4">
                <div>
                    <div className={'flex flex-col pb-2'}>
                        <h2 className={' text-xl font-semibold'}>
                            Chiffre d'affaire
                        </h2>
                        <p className={''}>
                            {!isLoadingRevenue && formatEuro(revenueData.total)}
                        </p>
                    </div>
                    {isLoadingInvoice ? (
                        <LoadingSpinner />
                    ) : (
                        <RevenueChart data={revenueData} />
                    )}
                </div>
                <div>
                    <div className={'flex flex-col pb-2'}>
                        <h2 className={'text-xl font-semibold'}>Reste dû</h2>
                        <p className={''}>
                            {!isLoadingOutstanding &&
                                formatEuro(outstandingBalance.total)}
                        </p>
                    </div>
                    {isLoadingOutstanding ? (
                        <LoadingSpinner />
                    ) : (
                        <OutstandingBalanceChart
                            outstandingBalance={outstandingBalance}
                        />
                    )}
                </div>
            </div>
            {/* Tableaux */}
            <div className="grid grid-cols-2 gap-4">
                {/*<h2 className={'pb-3 text-xl font-semibold'}>*/}
                {/*    CA par chauffeur*/}
                {/*</h2>*/}
                {/*{isLoadingDriver ? (*/}
                {/*    <LoadingSpinner />*/}
                {/*) : (*/}
                {/*    <div>Tableau de CA par chauffeur</div>*/}
                {/*)}*/}
            </div>
        </div>
    )
}

export default StatisticsContainer

// Composants de chargement
const LoadingCard = () => (
    <div className="animate-pulse rounded-lg bg-white p-4">
        <div className="mb-2 h-4 w-3/4 rounded bg-gray-200"></div>
        <div className="h-8 rounded bg-gray-200"></div>
    </div>
)
const LoadingSpinner = () => (
    <div className="flex h-64 items-center justify-center">
        <div className="h-12 w-12 animate-spin rounded-full border-b-2 border-primary-color"></div>
    </div>
)
