import React, { useState } from 'react'
import {
    useStickyDateState,
    useStickyEndDateState,
} from '../../../../hooks/LocalStorageDatesHooks'
import InvoiceCpamList from './InvoiceCpamList'
import InvoiceCpamFilter from './InvoiceCpamFilter'
import useConfiguratorStore from '../../../../stores/Configurator'

const InvoiceCpamContainer = () => {
    const filterPatient = useConfiguratorStore((state) => state.filterPatient)
    const setFilterPatient = useConfiguratorStore(
        (state) => state.setFilterPatient
    )
    let patientFilter
    if (
        filterPatient && // 👈 null and undefined check
        Object.keys(filterPatient).length === 0 &&
        Object.getPrototypeOf(filterPatient) === Object.prototype
    ) {
        patientFilter = null
    } else {
        patientFilter = filterPatient
    }
    const filterHealthComplementary = useConfiguratorStore(
        (state) => state.filterHealthComplementary
    )
    const setFilterHealthComplementary = useConfiguratorStore(
        (state) => state.setFilterHealthComplementary
    )
    let healthComplementaryFilter
    if (
        filterHealthComplementary && // 👈 null and undefined check
        Object.keys(filterHealthComplementary).length === 0 &&
        Object.getPrototypeOf(filterHealthComplementary) === Object.prototype
    ) {
        healthComplementaryFilter = null
    } else {
        healthComplementaryFilter = filterHealthComplementary
    }
    const filterOutstandingBalance = useConfiguratorStore(
        (state) => state.filterOutstandingBalance
    )
    const setFilterOutstandingBalance = useConfiguratorStore(
        (state) => state.setFilterOutstandingBalance
    )
    let outstandingBalanceFilter
    if (
        filterOutstandingBalance && // 👈 null and undefined check
        Object.keys(filterOutstandingBalance).length === 0 &&
        Object.getPrototypeOf(filterOutstandingBalance) === Object.prototype
    ) {
        outstandingBalanceFilter = null
    } else {
        outstandingBalanceFilter = filterOutstandingBalance
    }

    const [search, setSearch] = useState('')
    const [archived, setArchived] = useState(false)
    const [patient, setPatient] = useState(patientFilter)
    const [payingCenter, setPayingCenter] = useState(null)
    const [healthComplementary, setHealthComplementary] = useState(
        healthComplementaryFilter
    )
    const [outstandingBalance, setOutstandingBalance] = useState(
        outstandingBalanceFilter
    )
    const [dateFrom, setDateFrom] = useStickyDateState(new Date(), 'storedDate')
    const [dateTo, setDateTo] = useStickyEndDateState(
        new Date(),
        'storedDate',
        'storedEndDate'
    )
    const [openFilterModal, setOpenFilterModal] = useState(false)

    const [appliedFilters, setAppliedFilters] = useState({
        dateFrom: dateFrom,
        dateTo: dateTo,
        search: search,
        archived: archived,
        patient: patient,
        payingCenter: payingCenter,
        healthComplementary: healthComplementary,
        outstandingBalance: outstandingBalance,
    })

    const clearFilters = () => {
        setSearch('')
        setArchived(false)
        setPatient(null)
        setPayingCenter(null)
        setFilterHealthComplementary(null)
        setFilterOutstandingBalance(null)
        setFilterPatient(null)
        setHealthComplementary(null)
        setOutstandingBalance(null)
        setDateFrom(new Date())
        setDateTo(new Date())
        setArchived(null)
        applyFilters({})
    }

    const applyFilters = (filters) => {
        setAppliedFilters(filters)
        setOpenFilterModal(false)
    }

    return (
        <div className="pb-10">
            <InvoiceCpamFilter
                search={search}
                setSearch={setSearch}
                dateFrom={dateFrom}
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
                dateTo={dateTo}
                setArchived={setArchived}
                archived={archived}
                openFilterModal={openFilterModal}
                setOpenFilterModal={setOpenFilterModal}
                patient={patient}
                setPatient={setPatient}
                payingCenter={payingCenter}
                setPayingCenter={setPayingCenter}
                healthComplementary={healthComplementary}
                setHealthComplementary={setHealthComplementary}
                outstandingBalance={outstandingBalance}
                setOutstandingBalance={setOutstandingBalance}
                applyFilters={applyFilters}
                clearFilters={clearFilters}
            />
            <InvoiceCpamList
                dateFrom={dateFrom}
                dateTo={dateTo}
                search={search}
                archived={archived}
                patient={appliedFilters.patient}
                payingCenter={appliedFilters.payingCenter}
                healthComplementary={appliedFilters.healthComplementary}
                outstandingBalance={appliedFilters.outstandingBalance}
            />
        </div>
    )
}

export default InvoiceCpamContainer
