import React, { useState } from 'react'
import RegulationFilter from './RegulationFilter'
import Regulation from './Regulation'
import { Route, Routes, useLocation } from 'react-router-dom'
import useConfiguratorStore from '../../../stores/Configurator'
import {
    useStickyDateState,
    useStickyEndDateState,
} from '../../../hooks/LocalStorageDatesHooks'
import RegulationSeriesFilter from './RegulationSeriesFilter'
import RunTabs from './RunTabs'

const RegulationContainer = ({ isSocietyTaxi }) => {
    const filterPatient = useConfiguratorStore((state) => state.filterPatient)
    const setFilterPatient = useConfiguratorStore(
        (state) => state.setFilterPatient
    )
    const filterPartner = useConfiguratorStore((state) => state.filterPartner)
    const setFilterPartner = useConfiguratorStore(
        (state) => state.setFilterPartner
    )

    let partnerFilter
    let patientFilter

    if (
        filterPatient && // 👈 null and undefined check
        Object.keys(filterPatient).length === 0 &&
        Object.getPrototypeOf(filterPatient) === Object.prototype
    ) {
        patientFilter = null
    } else {
        patientFilter = filterPatient
    }
    if (
        filterPartner && // 👈 null and undefined check
        Object.keys(filterPartner).length === 0 &&
        Object.getPrototypeOf(filterPartner) === Object.prototype
    ) {
        partnerFilter = null
    } else {
        partnerFilter = filterPartner
    }

    const [transferLabel, setTransferLabel] = useState('Télétransmettre')
    const [search, setSearch] = useState('')
    const [refreshing, setRefreshing] = useState(false)
    const [firstLoading, setFirstLoading] = useState(true)
    const [dateFrom, setDateFrom] = useStickyDateState(new Date(), 'storedDate')

    const [dateTo, setDateTo] = useStickyEndDateState(
        new Date(),
        'storedDate',
        'storedEndDate'
    )
    const [patient, setPatient] = useState(patientFilter)
    const [partner, setPartner] = useState(partnerFilter)

    const [type, setType] = useState(null)
    const [object, setObject] = useState(null)
    const [status, setStatus] = useState(null)
    const [page, setPage] = useState(1)
    const [action, setAction] = useState(false)
    const [user, setUser] = useState(null)
    const [shareFilter, setShareFilter] = useState(null)
    const [transferGroup, setAddToTransferGroup] = useState([])
    const [selectAll, setSelectAll] = useState(null)
    const [excludeStatus, setExcludeStatus] = useState(null)

    const seriesFirstRunDate = useConfiguratorStore(
        (state) => state.seriesFirstRunDate
    )
    const setSeriesFirstRunDate = useConfiguratorStore(
        (state) => state.setSeriesFirstRunDate
    )
    const seriesId = new URLSearchParams(useLocation().search).get('series')

    const handleErase = () => {
        setObject(null)
        setPatient(null)
        setPartner(null)
        setUser(null)
        setStatus(null)
        setType(null)
        setFilterPatient({})
        setFilterPartner({})
        setDateTo(new Date())
        setDateFrom(new Date())
        setAddToTransferGroup([])
        setSelectAll(null)
        setPage(1)
        setAction(!action)
        setFirstLoading(true)
        setSearch('')
        setShareFilter(null)
        setExcludeStatus(null)
    }
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <div className="flex flex-col pb-10">
                        {seriesId ? (
                            <RegulationSeriesFilter
                                search={search}
                                setSearch={setSearch}
                                seriesId={seriesId}
                            />
                        ) : (
                            <RegulationFilter
                                search={search}
                                setSearch={setSearch}
                                setRefreshing={setRefreshing}
                                refreshing={refreshing}
                                dateFrom={dateFrom}
                                dateTo={dateTo}
                                setDateTo={setDateTo}
                                setDateFrom={setDateFrom}
                                type={type}
                                setType={setType}
                                setPage={setPage}
                                page={page}
                                setPatient={setPatient}
                                setPartner={setPartner}
                                partner={partner}
                                patient={patient}
                                action={action}
                                setAction={setAction}
                                setObject={setObject}
                                object={object}
                                handleErase={handleErase}
                                setStatus={setStatus}
                                status={status}
                                setFirstLoading={setFirstLoading}
                                user={user}
                                setUser={setUser}
                                setShareFilter={setShareFilter}
                                shareFilter={shareFilter}
                                transferLabel={transferLabel}
                                setTransferLabel={setTransferLabel}
                                transferGroup={transferGroup}
                                setAddToTransferGroup={setAddToTransferGroup}
                                setSelectAll={setSelectAll}
                                excludeStatus={excludeStatus}
                                setExcludeStatus={setExcludeStatus}
                            />
                        )}
                        <Regulation
                            user={user}
                            search={search}
                            dateFrom={dateFrom}
                            setRefreshing={setRefreshing}
                            dateTo={dateTo}
                            type={type}
                            page={page}
                            setPage={setPage}
                            patient={patient}
                            action={action}
                            setAction={setAction}
                            object={object}
                            handleErase={handleErase}
                            firstLoading={firstLoading}
                            setFirstLoading={setFirstLoading}
                            status={status}
                            partner={partner}
                            isSocietyTaxi={isSocietyTaxi}
                            seriesId={seriesId}
                            seriesFirstRunDate={seriesFirstRunDate}
                            shareFilter={shareFilter}
                            transferGroup={transferGroup}
                            setAddToTransferGroup={setAddToTransferGroup}
                            setSelectAll={setSelectAll}
                            selectAll={selectAll}
                            excludeStatus={excludeStatus}
                        />
                    </div>
                }
            />
            <Route
                path=":id"
                element={
                    <RunTabs
                        date={dateFrom}
                        setDate={setDateFrom}
                        setDateTo={setDateTo}
                    />
                }
            />
        </Routes>
    )
}

export default RegulationContainer
