import React from 'react'
import dayjs from 'dayjs'

const DateMobileSecondFormat = ({ row }) => {
    return (
        <div className="flex w-28 justify-center capitalize">
            {dayjs(row.date).local().format('ddd D MMM')}
        </div>
    )
}

export default DateMobileSecondFormat
