import React, { useEffect, useRef, useState } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import PatientContainer from './Patient/PatientContainer'
import UserContainer from './User/UserContainer'
import VehicleContainer from './Vehicle/VehicleContainer'
import RegulationContainer from './Regulation/RegulationContainer'
import Notification from './Notification'
import Profile from './Profile'
import API from '../../services/api'
import PartnerContainer from './Partner/PartnerContainer'
import ParameterContainer from './Parameter/ParameterContainer'
import * as dayjs from 'dayjs'
import SeriesContainer from './Series/SeriesContainer'
import Preferences from './Preferences'
import InvoiceContainer from './Invoice/InvoiceContainer'
import EstablishmentContainer from './Establishment/EstablishmentContainer'
import WelcomeContainer from './Welcome/WelcomeContainer'
import PlanningContainer from './Schedule/PlanningContainer'
import MobileHeader from '../DriverAccess/MobileHeader'
import MobileUserContainer from '../DriverAccess/MobileUserContainer'
import Navigator from './Navigator'
import NotifDropDown from '../Notifications/NotifDropDown'
import UserMenu from '../Commons/UserMenu'
import { NavArrowRight } from 'iconoir-react'
import InvoiceCpamContainer from './Invoice/Cpam/InvoiceCpamContainer'
import LotContainer from './Invoice/Lots/LotContainer'
import NoemieContainer from './Invoice/Noemie/NoemieContainer'
import Teams from './Team/Teams'
import Gleap from 'gleap'
import StatisticsContainer from './Statistics/StatisticsContainer'
import { DeviceManager } from '../../DeviceManager'
import { PlanningProvider } from './Schedule/PlanningContext'

const useOnClickUserMenuOutside = (ref, outsideHandler, clickHandler) => {
    useEffect(() => {
        const listener = (event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                // clickHandler(event)
            } else {
                outsideHandler(event)
            }
        }

        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [ref, outsideHandler, clickHandler])
}

const Dashboard = ({ socketNotification, setSocketNotification }) => {
    const [isMenuIconActive, setIsMenuIconActive] = useState(false)
    const [isSocietyTaxi, setIsSocietyTaxi] = useState(false)
    let history = useNavigate()
    let location = useLocation()
    const [showRunObjectColors, setShowRunObjectColors] = useState(false)

    const [welcomeModalOpen, setWelcomeModalOpen] = useState(
        localStorage.getItem('societyWelcomePresentationPassed') === 'false'
    )

    const toggleClass = () => {
        setIsMenuIconActive(!isMenuIconActive)
    }

    function fetchParametersTeam() {
        const society = localStorage.getItem('society')
            ? localStorage.getItem('society')
            : null
        if (society) {
            API.Parameter.get(society).then((response) => {
                response.json().then((data) => {
                    setIsSocietyTaxi(data.societyTaxiOnly)
                    setShowRunObjectColors(data.showRunObjectColors)
                })
            })
        } else {
            window.location.href = '/' // redirect
        }
    }

    const registrationIsPassed = () => {
        const society = localStorage.getItem('society')
            ? localStorage.getItem('society')
            : null
        if (society) {
            API.Society.get(society.replace(/\D/g, '')).then((response) => {
                response.json().then((data) => {
                    if (!data.registrationPassed) {
                        //user can't be here
                        DeviceManager.clearAllExceptDeviceId()
                        window.location.href = '/' // redirect
                    }
                    if (data.plan.id === 'free') {
                        const daysDiff = dayjs().diff(
                            dayjs(data.lastUpdatedPlanAt),
                            'days'
                        )

                        const daysLeft = 14 - daysDiff

                        if (daysLeft <= 0) {
                            localStorage.setItem('isFreeTrialEnded', 'true')
                            if (
                                !location.pathname.startsWith(
                                    '/parametres/abonnement'
                                )
                            ) {
                                history('/parametres/abonnement')
                            }
                        } else {
                            localStorage.setItem('isFreeTrialEnded', 'false')
                        }
                    } else {
                        localStorage.setItem('isFreeTrialEnded', 'false')
                        localStorage.setItem('premium', 'true')
                    }
                })
            })
        } else {
            return false
        }
    }

    const isUser = localStorage.getItem('role') === 'USER'
    // on cache gleap si l'utilisateur est un chauffeur
    if (isUser) {
        Gleap.showFeedbackButton(false)
    }

    useEffect(() => {
        registrationIsPassed()
        fetchParametersTeam()
    }, [])

    useEffect(() => {
        if (
            isUser &&
            !(
                location.pathname.startsWith('/profil') ||
                location.pathname.startsWith('/chauffeur')
            )
        ) {
            window.location.href = '/chauffeur'
        }
    }, [location.pathname])

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    /** Notifications */

    const [openNotifDropDown, setOpenNotifDropDown] = useState(false)
    const notifMenuRef = useRef()
    useOnClickUserMenuOutside(
        notifMenuRef,
        () => setOpenNotifDropDown(false),
        () => setOpenNotifDropDown(true)
    )
    const [loading, setLoading] = useState(true)
    const [reloadNotif, setReloadNotif] = useState(false)
    const [notifications, setNotifications] = useState(true)

    const getNotifs = () => {
        setLoading(true)
        API.Notifications.list().then((response) => {
            response.json().then((data) => {
                const formatted = data['hydra:member']
                setNotifications(formatted)
                setLoading(false)
            })
        })
    }

    useEffect(() => {
        getNotifs()
    }, [socketNotification, reloadNotif])

    /** Fin notifications */

    /** User menu */

    const [openUserMenu, setOpenUserMenu] = useState(false)
    const userMenuRef = useRef()
    useOnClickUserMenuOutside(
        userMenuRef,
        () => setOpenUserMenu(false),
        () => setOpenUserMenu(true)
    )

    /** Fin user menu */

    return (
        <div className="grid-container text-content-color">
            {isUser ? (
                <MobileHeader />
            ) : (
                <Navigator
                    setSocketNotification={setSocketNotification}
                    socketNotification={socketNotification}
                    isActive={isMenuIconActive}
                    toggleClass={toggleClass}
                    isUser={isUser}
                    setIsMenuIconActive={setIsMenuIconActive}
                    openNotifDropDown={openNotifDropDown}
                    setOpenNotifDropDown={setOpenNotifDropDown}
                    notifications={notifications}
                    setReloadNotif={setReloadNotif}
                    reloadNotif={reloadNotif}
                    openUserMenu={openUserMenu}
                    setOpenUserMenu={setOpenUserMenu}
                />
            )}
            <main
                className="main pb-47px invisible-scrollbar h-screen overflow-y-scroll border-t"
                id="main"
            >
                {!isMenuIconActive && windowWidth < 1250 && !isUser && (
                    <div
                        className="menu-icon flex h-14 items-center rounded-r-xl border-b border-r border-t border-secondary-color bg-white"
                        onClick={toggleClass}
                    >
                        <NavArrowRight className="text-xl text-secondary-color" />
                    </div>
                )}
                <div className="xl:px-10">
                    <Routes>
                        <Route
                            path="/transport/*"
                            element={
                                <RegulationContainer
                                    isSocietyTaxi={isSocietyTaxi}
                                />
                            }
                        />
                        <Route path="/profil" element={<Profile />} />
                        <Route path="/preferences" element={<Preferences />} />
                        <Route path="/ressources/equipes" element={<Teams />} />
                        <Route
                            path="/regulation/*"
                            element={
                                <PlanningProvider>
                                    <PlanningContainer
                                        showRunObjectColors={
                                            showRunObjectColors
                                        }
                                    />
                                </PlanningProvider>
                            }
                        />
                        <Route path="/series/*" element={<SeriesContainer />} />
                        <Route
                            path="/ressources/repertoire/*"
                            element={<PatientContainer />}
                        />
                        <Route
                            path="/ressources/partenaires/*"
                            element={<PartnerContainer />}
                        />
                        <Route
                            path="/ressources/utilisateurs/*"
                            element={<UserContainer />}
                        />
                        <Route
                            path="/ressources/vehicules/*"
                            element={<VehicleContainer />}
                        />
                        <Route
                            path="/parametres/*"
                            element={<ParameterContainer />}
                        />
                        <Route
                            path="/statistiques"
                            element={<StatisticsContainer />}
                        />
                        <Route
                            path="/facturation/factures/*"
                            element={<InvoiceContainer />}
                        />
                        <Route
                            path="/facturation/sanitaires/*"
                            element={<InvoiceCpamContainer />}
                        />
                        <Route
                            path="/facturation/lots/*"
                            element={<LotContainer />}
                        />
                        <Route
                            path="/facturation/noemie/*"
                            element={<NoemieContainer />}
                        />
                        <Route
                            path="/facturation/etablissements/*"
                            element={<EstablishmentContainer />}
                        />
                        <Route
                            path="/chauffeur/*"
                            element={<MobileUserContainer />}
                        />
                    </Routes>
                </div>
            </main>
            <Notification />
            {welcomeModalOpen && (
                <WelcomeContainer setWelcomeModalOpen={setWelcomeModalOpen} />
            )}
            {openNotifDropDown && (
                <NotifDropDown
                    notifMenuRef={notifMenuRef}
                    setOpenNotifDropDown={setOpenNotifDropDown}
                    notifications={notifications}
                    setReloadNotif={setReloadNotif}
                    reloadNotif={reloadNotif}
                    loading={loading}
                />
            )}

            {openUserMenu ? <UserMenu userMenuRef={userMenuRef} /> : null}
        </div>
    )
}

export default Dashboard
