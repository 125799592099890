import * as dayjs from 'dayjs'
import { sortWithNewTimes } from '../tools/Sort'

export const formatData = (runs) => {
    return runs.sort(sortWithNewTimes).map((run) => {
        const startDate = new Date(run.departureTime)
        const endDate = new Date(run.arrivingTime)
        const startMinutes =
            (startDate.getMinutes() < 10 ? '0' : '') + startDate.getMinutes()
        const endMinutes =
            (endDate.getMinutes() < 10 ? '0' : '') + endDate.getMinutes()
        const startHour = startDate.getHours() + 'h' + startMinutes
        const endHour = endDate.getHours() + 'h' + endMinutes
        let patientName = ''
        if (run.patient != null) {
            patientName = `${run.patient.firstname} ${run.patient.lastname}`
        }
        const ride = `${run.pickUpLocation.city} - ${run.depositLocation.city}`
        const address = `${
            run.pickUpLocation.street ? run.pickUpLocation.street + ' - ' : ''
        }${run.pickUpLocation.zipCode ? run.pickUpLocation.zipCode : ''} ${
            run.pickUpLocation.city
        }`
        let team = ''
        if (run.masterRun != null) {
            run.masterRun.users.forEach((user) => {
                if (team !== '') team += ' - ' + user.lastname
                else team = user.lastname
            })
        }

        return {
            ...run,
            fullname: patientName,
            heure: startHour + ' - ' + endHour,
            ride: ride,
            address: address,
            team: team,
        }
    })
}

function sortedRuns(runs) {
    return runs.sort(function (a, b) {
        return (
            new Date(a.departureTime) - new Date(b.departureTime) ||
            new Date(a.arrivingTime) - new Date(b.arrivingTime)
        )
    })
}

export const formatRunData = (runs) => {
    return runs.sort(sortWithNewTimes).map((run) => {
        return {
            date: dayjs(run.date).local().format('DD/MM/YYYY'),
            patient: run.patient.firstname + ' ' + run.patient.lastname,
            horaire:
                dayjs(run.departureTime).local().format('HH:mm') +
                ' - ' +
                dayjs(run.arrivingTime).local().format('HH:mm'),
            equipe: `${
                run.masterRun
                    ? run.masterRun.users.map(
                          (u, index) =>
                              `${
                                  index === 0 && run.masterRun.users.length > 1
                                      ? ' '
                                      : ''
                              } ${u.firstname} ${u.lastname}`
                      )
                    : "Pas d'équipe"
            }`,
            vehicle: run.masterRun ? run.masterRun.vehicle.label : '',
            pickUpLocation: `${
                run.pickUpLocation.street
                    ? run.pickUpLocation.street + ' - '
                    : ''
            } ${run.pickUpLocation.city}`,
            depositLocation: `${
                run.depositLocation.street
                    ? run.depositLocation.street + ' - '
                    : ''
            } ${run.depositLocation.city}`,
            objet: run.runObject.label,
            isReturnPath: `${run.isRoundTrip ? 'A/R' : ''} ${
                run.isReturnPath ? 'Retour' : 'Aller'
            }`,
            commentaire: run.comments,
            status: run.status.label,
            id: run.id,
        }
    })
}
