import React, { useEffect, useState } from 'react'
import SimpleSelectDropDownList from '../../Commons/SimpleSelectDropDownList'
import API from '../../../services/api'
import ClassicIntervalTimePickers from '../../Commons/Inputs/ClassicIntervalTimePickers'
import TimeField from 'react-simple-timefield'
import ActionModal from '../ActionModal'
import { useDebounce } from 'use-debounce'
import ModalTextArea from '../../Commons/ModalTextArea'
import SharingOptions from '../../../services/SharingOptions'
import { Documents } from '../../../services/Documents'
import DeleteButton from '../../Commons/Buttons/DeleteButton'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import { toast } from 'react-toastify'

const ActionView = ({
    history,
    run,
    setHasChanged,
    setHoursChanged,
    status,
    setStatus,
    statusList,
    runMasterRun,
    masterRunsList,
    setRunMasterRun,
    setDepartureTime,
    setIndirectReturnDepartureTime,
    indirectReturnDepartureTime,
    arrivingTime,
    setArrivingTime,
    departureTime,
    haveIndirectReturn,
    partner,
    setPartner,
    note,
    setNote,
    sharingOption,
    setSharingOption,
    openSharingOptions,
    setOpenSharingOptions,
    setDocuments,
    documents,
    isUserCommentsRed,
    setIsUserCommentsRed,
    approximateTime,
    setApproximateTime,
    effectiveDepartureTime,
    setEffectiveDepartureTime,
    effectiveArrivingTime,
    setEffectiveArrivingTime,
}) => {
    const [openValidationModal, setOpenValidationModal] = useState(false)
    const [partners, setPartners] = useState([])
    const [searchPartner, setSearchPartner] = useState('')
    const [labelModal, setLabelModal] = useState('')
    const [labelAction, setLabelAction] = useState('')
    const [actionButton, setActionButton] = useState('')
    const [debouncedSearchPartner] = useDebounce(searchPartner, 300)
    const handleStatusChange = (event) => {
        setHasChanged(true)
        setStatus(event)
    }

    const handleOptionChange = (event) => {
        if (event != null) {
            setSharingOption(event)
        } else {
            setSharingOption(null)
        }
        setHasChanged(true)
    }

    const handleDepartureTime = (value) => {
        setHasChanged(true)
        setHoursChanged(true)
        const time = value.split(':')
        let newDepartureTime = new Date(departureTime)
        newDepartureTime.setHours(time[0])
        newDepartureTime.setMinutes(time[1])
        setDepartureTime(newDepartureTime)
    }

    const handleIndirectReturnDepartureTime = (value) => {
        setHasChanged(true)
        setHoursChanged(true)
        const time = value.split(':')

        let newReturnDepartureTime = new Date(indirectReturnDepartureTime)
        newReturnDepartureTime.setHours(time[0])
        newReturnDepartureTime.setMinutes(time[1])

        setIndirectReturnDepartureTime(newReturnDepartureTime)
    }

    const handleArrivingTime = (value) => {
        setHasChanged(true)
        setHoursChanged(true)
        const time = value.split(':')
        let newArrivingTime = new Date(arrivingTime)
        newArrivingTime.setHours(time[0])
        newArrivingTime.setMinutes(time[1])
        setArrivingTime(newArrivingTime)
    }

    const handleEffectiveDepartureTime = (value) => {
        setHasChanged(true)
        setHoursChanged(true)
        const time = value.split(':')
        let newEffectiveDepartureTime = new Date(effectiveDepartureTime)
        newEffectiveDepartureTime.setHours(time[0])
        newEffectiveDepartureTime.setMinutes(time[1])
        setEffectiveDepartureTime(newEffectiveDepartureTime)
    }

    const handleEffectiveArrivingTime = (value) => {
        setHasChanged(true)
        setHoursChanged(true)
        const time = value.split(':')
        let newEffectiveArrivingTime = new Date(effectiveArrivingTime)
        newEffectiveArrivingTime.setHours(time[0])
        newEffectiveArrivingTime.setMinutes(time[1])
        setEffectiveArrivingTime(newEffectiveArrivingTime)
    }

    const handleActionModalLabel = (action) => {
        setOpenValidationModal(true)
        setLabelModal(
            action === 'delete'
                ? `Êtes-vous certain de vouloir supprimer la course pour ${run.patient.lastname} ${run.patient.firstname} ?`
                : `Êtes-vous sur de vouloir retirer le partage de la course à votre partenaire ${run.partner.lastname}  ${run.partner.firstname} ?`
        )
        setLabelAction(
            action === 'delete'
                ? 'Suppression de la course'
                : 'Retirer le partage'
        )
        setActionButton(action === 'delete' ? 'Supprimer' : 'Retirer')
    }

    const handleDocumentChange = (event) => {
        setHasChanged(true)
        setDocuments(event)
    }

    const handleRemoveShare = () => {
        const data = {
            sharingOption: null,
            note: null,
            partner: null,
            isGiven: null,
            sharedAt: null,
        }
        API.Runs.put(run.id, data).then((response) => {
            toast.success('Le partage a été retiré.')
            history(0)
        })
    }

    const handleDelete = () => {
        API.Runs.delete(run.id)
            .then((response) => {
                if (response.ok) {
                    history(0)
                }
            })
            .catch((error) => {
                setOpenValidationModal(false)
            })
    }

    const handleSearchInputPartnerChange = (event) => {
        setSearchPartner(event)
    }

    const handleApproximateTime = () => {
        setApproximateTime(!approximateTime)
        setHasChanged(true)
    }

    const handlePartnerChange = (event) => {
        if (event != null) {
            setPartner(event)
            setRunMasterRun(null)
            if (partner !== event) {
                setHasChanged(true)
            }
        } else {
            setHasChanged(true)
            setPartner(null)
        }
    }

    function fetchPartnersList(search = null) {
        API.Partners.list(search, null).then((response) => {
            response.json().then((data) => {
                const partnersList = data['hydra:member'].map((partner) => {
                    return {
                        value: partner['@id'],
                        label: partner.firstname + ' ' + partner.lastname,
                        email: partner.email,
                    }
                })
                setPartners(partnersList)
            })
        })
    }

    useEffect(() => {
        if (debouncedSearchPartner) {
            fetchPartnersList(debouncedSearchPartner)
        }
    }, [debouncedSearchPartner])

    return (
        <div className="flex h-full  w-full flex-col space-y-6 py-2 text-lg lg:text-base ">
            <div className="flex w-full items-center justify-between py-5 text-2xl text-gray-700 ">
                <h2 className="font-bold">Options</h2>
                <div className="text-base">
                    <DeleteButton
                        label="Supprimer"
                        title="Supprimer"
                        action={() => handleActionModalLabel('delete')}
                        hiddenLabelOnMobile={false}
                    />
                </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                    Changer le statut
                </label>
                <div className="lg:w-2/3">
                    <SimpleSelectDropDownList
                        icon=""
                        id={run.id}
                        options={statusList}
                        value={status}
                        handleOptionChange={handleStatusChange}
                        isClearable={false}
                        showLabel={false}
                        isSearchable={false}
                        menuPlacement="top"
                    />
                </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0 ">
                    Changer les documents
                </label>
                <div className="lg:w-2/3">
                    <SimpleSelectDropDownList
                        icon=""
                        id={run.id}
                        options={Documents}
                        value={documents}
                        handleOptionChange={handleDocumentChange}
                        isClearable={false}
                        showLabel={false}
                        isSearchable={false}
                        menuPlacement="top"
                    />
                </div>
            </div>
            {(effectiveDepartureTime || effectiveArrivingTime) && (
                <div className="flex flex-col lg:flex-row lg:items-center">
                    <label
                        className={`pb-2 font-bold underline lg:w-1/3  lg:p-0`}
                    >
                        Horaires effectifs
                    </label>
                    <div className="flex items-center text-xl">
                        <ClassicIntervalTimePickers
                            smSize={true}
                            showIcon={false}
                            showLabel={false}
                            departureTime={effectiveDepartureTime}
                            handleDepartureTime={handleEffectiveDepartureTime}
                            arrivingTime={effectiveArrivingTime}
                            handleArrivingTime={handleEffectiveArrivingTime}
                            error={
                                effectiveArrivingTime < effectiveDepartureTime
                            }
                        />
                    </div>
                </div>
            )}
            <div className="flex flex-col lg:flex-row lg:items-center ">
                <label className={`pb-2 font-bold lg:w-1/3 lg:p-0 `}>
                    Horaires
                </label>
                <div className="flex items-center text-xl">
                    <ClassicIntervalTimePickers
                        smSize={true}
                        showIcon={false}
                        showLabel={false}
                        departureTime={departureTime}
                        handleDepartureTime={handleDepartureTime}
                        arrivingTime={arrivingTime}
                        handleArrivingTime={handleArrivingTime}
                        error={arrivingTime < departureTime}
                    />
                    {haveIndirectReturn && (
                        <div className="flex items-center space-x-2 pl-2">
                            <i className="far fa-registered" />
                            <TimeField
                                value={indirectReturnDepartureTime.toLocaleTimeString(
                                    'fr-FR'
                                )}
                                onChange={(event, value) =>
                                    handleIndirectReturnDepartureTime(value)
                                }
                                input={
                                    <input
                                        className={`block flex h-10 w-20 appearance-none border-b-2 text-center leading-tight text-gray-700 focus:outline-none ${
                                            indirectReturnDepartureTime <
                                            arrivingTime
                                                ? 'border-red-500'
                                                : 'border-lighter-blue-green'
                                        }`}
                                    />
                                }
                                colon=":"
                            />
                        </div>
                    )}
                </div>
            </div>
            {run.isReturnPath && (
                <div
                    className="flex flex-col lg:flex-row lg:items-center "
                    onClick={handleApproximateTime}
                >
                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0 ">
                        Horaires approximatifs
                    </label>
                    <div className="pl-2 lg:pl-0">
                        <input
                            className="scale-1-5 accent-primary-color"
                            type="checkbox"
                            id="approx"
                            value="aproximatif"
                            name="aproximatif"
                            checked={approximateTime}
                            onChange={handleApproximateTime}
                        />
                    </div>
                </div>
            )}
            {run.userComments && (
                <div className="flex flex-col ">
                    <div className="flex flex-col lg:flex-row lg:items-center">
                        <label className="pb-2  font-bold lg:w-1/3 lg:p-0 ">
                            Commentaire chauffeur
                        </label>
                        <p className="w-full lg:w-2/3">{run.userComments}</p>
                    </div>
                    <div className="flex flex-col pt-5 lg:flex-row lg:items-center">
                        <label className="font-bold lg:w-1/3  lg:p-0"></label>
                        <button
                            className={`flex w-full items-center justify-center ${
                                !isUserCommentsRed
                                    ? 'bg-light-dark-green text-white hover:bg-dark-green'
                                    : 'border-2 bg-white text-light-dark-green hover:bg-dark-green hover:text-white'
                            } transition-color w-full rounded border-2 border-light-dark-green px-4 py-2 text-base font-bold duration-200 lg:w-2/3`}
                            onClick={() => {
                                setIsUserCommentsRed(!isUserCommentsRed)
                                setHasChanged(true)
                            }}
                        >
                            <p>
                                {isUserCommentsRed
                                    ? 'Marquer comme non lu'
                                    : 'Marquer le commentaire comme lu'}
                            </p>
                            {isUserCommentsRed ? (
                                <i className="far fa-comment pl-2" />
                            ) : (
                                <i className="fas fa-comment-slash pl-2" />
                            )}
                        </button>
                    </div>
                </div>
            )}
            {!openSharingOptions && (
                <div className="flex flex-col lg:flex-row lg:items-center">
                    <div className=" w-full">
                        <ModalTextArea
                            label={'Notes'}
                            height={'h-24'}
                            value={note}
                            setValue={(e) => {
                                setNote(e.target.value)
                                setHasChanged(true)
                            }}
                            placeholder={
                                'Notes importantes liées à la course...'
                            }
                        />
                    </div>
                </div>
            )}

            {openSharingOptions ? (
                <div className="flex h-full  w-full flex-col space-y-6 py-2">
                    <div className="flex flex-col lg:flex-row lg:items-center">
                        <label className="pb-2   font-bold lg:w-1/3 lg:p-0  ">
                            Partenaire
                        </label>
                        <div className="lg:w-2/3">
                            <SimpleSelectDropDownList
                                options={partners}
                                value={partner}
                                handleOptionChange={handlePartnerChange}
                                isDisabled={false}
                                isClearable={true}
                                handleSearchInputPatientChange={
                                    handleSearchInputPartnerChange
                                }
                            />
                        </div>
                    </div>
                    <div className="flex flex-col lg:flex-row lg:items-center ">
                        <label className="pb-2  font-bold lg:w-1/3 lg:p-0 ">
                            Méthode de partage
                        </label>
                        <div className="flex w-full items-center lg:w-2/3">
                            <SimpleSelectDropDownList
                                options={SharingOptions}
                                value={sharingOption}
                                handleOptionChange={handleOptionChange}
                                isDisabled={false}
                                isSearchable={false}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col lg:flex-row lg:items-center">
                        <div className=" w-full">
                            <ModalTextArea
                                label={'Notes'}
                                height={'h-24'}
                                value={note}
                                setValue={(e) => {
                                    setNote(e.target.value)
                                    setHasChanged(true)
                                }}
                                placeholder={
                                    'Notes importantes liées à la course...'
                                }
                            />
                        </div>
                    </div>
                </div>
            ) : !run.partner ? (
                <div className="flex flex-col pt-5 lg:flex-row lg:items-center">
                    <label className="pb-2  font-bold lg:w-1/3 lg:p-0 ">
                        Partage
                    </label>
                    <div className="flex w-full text-base">
                        <div className="ml-auto">
                            <PrimaryButton
                                label="Partager le transport"
                                title="Partager le transport"
                                action={() => {
                                    setOpenSharingOptions(true)
                                    setHasChanged(true)
                                }}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col pt-5 lg:flex-row lg:items-center">
                    <label className="pb-2  font-bold lg:w-1/3 lg:p-0 ">
                        Partage
                    </label>
                    <button
                        className="transition-color rounded  bg-red-500 px-4 py-2 text-base font-bold text-white duration-200 hover:bg-red-600 lg:w-2/3 "
                        onClick={() => handleActionModalLabel('partage')}
                    >
                        Retirer le partage
                    </button>
                </div>
            )}
            {openValidationModal ? (
                <ActionModal
                    setModalOpen={setOpenValidationModal}
                    label={labelModal}
                    action={
                        actionButton === 'Supprimer'
                            ? handleDelete
                            : handleRemoveShare
                    }
                    labelAction={labelAction}
                    actionButton={actionButton}
                />
            ) : null}
        </div>
    )
}

export default ActionView
