import {
    checkLunarDate,
    hasAllDateParts,
    hasOneDateParts,
    isDateValid,
} from '../../../services/DateService'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import API from '../../../services/api'
import {
    transformObjectsIntoIris,
    verifyKeyType97,
} from '../../../tools/Utility'

export const setPatientDateOfBirth = (patient, date, checkLunarDate) => {
    if (hasAllDateParts(date)) {
        if (checkLunarDate(date)) {
            patient.stringDateOfBirth = `${date.day}/${date.month}/${date.year}`
            patient.dateOfBirth = null
        } else {
            patient.dateOfBirth = hasAllDateParts(date)
                ? dayjs(new Date(date.year, date.month - 1, date.day))
                      .local()
                      .format('YYYY-MM-DD')
                : null
            patient.stringDateOfBirth = null
        }
    } else {
        patient.dateOfBirth = null
    }

    return patient
}

export const checkPatientFormErrors = (patient, date) => {
    let msg = []
    if (!patient.firstname) {
        msg.push('Veuillez renseigner un prénom.')
    }
    if (!patient.lastname) {
        msg.push('Veuillez renseigner un nom.')
    }
    if (hasOneDateParts(date)) {
        if (!isDateValid(date, !patient.isLunarDate, patient.isLunarDate)) {
            msg.push('Veuillez renseigner une date de naissance correcte')
        }
    }
    if (patient.socialNumber) {
        if (!verifyKeyType97(patient.socialNumber)) {
            msg.push('Veuillez renseigner un numéro de sécurité sociale valide')
        }
    }
    if (
        patient.patientsAddresses.some(
            (address) => !address.address.city || !address.label
        )
    ) {
        msg.push('Veuillez renseigner une adresse complète.')
    }
    const checkIfAtLeastOneDefaultAddressIsChecked =
        patient.patientsAddresses.filter(
            (address) => address.defaultAddress === true
        )
    if (
        patient.patientsAddresses.length > 0 &&
        checkIfAtLeastOneDefaultAddressIsChecked.length !== 1
    ) {
        msg.push('Veuillez renseigner une adresse par défaut')
    }
    if (
        patient.patientsContacts.some(
            (address) => address.label === '' || address.phoneNumber.length < 14
        )
    ) {
        msg.push('Veuillez renseigner un contact valide')
    }
    if (msg.length > 0) {
        msg.forEach((e) => {
            toast.error(e)
        })
        return true
    }
    return false
}

export const handlePatientForm = async (
    patient,
    date,
    historyPush,
    notif = true
) => {
    let result = { success: false, data: null }
    let msg = ''
    // Check date lunaire
    patient = setPatientDateOfBirth(patient, date, checkLunarDate)
    if (!checkPatientFormErrors(patient, date)) {
        const healthComplementaryIRI = patient.healthComplementary
            ? patient.healthComplementary['@id']
                ? patient.healthComplementary['@id']
                : `/health_complementaries/${patient.healthComplementary.id}`
            : null
        const payingCenterIRI = patient.payingCenter
            ? patient.payingCenter['@id']
                ? patient.payingCenter['@id']
                : `/paying_centers/${patient.payingCenter.id}`
            : null

        const patientInformationToSend = {
            ...patient,
            birthRank: patient.birthRank?.value,
            healthComplementary: healthComplementaryIRI,
            payingCenter: payingCenterIRI,
            dateOfBirth: hasAllDateParts(date)
                ? dayjs(new Date(date.year, date.month - 1, date.day))
                      .local()
                      .format('YYYY-MM-DD')
                : null,
            parent: patient.parent ? patient.parent['@id'] : null,
            beneficiaries: patient.beneficiaries?.map((beneficiary) =>
                transformObjectsIntoIris(beneficiary)
            ),
            patientsAddresses: patient.patientsAddresses.map((address) => {
                // Create a copy of the address object and remove id and '@id' properties
                const { id, '@id': atId, ...cleanedAddress } = address.address
                return { ...address, address: cleanedAddress }
            }),
        }

        try {
            let response
            if (
                patientInformationToSend.id === 0 ||
                patientInformationToSend.id === null ||
                patientInformationToSend.id === undefined
            ) {
                response = await API.Patients.addPatient(
                    patientInformationToSend
                )
                msg = 'Fiche ajoutée.'
            } else {
                response = await API.Patients.put(
                    patientInformationToSend.id,
                    patientInformationToSend
                )
                msg = 'Fiche modifiée.'
            }

            if (response.ok) {
                const jsonData = await response.json()
                if (notif) toast.success(msg)
                result.success = true
                result.data = jsonData

                if (historyPush) {
                    historyPush()
                }
            }
        } catch (error) {
            if (error.json) {
                const data = await error.json()
                toast.error(data['hydra:description'])
            } else {
                toast.error('Erreur, veuillez contacter un administrateur')
            }
            result.success = false
            result.data = null
        }
    }
    return result
}
