import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'
import {
    Bank,
    EmojiBall,
    EmojiSad,
    EmojiSatisfied,
    NavArrowDown,
    SendEuros,
} from 'iconoir-react'
import React from 'react'
import dayjs from 'dayjs'
import { findColorByStatus } from '../../../../services/InvoiceUtils'

const NoemieStatCard = ({
    title,
    value,
    subValue = null,
    subtitle,
    secondSubtitle = null,
    icon,
    handleAction,
    actionLabel,
    actionIcon,
    primary = false,
    status,
    date,
}) => {
    const [openStatusList, setOpenStatusList] = React.useState(false)
    return (
        <div className="w-full rounded-lg border bg-white p-4 shadow-md">
            <div className="flex items-center justify-between">
                <div>
                    <h2 className="text-xl font-bold">{title}</h2>
                    <h1 className="flex text-2xl">
                        {value}
                        <p className={'pl-1 text-red-500'}>{subValue}</p>
                    </h1>
                    <p className="text-gray-600">{subtitle}</p>
                    {secondSubtitle && (
                        <p className="text-gray-600">{secondSubtitle}</p>
                    )}
                </div>
                <div>{icon}</div>
            </div>
            {handleAction && primary ? (
                <div className={'pt-2  '}>
                    <PrimaryButton
                        label={actionLabel}
                        fullWidth={false}
                        action={handleAction}
                        icon={actionIcon}
                    />
                </div>
            ) : handleAction ? (
                <div className={'pt-2'}>
                    <div
                        tabIndex="-1"
                        className={
                            ' group relative ml-auto flex w-fit items-center justify-between text-right sm:ml-0'
                        }
                    >
                        <button
                            className={`text-md align-center flex w-fit justify-center rounded-full border-2  px-4 py-1 font-bold  hover:scale-[1.02] lg:w-44
                                           ${findColorByStatus(status)} `}
                            onClick={() => setOpenStatusList(!openStatusList)}
                        >
                            <p>{status}</p>
                            <NavArrowDown className={'ml-auto flex'} />
                        </button>
                        {openStatusList && (
                            <div className="invisible z-50 origin-top-right -translate-y-2 scale-95 transform opacity-0 transition-all duration-300 group-focus-within:visible group-focus-within:translate-y-0 group-focus-within:scale-100 group-focus-within:opacity-100">
                                <div
                                    className={`absolute right-0 top-8  w-56 origin-top-right divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none`}
                                    role="menu"
                                >
                                    <button
                                        role="menuitem"
                                        className="flex w-full px-4 py-2 text-left  leading-5 text-gray-700 hover:bg-gray-100 focus-visible:outline-1 focus-visible:outline-light-blue-green"
                                        onClick={() =>
                                            handleAction('Virement reçu')
                                        }
                                    >
                                        <EmojiSatisfied className={'mr-2'} />
                                        <p
                                            className={
                                                'font-semibold text-green-500'
                                            }
                                        >
                                            Virement reçu
                                        </p>
                                    </button>
                                    <button
                                        role="menuitem"
                                        className="flex w-full px-4 py-2 text-left  leading-5 text-gray-700 hover:bg-gray-100 focus-visible:outline-1 focus-visible:outline-light-blue-green"
                                        onClick={() =>
                                            handleAction('En attente')
                                        }
                                    >
                                        <EmojiBall className={'mr-2'} />
                                        <p className="font-semibold text-gray-500">
                                            En attente
                                        </p>
                                    </button>
                                    <button
                                        role="menuitem"
                                        className="flex w-full px-4 py-2 text-left  leading-5 text-gray-700 hover:bg-gray-100 focus-visible:outline-1 focus-visible:outline-light-blue-green"
                                        onClick={() => handleAction('Annulé')}
                                    >
                                        <EmojiSad className={'mr-2'} />
                                        <p className="font-semibold text-orange-500">
                                            Annulé
                                        </p>
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={'pt-2'}>
                        {status === 'Virement reçu' && date ? (
                            <div className={'flex items-center'}>
                                <SendEuros />
                                <Bank className={'mr-2'} />
                                <p className={'underline'}>
                                    {dayjs(date).local().format('DD/MM/YY')}
                                </p>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default NoemieStatCard
