import React from 'react'
import {
    Archive,
    Copy,
    EyeEmpty,
    EyeOff,
    List,
    Minus,
    PageEdit,
    Plus,
    ShareAndroid,
    Trash,
} from 'iconoir-react'
import { ACTIONS } from '../../services/Reducers/OptionsReducer'

const NewMobileOption = ({
    dispatch,
    rowId,
    handleModalDelete,
    historyPush,
    historyPushCopy,
    historyPushConsulting,
    handleModalShare,
    historyPushUpdate,
    isSeriesRun,
    handleModalAddToSeries,
    handleModalArchived,
    isArchivedSeries,
    isPartnerRun,
    handleHideTeam,
    isHidedTeam,
}) => {
    return (
        <div className="h-42 fixed bottom-0 left-0 right-0 z-50 w-full bg-white text-lg text-gray-800 shadow-mobile">
            <button
                className="fas fa-times absolute right-0 cursor-pointer px-2 pt-2"
                onClick={(e) => {
                    e.stopPropagation()
                    dispatch({ type: ACTIONS.CANCEL_SHOW_OPTIONS })
                }}
            />
            <div className="flex flex-col justify-between px-6 py-2 ">
                {handleModalShare && (
                    <button
                        className="flex w-full cursor-pointer items-center justify-between border-b border-gray-500 py-4"
                        onClick={(e) => {
                            e.stopPropagation()
                            handleModalShare(rowId)
                        }}
                    >
                        <ShareAndroid />
                        <div className="mx-auto">
                            {isPartnerRun ? 'Retirer le partage' : 'Partager'}
                        </div>
                    </button>
                )}
                {/*{historyPushCopy && (*/}
                {/*    <button*/}
                {/*        className={`flex w-full cursor-pointer items-center  justify-between border-b border-gray-500 py-4 `}*/}
                {/*        onClick={(e) => {*/}
                {/*            e.stopPropagation()*/}
                {/*            historyPushCopy(rowId)*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <Copy />*/}
                {/*        <div className="mx-auto">Copier</div>*/}
                {/*    </button>*/}
                {/*)}*/}

                {historyPushConsulting && (
                    <button
                        className="flex w-full cursor-pointer  items-center justify-between border-b border-gray-500 py-4"
                        onClick={(e) => {
                            e.stopPropagation()
                            historyPushConsulting(rowId)
                        }}
                    >
                        <List />
                        <div className="mx-auto">Consulter</div>
                    </button>
                )}
                {historyPush && (
                    <button
                        className="flex w-full cursor-pointer  items-center justify-between border-b border-gray-500 py-4"
                        onClick={(e) => {
                            e.stopPropagation()
                            historyPushUpdate(rowId)
                        }}
                    >
                        <PageEdit />
                        <div className="mx-auto">Modifier</div>
                    </button>
                )}
                {handleModalArchived && (
                    <button
                        className="flex w-full cursor-pointer  items-center justify-between border-b border-gray-500 py-4"
                        onClick={(e) => {
                            e.stopPropagation()
                            handleModalArchived(rowId)
                        }}
                    >
                        <Archive />
                        <div className="mx-auto">
                            {isArchivedSeries ? 'Désarchiver' : 'Archiver'}
                        </div>
                    </button>
                )}
                {handleModalAddToSeries && (
                    <button
                        className="flex w-full cursor-pointer  items-center justify-between border-b border-gray-500 py-4"
                        onClick={(e) => {
                            e.stopPropagation()
                            handleModalAddToSeries(rowId)
                        }}
                    >
                        {isSeriesRun ? <Minus /> : <Plus />}
                        <div className="mx-auto">Série</div>
                    </button>
                )}
                {handleModalDelete && (
                    <button
                        className="flex w-full cursor-pointer items-center border-b border-gray-500 py-4 text-red-600"
                        onClick={(e) => {
                            e.stopPropagation()
                            handleModalDelete(rowId)
                        }}
                    >
                        <Trash />
                        <div className="mx-auto">Supprimer</div>
                    </button>
                )}
                {handleHideTeam && (
                    <button
                        className="flex w-full cursor-pointer items-center border-b border-gray-500 py-4"
                        onClick={(e) => {
                            e.stopPropagation()
                            handleHideTeam(rowId)
                        }}
                    >
                        {isHidedTeam ? <EyeEmpty /> : <EyeOff />}
                        <div className="mx-auto">
                            {isHidedTeam ? 'Afficher' : 'Cacher'}
                        </div>
                    </button>
                )}
            </div>
        </div>
    )
}

export default NewMobileOption
