import dayjs from 'dayjs'
import { BirthRank } from '../../../../services/BirthRank'
import { formatSavedAddresses } from '../../../../services/address'
import API from '../../../../services/api'

export const PATIENT_FORM_ACTIONS = {
    SET_FIRSTNAME: 'setFirstname',
    SET_LASTNAME: 'setLastname',
    SET_BIRTHDATE: 'setBirthdate',
    SET_PHONE_NUMBER: 'setPhoneNumber',
    SET_DESCRIPTION: 'setDescription',
    SET_SOCIAL_NUMBER: 'setSocialNumber',
    IS_PATIENT: 'isPatient',
    SET_DEFAULT_PICK_UP_ADDRESS: 'setDefaultPickUpAddress',
    SET_DEFAULT_DEPOSIT_ADDRESS: 'setDefaultDepositAddress',
    ALLOW_ADD_PATIENT: 'addPatient',
    EDIT_PATIENT: 'editPatient',
    GET_PATIENT: 'getPatient',
    CLEAR_PATIENT: 'CLEAR_PATIENT',
    SET_ERROR: 'setError',
    UPDATE_ADDRESS: 'updateAddress',
    UPDATE_ADDRESS_LABEL: 'updateAddressLabel',
    UPDATE_ADDRESS_INFO: 'updateAddressInfo',
    ADD_ADDRESS: 'addAddress',
    REMOVE_ADDRESS: 'removeAddress',
    DELETE_ADDRESS: 'deleteAddress',
    ADD_CONTACT: 'addContact',
    REMOVE_CONTACT: 'removeContact',
    UPDATE_CONTACT_INFO: 'updateContactInfo',
    DELETE_CONTACT: 'deleteContact',
    CLEAR_ID_FROM_ADDRESSES: 'clearIdFromAddresses',
    REMOVE_LOADING: 'removeLoading',
    SHOW_PEC_MODAL: 'showPecModal',
    SET_BIRTH_RANK: 'setBirthRank',
    SET_IS_LUNAR_DATE: 'setIsLunarDate',
    SET_HAS_CHANGED: 'setHasChanged',
    SET_HEALTH_COMPLEMENTARY: 'setHealthComplementary',
    SHOW_HEALTH_COMPLEMENTARY_MODAL: 'showHealthComplementaryModal',
    SHOW_PAYING_CENTER_MODAL: 'SHOW_PAYING_CENTER_MODAL',
    SET_HEALTH_COMPLEMENTARY_OPTIONS: 'SET_HEALTH_COMPLEMENTARY_OPTIONS',
    SET_MEMBERSHIP_NUMBER: 'SET_MEMBERSHIP_NUMBER',
    SET_TOP_MUTUALISTE: 'SET_TOP_MUTUALISTE',
    SET_IS_ALD: 'SET_IS_ALD',
    SET_PAYING_CENTER: 'SET_PAYING_CENTER',
    SET_PAYING_CENTERS_OPTIONS: 'SET_PAYING_CENTERS_OPTIONS',
    SHOW_BENEFICIARY_MODAL: 'SHOW_BENEFICIARY_MODAL',
    SET_BENEFICIARIES: 'SET_BENEFICIARIES',
    ADD_FORM_BENEFICIARY: 'ADD_FORM_BENEFICIARY',
    SELECT_BENEFICIARY: 'SELECT_BENEFICIARY',
    REMOVE_FORM_BENEFICIARY: 'REMOVE_FORM_BENEFICIARY',
    SELECT_BENEFICIARY_LINK: 'SELECT_BENEFICIARY_LINK',
}

export const getPatientFormInitialState = ({ patient }) => {
    if (patient) {
        let payingCentersOptions = patient.payingCentersOptions
        return {
            id: patient.id,
            firstname: patient.firstname || '',
            lastname: patient.lastname || '',
            phoneNumber: patient.phoneNumber || '',
            dateOfBirth:
                (patient.dateOfBirth &&
                    dayjs(patient.dateOfBirth).local().format('YYYY-MM-DD')) ||
                null,
            description: patient.description || '',
            socialNumber: patient.socialNumber || '',
            hasChanged: false,
            loading: false,
            error: '',
            create: false,
            showPecModal: false,
            isPatient: patient ? patient.isPatient : true,
            patientsAddresses: patient
                ? formatSavedAddresses(patient.patientsAddresses).sort(
                      (a, b) => {
                          return a.id - b.id
                      }
                  )
                : [],
            patientsContacts: patient
                ? patient.patientsContacts.sort((a, b) => {
                      return a.id - b.id
                  })
                : [],
            birthRank: BirthRank[0],
            isLunarDate: !!patient?.stringDateOfBirth,
            healthComplementary: patient?.healthComplementary,
            healthComplementaryOptions: [],
            payingCentersOptions: payingCentersOptions
                ? payingCentersOptions
                : [],
            payingCenter: patient?.payingCenter,
            membershipNumber: patient?.membershipNumber,
            beneficiaries: patient.beneficiaries,
            parent: patient.parent,
            isTopMutualiste: patient.isTopMutualiste,
            beneficiaryLink: patient.beneficiaryLink,
        }
    } else {
        return {
            id: null,
            firstname: null,
            lastname: null,
            phoneNumber: null,
            dateOfBirth: null,
            description: null,
            socialNumber: null,
            isPatient: true,
            hasChanged: false,
            loading: false,
            errors: '',
            create: false,
            addresses: null,
            birthRank: BirthRank[0],
            isLunarDate: false,
            patientsAddresses: [],
            patientsContacts: [],
            healthComplementaryOptions: [],
            payingCentersOptions: [],
            payingCenter: null,
            healthComplementary: null,
            membershipNumber: null,
            isTopMutualiste: false,
            isAld: false,
            showPayingCenterModal: false,
            showBeneficiaryModal: false,
            beneficiaries: [],
            parent: null,
            selectedBeneficiary: null,
            beneficiaryLink: null,
        }
    }
}

export const PatientFormReducer = (state, action) => {
    switch (action.type) {
        case PATIENT_FORM_ACTIONS.GET_PATIENT:
            const address = action.payload.patientsAddresses
            const sortedAddresses = address?.sort((a, b) => {
                return a.id - b.id
            })
            const defaultAddress = address.find((a) => a.defaultAddress)
            return {
                ...state,
                ['@id']: action.payload['@id'],
                id: action.payload.id,
                firstname: action.payload.firstname,
                lastname: action.payload.lastname,
                dateOfBirth: action.payload.dateOfBirth
                    ? dayjs(action.payload.dateOfBirth)
                          .local()
                          .format('YYYY-MM-DD')
                    : null,
                phoneNumber: action.payload.phoneNumber,
                description: action.payload.description,
                socialNumber: action.payload.socialNumber,
                isPatient: action.payload.isPatient,
                defaultAddress: defaultAddress,
                patientsAddresses: sortedAddresses,
                birthRank: BirthRank.find(
                    (item) => item.value === action.payload.birthRank
                ),
                patientsContacts: action.payload.patientsContacts,
                loading: false,
                showPecModal: false,
                isLunarDate: false,
                healthComplementary: action.payload.healthComplementary,
                payingCenter: action.payload.payingCenter,
                membershipNumber: action.payload.membershipNumber,
                isTopMutualiste: action.payload.isTopMutualiste,
                isAld: action.payload.isAld,
                beneficiaries: action.payload.beneficiaries,
                beneficiaryLink: action.payload.beneficiaryLink,
                parent: action.payload.parent,
            }
        case PATIENT_FORM_ACTIONS.SET_FIRSTNAME:
            return {
                ...state,
                firstname: action.payload,
                hasChanged: true,
            }
        case PATIENT_FORM_ACTIONS.SET_LASTNAME:
            return {
                ...state,
                lastname: action.payload,
                hasChanged: true,
            }
        case PATIENT_FORM_ACTIONS.SET_BIRTHDATE:
            return {
                ...state,
                dateOfBirth: action.payload ? action.payload : null,
                hasChanged: true,
            }
        case PATIENT_FORM_ACTIONS.SET_PHONE_NUMBER:
            return {
                ...state,
                phoneNumber: action.payload
                    .replace(/[^\dA-Z]/g, '')
                    .replace(/(.{2})/g, '$1 ')
                    .substring(0, 15)
                    .trim(),

                hasChanged: true,
            }
        case PATIENT_FORM_ACTIONS.SET_DESCRIPTION:
            return {
                ...state,
                description: action.payload,
                hasChanged: true,
            }
        case PATIENT_FORM_ACTIONS.SET_SOCIAL_NUMBER:
            return {
                ...state,
                socialNumber: action.payload
                    ?.replace(/[^\dA-Z]/g, '')
                    ?.slice(0, 15),
                hasChanged: true,
            }
        case PATIENT_FORM_ACTIONS.SET_DEFAULT_PICK_UP_ADDRESS:
            return {
                ...state,
                defaultPickUpAddress: action.payload,
                hasChanged: true,
            }
        case PATIENT_FORM_ACTIONS.IS_PATIENT: {
            return {
                ...state,
                isPatient: action.payload,
                hasChanged: true,
            }
        }
        case PATIENT_FORM_ACTIONS.SET_DEFAULT_DEPOSIT_ADDRESS:
            return {
                ...state,
                defaultDepositAddress: action.payload,
                hasChanged: true,
            }
        case PATIENT_FORM_ACTIONS.ALLOW_ADD_PATIENT:
            return {
                ...state,
                create: true,
                loading: true,
            }
        case PATIENT_FORM_ACTIONS.ADD_ADDRESS:
            return {
                ...state,
                patientsAddresses: [
                    ...state.patientsAddresses,
                    newAddressLine(
                        action.payload,
                        state.patientsAddresses.length + 1
                    ),
                ],
            }
        case PATIENT_FORM_ACTIONS.REMOVE_ADDRESS:
            return {
                ...state,
                patientsAddresses: state.patientsAddresses.filter(
                    (address) => address['@id'] !== action.payload
                ),
                hasChanged: true,
            }

        case PATIENT_FORM_ACTIONS.CLEAR_PATIENT: {
            return {
                isPatient: true,
                patientsAddresses: [],
                patientsContacts: [],
                healthComplementaryOptions: state.healthComplementaryOptions,
            }
        }

        case PATIENT_FORM_ACTIONS.UPDATE_ADDRESS:
            return {
                ...state,
                patientsAddresses: [
                    ...state.patientsAddresses.map((address) => {
                        if (address['@id'] === action.payload['@id']) {
                            return {
                                ...address,
                                ...action.payload,
                            }
                        } else {
                            return address
                        }
                    }),
                ],
                hasChanged: true,
            }

        case PATIENT_FORM_ACTIONS.DELETE_ADDRESS: // delete only the address info
            return {
                ...state,
                patientsAddresses: [
                    ...state.patientsAddresses.map((address) => {
                        if (address['@id'] === action.payload['@id']) {
                            return {
                                ...action.payload,
                                label: address.label,
                                phoneNumber: address.phoneNumber,
                            }
                        } else {
                            return address
                        }
                    }),
                ],
            }

        case PATIENT_FORM_ACTIONS.UPDATE_ADDRESS_LABEL:
            return {
                ...state,
                patientsAddresses: [
                    ...state.patientsAddresses.map((address) => {
                        if (address['@id'] === action.payload['@id']) {
                            return {
                                ...address,
                                description: action.payload.description,
                            }
                        } else {
                            return address
                        }
                    }),
                ],
                hasChanged: true,
            }
        case PATIENT_FORM_ACTIONS.UPDATE_ADDRESS_INFO:
            return {
                ...state,
                patientsAddresses: [
                    ...state.patientsAddresses.map((address) => {
                        if (address['@id'] === action.payload['@id']) {
                            return {
                                ...action.payload,
                                phoneNumber: action.payload.phoneNumber
                                    ?.replace(/[^\dA-Z]/g, '')
                                    .replace(/(.{2})/g, '$1 ')
                                    .substring(0, 15)
                                    .trim(),
                                defaultHasChanged: false,
                            }
                        } else {
                            return {
                                ...address,
                                defaultAddress: action.payload.defaultHasChanged
                                    ? false
                                    : address.defaultAddress,
                            }
                        }
                    }),
                ],
                hasChanged: true,
            }
        case PATIENT_FORM_ACTIONS.ADD_CONTACT:
            return {
                ...state,
                patientsContacts: [
                    ...state.patientsContacts,
                    newContactLine(
                        action.payload,
                        state.patientsContacts.length + 1
                    ),
                ],
                hasChanged: true,
            }
        case PATIENT_FORM_ACTIONS.REMOVE_CONTACT:
            return {
                ...state,
                patientsContacts: state.patientsContacts.filter(
                    (address) => address['@id'] !== action.payload
                ),
                hasChanged: true,
            }

        case PATIENT_FORM_ACTIONS.DELETE_CONTACT:
            return {
                ...state,
                patientsContacts: [
                    ...state.patientsContacts.map((address) => {
                        if (address['@id'] === action.payload['@id']) {
                            return {
                                ...action.payload,
                                label: address.label,
                                phoneNumber: address.phoneNumber
                                    .replace(/[^\dA-Z]/g, '')
                                    .replace(/(.{2})/g, '$1 ')
                                    .substring(0, 15)
                                    .trim(),
                            }
                        } else {
                            return address
                        }
                    }),
                ],
                hasChanged: true,
            }
        case PATIENT_FORM_ACTIONS.UPDATE_CONTACT_INFO:
            return {
                ...state,
                patientsContacts: [
                    ...state.patientsContacts.map((address) => {
                        if (address['@id'] === action.payload['@id']) {
                            return {
                                ...action.payload,
                                phoneNumber: action.payload.phoneNumber
                                    .replace(/[^\dA-Z]/g, '')
                                    .replace(/(.{2})/g, '$1 ')
                                    .substring(0, 15)
                                    .trim(),
                            }
                        } else {
                            return address
                        }
                    }),
                ],
                hasChanged: true,
            }

        case PATIENT_FORM_ACTIONS.CLEAR_ID_FROM_ADDRESSES:
            action.payload.patientsAddresses?.forEach((obj, i) => {
                delete action.payload.patientsAddresses[i].address['@id']
                delete action.payload.patientsAddresses[i].address.id
            })
            return {
                ...state,
                patientsAddresses: action.payload.patientsAddresses,
            }

        case PATIENT_FORM_ACTIONS.EDIT_PATIENT:
            action.payload.patientsAddresses?.forEach((obj, i) => {
                delete action.payload.patientsAddresses[i].address['@id']
                delete action.payload.patientsAddresses[i].address.id
            })
            const birthRank = action.payload.birthRank
            action.payload.birthRank = action.payload.birthRank?.value
            API.Patients.put(action.payload.id, action.payload)
                .then((res) => {
                    console.log(res)
                })
                .catch((err) => {
                    console.log(err)
                })
            return {
                ...state,
                loading: true,
                birthRank: birthRank,
            }
        case PATIENT_FORM_ACTIONS.SET_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case PATIENT_FORM_ACTIONS.SHOW_PEC_MODAL:
            return {
                ...state,
                showPecModal: action.payload,
            }
        case PATIENT_FORM_ACTIONS.REMOVE_LOADING:
            return {
                ...state,
                loading: false,
            }
        case PATIENT_FORM_ACTIONS.SET_BIRTH_RANK:
            return {
                ...state,
                hasChanged: true,
                birthRank: action.payload,
            }
        case PATIENT_FORM_ACTIONS.SET_IS_LUNAR_DATE:
            return {
                ...state,
                isLunarDate: action.payload,
                hasChanged: true,
            }
        case PATIENT_FORM_ACTIONS.SET_HAS_CHANGED:
            return {
                ...state,
                hasChanged: true,
            }
        case PATIENT_FORM_ACTIONS.SET_HEALTH_COMPLEMENTARY_OPTIONS:
            return {
                ...state,
                healthComplementaryOptions: action.payload,
            }
        case PATIENT_FORM_ACTIONS.SET_HEALTH_COMPLEMENTARY:
            return {
                ...state,
                healthComplementary: action.payload,
                hasChanged: true,
            }
        case PATIENT_FORM_ACTIONS.SHOW_HEALTH_COMPLEMENTARY_MODAL:
            return {
                ...state,
                showHealthComplementaryModal: action.payload,
            }
        case PATIENT_FORM_ACTIONS.SHOW_PAYING_CENTER_MODAL:
            return {
                ...state,
                showPayingCenterModal: action.payload,
            }
        case PATIENT_FORM_ACTIONS.SET_MEMBERSHIP_NUMBER:
            return {
                ...state,
                membershipNumber: action.payload.substring(0, 8),
            }
        case PATIENT_FORM_ACTIONS.SET_TOP_MUTUALISTE:
            return {
                ...state,
                isTopMutualiste: action.payload,
            }
        case PATIENT_FORM_ACTIONS.SET_IS_ALD:
            return {
                ...state,
                isAld: action.payload,
            }
        case PATIENT_FORM_ACTIONS.SET_PAYING_CENTERS_OPTIONS:
            return {
                ...state,
                payingCentersOptions: action.payload,
            }
        case PATIENT_FORM_ACTIONS.SET_PAYING_CENTER:
            return {
                ...state,
                payingCenter: action.payload ? action.payload : null,
            }
        case PATIENT_FORM_ACTIONS.SHOW_BENEFICIARY_MODAL:
            return {
                ...state,
                showBeneficiaryModal: !state.showBeneficiaryModal,
                selectedBeneficiary: null,
            }
        case PATIENT_FORM_ACTIONS.SET_BENEFICIARIES:
            return {
                ...state,
                beneficiaries: action.payload,
            }
        case PATIENT_FORM_ACTIONS.ADD_FORM_BENEFICIARY:
            return {
                ...state,
                beneficiariesForm: [...state.beneficiaries, action.payload],
                beneficiary: action.payload,
            }
        case PATIENT_FORM_ACTIONS.SELECT_BENEFICIARY:
            return {
                ...state,
                selectedBeneficiary: action.payload,
                showBeneficiaryModal: true,
            }
        case PATIENT_FORM_ACTIONS.REMOVE_FORM_BENEFICIARY:
            const beneficiaries = state.beneficiaries.filter(
                (beneficiary) => beneficiary['@id'] !== action.payload['@id']
            )
            return {
                ...state,
                beneficiariesForm: beneficiaries,
                beneficiaries: beneficiaries,
            }
        case PATIENT_FORM_ACTIONS.SELECT_BENEFICIARY_LINK:
            // edit beneficiariesForm with the selected beneficiary and add the action.payload
            const benef = [
                ...state.beneficiariesForm.map((beneficiary) => {
                    if (beneficiary['@id'] === state.beneficiary['@id']) {
                        return {
                            ...state.beneficiary,
                            beneficiaryLink: action.payload.value,
                        }
                    } else {
                        return beneficiary
                    }
                }),
            ]
            return {
                ...state,
                beneficiariesForm: benef,
            }
        default:
            return state
    }
}

const newAddressLine = (payload, i) => {
    return {
        ['@id']: 'new_address' + i,
        address: '',
        label: '',
        new: true,
        description: '',
        phoneNumber: '',
        defaultAddress: i === 1,
        defaultHasChanged: false,
    }
}

const newContactLine = (payload, i) => {
    return {
        ['@id']: 'new_address' + i,
        contact: '',
        label: '',
        new: true,
        phoneNumber: '',
    }
}
