import React, { useEffect, useState } from 'react'
import API from '../../../services/api'
import SimpleSelectDropDownList from '../../Commons/SimpleSelectDropDownList'
import { debounce } from '../../../services/debounce'
import { SharingOptionsFilters } from '../../../services/SharingOptions'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'

const formatOptions = (list) => {
    const options = []
    list.forEach((option) => {
        const data = {
            value: option['@id'],
            label: option.label,
        }

        options.push(data)
    })
    return options
}

const excludeStatuses = [
    {
        value: 'charged',
        label: 'Facturé',
    },
    {
        value: 'canceled',
        label: 'Annulé',
    },
    {
        value: 'charged and canceled',
        label: 'Facturé et annulé',
    },
]

const RegulationPopUpFilter = ({
    setShowPopUpFilter,
    action,
    setAction,
    patient,
    setPatient,
    setPage,
    setType,
    type,
    id,
    object,
    setObject,
    dateTo,
    handleErase,
    status,
    setStatus,
    ymdTo,
    ymdFrom,
    setFirstLoading,
    user,
    setUser,
    partner,
    setPartner,
    setShareFilter,
    shareFilter,
    excludeStatus,
    setExcludeStatus,
}) => {
    const [types, setTypes] = useState([])
    const [patients, setPatients] = useState([])
    const [partners, setPartners] = useState([])
    const [users, setUsers] = useState([])
    const [objects, setObjects] = useState([])
    const [statuses, setStatuses] = useState([])

    const searchPatient = debounce((e) => {
        if (e !== '') {
            API.Patients.list(e).then((response) => {
                response.json().then((data) => {
                    const patientList = data['hydra:member'].map((patient) => {
                        return {
                            value: patient['@id'],
                            label: patient.firstname + ' ' + patient.lastname,
                            patient: patient,
                        }
                    })
                    setPatients(patientList)
                })
            })
        }
    }, 500)

    const fetchPartnersList = () => {
        API.Partners.list().then((response) => {
            response.json().then((data) => {
                const partnerList = data['hydra:member'].map((partner) => {
                    return {
                        value: partner['@id'],
                        label: partner.firstname + ' ' + partner.lastname,
                        partner: partner,
                    }
                })
                setPartners(partnerList)
            })
        })
    }

    const fetchUsersList = () => {
        API.Users.list().then((response) => {
            response.json().then((data) => {
                const userList = data['hydra:member'].map((user) => {
                    return {
                        value: user['@id'],
                        label: user.firstname + ' ' + user.lastname,
                        user: user,
                    }
                })
                setUsers(userList)
            })
        })
    }

    const fetchObjectList = () => {
        API.RunObject.list().then((response) => {
            response.json().then((data) => {
                const formatted = formatOptions(data['hydra:member'])
                setObjects(formatted)
            })
        })
    }

    function getTypes() {
        API.Types.list().then((response) => {
            response.json().then((data) => {
                const formatted = formatOptions(data['hydra:member'])
                setTypes(formatted)
            })
        })
    }

    function getStatus() {
        API.Status.list().then((response) => {
            response.json().then((data) => {
                const formatted = formatOptions(data['hydra:member'])
                setStatuses(formatted)
            })
        })
    }

    useEffect(() => {
        getTypes()
        fetchObjectList()
        getStatus()
        fetchUsersList()
        fetchPartnersList()
    }, [])

    return (
        <div className="invisible-scrollbar fixed bottom-0 left-0 z-50 flex h-full w-full overflow-y-scroll bg-gray-600 bg-opacity-50 text-gray-800 shadow-md">
            <div className="mx-auto w-full max-w-md pt-20 text-gray-800 lg:max-w-lg xl:max-w-3xl ">
                <div className="min-h-filter flex flex-col rounded-xl bg-white p-4">
                    <div className="flex justify-between border-b py-2">
                        <div className="flex items-center">
                            <h1 className="items-center text-2xl font-bold">
                                Filtrer
                            </h1>
                            <i className="fa fa-filter pl-2 text-base" />
                        </div>
                        <button
                            className={`${
                                patient ||
                                partner ||
                                object ||
                                status ||
                                user ||
                                dateTo === null ||
                                ymdTo !== ymdFrom ||
                                type
                                    ? ''
                                    : 'hidden'
                            } flex items-center rounded border border-dark-green bg-transparent px-4  font-semibold text-dark-green hover:border-transparent hover:bg-light-blue-green hover:text-white `}
                            onClick={() => {
                                setShowPopUpFilter(false)
                                handleErase()
                            }}
                        >
                            <i className="fas fa-eraser fa-lg pr-2" />
                            Effacer
                        </button>
                    </div>
                    <div className="my-auto grid gap-2 pb-5 lg:grid-cols-2 lg:gap-5 ">
                        <SimpleSelectDropDownList
                            icon={'fa-user-injured'}
                            label="Patient"
                            options={patients}
                            value={patient}
                            isClearable={!!patient}
                            handleOptionChange={(e) => {
                                setPatient(e)
                            }}
                            handleSearchInputPatientChange={searchPatient}
                        />
                        <SimpleSelectDropDownList
                            label="Partenaire"
                            id={id}
                            options={partners}
                            value={partner}
                            isClearable={!!partner}
                            handleOptionChange={(e) => {
                                setPartner(e)
                            }}
                            isDisabled={false}
                            icon={'fa-hands-helping'}
                        />
                        <SimpleSelectDropDownList
                            label="Partage"
                            id={id}
                            options={SharingOptionsFilters}
                            value={shareFilter}
                            isClearable={!!shareFilter}
                            handleOptionChange={(e) => {
                                setShareFilter(e)
                            }}
                            isDisabled={false}
                            icon={'fa-hand-holding'}
                        />
                        <SimpleSelectDropDownList
                            label="Utilisateur"
                            options={users}
                            value={user}
                            isClearable={!!user}
                            handleOptionChange={(e) => {
                                setUser(e)
                            }}
                            icon={'fa-user'}
                        />
                        <SimpleSelectDropDownList
                            label="Statut"
                            id={id}
                            options={statuses}
                            value={status}
                            isClearable={!!status}
                            handleOptionChange={(e) => {
                                setStatus(e)
                            }}
                            isDisabled={false}
                            icon={'fa-info-circle'}
                        />
                        <SimpleSelectDropDownList
                            label="Type"
                            id={id}
                            options={types}
                            value={type}
                            isClearable={!!type}
                            handleOptionChange={(e) => {
                                setType(e)
                            }}
                            isDisabled={false}
                            icon={'fa-ambulance'}
                        />
                        <SimpleSelectDropDownList
                            label="Objet"
                            id={id}
                            options={objects}
                            value={object}
                            isClearable={!!object}
                            handleOptionChange={(e) => {
                                setObject(e)
                            }}
                            isDisabled={false}
                            icon={'fa-bullseye'}
                        />
                        <SimpleSelectDropDownList
                            label="Exclure statut"
                            options={excludeStatuses}
                            value={excludeStatus}
                            isClearable={!!excludeStatus}
                            handleOptionChange={(e) => {
                                setExcludeStatus(e)
                            }}
                            isDisabled={false}
                            icon={'fa-times'}
                        />
                    </div>
                    <div className="mt-auto flex justify-between border-t pt-5">
                        <SecondaryButton
                            label="Annuler"
                            title="Annuler"
                            hiddenLabelOnMobile={false}
                            action={() => setShowPopUpFilter(false)}
                        />
                        <PrimaryButton
                            label="Valider"
                            title="Valider"
                            action={() => {
                                setPage(1)
                                setAction(!action)
                                setShowPopUpFilter(false)
                                setFirstLoading(true)
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegulationPopUpFilter
