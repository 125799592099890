import Loader from '../../Commons/Loaders/Loader'
import NoData from '../NoData'
import React, { useEffect, useState } from 'react'
import InvoiceList from './InvoiceList'
import * as dayjs from 'dayjs'
import API from '../../../services/api'
import FilterContainer from './FilterContainer'

const Invoice = ({
    dateFrom,
    dateTo,
    search,
    archived,
    openFilterModal,
    setOpenFilterModal,
}) => {
    const [totalItems, setTotalItems] = useState(0)
    const [firstLoading, setFirstLoading] = useState(true)
    const [loading, setLoading] = useState(true)
    const [invoices, setInvoices] = useState([])

    const getInvoices = () => {
        const between = {
            strictlyBefore: dayjs(dateTo).local().format('YYYY-MM-DD'),
            strictlyAfter: dayjs(dateFrom).local().format('YYYY-MM-DD'),
        }
        setLoading(true)
        API.Invoices.list(search, between, archived).then((response) => {
            response.json().then((data) => {
                setInvoices(data['hydra:member'])
                setTotalItems(data['hydra:totalItems'])
                setLoading(false)
                setFirstLoading(false)
            })
        })
    }

    useEffect(() => {
        getInvoices()
    }, [search, dateFrom, dateTo, archived])

    return (
        <div>
            {firstLoading ? (
                <Loader />
            ) : invoices.length > 0 ? (
                <InvoiceList
                    invoices={invoices}
                    totalItems={totalItems}
                    loading={loading}
                    openFilterModal={openFilterModal}
                    setOpenFilterModal={setOpenFilterModal}
                />
            ) : (
                <NoData message="Aucune facture disponible" />
            )}
            {openFilterModal && (
                <FilterContainer
                    setOpenFilterModal={setOpenFilterModal}
                    openFilterModal={openFilterModal}
                />
            )}
        </div>
    )
}

export default Invoice
