import React from 'react'
import Patient from '../App/Regulation/RunListComponents/Patient'
import Vehicle from '../App/Regulation/RunListComponents/Vehicle'
import TimesAndTimeValidation from '../App/Regulation/RunListComponents/TimesAndTimeValidation'
import Times from '../App/Regulation/RunListComponents/Times'
import Status from '../App/Regulation/RunListComponents/Status'
import Team from '../App/Regulation/RunListComponents/Team'
import Return from '../App/Regulation/RunListComponents/Return'
import DepositLocationMobile from '../App/Regulation/RunListComponents/DepositLocationMobile'
import ObjectMobile from '../App/Regulation/RunListComponents/ObjectMobile'
import DateMobileFirstFormat from '../App/Regulation/RunListComponents/DateMobileFirstFormat'
import DateMobileSecondFormat from '../App/Regulation/RunListComponents/DateMobileSecondFormat'
import RunNumberInSeries from '../App/Regulation/RunListComponents/RunNumberInSeries'
import DepartureOrder from '../App/Regulation/RunListComponents/DepartureOrder'

const MobileConfiguratorDisplayed = ({ key, value, row }) => {
    if (value === 'patient') {
        return <Patient row={row} />
    } else if (value === 'vehicle') {
        return <Vehicle row={row} />
    } else if (value === 'timesAndTimeValidation') {
        return <TimesAndTimeValidation row={row} />
    } else if (value === 'timesOnly') {
        return <Times row={row} />
    } else if (value === 'depositLocation') {
        return <DepositLocationMobile row={row} />
    } else if (value === 'return') {
        return <Return row={row} />
    } else if (value === 'object') {
        return <ObjectMobile row={row} />
    } else if (value === 'dateFirstFormat') {
        return <DateMobileFirstFormat row={row} />
    } else if (value === 'dateSecondFormat') {
        return <DateMobileSecondFormat row={row} />
    } else if (value === 'team') {
        return <Team row={row} />
    } else if (value === 'status') {
        return <Status row={row} />
    } else if (value === 'runNumberInSeries') {
        return <RunNumberInSeries row={row} isComputer={true} />
    } else if (value === 'departureOrder') {
        return <DepartureOrder row={row} isComputer={true} />
    }
}

export default MobileConfiguratorDisplayed
