import React, { useEffect, useState } from 'react'
import API from '../../../services/api'
import NoData from '../NoData'
import SeriesListContainer from './SeriesListContainer'
import Loader from '../../Commons/Loaders/Loader'
import { debounce } from '../../../services/debounce'
import { toast } from 'react-toastify'

const Series = ({
    search,
    archived,
    fetchRunsSeriesList,
    firstLoading,
    rows,
    totalItems,
    loading,
}) => {
    const handleDeleteSeries = (id) => {
        API.Series.delete(id).then((response) => {
            toast.success('La série a bien été supprimée.')
            fetchRunsSeriesList()
        })
    }
    const handleArchivedSeries = (series) => {
        const data = { archived: !series.archived, fromArchivedUpdate: true }
        API.Series.put(series.id, data).then((response) => {
            toast.success('La série a bien été archivée.')
            fetchRunsSeriesList()
        })
    }

    return (
        <div>
            {firstLoading ? (
                <Loader />
            ) : rows.length > 0 ? (
                <SeriesListContainer
                    rows={rows}
                    totalItems={totalItems}
                    handleDeleteSeries={handleDeleteSeries}
                    handleArchivedSeries={handleArchivedSeries}
                    loading={loading}
                    archived={archived}
                />
            ) : (
                <NoData message="Aucune série disponible" />
            )}
        </div>
    )
}

export default Series
