import React from 'react'

const Team = ({ row, isComputer }) => {
    return (
        <div
            className={`flex flex-col justify-center ${
                isComputer ? 'px-6 text-base w-1/6' : 'text-xs'
            }`}
        >
            {row?.masterRun?.users.map((user) => (
                <div>{`${user.firstname} ${user.lastname}`}</div>
            ))}
        </div>
    )
}

export default Team
