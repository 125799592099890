import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import API from '../../../services/api'
import Loader from '../../Commons/Loaders/Loader'
import * as dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import { useNavigate } from 'react-router-dom'
import { formatEuro } from './Manage'
import useConfiguratorStore from '../../../stores/Configurator'
import SelectDropDownList from '../../Commons/DropDownLists/SelectDropDownList'
import { InvoiceStatus } from '../../../services/InvoiceStatus'
import ClassicTextarea from '../../Commons/Inputs/ClassicTextarea'
import { toast } from 'react-toastify'
import { Download, Plus } from 'iconoir-react'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import DeleteButton from '../../Commons/Buttons/DeleteButton'

const InvoiceConsult = () => {
    const { id } = useParams()
    const [invoice, setInvoice] = useState(null)
    const [loading, setLoading] = useState(true)
    const [archived, setArchived] = useState(true)
    const [status, setStatus] = useState({})
    const [note, setNote] = useState('')
    const history = useNavigate()

    const getInvoice = () => {
        API.Invoices.get(id).then((response) => {
            response.json().then((data) => {
                setInvoice(data)
                setStatus({
                    value: data.status.label,
                    label: data.status.label,
                    ['@id']: data.status['@id'],
                })
                setNote(data.note)
                setArchived(data.archived)
                setLoading(false)
            })
        })
    }

    const handleStatusChange = (event) => {
        const data = {
            status: event['@id'],
        }

        setStatus(event)
        setLoading(true)
        API.Invoices.put(id, data).then((response) => {
            response.json().then((data) => {
                invoice.status = data.status
                setLoading(false)
                toast.success('Statut changé avec succès.')
            })
        })
    }

    const downloadInvoicePdf = () => {
        API.Pdf.downloadInvoice(id)
            .then((response) => {
                return response.blob()
            })
            .then((blob) => {
                toast.success('Facture générée avec succès.')
                saveAs(blob, `${invoice.number}.pdf`)
            })
            .catch((error) => {
                console.log('error', error)
            })
    }

    const archivingInvoice = () => {
        const data = {
            archived: !archived,
        }
        API.Invoices.put(id, data).then((response) => {
            response.json().then((data) => {
                setInvoice(data)
                setLoading(false)
                toast.success('Facture archivée.')
                history('/facturation/factures')
            })
        })
    }

    const addRunComments = () => {
        if (invoice.note === note) {
            return
        }
        const data = {
            note: note,
        }
        API.Invoices.put(id, data).then((response) => {
            response.json().then((data) => {
                toast.success('Note modifiée avec succès.')
            })
        })
    }

    useEffect(() => {
        getInvoice()
    }, [])

    return (
        <div className="flex flex-col p-2 pt-4 text-gray-700">
            {loading ? (
                <Loader />
            ) : (
                <div className="flex flex-col">
                    <div className="flex justify-between">
                        <h2 className="text-2xl font-bold">Consultation</h2>

                        <div className="flex pb-4 pr-3">
                            <PrimaryButton
                                label="Modifier la facture"
                                title="Modifier la facture"
                                action={() =>
                                    history('/facturation/factures/' + id)
                                }
                            />
                        </div>
                    </div>
                    <div className="max-w-days-select flex pb-4">
                        <SelectDropDownList
                            label="Modifier le statut"
                            icon=""
                            showLabel={true}
                            options={InvoiceStatus}
                            value={status}
                            handleOptionChange={handleStatusChange}
                            isClearable={false}
                            isDisabled={false}
                        />
                    </div>
                    <div className="grid gap-4 pt-5 lg:grid-cols-2">
                        <div className="rounded-xl bg-white shadow-md">
                            <div className="flex w-full flex-col space-y-4 px-3 py-4">
                                <div className="flex justify-between py-5 text-gray-700 ">
                                    <h2 className="text-2xl font-bold ">
                                        Détail de la facture
                                    </h2>
                                    <div className="flex items-center justify-center">
                                        <SecondaryButton
                                            label="Télécharger la facture"
                                            title="Télécharger la facture"
                                            action={downloadInvoicePdf}
                                            icon={<Download />}
                                            hiddenLabelOnMobile={true}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col lg:flex-row lg:items-center">
                                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                                        Numéro de facture
                                    </label>
                                    <p className="font-bold">
                                        {invoice.number}
                                    </p>
                                </div>
                                <div className="flex flex-col lg:flex-row lg:items-center">
                                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                                        Statut
                                    </label>
                                    <span
                                        className={`flex inline-flex w-32 max-w-full justify-center rounded-full px-2 text-base font-semibold leading-5 text-white ${
                                            invoice.status.label === 'Réglée'
                                                ? 'bg-purple-500 '
                                                : invoice.status.label ===
                                                  'Créée'
                                                ? 'bg-gray-400 '
                                                : invoice.status.label ===
                                                  'Transmise'
                                                ? 'bg-blue-400  '
                                                : 'bg-orange-400 '
                                        }`}
                                    >
                                        {invoice.status.label}
                                    </span>
                                </div>
                                <div className="flex flex-col lg:flex-row lg:items-center">
                                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                                        Date de facturation
                                    </label>
                                    <p>
                                        {dayjs(invoice.date)
                                            .local()
                                            .format('DD/MM/YYYY')}
                                    </p>
                                </div>
                                <div className="flex flex-col lg:flex-row lg:items-center">
                                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                                        Méthode de paiement
                                    </label>
                                    <p>{invoice.paymentMethod.label}</p>
                                </div>

                                <div className="flex flex-col lg:flex-row lg:items-center">
                                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                                        TVA
                                    </label>
                                    <p>
                                        {formatEuro(
                                            invoice.totalIncludingTaxes -
                                                invoice.totalExcludingTaxes
                                        )}
                                    </p>
                                </div>
                                <div className="flex flex-col lg:flex-row lg:items-center">
                                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                                        Total HT
                                    </label>
                                    <p>
                                        {formatEuro(
                                            invoice.totalExcludingTaxes
                                        )}
                                    </p>
                                </div>
                                <div className="flex flex-col lg:flex-row lg:items-center">
                                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                                        Total TTC
                                    </label>
                                    <p className="font-bold">
                                        {formatEuro(
                                            invoice.totalIncludingTaxes
                                        )}
                                    </p>
                                </div>
                                <div className="flex flex-col lg:flex-row lg:items-center">
                                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                                        Période
                                    </label>
                                    <p>
                                        {dayjs(invoice.dateFrom)
                                            .local()
                                            .format('DD/MM/YYYY')}{' '}
                                        au{' '}
                                        {dayjs(invoice.dateTo)
                                            .local()
                                            .format('DD/MM/YYYY')}{' '}
                                    </p>
                                </div>
                                <div className="flex flex-col lg:flex-row lg:items-center">
                                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                                        Commentaire
                                    </label>
                                    <p className="lg:w-2/3">
                                        {invoice.commentaire}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="rounded-xl bg-white shadow-md">
                            <div className="flex w-full flex-col space-y-4 px-3 py-4">
                                <div className="flex justify-between py-5 text-gray-700 ">
                                    <h2 className="text-2xl font-bold ">
                                        {invoice.patient.isPatient
                                            ? 'Fiche patient :'
                                            : 'Fiche client :'}
                                    </h2>
                                    <div className="flex items-center justify-center">
                                        <SecondaryButton
                                            label={`Voir le ${
                                                invoice.patient.isPatient
                                                    ? 'patient'
                                                    : 'client'
                                            }`}
                                            title={`Voir le ${
                                                invoice.patient.isPatient
                                                    ? 'patient'
                                                    : 'client'
                                            }`}
                                            action={() =>
                                                history(
                                                    `/ressources/repertoire/${invoice.patient.id}`
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col lg:flex-row lg:items-center">
                                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                                        Prénom
                                    </label>
                                    <p>{invoice.patient.firstname}</p>
                                </div>
                                <div className="flex flex-col lg:flex-row lg:items-center">
                                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                                        Nom
                                    </label>
                                    <p>{invoice.patient.lastname}</p>
                                </div>

                                <div className="flex flex-col lg:flex-row lg:items-center">
                                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                                        Téléphone
                                    </label>
                                    <p>
                                        {invoice.patient.phoneNumber ? (
                                            invoice.patient.phoneNumber
                                        ) : (
                                            <i className="fa fa-times " />
                                        )}
                                    </p>
                                </div>
                                <div className="flex flex-col lg:flex-row lg:items-center">
                                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                                        Date de naissance
                                    </label>
                                    <p>
                                        {invoice.patient.dateOfBirth ? (
                                            dayjs(invoice.patient.dateOfBirth)
                                                .local()
                                                .format('DD/MM/YYYY')
                                        ) : (
                                            <i className="fa fa-times " />
                                        )}
                                    </p>
                                </div>
                                <div className="flex flex-col lg:flex-row lg:items-center">
                                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                                        Commentaire
                                    </label>
                                    <p>{invoice.patient.description}</p>
                                </div>
                                {invoice.patient.socialNumber && (
                                    <div className="flex flex-col lg:flex-row lg:items-center">
                                        <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                                            N° de sécu :
                                        </label>
                                        <p>{invoice.patient.socialNumber}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                        {invoice.invoicer && (
                            <div className="rounded-xl bg-white shadow-md">
                                <div className="flex w-full flex-col space-y-4 px-3 py-4">
                                    <div className="flex justify-between py-5 text-gray-700 ">
                                        <h2 className="text-2xl font-bold ">
                                            Facturation
                                        </h2>
                                        <div className="flex items-center justify-center">
                                            <SecondaryButton
                                                label="Voir l'établissement"
                                                title="Voir l'établissement"
                                                action={() =>
                                                    history(
                                                        '/facturation/etablissements/' +
                                                            invoice.invoicer.id
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col lg:flex-row lg:items-center">
                                        <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                                            Label
                                        </label>
                                        <p> {invoice.invoicer.label}</p>
                                    </div>
                                    <div className="flex flex-col lg:flex-row lg:items-center">
                                        <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                                            Téléphone
                                        </label>
                                        <p> {invoice.invoicer.phoneNumber}</p>
                                    </div>
                                    <div className="flex flex-col lg:flex-row lg:items-center">
                                        <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                                            Mail
                                        </label>
                                        <p> {invoice.invoicer.mail}</p>
                                    </div>
                                    <div className="flex flex-col lg:flex-row lg:items-center">
                                        <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                                            Adresse
                                        </label>
                                        <p>
                                            {invoice.invoicer.address.street
                                                ? invoice.invoicer.address
                                                      .street + ' - '
                                                : ''}
                                            {invoice.invoicer.address.city}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="w-full rounded-xl bg-white px-5 py-5 shadow-md">
                            <div className="pb-2 font-bold">Note</div>
                            <div className="px-4 ">
                                <ClassicTextarea
                                    value={note}
                                    placeholder={'Ajouter une note'}
                                    setValue={(e) => setNote(e.target.value)}
                                    className={'h-32'}
                                />

                                <div className="flex w-full">
                                    <div className="ml-auto ">
                                        <PrimaryButton
                                            label="Modifier"
                                            title="Modifier"
                                            action={addRunComments}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ml-auto mt-5 flex justify-between">
                        {!invoice.archived ? (
                            <DeleteButton
                                label="Archiver la facture"
                                title="Archiver la facture"
                                action={archivingInvoice}
                            />
                        ) : (
                            <PrimaryButton
                                label="Restaurer la facturer"
                                title="Restaurer la facturer"
                                action={archivingInvoice}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default InvoiceConsult
