import React, { useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import API from '../../../services/api'
import Loader from '../../Commons/Loaders/Loader'
import ClassicSwitch from '../../Commons/Inputs/ClassicSwitch'
import VehicleTypes from '../../../services/VehicleTypes'
import SelectDropDownList from '../../Commons/DropDownLists/SelectDropDownList'
import Error from '../../Commons/Error'
import {
    ERROR_ACTIONS,
    ErrorReducer,
} from '../../../services/Reducers/ErrorReducer'
import { toast } from 'react-toastify'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import { LockKey, Wrench } from 'iconoir-react'

const formatOptions = (list) => {
    const options = []
    if (list === undefined || list === null) {
        list = VehicleTypes
    }
    list.forEach((option) => {
        const data = {
            value: option.label,
            label: option.label,
            ['@id']: `/types/${option.label}`,
        }

        options.push(data)
    })
    return options
}

const ParametersConfig = ({ parameters }) => {
    const [loading, setLoading] = useState(true)
    const history = useNavigate()
    const [taxiView, setTaxiView] = useState(null)
    const [usersShowAllRuns, setUsersShowAllRuns] = useState(null)
    const [hideFuturPlanningDaysForUsers, setHideFuturPlanningDaysForUsers] =
        useState(null)
    const [defaultVehicleType, setDefaultVehicleType] = useState(null)
    const [transfertEffectiveHours, setTransfertEffectiveHours] = useState(null)
    const [defaultVehiclesTypes, setDefaultVehiclesTypes] = useState(null)
    const [defaultVehiclesTypesOptions, setDefaultVehiclesTypesOptions] =
        useState(VehicleTypes)
    const [reload, setReload] = useState(false)
    const [hasChanged, setHasChanged] = useState(false)
    const [usersShowUnassignedRuns, setUsersShowUnassignedRuns] = useState(null)
    const [showRunObjectColors, setShowRunObjectColors] = useState(null)
    const [allowedToChangePriceBasis, setAllowedToChangePriceBasis] =
        useState(false)
    const [allowedToInvoiceWithoutAMC, setAllowedToInvoiceWithoutAMC] =
        useState(false)

    const [errors, dispatchError] = useReducer(ErrorReducer, [])

    const getParameters = () => {
        const data = parameters
        setUsersShowAllRuns(data.usersShowAllRuns)
        setShowRunObjectColors(data.showRunObjectColors)
        setTaxiView(data.taxi)
        setDefaultVehicleType({
            label: data.defaultVehicleType.label,
            value: data.defaultVehicleType.label,
            ['@id']: data.defaultVehicleType['@id'],
        })
        setTransfertEffectiveHours(data.transfertEffectiveHours)
        setAllowedToChangePriceBasis(data.allowedToChangePriceBasis)
        setAllowedToInvoiceWithoutAMC(data.allowedToInvoiceWithoutAMC)
        if (data.defaultVehiclesTypes) {
            setDefaultVehiclesTypes(formatOptions(data.defaultVehiclesTypes))
            const vehicleOptions = VehicleTypes.filter(function (type) {
                return !data.defaultVehiclesTypes.some(function (type2) {
                    return type['@id'] === type2['@id']
                })
            })
            setDefaultVehiclesTypesOptions(vehicleOptions)
        } else {
            setDefaultVehiclesTypes(VehicleTypes)
        }

        setUsersShowUnassignedRuns(data.usersShowUnassignedRuns)
        setHideFuturPlanningDaysForUsers(data.hideFuturPlanningDaysForUsers)
        setLoading(false)
    }
    useEffect(() => {
        getParameters()
    }, [reload])

    const handleUsersShowAllRuns = () => {
        setHasChanged(true)
        if (usersShowUnassignedRuns) {
            setUsersShowUnassignedRuns(false)
        }
        setUsersShowAllRuns(!usersShowAllRuns)
    }

    const handleUsersShowUnassignedRuns = () => {
        setHasChanged(true)
        if (usersShowAllRuns) {
            setUsersShowAllRuns(false)
        }
        setUsersShowUnassignedRuns(!usersShowUnassignedRuns)
    }

    const handleHideFuturPlanningDaysForUsers = () => {
        setHasChanged(true)
        setHideFuturPlanningDaysForUsers(!hideFuturPlanningDaysForUsers)
    }

    const reloading = () => {
        setLoading(true)
        setReload(!reload)
    }

    const checkErrors = () => {
        dispatchError({
            type: ERROR_ACTIONS.CLEAR_ALL_ERRORS,
        })
        let printedErrors = []
        if (!defaultVehicleType) {
            printedErrors.push({
                id: 'bad default vehicle type',
                message: 'Veuillez au moins choisir un véhicule par défaut',
            })
        }
        if (!defaultVehiclesTypes) {
            printedErrors.push({
                id: 'bad default vehicle type',
                message:
                    'Veuillez au moins choisir un type de véhicule par défaut',
            })
        }
        if (printedErrors.length === 0) {
            return false
        } else {
            printedErrors.map((error) => {
                dispatchError({
                    type: ERROR_ACTIONS.SET_ERROR,
                    payload: error,
                })
            })
            return true
        }
    }

    function handleForm() {
        if (!checkErrors()) {
            const data = {
                taxi: taxiView,
                usersShowAllRuns: usersShowAllRuns,
                usersShowUnassignedRuns: usersShowUnassignedRuns,
                defaultVehicleType: defaultVehicleType['@id'],
                defaultVehiclesTypes: defaultVehiclesTypes,
                hideFuturPlanningDaysForUsers: hideFuturPlanningDaysForUsers,
                showRunObjectColors: showRunObjectColors,
                allowedToChangePriceBasis: allowedToChangePriceBasis,
                allowedToInvoiceWithoutAMC: allowedToInvoiceWithoutAMC,
            }
            if (hasChanged) {
                setLoading(true)
                API.Parameter.put(localStorage.getItem('society'), data)
                    .then((response) => {
                        setLoading(false)
                        toast.success('Préférences changées avec succès.')
                        return Promise.reject(new Error(response.statusText))
                    })
                    .catch((error) => {
                        if (error.json) {
                            setLoading(false)
                            error.json().then((data) => {})
                        }
                    })
            }
        }
    }

    const handleSelectVehicleTypes = (e) => {
        {
            setHasChanged(true)
            if (e === null) {
                setDefaultVehiclesTypesOptions(VehicleTypes)
                setDefaultVehiclesTypes(null)
                setDefaultVehicleType(null)
            } else {
                if (
                    defaultVehicleType &&
                    !e.some((type) => type['@id'] === defaultVehicleType['@id'])
                ) {
                    setDefaultVehicleType(e[0])
                }
                setDefaultVehiclesTypes(e)
                const vehicleOptions = VehicleTypes.filter(function (type) {
                    return !e.some(function (type2) {
                        return type['@id'] === type2['@id']
                    })
                })
                setDefaultVehiclesTypesOptions(vehicleOptions)
            }
        }
    }

    return (
        <div className="pt-6 ">
            <div className="min-height-60 flex flex-col  rounded-xl bg-white shadow-md ">
                {loading ? (
                    <Loader />
                ) : (
                    <div className=" flex flex-col border-gray-200 p-4">
                        <div className="grid w-full grid-cols-1 sm:grid-cols-2">
                            <div className="col-span-2 ">
                                <h1 className="flex w-full items-center border-b pb-4 text-2xl font-semibold ">
                                    <Wrench className={'mr-2'} /> Préférences
                                </h1>
                                <div className="flex flex-col space-y-3 px-4 pt-5 lg:w-3/4 lg:space-y-5">
                                    <h2 className={'text-xl font-semibold '}>
                                        Affichage chauffeur
                                    </h2>
                                    <div className="flex items-center justify-between space-x-4 pb-4 ">
                                        <div
                                            className={
                                                'flex w-3/4 flex-col lg:w-1/2'
                                            }
                                        >
                                            <p className="">
                                                Autoriser les chauffeurs à
                                                visualiser les courses de leurs
                                                collègues
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                'flex w-1/4 items-center justify-center lg:w-1/2'
                                            }
                                        >
                                            <ClassicSwitch
                                                setValue={
                                                    handleUsersShowAllRuns
                                                }
                                                value={usersShowAllRuns}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between space-x-4 pb-4 ">
                                        <div
                                            className={
                                                'flex w-3/4 flex-col lg:w-1/2'
                                            }
                                        >
                                            <p className="">
                                                Autoriser les chauffeurs à
                                                visualiser les courses non
                                                assignées
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                'flex w-1/4 items-center justify-center lg:w-1/2'
                                            }
                                        >
                                            <ClassicSwitch
                                                setValue={
                                                    handleUsersShowUnassignedRuns
                                                }
                                                value={usersShowUnassignedRuns}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between space-x-4 pb-4 ">
                                        <div
                                            className={
                                                'flex w-3/4 flex-col lg:w-1/2'
                                            }
                                        >
                                            <p className="">
                                                Empêcher les chauffeurs de
                                                visualiser les prochains jours
                                                du planning. Les chauffeurs
                                                peuvent visualiser le prochain
                                                jour du planning à partir de 18h
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                'flex w-1/4 items-center justify-center lg:w-1/2'
                                            }
                                        >
                                            <ClassicSwitch
                                                setValue={
                                                    handleHideFuturPlanningDaysForUsers
                                                }
                                                value={
                                                    hideFuturPlanningDaysForUsers
                                                }
                                            />
                                        </div>
                                    </div>
                                    <h2 className={'text-xl font-semibold '}>
                                        Télétransmission
                                    </h2>
                                    <div className="flex items-center justify-between space-x-4 pb-4 ">
                                        <div
                                            className={
                                                'flex w-3/4 flex-col lg:w-1/2'
                                            }
                                        >
                                            <p className="flex items-center">
                                                Utilisation des heures
                                                effectives lors du transfert des
                                                transports
                                                <i
                                                    className="far fa-question-circle fa-lg cursor-pointer pl-2"
                                                    title={
                                                        'Veuillez contacter un administrateur pour changer ce paramètre'
                                                    }
                                                />
                                                <LockKey />
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                'flex w-1/4 items-center justify-center lg:w-1/2'
                                            }
                                        >
                                            <ClassicSwitch
                                                disabled={true}
                                                value={transfertEffectiveHours}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between space-x-4 pb-4 ">
                                        <div
                                            className={
                                                'flex w-3/4 flex-col lg:w-1/2'
                                            }
                                        >
                                            <p className="">
                                                Autoriser le changement de tarif
                                                lors de la facturation
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                'flex w-1/4 items-center justify-center lg:w-1/2'
                                            }
                                        >
                                            <ClassicSwitch
                                                setValue={() => {
                                                    setAllowedToChangePriceBasis(
                                                        !allowedToChangePriceBasis
                                                    )
                                                    setHasChanged(true)
                                                }}
                                                value={
                                                    allowedToChangePriceBasis
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between space-x-4 pb-4 ">
                                        <div
                                            className={
                                                'flex w-3/4 flex-col lg:w-1/2'
                                            }
                                        >
                                            <p className="">
                                                Pouvoir facturer directement au
                                                patient sans
                                                mutuelle/complémentaire
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                'flex w-1/4 items-center justify-center lg:w-1/2'
                                            }
                                        >
                                            <ClassicSwitch
                                                setValue={() => {
                                                    setAllowedToInvoiceWithoutAMC(
                                                        !allowedToInvoiceWithoutAMC
                                                    )
                                                    setHasChanged(true)
                                                }}
                                                value={
                                                    allowedToInvoiceWithoutAMC
                                                }
                                            />
                                        </div>
                                    </div>

                                    <h2 className={'text-xl font-semibold '}>
                                        Affichage de la régulation
                                    </h2>
                                    <div className="flex items-center justify-between space-x-4 pb-4 ">
                                        <div
                                            className={
                                                'flex w-3/4 flex-col lg:w-1/2'
                                            }
                                        >
                                            <p className="">
                                                Afficher des couleurs
                                                différentes selon les objets de
                                                courses
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                'flex w-1/4 items-center justify-center lg:w-1/2'
                                            }
                                        >
                                            <ClassicSwitch
                                                setValue={() => {
                                                    setHasChanged(true)
                                                    setShowRunObjectColors(
                                                        !showRunObjectColors
                                                    )
                                                }}
                                                value={showRunObjectColors}
                                            />
                                        </div>
                                    </div>
                                    <h2 className={'text-xl font-semibold '}>
                                        Configuration des véhicules
                                    </h2>
                                    <SelectDropDownList
                                        icon={''}
                                        label="Types de véhicules"
                                        options={defaultVehiclesTypesOptions}
                                        value={defaultVehiclesTypes}
                                        id="role"
                                        handleOptionChange={(e) =>
                                            handleSelectVehicleTypes(e)
                                        }
                                        isDisabled={false}
                                        isMulti={true}
                                        showLabel={true}
                                        isSearchable={false}
                                    />

                                    <SelectDropDownList
                                        icon={''}
                                        label="Type de véhicule par défaut"
                                        options={defaultVehiclesTypes}
                                        value={defaultVehicleType}
                                        id="vehicleType"
                                        handleOptionChange={(e) => {
                                            setHasChanged(true)
                                            setDefaultVehicleType(e)
                                        }}
                                        isClearable={false}
                                        isDisabled={
                                            defaultVehiclesTypes === null
                                        }
                                        showLabel={true}
                                        isSearchable={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="fixed right-0 top-0 z-50 mr-5 flex flex-col space-y-2 text-gray-700">
                {errors.map((error) => {
                    return (
                        <Error
                            key={error.id}
                            error={error}
                            dispatch={dispatchError}
                        />
                    )
                })}
            </div>

            <div className="mt-auto">
                <div className="w-full">
                    <div className="px-8 pb-8 pt-8">
                        <hr className="pb-5" />
                        <div className="flex justify-between text-xl lg:text-base">
                            <SecondaryButton
                                label="Annuler"
                                title="Annuler"
                                action={reloading}
                            />
                            <PrimaryButton
                                label="Modifier"
                                title="Modifier"
                                action={handleForm}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ParametersConfig
