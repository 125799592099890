import { useParams, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import Loader from '../../../Commons/Loaders/Loader'
import * as dayjs from 'dayjs'
import API from '../../../../services/api'
import { toast } from 'react-toastify'
import { saveAs } from 'file-saver'
import NoemieStats from './NoemieStats'
import NoemieHeader from './NoemieHeader'
import NoemieLot from './NoemieLot'
import NoemieRejectionHistory from './NoemieRejectionHistory'
import SelectDateModalForm from './SelectDateModalForm'
import ActionModal from '../../../Modal/ActionModal'
import { formatAndSortInvoices } from '../../../../services/InvoiceUtils'

const NoemieForm = () => {
    const { id } = useParams()
    const [openedCardId, setOpenedCardId] = useState(null)
    const [loading, setLoading] = useState(true)
    const [noemie, setNoemie] = useState([])
    const [isReceived, setIsReceived] = useState(false)
    const [showSelectDateModal, setShowSelectDateModal] = useState(false)
    const [selectedLot, setSelectedLot] = useState(null)
    const [actionInitiator, setActionInitiator] = useState(null)
    const [status, setStatus] = useState(null)
    const history = useNavigate()
    const [showResolvingModal, setShowResolvingModal] = useState(false)
    const [date, setDate] = useState(new Date())

    const fetchNoemie = async () => {
        if (id) {
            setLoading(true)
            const response = await API.Noemie.get(id)
            if (response.ok) {
                const jsonData = await response.json()
                if (jsonData.noemieReceivedAt) {
                    jsonData.cpamInvoiceGroups.forEach((group) => {
                        // Filtrer les factures rejetées
                        const formattedInvoices = formatAndSortInvoices(
                            group.cpamInvoices
                        )

                        group.rejectedInvoices = formattedInvoices.filter(
                            (invoice) => invoice.status === 'Rejetée'
                        )
                        // Filtrer les factures qui ne sont pas rejetées
                        group.validatedInvoices = formattedInvoices.filter(
                            (invoice) => invoice.status !== 'Rejetée'
                        )
                    })

                    setNoemie(jsonData)
                    setIsReceived(true)
                } else {
                    setNoemie(jsonData)
                    setIsReceived(false)
                }
            }
        }
    }

    useEffect(() => {
        fetchNoemie().then(() => setLoading(false))
    }, [])

    const handlePrintAction = async () => {
        setLoading(true)
        const groupIds = noemie.cpamInvoiceGroups.map((group) => group.groupId)
        const groupIdsString = groupIds.join('_')
        const pdfName = `${noemie.cpamInvoiceGroups[0].payingCenter.label}_${groupIdsString}.pdf`
        try {
            const response = await API.Noemie.print(id)
            if (response.ok) {
                response.blob().then((blob) => {
                    saveAs(blob, pdfName)
                    toast.success('Pdf téléchargé avec succès')
                })
            }
        } catch (error) {
            if (error.json) {
                const data = await error.json()
                toast.error(data['hydra:description'])
            } else {
                toast.error('Erreur, veuillez contacter un administrateur')
            }
        }
        setLoading(false)
    }

    const handleReceivedPayment = async () => {
        setShowSelectDateModal(false)
        const data = {
            date: dayjs(date).format('YYYY-MM-DD'),
            organization: actionInitiator,
            b2FileIri: '/b2_files/' + id,
            status: status,
        }
        setLoading(true)
        try {
            const response = await API.Noemie.receivedPayment(data)
            if (response.ok) {
                toast.success('Status modifié avec succès')
                await fetchNoemie()
            }
        } catch (error) {
            if (error.json) {
                const data = await error.json()
                toast.error(data['hydra:description'])
            } else {
                toast.error('Erreur, veuillez contacter un administrateur')
            }
        }
        setLoading(false)
    }

    const handleReceivedPaymentAMO = (status) => {
        setActionInitiator('AMO')
        setStatus(status)
        setShowSelectDateModal(true)
    }

    const handleReceivedPaymentAMC = (status) => {
        setActionInitiator('AMC')
        setStatus(status)
        setShowSelectDateModal(true)
    }

    const handleClosedNoemie = async () => {
        // ADD HISTORY VIEW
        if (noemie.cpamInvoiceGroups[0].rejectedInvoices.length > 0) {
            toast.error(
                'Vous ne pouvez pas clôturer le retour car il y a des factures rejetées'
            )
            return
        }
        setLoading(true)
        try {
            const response = await API.Noemie.close(id)
            if (response.ok) {
                toast.success('Status modifié avec succès')
                history(-1)
            }
        } catch (error) {
            if (error.json) {
                const data = await error.json()
                toast.error(data['hydra:description'])
            } else {
                toast.error('Erreur, veuillez contacter un administrateur')
            }
        }
    }

    const handleDateChange = (date) => {
        // verifier si la date n'est pas dans le futur sinon erreur
        if (date > new Date()) {
            toast.error('La date ne peut pas être dans le futur')
        } else {
            setDate(date)
        }
    }

    const handleShowLotResolvingModal = (lot) => {
        setShowResolvingModal(true)
        setSelectedLot(lot)
    }

    const handleResolveRejectedInvoices = async () => {
        setShowResolvingModal(false)
        setLoading(true)
        try {
            const response = await API.Noemie.resolveRejectedInvoices(
                selectedLot
            )
            if (response.ok) {
                toast.success('Factures résolues avec succès')
                await fetchNoemie()
            }
        } catch (error) {
            if (error.json) {
                const data = await error.json()
                toast.error(data['hydra:description'])
            } else {
                toast.error('Erreur, veuillez contacter un administrateur')
            }
        }
        setLoading(false)
    }

    return (
        <div className="px-2 xl:pt-5">
            {loading ? (
                <Loader />
            ) : (
                <div className="flex w-full flex-col space-y-2 lg:space-y-0 ">
                    <NoemieHeader
                        isReceived={isReceived}
                        noemie={noemie}
                        handlePrintAction={handlePrintAction}
                    />
                    <div className={'py-5'}>
                        <NoemieStats
                            noemie={noemie}
                            handleReceivedPaymentAMC={handleReceivedPaymentAMC}
                            setActionInitiator={setActionInitiator}
                            handleReceivedPaymentAMO={handleReceivedPaymentAMO}
                            handleClosedNoemie={
                                noemie.status === 'Réglé AMO'
                                    ? handleClosedNoemie
                                    : null
                            }
                        />
                    </div>
                    {noemie.cpamInvoiceGroups?.map((lot) => (
                        <NoemieLot
                            key={lot.id}
                            lot={lot}
                            openedCardId={openedCardId}
                            setOpenedCardId={setOpenedCardId}
                            isReceived={isReceived}
                            handleShowLotResolvingModal={
                                handleShowLotResolvingModal
                            }
                        />
                    ))}
                    {noemie.cpamInvoiceRejectionDetails?.length > 0 && (
                        <NoemieRejectionHistory noemie={noemie} />
                    )}
                </div>
            )}
            {showSelectDateModal && (
                <SelectDateModalForm
                    date={date}
                    handleCloseModal={() => setShowSelectDateModal(false)}
                    handleDateChange={handleDateChange}
                    handleForm={handleReceivedPayment}
                />
            )}
            {showResolvingModal && (
                <ActionModal
                    action={handleResolveRejectedInvoices}
                    label={
                        "Les factures rejetées seront décomptées du total du lot et seront placées dans l'historique des refus."
                    }
                    actionButton={'Résoudre les factures rejetées'}
                    setModalOpen={() => setShowResolvingModal(false)}
                    actionLabel={'Résoudre'}
                    labelAction={'Résoudre les factures rejetées'}
                />
            )}
        </div>
    )
}

export default NoemieForm
