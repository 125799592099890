import React from 'react'
import { User, Cancel, Healthcare } from 'iconoir-react'
import { formatEuro } from '../Manage'
import { ReactComponent as Cpam } from '../../../../files/cpam.svg'

const NoemieRejectionHistory = ({ noemie }) => (
    <div>
        <h2 className={'py-5 text-xl font-semibold'}>Historique des rejets</h2>
        {noemie.cpamInvoiceRejectionDetails?.map((rejection, key) => (
            <div key={key} className={'rounded bg-white px-2 shadow-mobile'}>
                <div className="mb-4 border-b  py-2">
                    <div className="mb-2 flex justify-between">
                        <div className="flex items-center">
                            <span className="text-lg font-semibold text-blue-500">
                                {rejection.invoiceId}
                            </span>
                        </div>
                        <div className="flex items-center truncate">
                            <User />
                            <span className="ml-2">{rejection.fullname}</span>
                        </div>
                    </div>
                    <div className="grid grid-cols-2  gap-2 pb-2 md:grid-cols-3">
                        <div className="flex items-center">
                            <Cpam height={24} width={24} />
                            <span className="ml-2">
                                {formatEuro(rejection.payingCenterAmount)}
                            </span>
                        </div>
                        {rejection.healthComplementaryAmount > 0 ? (
                            <div className="mx-auto flex items-center">
                                <Healthcare height={24} width={24} />
                                <span className="ml-2">
                                    {formatEuro(
                                        rejection.healthComplementaryAmount
                                    )}
                                </span>
                            </div>
                        ) : (
                            <div className="mx-auto flex items-center">
                                <span
                                    className={
                                        'flex w-20 justify-center rounded-full bg-blue-100 text-center font-bold text-blue-800'
                                    }
                                >
                                    100%
                                </span>
                            </div>
                        )}
                        <div className="ml-auto flex items-center ">
                            <span className="ml-2 font-bold">
                                {formatEuro(rejection.totalInvoiceAmount)}
                            </span>
                            <Cancel className={'ml-2 text-red-500'} />
                        </div>
                    </div>
                    <div className="col-span-2 flex w-full items-center rounded-lg bg-red-100 px-2 py-2 md:col-span-4">
                        <p>{rejection.rejectionReason}</p>
                    </div>
                </div>
            </div>
        ))}
    </div>
)

export default NoemieRejectionHistory
