import React from 'react'

const LineInput = ({
    label,
    id,
    type,
    placeholder,
    value,
    onChange,
    required,
    min,
    max,
    disabled,
    className,
    error,
    pattern,
}) => {
    return (
        <div className={`flex flex-col w-full space-y-2 lg:space-y-0 `}>
            <label className="block uppercase tracking-wide text-gray-700 text-sm lg:text-xs font-bold lg:pb-2">
                {label}
            </label>
            <input
                name="form"
                className={`flex appearance-none w-full text-gray-700 h-10 leading-tight border rounded-xl  border-gray-300 hover:border-lighter-blue-green
                focus:outline-none  ${error && 'border-red-500 '} ${className}`}
                id={id}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                required={required}
                min={min}
                max={max}
                disabled={disabled}
                pattern={pattern}
            />
        </div>
    )
}

export default LineInput
