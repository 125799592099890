import React from 'react'
import dayjs from 'dayjs'

const ObjectAndDateFirstFormat = ({ row }) => {
    return (
        <div className="w-40 max-w-40 px-6 truncate">
            <div className="flex justify-between truncate">
                <div className="flex flex-col truncate">
                    <div className="font-semibold text-sm truncate">
                        {row.runObject.label}
                    </div>
                    <div>{dayjs(row.date).local().format('DD/MM/YYYY')}</div>
                </div>
            </div>
        </div>
    )
}

export default ObjectAndDateFirstFormat
