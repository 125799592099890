import React from 'react'
import Patient from '../App/Regulation/RunListComponents/Patient'
import ObjectAndDateFirstFormat from '../App/Regulation/RunListComponents/ObjectAndDateFirstFormat'
import ObjectAndDateSecondFormat from '../App/Regulation/RunListComponents/ObjectAndDateSecondFormat'
import Vehicle from '../App/Regulation/RunListComponents/Vehicle'
import DepositLocation from '../App/Regulation/RunListComponents/DepositLocation'
import TimesAndTimeValidation from '../App/Regulation/RunListComponents/TimesAndTimeValidation'
import Times from '../App/Regulation/RunListComponents/Times'
import Status from '../App/Regulation/RunListComponents/Status'
import Team from '../App/Regulation/RunListComponents/Team'
import Return from '../App/Regulation/RunListComponents/Return'
import RunNumberInSeries from '../App/Regulation/RunListComponents/RunNumberInSeries'
import DepartureOrder from '../App/Regulation/RunListComponents/DepartureOrder'

const ComputerConfiguratorDisplayed = ({ key, value, row, isSocietyTaxi }) => {
    if (value === 'patient') {
        return <Patient row={row} isComputer={true} />
    } else if (value === 'objectAndDateFirstFormat') {
        return <ObjectAndDateFirstFormat row={row} />
    } else if (value === 'objectAndDateSecondFormat') {
        return <ObjectAndDateSecondFormat row={row} />
    } else if (value === 'vehicle') {
        return (
            <Vehicle
                row={row}
                isSocietyTaxi={isSocietyTaxi}
                isComputer={true}
            />
        )
    } else if (value === 'depositLocation') {
        return <DepositLocation row={row} isComputer={true} />
    } else if (value === 'timesAndTimeValidation') {
        return <TimesAndTimeValidation row={row} isComputer={true} />
    } else if (value === 'timesOnly') {
        return <Times row={row} isComputer={true} />
    } else if (value === 'status') {
        return <Status row={row} isComputer={true} />
    } else if (value === 'team') {
        return <Team row={row} isComputer={true} />
    } else if (value === 'return') {
        return <Return row={row} />
    } else if (value === 'runNumberInSeries') {
        return <RunNumberInSeries row={row} isComputer={true} />
    } else if (value === 'departureOrder') {
        return <DepartureOrder row={row} isComputer={true} />
    }
}

export default ComputerConfiguratorDisplayed
