import React from 'react'
import * as dayjs from 'dayjs'
import { Edit } from 'iconoir-react'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import { getTextAndBackgroundColor } from '../../../services/RunStatus'

const RunView = ({
    history,
    run,
    setOpenRunManageModal,
    setModalOpen,
    fromDashboard,
}) => {
    return (
        <div className="flex w-full flex-col  space-y-6 bg-white py-2 text-lg lg:text-base">
            <div className="flex w-full items-center justify-between py-5  text-gray-700 ">
                <h2 className="text-2xl font-bold">Détails du transport</h2>
                <PrimaryButton
                    label="Modifier"
                    title="Modifier"
                    action={() => {
                        if (fromDashboard) {
                            setOpenRunManageModal(true)
                            setModalOpen(false)
                        } else {
                            history(`/transport/${run.id}`)
                        }
                    }}
                    icon={<Edit />}
                    hiddenLabelOnMobile={true}
                    hiddenIconOnPc={true}
                />
            </div>

            <div className="flex flex-col lg:flex-row lg:items-center">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">Equipe</label>
                {run.masterRun ? (
                    <div className="flex">
                        {run.masterRun.users.map((user, index) => (
                            <div key={user.id} className="flex">
                                <p className="pr-1">{user.lastname}</p>
                                <p>{user.firstname}</p>
                                {index === 0 &&
                                    run.masterRun.users.length > 1 && (
                                        <span className="px-1"> | </span>
                                    )}
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>Aucune équipe</p>
                )}
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                    {run.isRoundTrip ? 'A/R' : ''}
                    {run.isReturnPath ? ' Retour' : ' Aller'}
                </label>
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">Statut</label>
                <span
                    className={`flex w-32 max-w-full justify-center rounded-full px-2 text-base font-semibold leading-5 ${getTextAndBackgroundColor(
                        run.status.id
                    )}`}
                >
                    {run.status.label}
                </span>
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                    Documents
                </label>
                <div className="flex w-2/3 justify-between">
                    <p>{run.documents ? run.documents.label : 'Aucun'}</p>
                    {run.documents?.label === 'Complet' ? (
                        <i className="far fa-clone fa-lg" />
                    ) : run.documents?.label === 'Aucun' || !run.documents ? (
                        <i className="fas fa-times fa-lg" />
                    ) : (
                        <i className="far fa-file fa-lg" />
                    )}
                </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">Objet</label>
                <p>{run.runObject.label}</p>
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center">
                <label className={`pb-2 font-bold font-bold lg:w-1/3 lg:p-0  `}>
                    Horaires
                </label>
                <span className="">
                    <span className="inline-flex rounded-md bg-gray-200 px-2 py-1 text-lg font-semibold  leading-5 text-gray-800 ">
                        {dayjs(run.departureTime).local().format('HH : mm')}
                    </span>
                    <span className="px-2">-></span>
                    <span className="inline-flex rounded-md bg-gray-200 px-2 py-1 text-lg font-semibold  leading-5  text-gray-800 ">
                        {dayjs(run.arrivingTime).local().format('HH : mm')}
                    </span>
                </span>
            </div>

            {(run.effectiveDepartureTime || run.effectiveArrivingTime) && (
                <div className="flex flex-col lg:flex-row lg:items-center">
                    <label
                        className={`pb-2 font-bold font-bold lg:w-1/3 lg:p-0  `}
                    >
                        Horaires effectifs
                    </label>
                    <span className="">
                        <span className="inline-flex rounded-md bg-gray-200 px-2 py-1 text-lg font-semibold  leading-5 text-gray-800 ">
                            {run.effectiveDepartureTime
                                ? dayjs(run.effectiveDepartureTime)
                                      .local()
                                      .format('HH : mm')
                                : 'X'}
                        </span>
                        <span className="px-2">-></span>
                        <span className="inline-flex rounded-md bg-gray-200 px-2 py-1 text-lg font-semibold  leading-5  text-gray-800 ">
                            {run.effectiveArrivingTime
                                ? dayjs(run.effectiveArrivingTime)
                                      .local()
                                      .format('HH : mm')
                                : 'X'}
                        </span>
                    </span>
                </div>
            )}

            <div className="flex flex-col lg:flex-row lg:items-center">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">Départ</label>
                <div className="flex flex-col ">
                    <p>
                        {run.pickUpLocation.street
                            ? run.pickUpLocation.street + ' '
                            : ''}
                        <span className="underline">
                            {run.pickUpLocation.city}
                        </span>
                    </p>
                </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                    Arrivée
                </label>
                <div className="flex flex-col">
                    <p>
                        {run.depositLocation.street
                            ? run.depositLocation.street + ' '
                            : ''}
                        <span className="underline">
                            {run.depositLocation.city}
                        </span>
                    </p>
                </div>
            </div>
            {run.series && run.seriesIndex && run.series.nbRuns && (
                <div className="flex flex-col lg:flex-row lg:items-center">
                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                        Série
                    </label>
                    <div className="flex flex-col">
                        <p className="font-bold">
                            {run.seriesIndex}/{run.series.nbRuns}
                        </p>
                    </div>
                </div>
            )}
            {run.departureOrder && (
                <div className="flex flex-col lg:flex-row lg:items-center">
                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                        Ordre de départ
                    </label>
                    <p className="w-full font-bold lg:w-2/3">
                        {run.departureOrder}
                    </p>
                </div>
            )}
            {run.depositLocation.label && (
                <div className="flex flex-col lg:flex-row lg:items-center">
                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                        Etablissement
                    </label>
                    {run.depositLocation.label}
                </div>
            )}

            {run.comments && (
                <div className="flex flex-col lg:flex-row lg:items-center">
                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                        Commentaire
                    </label>
                    <p className="w-full lg:w-2/3">{run.comments}</p>
                </div>
            )}

            {run?.series?.description && (
                <div className="flex flex-col lg:flex-row lg:items-center">
                    <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                        Description série
                    </label>
                    <p className="w-full lg:w-2/3">{run.series.description}</p>
                </div>
            )}
        </div>
    )
}

export default RunView
