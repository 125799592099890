import { parseStringBooleanToBoolean } from '../../tools/Utility'
import dayjs from 'dayjs'

export const ACTIONS = {
    SET_VERTICAL_TIMELINE_POSITION: 'setVerticalTimeLinePosition',
    SET_TEAMS: 'setTeams',
    SET_UNASSIGNED_RUNS: 'setUnassignedRuns',
    SET_RANGE: 'setRange',
    SET_NB_TEAMS_RUNS: 'setNbTeamsRuns',
    SET_NB_PARTNERS_RUNS: 'setNbPartnersRuns',
    SET_TEAM_RUN_SELECTED: 'setTeamRunSelected',
    SET_UNASSIGNED_RUN_SELECTED: 'setUnassignedRunSelected',
    SET_LOADING: 'setLoading',
    SET_TEAM_AND_RUNS_TO_GROUP: 'setTeanAndRunsToGroup',
    SET_ACTION_MODAL_OPEN: 'setActionModalOpen',
    SET_FOCUS_MODE: 'setFocusMode',
    SET_HIDE_TEAM: 'setHideTeam',
    SET_UNSELECTED_ALL_RUNS: 'setUnselectAllRuns',
    SET_UNASSIGNED_SELECTED_TEAMS_RUNS: 'setUnassignedTeamsRuns',
    SET_IS_PATIENT_FILTER: 'setIsPatientFilter',
    SET_NB_TOTAL_RUNS: 'setNbTotalRuns',
    SET_NB_UNASSIGNED_RUNS: 'setNbUnassignedRuns',
    SET_NB_ASSIGNED_RUNS: 'setNbAssignedRuns',
    SET_NB_SHARED_RUNS: 'setNbSharedRuns',
    SET_NB_TOTAL_CANCELED_RUNS: 'setNbTotalCanceledRuns',
    SET_NB_TEAMS_RUNS_AND_CANCELED_TEAMS_RUNS:
        'setNbTeamsRunsAndCanceledTeamsRuns',
    SET_OPEN_RUN_MANAGE_MODAL: 'setOpenRunManageModal',
    SET_OPEN_COMPETITION_MODAL: 'setOpenCompetitionModal',
    SET_OPEN_NEW_RUN_MANAGE_MODAL: 'setOpenNewRunManageModal',
    SET_OPEN_SERIES_MANAGE_MODAL: 'setOpenSeriesManageModal',
    SET_OPEN_NEW_SERIES_MANAGE_MODAL: 'setOpenNewSeriesManageModal',
    SET_OPEN_SELECT_DATE_PICKER: 'SetOpenSelectDatePicker',
    SET_OPEN_ADD_MENU: 'SetOpenAddMenu',
    ADD_TRANSPORT_BUTTON_CLICKED: 'AddTransportButtonClicked',
    ADD_SERIES_BUTTON_CLICKED: 'AddSeriesButtonClicked',
    SET_CLICKED_RUN: 'SetClickedRun',
    SET_SERIES_ID: 'SetSeriesId',
    SET_INFO_CARD_CLICKED: 'SetCardClicked',
    SET_STATUS_LIST: 'SetStatusList',
    SET_TEAMS_CREATED: 'SetTeamsCreated',
    SET_SELECTED_TEAM: 'SetSelectedTeam',
    SET_SELECTED_TEAM_RUN_SELECTED: 'SetSelectedTeamRunSelected',
    SET_INFORMATION_RUN_MODAL_OPEN: 'SetInformationRunModalOpen',
    SET_SELECTED_UNASSIGNED_RUNS: 'SetSelectedUnassignedRuns',
    OPEN_TEAMS_LIST: 'OpenTeamsList',
    OPEN_GROUP_TEAMS_LIST: 'OpenGroupTeamsList',
    CLOSE_TEAMS_LIST_MODAL: 'CloseTeamsListModal',
    SET_OPEN_GLOBAL_VIEW: 'SetOpenGlobalView',
    SET_DOWNLOAD_BUTTON_LOADING: 'SetDownloadButtonLoading',
    SET_OPEN_CONFIG_MODAL: 'SetOpenConfigModal',
    SET_OPEN_TEAMS_MODAL: 'SetOpenTeamsModal',
    RESET_CONFIG: 'ResetConfig',
    SET_CONFIG: 'SetConfig',
    CLOSE_MODALS: 'CloseModals',
    SET_DISABLED_BUTTONS: 'SetDisabledButtons',
    SET_IS_LOADING_PLANNING: 'SetLoadingProtected',
    SET_IS_FIRST_LOADING_PLANNING: 'SetIsFirstLoadingProtected',
    SET_PLANNING: 'SetPlanning',
    SET_PLANNINGS: 'SetPlannings',
    SET_SHOW_LOCK_MODAL: 'SetShowLockModal',
    UPDATE_PLANNINGS: 'UpdatePlannings',
    REFRESH_UPDATED_TEAMS: 'RefreshUpdatedTeams',
}

export const getInitialState = ({ date }) => {
    return {
        date: date,
        times: [
            { value: 0, label: '00h00' },
            { value: 1, label: '01h00' },
            { value: 2, label: '02h00' },
            { value: 3, label: '03h00' },
            { value: 4, label: '04h00' },
            { value: 5, label: '05h00' },
            { value: 6, label: '06h00' },
            { value: 7, label: '07h00' },
            { value: 8, label: '08h00' },
            { value: 9, label: '09h00' },
            { value: 10, label: '10h00' },
            { value: 11, label: '11h00' },
            { value: 12, label: '12h00' },
            { value: 13, label: '13h00' },
            { value: 14, label: '14h00' },
            { value: 15, label: '15h00' },
            { value: 16, label: '16h00' },
            { value: 17, label: '17h00' },
            { value: 18, label: '18h00' },
            { value: 19, label: '19h00' },
            { value: 20, label: '20h00' },
            { value: 21, label: '21h00' },
            { value: 22, label: '22h00' },
            { value: 23, label: '23h00' },
            { value: 0, label: '0h00' },
        ],
        rangeList: [
            { value: 0.25, label: '0.25' },
            { value: 0.5, label: '0.5' },
            { value: 0.75, label: '0.75' },
            { value: 1, label: '1' },
            { value: 1.25, label: '1.25' },
            { value: 1.5, label: '1.5' },
            { value: 1.75, label: '1.75' },
            { value: 2, label: '2' },
        ],
        intervalSize: {
            size: 300,
            range: localStorage.getItem('dashboardRange')
                ? parseFloat(localStorage.getItem('dashboardRange'))
                : 1,
            width: 0.8,
        },
        isPatientFilterList: [
            { value: null, label: 'Toutes' },
            { value: true, label: 'Patients' },
            { value: false, label: 'Clients' },
        ],
        isPatientFilter: { value: null, label: 'Toutes' },
        //padding correspondant au padding de la barre sous les heures
        padding: 22,
        verticalTimeLinePosition: false,
        teams: [],
        unassignedRuns: [],
        nbTeamsRuns: 0,
        nbPartnersRuns: 0,
        loading: true,
        teamAndRunsToGroup: {},
        actionModalOpen: false,
        focusMode: false,
        hide: false,
        nbTotalRuns: 0,
        nbUnassignedRuns: 0,
        nbAssignedRuns: 0,
        nbSharedRuns: 0,
        nbTotalCanceledRuns: 0,
        nbCanceledUnassignedRuns: 0,
        nbCanceledTeamsRuns: 0,
        openRunManageModal: false,
        openCompetitionModal: false,
        openNewRunManageModal: false,
        openSeriesManageModal: false,
        openNewSeriesManageModal: false,
        openSelectDatePicker: false,
        openAddMenu: false,
        clickedRun: {},
        seriesId: 0,
        statusList: [],
        teamsCreated: false,
        firstLoading: true,
        selectedTeam: null,
        selectedUnassignedRuns: false,
        informationRunModalOpen: false,
        openTeamsList: false,
        openGroupTeamsList: false,
        openGlobalView: false,
        downloadButtonLoading: false,
        openConfigModal: false,
        openTeamsModal: false,
        showPartners: parseStringBooleanToBoolean(
            localStorage.getItem('showPartners')
        ),
        defaultConfig: {
            showEffectiveHours: false,
            displayedTeams: [], //if empty, all teams are displayed,
            isPatientFilter: { value: null, label: 'Toutes' },
            showPartners: false,
            displayVehicleColor: false,
        },
        showEffectiveHours: parseStringBooleanToBoolean(
            localStorage.getItem('showEffectiveHours')
        ),
        displayedTeams: [],
        clientOpenedPageDatetime: dayjs(new Date())
            .utc()
            .local()
            .format('YYYY-MM-DDTHH:mm:ss'),
        disabledButtons: false,
        displayVehicleColor: false,
        isLoadingProtectedPlanning: false,
        isLocked: false,
        plannings: [],
        planning: null,
        showLockModal: false,
        isLoadingPlanning: false,
    }
}

export const PlanningReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_LOADING:
            return {
                ...state,
                disabledButtons: false,
                loading: action.payload,
            }
        case ACTIONS.SET_VERTICAL_TIMELINE_POSITION:
            return {
                ...state,
                verticalTimeLinePosition: action.payload,
            }
        case ACTIONS.SET_TEAMS:
            return {
                ...state,
                teams: action.payload,
                selectedTeam: state.selectedTeam
                    ? action.payload.find(
                          (team) => team.id === state.selectedTeam.id
                      )
                    : null,
                firstLoading: false,
                clientOpenedPageDatetime: new Date(),
            }
        case ACTIONS.REFRESH_UPDATED_TEAMS:
            return {
                ...state,
                teams: state.teams.map((team) => {
                    const teamUpdated = action.payload.find(
                        (t) => t.id === team.id
                    )
                    if (teamUpdated) {
                        return teamUpdated
                    } else {
                        return team
                    }
                }),
                loading: false,
                clientOpenedPageDatetime: new Date(),
            }
        case ACTIONS.SET_UNASSIGNED_RUNS:
            return {
                ...state,
                unassignedRuns: action.payload,
            }
        case ACTIONS.SET_RANGE:
            return {
                ...state,
                intervalSize: {
                    size: 300,
                    range: action.payload,
                },
            }
        case ACTIONS.SET_IS_PATIENT_FILTER:
            return {
                ...state,
                isPatientFilter: action.payload,
            }
        case ACTIONS.SET_NB_TEAMS_RUNS:
            return {
                ...state,
                nbTeamsRuns: action.payload,
            }
        case ACTIONS.SET_NB_PARTNERS_RUNS:
            return {
                ...state,
                nbPartnersRuns: action.payload,
            }
        case ACTIONS.SET_UNASSIGNED_RUN_SELECTED:
            return {
                ...state,
                unassignedRuns: state.unassignedRuns.map((runs) => {
                    return runs.map((run) => {
                        if (run.id === action.payload.id) {
                            return { ...run, selected: action.payload.selected }
                        } else {
                            return run
                        }
                    })
                }),
            }
        case ACTIONS.SET_TEAM_RUN_SELECTED:
            return {
                ...state,
                teams: state.teams.map((team) => {
                    if (team.id === action.payload.teamId) {
                        return {
                            ...team,
                            runs: team.runs.map((run) => {
                                if (run.id === action.payload.id) {
                                    return {
                                        ...run,
                                        selected: action.payload.selected,
                                    }
                                } else {
                                    return run
                                }
                            }),
                        }
                    } else {
                        return team
                    }
                }),
            }
        case ACTIONS.SET_TEAM_AND_RUNS_TO_GROUP:
            return {
                ...state,
                teamAndRunsToGroup: {
                    team: action.payload.team,
                    groupsOfRunsToGroup: action.payload.groupsOfRunsToGroup,
                },
            }
        case ACTIONS.SET_ACTION_MODAL_OPEN:
            return {
                ...state,
                actionModalOpen: action.payload,
            }
        case ACTIONS.SET_FOCUS_MODE:
            return {
                ...state,
                focusMode: action.payload,
            }
        case ACTIONS.SET_HIDE_TEAM:
            return {
                ...state,
                teams: state.teams.map((team) => {
                    if (team.id === action.payload.teamId) {
                        return {
                            ...team,
                            hide: action.payload.hide,
                        }
                    } else {
                        return team
                    }
                }),
            }
        case ACTIONS.SET_UNSELECTED_ALL_RUNS:
            return {
                ...state,
                unassignedRuns: state.unassignedRuns.map(
                    (groupUnassignedRun) => {
                        return groupUnassignedRun.map((unassignedRun) => {
                            return { ...unassignedRun, selected: false }
                        })
                    }
                ),
                teams: state.teams.map((team) => {
                    return {
                        ...team,
                        runs: team.runs.map((r) => {
                            return { ...r, selected: false }
                        }),
                    }
                }),
                selectedUnassignedRuns: false,
                selectedTeam: null,
            }
        case ACTIONS.SET_UNASSIGNED_SELECTED_TEAMS_RUNS:
            return {
                ...state,
            }
        case ACTIONS.SET_NB_UNASSIGNED_RUNS:
            return {
                ...state,
                nbUnassignedRuns: action.payload.nbUnassignedRuns,
                nbCanceledUnassignedRuns:
                    action.payload.nbCanceledUnassignedRuns,
            }
        case ACTIONS.SET_NB_ASSIGNED_RUNS:
            return {
                ...state,
                nbAssignedRuns: action.payload,
            }
        case ACTIONS.SET_NB_SHARED_RUNS:
            return {
                ...state,
                nbSharedRuns: action.payload,
            }
        case ACTIONS.SET_NB_TOTAL_CANCELED_RUNS:
            return {
                ...state,
                nbTotalCanceledRuns: action.payload,
            }
        case ACTIONS.SET_NB_TEAMS_RUNS_AND_CANCELED_TEAMS_RUNS:
            return {
                ...state,
                nbTeamsRuns: action.payload.nbTeamsRuns,
                nbCanceledTeamsRuns: action.payload.nbCanceledTeamsRuns,
            }

        case ACTIONS.SET_NB_TOTAL_RUNS:
            return {
                ...state,
                nbTotalRuns: action.payload.total,
                nbSharedRuns: action.payload.shared,
            }
        case ACTIONS.SET_OPEN_NEW_RUN_MANAGE_MODAL:
            return {
                ...state,
                openNewRunManageModal: action.payload,
            }
        case ACTIONS.SET_OPEN_RUN_MANAGE_MODAL:
            return {
                ...state,
                openRunManageModal: action.payload,
            }
        case ACTIONS.SET_OPEN_COMPETITION_MODAL:
            return {
                ...state,
                openCompetitionModal: action.payload,
            }
        case ACTIONS.SET_OPEN_NEW_SERIES_MANAGE_MODAL:
            return {
                ...state,
                openNewSeriesManageModal: action.payload,
            }
        case ACTIONS.SET_OPEN_SERIES_MANAGE_MODAL:
            return {
                ...state,
                openSeriesManageModal: action.payload,
            }
        case ACTIONS.SET_OPEN_SELECT_DATE_PICKER:
            return {
                ...state,
                openSelectDatePicker: action.payload,
            }
        case ACTIONS.SET_OPEN_ADD_MENU:
            return {
                ...state,
                openAddMenu: action.payload,
            }
        case ACTIONS.ADD_TRANSPORT_BUTTON_CLICKED:
            return {
                ...state,
                openAddMenu: false,
                clickedRun: {},
                seriesId: 0,
                openNewRunManageModal: true,
            }
        case ACTIONS.ADD_SERIES_BUTTON_CLICKED:
            return {
                ...state,
                openAddMenu: false,
                clickedRun: {},
                seriesId: 0,
                openNewSeriesManageModal: true,
            }
        case ACTIONS.SET_CLICKED_RUN:
            return {
                ...state,
                clickedRun: action.payload,
            }
        case ACTIONS.SET_SERIES_ID:
            return {
                ...state,
                seriesId: action.payload,
            }
        case ACTIONS.SET_INFO_CARD_CLICKED:
            return {
                ...state,
                seriesId: action.payload.seriesId,
                clickedRun: action.payload.run,
                informationRunModalOpen: true,
            }
        case ACTIONS.SET_STATUS_LIST:
            return {
                ...state,
                statusList: action.payload,
            }
        case ACTIONS.SET_TEAMS_CREATED:
            return {
                ...state,
                teamsCreated: action.payload,
            }
        case ACTIONS.SET_SELECTED_TEAM:
            return {
                ...state,
                selectedTeam: action.payload,
            }
        case ACTIONS.SET_SELECTED_UNASSIGNED_RUNS:
            return {
                ...state,
                selectedUnassignedRuns: action.payload,
            }
        case ACTIONS.SET_INFORMATION_RUN_MODAL_OPEN:
            return {
                ...state,
                informationRunModalOpen: action.payload,
            }
        case ACTIONS.SET_SELECTED_TEAM_RUN_SELECTED:
            return {
                ...state,
                selectedTeam: {
                    ...state.selectedTeam,
                    runs: state.selectedTeam.runs.map((run) => {
                        if (run.id === action.payload.id) {
                            return {
                                ...run,
                                selected: action.payload.selected,
                            }
                        } else {
                            return run
                        }
                    }),
                },
            }
        case ACTIONS.OPEN_TEAMS_LIST:
            return {
                ...state,
                openTeamsList: action.payload,
            }

        case ACTIONS.OPEN_GROUP_TEAMS_LIST:
            return {
                ...state,
                openGroupTeamsList: action.payload,
            }

        case ACTIONS.CLOSE_TEAMS_LIST_MODAL:
            return {
                ...state,
                openTeamsList: false,
                openGroupTeamsList: false,
            }
        case ACTIONS.SET_OPEN_GLOBAL_VIEW:
            return {
                ...state,
                openGlobalView: action.payload,
            }
        case ACTIONS.SET_DOWNLOAD_BUTTON_LOADING:
            return {
                ...state,
                downloadButtonLoading: action.payload,
            }
        case ACTIONS.SET_OPEN_TEAMS_MODAL:
            return {
                ...state,
                openTeamsModal: action.payload,
            }
        case ACTIONS.SET_OPEN_CONFIG_MODAL:
            return {
                ...state,
                openConfigModal: action.payload,
            }
        case ACTIONS.RESET_CONFIG:
            localStorage.setItem(
                'showEffectiveHours',
                state.defaultConfig.showEffectiveHours
            )
            localStorage.setItem(
                'showPartners',
                state.defaultConfig.showPartners
            )
            localStorage.setItem(
                'displayVehicleColor',
                state.defaultConfig.displayVehicleColor
            )
            return {
                ...state,
                showEffectiveHours: state.defaultConfig.showEffectiveHours,
                showPartners: state.defaultConfig.showPartners,
                isPatientFilter: state.defaultConfig.isPatientFilter,
                displayVehicleColor: state.defaultConfig.displayVehicleColor,
            }
        case ACTIONS.SET_CONFIG:
            localStorage.setItem(
                'showEffectiveHours',
                action.payload.showEffectiveHours
            )
            localStorage.setItem('showPartners', action.payload.showPartners)
            localStorage.setItem(
                'displayVehicleColor',
                action.payload.displayVehicleColor
            )
            return {
                ...state,
                showEffectiveHours: action.payload.showEffectiveHours,
                showPartners: action.payload.showPartners,
                isPatientFilter: action.payload.isPatientFilter,
                displayVehicleColor: action.payload.displayVehicleColor,
                openConfigModal: false,
            }
        case ACTIONS.CLOSE_MODALS:
            return {
                ...state,
                openCompetitionModal: false,
                informationRunModalOpen: false,
            }
        case ACTIONS.SET_DISABLED_BUTTONS:
            return {
                ...state,
                disabledButtons: action.payload,
            }
        case ACTIONS.SET_IS_LOADING_PLANNING:
            return {
                ...state,
                isLoadingPlanning: action.payload,
            }
        case ACTIONS.SET_PLANNINGS:
            return {
                ...state,
                plannings: action.payload,
                isFirstLoadingPlanning: false,
            }
        case ACTIONS.SET_SHOW_LOCK_MODAL:
            return {
                ...state,
                showLockModal: !state.showLockModal,
            }
        case ACTIONS.SET_PLANNING:
            return {
                ...state,
                isLocked: action.payload?.isLocked,
                planning: action.payload,
            }
        case ACTIONS.SET_IS_FIRST_LOADING_PLANNING:
            return {
                ...state,
                isFirstLoadingPlanning: action.payload,
            }
        default:
            throw new Error('Action non reconnue')
    }
}
