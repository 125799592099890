import React from 'react'

// hook personnalisé qui permet de sauvegarder les dates dans le local storage
export const useStickyDateState = (defaultValue, key) => {
    const [value, setValue] = React.useState(() => {
        const stickyValue = window.localStorage.getItem(key)
        return stickyValue !== null
            ? new Date(stickyValue)
            : new Date(defaultValue)
    })
    React.useEffect(() => {
        window.localStorage.setItem(key, value)
    }, [key, value])
    return [value, setValue]
}

export const useStickyEndDateState = (
    defaultValue,
    startDateKey,
    endDatekey
) => {
    const [value, setValue] = React.useState(() => {
        const stickyStartValue = window.localStorage.getItem(startDateKey)
        const stickyEndValue = window.localStorage.getItem(endDatekey)

        if (stickyEndValue && stickyStartValue) {
            const stickyEndDate = new Date(stickyEndValue)
            const stickyStartDate = new Date(stickyStartValue)
            return stickyEndDate < stickyStartDate
                ? stickyStartDate
                : stickyEndDate
        } else {
            return new Date(defaultValue)
        }
    })
    React.useEffect(() => {
        window.localStorage.setItem(endDatekey, value)
    }, [endDatekey, value])
    return [value, setValue]
}
