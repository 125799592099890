import React, { useEffect } from 'react'
import * as dayjs from 'dayjs'
import { openMaps } from '../../../services/address'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import { Edit } from 'iconoir-react'

const PatientView = ({ history, run }) => {
    return (
        <div className="flex h-full  w-full flex-col space-y-6 py-2 text-lg lg:text-base">
            <div className="flex w-full items-center justify-between py-5 text-gray-700 ">
                <h2 className="text-2xl font-bold">
                    Fiche {run.patient.isPatient ? 'patient' : 'client'}
                </h2>
                <PrimaryButton
                    label="Modifier"
                    title="Modifier"
                    action={() =>
                        history(`/ressources/repertoire/${run.patient.id}`)
                    }
                    hiddenLabelOnMobile={true}
                    icon={<Edit />}
                    hiddenIconOnPc={true}
                />
            </div>
            <div className="flex flex-col lg:flex-row">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">Prénom</label>
                <p>{run.patient.firstname}</p>
            </div>
            <div className="flex flex-col lg:flex-row">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">Nom</label>
                <p className=" ">{run.patient.lastname}</p>
            </div>
            <div className="flex flex-col lg:flex-row">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                    N° de sécu
                </label>
                <p className=" ">{run.patient.socialNumber}</p>
            </div>
            <div className="flex flex-col lg:flex-row">
                {run.patient.patientsAddresses && (
                    <div className="flex w-full flex-col space-y-8">
                        {run.patient.patientsAddresses.map(
                            (savedAddress, key) => (
                                <div
                                    key={key}
                                    onClick={() =>
                                        openMaps(savedAddress.address)
                                    }
                                    className="flex flex-col space-y-1 lg:flex-row lg:items-center"
                                >
                                    <p className="flex w-1/3 font-bold text-gray-600">
                                        {savedAddress.label}
                                    </p>
                                    <p className="cursor-pointer">
                                        {savedAddress.address.street
                                            ? savedAddress.address.street + ', '
                                            : ''}
                                        {savedAddress.address.secondaryStreet
                                            ? savedAddress.address
                                                  .secondaryStreet + ', '
                                            : ''}
                                        <span className="underline">
                                            {savedAddress.address.city}
                                        </span>
                                    </p>
                                </div>
                            )
                        )}
                    </div>
                )}
            </div>
            <div className="flex flex-col lg:flex-row">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                    Téléphone
                </label>
                <p>{run.patient.phoneNumber}</p>
            </div>
            <div className="flex flex-col lg:flex-row">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                    Date de naissance
                </label>
                <p className=" ">
                    {run.patient.dateOfBirth
                        ? dayjs(run.patient.dateOfBirth)
                              .local()
                              .format('DD/MM/YYYY')
                        : null}
                </p>
            </div>
            <div className="flex flex-col lg:flex-row">
                <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                    Description
                </label>
                <p>{run.patient.description}</p>
            </div>
        </div>
    )
}

export default PatientView
