import React, { useState, useEffect } from 'react'
import ScheduleRunsFilter from './ScheduleRunsFilter'
import ScheduleRunsList from './ScheduleRunsList'
import useUserAccessStore from '../../stores/UserAccess'
import { useStickyDateState } from '../../hooks/LocalStorageDatesHooks'
import * as dayjs from 'dayjs'
import API from '../../services/api'
import { SortRunsByGroupAndDepartureTime } from '../../tools/Sort'
import Loader from '../Commons/Loaders/Loader'

const formatData = (runs, assigned = false) => {
    return SortRunsByGroupAndDepartureTime(
        runs.map((run) => {
            const startDate = new Date(run.departureTime)
            const endDate = new Date(run.arrivingTime)
            const predictionArrivingDate = new Date(run.predictionArrivingTime)
            const predictionDepartureDate = new Date(
                run.predictionDepartureTime
            )
            const startMinutes =
                (startDate.getMinutes() < 10 ? '0' : '') +
                startDate.getMinutes()
            const endMinutes =
                (endDate.getMinutes() < 10 ? '0' : '') + endDate.getMinutes()
            const predicitonArrMinutes =
                (predictionArrivingDate.getMinutes() < 10 ? '0' : '') +
                predictionArrivingDate.getMinutes()
            const predicitonDepMinutes =
                (predictionDepartureDate.getMinutes() < 10 ? '0' : '') +
                predictionDepartureDate.getMinutes()
            const startHour = startDate.getHours() + 'h' + startMinutes
            const endHour = endDate.getHours() + 'h' + endMinutes
            const predictionArrHour =
                predictionArrivingDate.getHours() + 'h' + predicitonArrMinutes
            const predictionDepHour =
                predictionDepartureDate.getHours() + 'h' + predicitonDepMinutes

            let patientName = ''
            if (run.patient != null) {
                patientName = `${run.patient.lastname} ${run.patient.firstname}`
            }
            const pickUpAddress = `${
                run.pickUpLocation.street ? run.pickUpLocation.street : ''
            } - ${run.pickUpLocation.city}`
            const depositAddress = `${
                run.depositLocation.street ? run.depositLocation.street : ''
            } - ${run.depositLocation.city}`
            let isUserRun = assigned
            let isNotAssignedRun = false
            if (run.masterRun) {
                isUserRun = run.masterRun.users.some(
                    (u) => u.id === parseInt(localStorage.getItem('id'))
                )
            } else {
                isNotAssignedRun = true
            }
            const firstUser = run.masterRun?.users[0]
            const firstUserFullName = `${firstUser?.firstname} ${firstUser?.lastname}`
            const firstUserInitial =
                firstUser?.firstname.charAt(0) + firstUser?.lastname.charAt(0)
            return {
                ...run,
                fullname: patientName,
                firstUserInitial: firstUserInitial,
                firstUserFullName: firstUserFullName,
                heure: startHour + ' - ' + endHour,
                startHour: startHour,
                endHour: endHour,
                predictionArrHour: predictionArrHour,
                predictionDepHour: predictionDepHour,
                pickUpAddress: pickUpAddress,
                depositAddress: depositAddress,
                isUserRun: isUserRun,
                isNotAssignedRun: isNotAssignedRun,
            }
        })
    )
}

const ScheduleRunsContainer = () => {
    const setOnlyUserRuns = useUserAccessStore((state) => state.setOnlyUserRuns)
    const onlyUserRuns = useUserAccessStore((state) => state.onlyUserRuns)

    const setShowUnassignedRuns = useUserAccessStore(
        (state) => state.setShowUnassignedRuns
    )
    const showUnassignedRuns = useUserAccessStore(
        (state) => state.showUnassignedRuns
    )

    const [date, setDate] = useStickyDateState(
        new Date(dayjs().add(1, 'day')),
        'storedDate'
    )
    const [loading, setLoading] = useState(true)
    const [rows, setRows] = useState([])
    const [userColor, setUserColor] = useState('#4299E1')
    const [uniqueMasterRuns, setUniqueMasterRuns] = useState([])
    const [authorizedShowAllRuns, setAuthorizedShowAllRuns] = useState(false)
    const [authorizedShowUnassignedRuns, setAuthorizedShowUnassignedRuns] =
        useState(false)
    const [hideFuturPlanningDaysForUsers, setHideFuturPlanningDaysForUsers] =
        useState(false)

    useEffect(() => {
        fetchParameters()
    }, [])

    const fetchParameters = () => {
        API.Users.get(localStorage.getItem('id')).then((response) => {
            response.json().then((userData) => {
                API.Parameter.get(localStorage.getItem('society')).then(
                    (response) => {
                        response.json().then((parametersData) => {
                            if (!userData.hasScheduleConfiguration) {
                                setAuthorizedShowAllRuns(
                                    parametersData.usersShowAllRuns
                                )
                                setAuthorizedShowUnassignedRuns(
                                    parametersData.usersShowUnassignedRuns
                                )
                            } else {
                                setAuthorizedShowAllRuns(
                                    userData.canViewAllRuns
                                )
                                setAuthorizedShowUnassignedRuns(
                                    userData.canViewUnassignedRuns
                                )
                                setHideFuturPlanningDaysForUsers(
                                    userData.hideFuturPlanningDaysForUser
                                )
                            }

                            if (userData.hideFuturPlanningDaysForUser) {
                                setHideFuturPlanningDaysForUsers(
                                    userData.hideFuturPlanningDaysForUser
                                )
                            } else {
                                setHideFuturPlanningDaysForUsers(
                                    parametersData.hideFuturPlanningDaysForUsers
                                )
                            }
                        })
                    }
                )
            })
        })
    }

    const fetchRuns = () => {
        setLoading(true)
        if (onlyUserRuns) {
            API.MasterRuns.list(
                null,
                null,
                null,
                null,
                null,
                date,
                localStorage.getItem('id')
            ).then((data) => {
                let runs = []
                const masterRuns = data['hydra:member']
                if (masterRuns.length > 0) {
                    masterRuns.forEach((m) => {
                        runs = runs.concat(m.runs)
                    })
                    if (!authorizedShowAllRuns && showUnassignedRuns) {
                        API.Runs.list(
                            null,
                            date,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            false,
                            false,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            false
                        ).then((notAssignedRuns) => {
                            let unassigned = notAssignedRuns['hydra:member']

                            setRows([
                                ...formatData(runs, true),
                                ...formatData(unassigned),
                            ])
                            setLoading(false)
                        })
                    } else {
                        setRows(formatData(runs, true))
                    }
                } else {
                    setRows([])
                }
                setLoading(false)
            })
        } else if (!authorizedShowAllRuns && showUnassignedRuns) {
            API.Runs.list(
                null,
                date,
                null,
                null,
                null,
                null,
                null,
                null,
                false,
                false,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                false
            ).then((notAssignedRuns) => {
                let runs = notAssignedRuns['hydra:member']
                setRows(formatData(runs))
                setLoading(false)
            })
        } else {
            //show all runs
            API.Runs.list(
                null,
                date,
                null,
                null,
                null,
                null,
                null,
                null,
                true,
                false,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                false
            ).then((data) => {
                let runs = data['hydra:member']
                API.Runs.list(
                    null,
                    date,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    false,
                    false,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    false
                ).then((notAssignedRuns) => {
                    let uniqueMasterRunsReduce = Object.values(
                        runs.reduce((acc, run) => {
                            const { id } = run.masterRun
                            if (!acc[id]) {
                                acc[id] = run.masterRun
                            }
                            return acc
                        }, {})
                    )
                    setUniqueMasterRuns(uniqueMasterRunsReduce)
                    runs = runs.concat(notAssignedRuns['hydra:member'])
                    runs = formatData(runs)
                    setRows(runs)

                    if (runs.length > 0) {
                        const userRun = runs.find((run) =>
                            run.masterRun?.users.find(
                                (user) =>
                                    user.id ===
                                    parseInt(localStorage.getItem('id'))
                            )
                        )
                        if (userRun) {
                            setUserColor(userRun.masterRun.vehicle.color)
                        }
                    }
                })
            })
            setLoading(false)
        }
    }

    const isHidedPlanning = () => {
        if (hideFuturPlanningDaysForUsers) {
            const dateNow = dayjs(new Date())
            const diff = dayjs(date).diff(dateNow, 'day', true)
            if (diff > 1) {
                return true
            }
            if (diff > 0 && diff < 1 && dayjs(dateNow).hour() < 18) {
                //before 18h, driver can't see the planning for next day
                return true
            }
            return false
        }
        return false
    }

    useEffect(() => {
        if (!isHidedPlanning()) {
            fetchRuns()
        }
    }, [date, onlyUserRuns, showUnassignedRuns])

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    <ScheduleRunsFilter
                        onlyUserRuns={onlyUserRuns}
                        setOnlyUserRuns={setOnlyUserRuns}
                        date={date}
                        setDate={setDate}
                        rows={rows}
                        authorizedShowAllRuns={authorizedShowAllRuns}
                        showUnassignedRuns={showUnassignedRuns}
                        authorizedShowUnassignedRuns={
                            authorizedShowUnassignedRuns
                        }
                        setShowUnassignedRuns={setShowUnassignedRuns}
                    />
                    <ScheduleRunsList
                        onlyUserRuns={onlyUserRuns}
                        showUnassignedRun={showUnassignedRuns}
                        rows={rows}
                        userColor={userColor}
                        uniqueMasterRuns={uniqueMasterRuns}
                        loading={loading}
                        isHidedPlanning={isHidedPlanning}
                    />
                </div>
            )}
        </div>
    )
}

export default ScheduleRunsContainer
