import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import MobileConfiguratorDisplayed from '../../../Commons/MobileConfiguratorDisplayed'
import ComputerConfiguratorDisplayed from '../../../Commons/ComputerConfiguratorDisplayed'
import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import { Erase } from 'iconoir-react'

const Configurator = ({
    fakeRow,
    configuratorElements,
    setConfiguratorElements,
    resetConfigurator,
    previewLoading,
    setPreviewLoading,
    configuratorName,
}) => {
    const onDragStart = (result) => {
        setPreviewLoading(true)
    }

    const onDragEnd = (result) => {
        const { destination, source, draggableId } = result
        if (!destination) {
            return
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return
        }

        const newElementIds = Array.from(configuratorElements.elementIds)
        newElementIds.splice(source.index, 1)
        newElementIds.splice(destination.index, 0, draggableId)

        const newState = {
            ...configuratorElements,
            elementIds: newElementIds,
        }
        setPreviewLoading(false)
        setConfiguratorElements(newState)
    }

    const handleIsCheckedChange = (key, checked) => {
        let newElementIds = configuratorElements.elementIds

        let newConfiguratorElements = configuratorElements.elements
        newConfiguratorElements[key].isChecked = checked

        if (checked === false) {
            newElementIds = newElementIds.filter((el) => el !== key)
        } else {
            newElementIds.push(key)
            if (configuratorName === 'computer') {
                if (key === 'timesOnly') {
                    newElementIds = newElementIds.filter(
                        (el) => el !== 'timesAndTimeValidation'
                    )
                    newConfiguratorElements[
                        'timesAndTimeValidation'
                    ].isChecked = false
                } else if (key === 'timesAndTimeValidation') {
                    newElementIds = newElementIds.filter(
                        (el) => el !== 'timesOnly'
                    )
                    newConfiguratorElements['timesOnly'].isChecked = false
                } else if (key === 'objectAndDateSecondFormat') {
                    newElementIds = newElementIds.filter(
                        (el) => el !== 'objectAndDateFirstFormat'
                    )
                    newConfiguratorElements[
                        'objectAndDateFirstFormat'
                    ].isChecked = false
                } else if (key === 'objectAndDateFirstFormat') {
                    newElementIds = newElementIds.filter(
                        (el) => el !== 'objectAndDateSecondFormat'
                    )
                    newConfiguratorElements[
                        'objectAndDateSecondFormat'
                    ].isChecked = false
                }
            } else {
                if (key === 'timesOnly') {
                    newElementIds = newElementIds.filter(
                        (el) => el !== 'timesAndTimeValidation'
                    )
                    newConfiguratorElements[
                        'timesAndTimeValidation'
                    ].isChecked = false
                } else if (key === 'timesAndTimeValidation') {
                    newElementIds = newElementIds.filter(
                        (el) => el !== 'timesOnly'
                    )
                    newConfiguratorElements['timesOnly'].isChecked = false
                } else if (key === 'dateSecondFormat') {
                    newElementIds = newElementIds.filter(
                        (el) => el !== 'dateFirstFormat'
                    )
                    newConfiguratorElements['dateFirstFormat'].isChecked = false
                } else if (key === 'dateFirstFormat') {
                    newElementIds = newElementIds.filter(
                        (el) => el !== 'dateSecondFormat'
                    )
                    newConfiguratorElements[
                        'dateSecondFormat'
                    ].isChecked = false
                }
            }
        }

        const newState = {
            ...configuratorElements,
            elements: {
                ...newConfiguratorElements,
            },
            elementIds: newElementIds,
        }
        setConfiguratorElements(newState)
    }

    return (
        <div>
            <div className="flex justify-end pb-6 pr-4 lg:pb-10">
                <SecondaryButton
                    label="Réinitialiser"
                    title="Réinitialiser"
                    icon={<Erase />}
                    action={() => resetConfigurator(configuratorName)}
                />
            </div>
            <div className="flex flex-col pb-6 lg:flex-row lg:pb-20">
                <div className="lg:w-1/2">
                    <div className="pb-6 text-xl  font-bold">
                        Informations à afficher :
                    </div>
                    <div className="">
                        <div className="mr-4 h-64 max-h-64 overflow-y-scroll pb-10 pr-4">
                            <DragDropContext
                                onDragEnd={onDragEnd}
                                onDragStart={onDragStart}
                            >
                                <Droppable
                                    droppableId="column-1"
                                    key="column-1"
                                >
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            className="flex flex-col space-y-2"
                                        >
                                            {configuratorElements.elementIds.map(
                                                (elementId, index) => (
                                                    <Draggable
                                                        draggableId={
                                                            configuratorElements
                                                                .elements[
                                                                elementId
                                                            ].id
                                                        }
                                                        index={index}
                                                        key={
                                                            configuratorElements
                                                                .elements[
                                                                elementId
                                                            ].id
                                                        }
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                                className="flex h-8 justify-between rounded border bg-white"
                                                            >
                                                                <div className="flex items-center space-x-2 pl-4">
                                                                    <i className="fas fa-arrows-alt" />
                                                                    <div>
                                                                        {index +
                                                                            1}
                                                                        -
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            configuratorElements
                                                                                .elements[
                                                                                elementId
                                                                            ]
                                                                                .content
                                                                        }
                                                                    </div>
                                                                    {configuratorElements
                                                                        .elements[
                                                                        elementId
                                                                    ]
                                                                        .description && (
                                                                        <i
                                                                            className="far fa-question-circle fa-lg cursor-pointer pr-2"
                                                                            title={
                                                                                configuratorElements
                                                                                    .elements[
                                                                                    elementId
                                                                                ]
                                                                                    .description
                                                                            }
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="flex items-center">
                                                                    <i
                                                                        className="fas fa-lg fa-minus-circle cursor-pointer text-red-600"
                                                                        onClick={() =>
                                                                            handleIsCheckedChange(
                                                                                configuratorElements
                                                                                    .elements[
                                                                                    elementId
                                                                                ]
                                                                                    .id,
                                                                                false
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            )}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                </div>
                <div className="pt-4 lg:w-1/2 lg:pt-0">
                    <div className="pb-6 text-xl font-bold">
                        Informations non affichées :
                    </div>
                    <div className="mr-4 h-64 max-h-64 overflow-hidden overflow-y-scroll pr-4">
                        {Object.entries(configuratorElements.elements).map(
                            ([key, element]) => {
                                if (!element.isChecked) {
                                    return (
                                        <div className="mb-2 flex h-8 justify-between rounded border bg-white">
                                            <div className="flex items-center space-x-2 pl-4">
                                                <div>{element.content}</div>
                                                {element.description && (
                                                    <i
                                                        className="far fa-question-circle fa-lg cursor-pointer pr-2"
                                                        title={
                                                            element.description
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <div className="flex items-center">
                                                <i
                                                    className="fas fa-lg fa-plus-circle cursor-pointer text-green-600"
                                                    onClick={() =>
                                                        handleIsCheckedChange(
                                                            element.id,
                                                            true
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            }
                        )}
                    </div>
                </div>
            </div>
            <div>
                <div className="pb-6 text-xl font-bold ">
                    Prévisualisation :
                </div>
                <div
                    className={`grid w-full select-none rounded border-l-4 border-green-500 bg-white shadow-mobile lg:select-auto lg:border-l-8 ${
                        configuratorName === 'mobile' && 'w-mobile'
                    }`}
                >
                    <div
                        className={`row-span-1 flex grid-rows-1 flex-wrap items-center justify-between px-2 py-1 text-base ${
                            configuratorName === 'mobile'
                                ? 'min-h-5-3'
                                : 'min-h-2-7'
                        } parents`}
                    >
                        {previewLoading ? (
                            <div className="flex w-full justify-center">
                                <svg
                                    className="h-5 w-5 animate-spin "
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                            </div>
                        ) : configuratorName === 'computer' ? (
                            Object.entries(configuratorElements.elementIds).map(
                                ([key, id]) => (
                                    <ComputerConfiguratorDisplayed
                                        row={fakeRow}
                                        value={id}
                                        key={key}
                                    />
                                )
                            )
                        ) : (
                            Object.entries(configuratorElements.elementIds).map(
                                ([key, id]) => (
                                    <MobileConfiguratorDisplayed
                                        row={fakeRow}
                                        value={id}
                                        key={key}
                                    />
                                )
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Configurator
