import ActionModal from '../../Modal/ActionModal'
import React from 'react'
import RUN from '../../../services/run'
import { toast } from 'react-toastify'
import PatientFormContent from '../Patient/PatientFormContent'
import { TRANSPORT_ACTIONS } from './Reducers/TransportFormReducer'
import { handlePatientForm } from '../Patient/handlePatientForm'
import { PATIENT_FORM_ACTIONS } from './Reducers/PatientFormReducer'
import { MODAL_FORM_ACTIONS } from './Reducers/ModalReducer'
import ModalSkeleton from '../../Modal/ModalSkeleton'
const RunsModals = ({
    run,
    date,
    modal,
    dateDispatch,
    patientForm,
    dispatch,
    handleForm,
    disabledSubmit,
}) => {
    const handleErrors = (violations) => {
        toast.error(violations['hydra:description'])
    }

    const handleRemoveShare = () => {
        RUN.RemoveShare(run, handleErrors)
    }

    const handleCandelModal = () => {
        dispatch({
            type: TRANSPORT_ACTIONS.SHOW_PATIENT_MODAL,
            payload: false,
        })
    }

    const handlePatientModalForm = async () => {
        console.log('tqwrwqdqwdqw')
        const formResponse = await handlePatientForm(patientForm, date)
        if (formResponse.success) {
            // Mettez à jour l'état du patient avec le reducer
            // mettre à jour le patient pour le patient form avec le nouvel ID si creation
            dispatch({
                type: PATIENT_FORM_ACTIONS.GET_PATIENT,
                payload: formResponse.data,
            })
            dispatch({
                type: TRANSPORT_ACTIONS.UPDATE_PATIENT,
                payload: formResponse.data,
            })
            handleCandelModal()
        } else {
            console.log('error')
            dispatch({
                type: PATIENT_FORM_ACTIONS.GET_PATIENT,
                payload: patientForm,
            })
        }
        console.log('formResponse', formResponse)
    }

    return (
        <>
            {modal.modalOpen && (
                <ActionModal
                    setModalOpen={() =>
                        dispatch({ type: MODAL_FORM_ACTIONS.CLOSE_MODAL })
                    }
                    action={
                        modal.actionButton === 'Retirer'
                            ? handleRemoveShare
                            : handleForm
                    }
                    actionButton={modal.actionButton}
                    label={modal.label}
                    labelAction={modal.labelAction}
                />
            )}
            {modal.modalOpenArToA && (
                <ActionModal
                    setModalOpen={() =>
                        dispatch({ type: MODAL_FORM_ACTIONS.CLOSE_MODAL })
                    }
                    action={handleForm}
                    actionButton={modal.actionButton}
                    label={modal.label}
                    labelAction={modal.labelAction}
                    secondaryAction={() => handleForm(true)}
                    secondaryActionButton="Le laisser"
                />
            )}
            {run.showPatientModal && (
                <ModalSkeleton
                    label={`${
                        patientForm.id ? 'Modifier' : 'Ajouter'
                    } un patient`}
                    componentProps={{
                        patient: patientForm,
                        dispatch,
                        date,
                        dateDispatch,
                        fromModal: true,
                    }}
                    Component={PatientFormContent}
                    handleForm={handlePatientModalForm}
                    handleCloseModal={handleCandelModal}
                    disabledSubmit={disabledSubmit}
                />
            )}
        </>
    )
}

export default RunsModals
