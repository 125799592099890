import SelectDropDownListV2 from '../../../Commons/DropDownLists/SelectDropDownListV2'
import ClassicTextarea from '../../../Commons/Inputs/ClassicTextarea'
import SharingOptions from '../../../../services/SharingOptions'
import DeleteButton from '../../../Commons/Buttons/DeleteButton'
import { formatName } from '../../../../tools/Utility'
import { TRANSPORT_ACTIONS } from '../Reducers/TransportFormReducer'

const ShareForm = ({ run, dispatch, handleRemoveShare }) => {
    return (
        <div className="flex flex-col space-y-4">
            <h1 className="py-10 pl-4 text-2xl font-semibold lg:pl-0">
                Échangez des transports avec vos partenaires
            </h1>
            <div
                className={`min-h-row-date flex w-full flex-wrap items-center space-y-5 rounded-xl bg-white p-2 pb-10 pt-10 shadow-md lg:space-y-5 lg:pt-5`}
            >
                <div className="min-h-row-date flex w-full flex-col items-center space-y-5 px-2 lg:flex-row lg:space-y-0 lg:px-5">
                    <div className="flex w-full items-center lg:w-1/2 lg:pr-5">
                        <div className="flex w-full items-center">
                            <SelectDropDownListV2
                                label={'Partenaire'}
                                showLabel={true}
                                icon={'fa-hands-helping'}
                                value={formatName(run.partner)}
                                options={run.partners}
                                handleOptionChange={(e) =>
                                    dispatch({
                                        type: TRANSPORT_ACTIONS.SET_PARTNER,
                                        payload: e,
                                    })
                                }
                                isDisabled={
                                    run.isRoundTripReturn || !!run.sharedAt
                                }
                                isClearable={!run.sharedAt}
                                isSearchable={true}
                            />
                        </div>
                    </div>
                    <div className="flex w-full items-center justify-center lg:w-1/2 lg:pl-5">
                        <div className="flex w-full items-center  justify-center space-x-6 pt-5">
                            <div className="pr-10">
                                <label htmlFor="donner" className="pr-2">
                                    Transport à donner
                                </label>
                                <input
                                    type="radio"
                                    id="donner"
                                    value="donner"
                                    name="donner"
                                    checked={run.isGiven}
                                    onChange={(e) =>
                                        dispatch({
                                            type: TRANSPORT_ACTIONS.IS_GIVEN_BY_PARTNER,
                                            payload: true,
                                        })
                                    }
                                    disabled={!!run.sharedAt}
                                />
                            </div>
                            <div>
                                <label htmlFor="recevoir" className="pr-2">
                                    Transport reçu
                                </label>
                                <input
                                    type="radio"
                                    id="recevoir"
                                    value="recevoir"
                                    name="recevoir"
                                    checked={!run.isGiven}
                                    onChange={(e) =>
                                        dispatch({
                                            type: TRANSPORT_ACTIONS.IS_GIVEN_BY_PARTNER,
                                            payload: false,
                                        })
                                    }
                                    disabled={!!run.sharedAt}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-h-row-date flex w-full flex-col space-y-5 px-2 py-5 lg:flex-row lg:space-y-0 lg:px-5">
                    <div className="flex w-full lg:w-1/2  lg:pr-5">
                        <div className="flex w-full ">
                            <SelectDropDownListV2
                                label={'Methode de partage'}
                                showLabel={true}
                                icon={'fa-paper-plane'}
                                value={run.sharingOption}
                                options={SharingOptions}
                                handleOptionChange={(e) =>
                                    dispatch({
                                        type: TRANSPORT_ACTIONS.SET_SHARING_OPTION,
                                        payload: e,
                                    })
                                }
                                isDisabled={
                                    run.isRoundTripReturn ||
                                    !!run.sharedAt ||
                                    !run.isGiven
                                }
                            />
                        </div>
                    </div>
                    <div className="flex w-full pt-5 lg:w-1/2 lg:pl-5 lg:pt-0">
                        <div className="flex w-full ">
                            <ClassicTextarea
                                setValue={(e) =>
                                    dispatch({
                                        type: TRANSPORT_ACTIONS.SET_NOTE,
                                        payload: e.target.value,
                                    })
                                }
                                value={run.note}
                                label={'Note de partage'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex w-full items-center justify-between px-2 py-5 lg:px-5">
                {run.partner && run.sharedAt ? (
                    <DeleteButton
                        label="Retirer le partage"
                        title="Retirer le partage"
                        action={handleRemoveShare}
                    />
                ) : null}
            </div>
        </div>
    )
}

export default ShareForm
