import * as dayjs from 'dayjs'
import { apiClient, withErrorHandling } from '../apiClient'
import { concat, formatHydraData } from '../../api'

const PATH_KEY = '/regulation'

export const Regulation = {
    fetchMasterRunsUnassignedRunsAndPartnersRuns: withErrorHandling(
        async (props) => {
            const { date, isPatient } = props
            const formattedDate = dayjs(date).utc().local().format('YYYY-MM-DD')
            let path = PATH_KEY + `?date=${formattedDate}`
            if (isPatient !== null)
                path = concat(path, `isPatient=${isPatient}`)

            return formatHydraData(await apiClient.get(path))
        }
    ),
    checkIfMasterRunsAreCreated: withErrorHandling(async (date) => {
        const formattedDate = dayjs(date).utc().local().format('YYYY-MM-DD')
        const path = `${PATH_KEY}/checkMasterRuns?date=${formattedDate}`

        return formatHydraData(await apiClient.get(path))
    }),
    addRunsToMasterRun: withErrorHandling(async (props) => {
        const { id, data } = props
        const path = `${PATH_KEY}/add_run_to_master_run/${id}`

        return formatHydraData(await apiClient.put(path, data))
    }),
    unassignedRunsFromMasterRun: withErrorHandling(async (props) => {
        const { id, data } = props
        const path = `${PATH_KEY}/unassigned_runs/${id}`

        return formatHydraData(await apiClient.put(path, data))
    }),
    groupMasterRunRuns: withErrorHandling(async (props) => {
        const { id, data } = props
        const path = `${PATH_KEY}/group_master_run_runs/${id}`

        return formatHydraData(await apiClient.put(path, data))
    }),
}
