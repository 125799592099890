import { findCurrentPriceBasis } from '../../../../services/InvoiceUtils'
import { toast } from 'react-toastify'
import API from '../../../../services/api'
import { getNormalDateFormatForApi } from '../../../../services/DateService'

export const putOrPostSeriesInvoiceForm = async (
    prescription,
    patientForm,
    series,
    seriesCpamInvoiceForm,
    seriesId
) => {
    const priceBasis = !seriesCpamInvoiceForm.priceBasis
        ? findCurrentPriceBasis(series.priceBases, series.firstRunDate)
        : seriesCpamInvoiceForm.priceBasis
    const { id } = seriesCpamInvoiceForm
    const basePath = '/runs/'

    // get the runId inside the runsInvoiceFormsDetails and spread
    const runs = seriesCpamInvoiceForm.runsInvoiceFormsDetails.map((detail) => {
        const runInvoiceDetails = {
            ...detail,
            priceBasis: detail.priceBasis['@id'],
        }
        const totalAmount = parseFloat(detail.totalAmount.toFixed(2))
        const totalBeforeAbatement = parseFloat(
            detail.totalBeforeAbatement?.toFixed(2)
        )

        const abatementAmount = totalAmount - totalBeforeAbatement
        return {
            ['@id']: basePath + detail.runId,
            prescription: prescription['@id'],
            runInvoiceDetails: {
                ...runInvoiceDetails,
                abatement: detail.abatement ? detail.abatement.value : null,
                abatementAmount: Math.abs(
                    detail.totalBeforeAbatement > 0 ? abatementAmount : null
                ),
                minimumPerceptionAmount: parseFloat(
                    detail.minimumPerceptionAmount
                ),
                nbPeople: detail.nbPeople ? parseInt(detail.nbPeople) : 1,
                totalDiscount: parseFloat(
                    parseFloat(Math.abs(detail.totalDiscount)).toFixed(2)
                ),
                kilometerA: parseFloat(detail.kilometerA),
                kilometerB: parseFloat(detail.kilometerB),
                kilometerC: parseFloat(detail.kilometerC),
                kilometerD: parseFloat(detail.kilometerD),
            },
        }
    })

    const kilometerTypes = ['A', 'B', 'C', 'D']
    const kilometers = {}

    kilometerTypes.forEach((type) => {
        kilometers[`kilometer${type}`] = runs.reduce((acc, run) => {
            const detail = run.runInvoiceDetails.details.find(
                (detail) => detail.tarifType === type
            )
            return acc + (detail ? detail.kilometer : 0)
        }, 0)
    })

    let result = { success: false, data: null }
    const dataInvoiceForm = {
        payingCenter: patientForm.payingCenter['@id'],
        patient: patientForm['@id'],
        runObject: series.runObject['@id'],
        prescription: prescription['@id'],
        healthComplementary:
            patientForm.healthComplementary && prescription.rate.value !== '100'
                ? patientForm.healthComplementary['@id']
                : null,
        totalInvoiceAmount: parseFloat(
            seriesCpamInvoiceForm.totals.totalAmount.toFixed(2)
        ),
        payingCenterAmount: parseFloat(
            seriesCpamInvoiceForm.totals.totalAmo.toFixed(2)
        ),
        totalRefundBase: parseFloat(
            seriesCpamInvoiceForm.totals.totalAmount.toFixed(2)
        ),
        healthComplementaryAmount: parseFloat(
            seriesCpamInvoiceForm.totals.totalAmc.toFixed(2)
        ),
        tarifPC: parseFloat(
            seriesCpamInvoiceForm.totals.totalPecAmount.toFixed(2)
        ),
        series: '/series/' + seriesId,
        priceBasis: '/price_bases/' + priceBasis.id,
        runs: runs,
        kilometerA: parseFloat(kilometers.kilometerA),
        kilometerB: parseFloat(kilometers.kilometerB),
        kilometerC: parseFloat(kilometers.kilometerC),
        kilometerD: parseFloat(kilometers.kilometerD),
        date: getNormalDateFormatForApi(seriesCpamInvoiceForm.date),
    }
    let msg = ''
    try {
        let response
        if (id) {
            response = await API.CpamInvoice.put(id, dataInvoiceForm)
            msg = 'Facture modifiée avec succès'
        } else {
            response = await API.CpamInvoice.post(dataInvoiceForm)
            msg = 'Facture ajoutée avec succès'
        }
        toast.success(msg)
        result.success = true
        result.data = response
    } catch (error) {
        if (error) {
            error = error['hydra:description']
        } else {
            toast.error('Erreur, veuillez contacter un administrateur')
        }
        result.success = false
        result.data = error
    }

    return result
}

export const updateStatusCounters = (status, counters) => {
    switch (status) {
        case 'done':
            counters.doneCount++
            break
        case 'transmitted':
            counters.nbTransmitted++
            break
        case 'charged':
            counters.nbCharged++
            break
        case 'closed':
            counters.nbCharged++
            break
        // Add other cases as needed
        default:
            counters.todoCount++
    }
}

export const countStatus = (data, startDate = null, endDate = null) => {
    const counts = data.reduce(
        (acc, item) => {
            // Optionally filter by date range

            // Update counters based on item status
            updateStatusCounters(item.status, acc)
            if (item.isRoundTrip) {
                updateStatusCounters(item.returnStatus, acc)
            }
            // Update firstDoneAt and lastDoneAt for 'done' status
            if (item.status === 'done' && !item.runSharedAndGiven) {
                acc.firstDoneAt = acc.firstDoneAt || item.date
                acc.lastDoneAt = item.date
            }

            if (item.returnStatus === 'done' && !item.returnRunSharedAndGiven) {
                acc.firstDoneAt = acc.firstDoneAt || item.date
                acc.lastDoneAt = item.date
            }

            return acc
        },
        {
            todoCount: 0,
            doneCount: 0,
            firstDoneAt: null,
            lastDoneAt: null,
            nbTransmitted: 0,
            nbCharged: 0,
            // Initialize other counters as needed
        }
    )

    // Second pass: count items within the firstDoneAt and lastDoneAt interval
    if (counts.firstDoneAt && counts.lastDoneAt) {
        const firstDate = startDate ? startDate : new Date(counts.firstDoneAt)
        const lastDate = endDate ? endDate : new Date(counts.lastDoneAt)

        counts.runsInInterval = data.filter((item) => {
            const itemDate = new Date(item.date)
            return (
                itemDate >= firstDate &&
                itemDate <= lastDate &&
                item.status === 'done' &&
                !item.runSharedAndGiven
            )
        })

        counts.counter = counts.runsInInterval.length
        // if item have directReturn and the return is done add the same item 2 times
        counts.runsInInterval.forEach((item) => {
            if (item.returnStatus === 'done' && !item.returnRunSharedAndGiven) {
                console.log(!item.returnRunSharedAndGiven, 'item')
                counts.counter++
            }
        })

        // gérer le cas ou l'aller a est donné mais que le retour rentre dans la condition de facturation
        let aloneReturn = data.filter((item) => {
            const itemDate = new Date(item.date)
            return (
                itemDate >= firstDate &&
                itemDate <= lastDate &&
                item.runSharedAndGiven &&
                item.returnStatus === 'done' &&
                !item.returnRunSharedAndGiven
            )
        })

        counts.counter += aloneReturn.length

        counts.runsInInterval.push(...aloneReturn)
    } else {
        counts.runsInInterval = 0
    }

    return counts
}
