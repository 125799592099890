import React from 'react'
import { InfoEmpty } from 'iconoir-react'
import classNames from 'classnames'
import { RunObjectColors } from '../../../../../services/RunObjectColors'

// MOBILE
const SortedRunCard = ({
    run,
    onCardClicked,
    onInfoClicked,
    fromGlobalView = false,
    showRunObjectColors,
}) => {
    return (
        <div
            className={classNames(
                `sorted-run-car-grid grid rounded-lg border-l-4 shadow-lg ${run.statusBorderColor}`,
                {
                    stripeBg: run.partner && run.isGiven,
                    stripeBg_canceled:
                        run.status.id === 'canceled' && !run.selected,
                    'bg-white text-gray-800':
                        (!run.selected && !showRunObjectColors) ||
                        fromGlobalView,
                    'bg-light-dark-green text-white':
                        run.selected && !fromGlobalView,
                    [`${RunObjectColors[run.runObject.color]}`]:
                        showRunObjectColors && !run.selected,
                }
            )}
        >
            <div
                className="col-span-1 flex flex-row items-center py-4"
                onClick={() => {
                    if (!fromGlobalView) {
                        onCardClicked(run)
                    }
                }}
            >
                <div className="flex w-full flex-row">
                    <div className="flex w-2/5 flex-col pl-4 text-lg font-bold">
                        <div className="">{run.departureTimeHour}</div>
                        <div className="pl-4">-</div>
                        <div className="">{run.arrivingTimeHour}</div>
                    </div>
                    <span className="flex w-3/5 items-center truncate font-bold">
                        {run.patientName}
                    </span>
                </div>
            </div>
            {!fromGlobalView && (
                <div
                    className="col-span-1 flex w-full items-center justify-center py-6 pr-4"
                    onClick={() => {
                        onInfoClicked(run)
                    }}
                >
                    <div className="text-3xl">
                        <InfoEmpty />
                    </div>
                </div>
            )}
        </div>
    )
}

export default SortedRunCard
