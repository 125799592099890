import DoughnutChart from '../../../../Commons/Charts/DoughnutChart'
import React, { useEffect, useState } from 'react'
import CalendarDatePicker from '../../../../Commons/CalendarDatePickers/CalendarDatePicker'
import { ArrowRight } from 'iconoir-react'
import PrimaryButton from '../../../../Commons/Buttons/PrimaryButton'
import ModalSkeleton from '../../../../Modal/ModalSkeleton'
import { SERIES_CPAM_INVOICE_FORM_ACTION } from '../SeriesCpamInvoiceFormReducer'
import SeriesCpamInvoiceFormModal from './SeriesCpamInvoiceFormModal'
import RUN from '../../../../../services/run'
import * as dayjs from 'dayjs'
import {
    findCurrentPriceBasis,
    getTotalsFromInvoiceFormDetails,
    RenderInvoiceId,
    getRunInvoiceFormDetailsFromRuns,
    updateCalculatedPriceFromRunInvoiceDetailsForms,
} from '../../../../../services/InvoiceUtils'
import { formatEuro } from '../../../Invoice/Manage'
import { countStatus, putOrPostSeriesInvoiceForm } from '../seriesUtils'
import { findBorderByStatusType } from '../../../Invoice/Cpam/InvoiceCpamListComponent'
import { toast } from 'react-toastify'
import { isRunAbleToBeInvoicedPrompt } from '../../../../../services/fetchRunsInformations'
import { checkIfSerieIsAbleToBeInvoiced } from '../../../Regulation/FormHandlers/handleInvoiceForm'
import API from '../../../../../services/api'

const SeriesCpamInvoiceForm = ({
    series,
    prescription,
    patientForm,
    seriesCpamInvoiceForm,
    dispatch,
    prsDate,
    dateMaternity,
    workAccidentDate,
    priorApprovalDate,
}) => {
    const { priceBases, cpamInvoices, id } = series
    cpamInvoices.sort((a, b) => {
        return a.cpamInvoiceId - b.cpamInvoiceId
    })

    const labels = ['Faits', 'Autres', 'Transmis', 'Facturés']
    const backgroundColor = ['#52b72e', '#afafaf', '#E879F9', '#bd6633']
    const hoverBackgroundColor = ['#52b72e', '#afafaf', '#E879F9', '#bd6633']
    const cutout = '70%'

    // Initial calculation to get firstDoneAt and lastDoneAt
    const initialCounts = countStatus(series.runsToInsert)

    const [openSelectStartDatePicker, setOpenSelectStartDatePicker] =
        useState(false)
    const [openSelectEndDatePicker, setOpenSelectEndDatePicker] =
        useState(false)
    const [startDate, setStartDate] = useState(
        new Date(
            initialCounts.firstDoneAt
                ? initialCounts.firstDoneAt
                : series.firstRunDate
        )
    )
    const [endDate, setEndDate] = useState(
        new Date(
            initialCounts.lastDoneAt
                ? initialCounts.lastDoneAt
                : series.lastRunDate
        )
    )
    const [countData, setCountData] = useState(initialCounts)
    const {
        todoCount,
        doneCount,
        runsInInterval,
        nbTransmitted,
        nbCharged,
        counter,
    } = countData
    const data = [doneCount, todoCount, nbTransmitted, nbCharged]
    const statsLabels = ['Faits', 'Autres', 'Transmis', 'Facturés']

    useEffect(() => {
        // Recalculate counts whenever startDate, endDate, or runsToInsert changes
        const updatedCounts = countStatus(
            series.runsToInsert,
            startDate,
            endDate
        )
        setCountData(updatedCounts)
    }, [series.runsToInsert, startDate, endDate])

    const handleShowModal = () => {
        dispatch({
            type: SERIES_CPAM_INVOICE_FORM_ACTION.SHOW_MODAL,
        })
    }

    const checkIfRunSelectedHaveSamePriceBasis = (
        priceBases,
        runsInInterval
    ) => {
        const [firstPriceBasisId, ...restPriceBasisIds] = runsInInterval.map(
            (run) => findCurrentPriceBasis(priceBases, run.date).id
        )
        const allSame = restPriceBasisIds.every(
            (id) => id === firstPriceBasisId
        )

        if (!allSame) {
            toast.error(
                'Les transports doivent avoir la même base tarifaire, veuillez ne pas chevaucher les bases tarifaires selon les dates des transports'
            )
        }

        return allSame
    }

    const checkIfDirectRunAreSepareted = (runsInInterval) => {
        // if there is a directReturn run, check if both runs are present in invoice.runs, if not prompt user to add the missing run
        const hasDirectReturn = runsInInterval.some((run) => run.directReturn)
        console.log(hasDirectReturn, 'hasDirectReturn', runsInInterval)
        if (hasDirectReturn) {
            const allDirectRunsHaveLinkedInInterval = runsInInterval.every(
                (run) => {
                    if (run.directReturn) {
                        const linkedRun = run.return
                            ? run.return
                            : run.lead
                            ? run.lead
                            : null
                        // Check if there is a corresponding return run in the runsInInterval array
                        return runsInInterval.some(
                            (returnRun) => returnRun.id === linkedRun?.id
                        )
                    }
                    return true // For runs that are not directReturn, we don't need to check anything
                }
            )

            if (!allDirectRunsHaveLinkedInInterval) {
                toast.error(
                    'Impossible de facturer un transport direct sans le trajet retour ou aller'
                )
                return true
            }
        }
        return false
    }

    const handleCreateInvoice = async () => {
        if (
            !checkIfSerieIsAbleToBeInvoiced(
                prescription,
                patientForm,
                series.runsToInsert[0].date,
                prsDate,
                dateMaternity,
                workAccidentDate,
                priorApprovalDate
            )
        ) {
            dispatch({
                type: SERIES_CPAM_INVOICE_FORM_ACTION.IS_LOADING,
                payload: true,
            })
            const between = {
                strictlyBefore: dayjs(endDate).local().format('YYYY-MM-DD'),
                strictlyAfter: dayjs(startDate).local().format('YYYY-MM-DD'),
            }
            dispatch({
                type: SERIES_CPAM_INVOICE_FORM_ACTION.RESET_INVOICE_FORM,
            })
            await RUN.GetRunsBySeriesIdAndDateBetween(
                series.id,
                between,
                'done',
                dispatch,
                priceBases,
                prescription
            ).then(() => {})

            handleShowModal()
        }
    }

    const checkInvoiceErrors = () => {
        const msg = []
        const isAbleToBeInvoiced = seriesCpamInvoiceForm.runs.every((run) =>
            isRunAbleToBeInvoicedPrompt(run, patientForm)
        )

        // Check if every runs have the same priceBasis.id, if not return error
        const isPriceBasisIdDifferent =
            seriesCpamInvoiceForm.runsInvoiceFormsDetails.some(
                (detail) =>
                    detail.priceBasis.id !==
                    seriesCpamInvoiceForm.runsInvoiceFormsDetails[0].priceBasis
                        .id
            )
        if (isPriceBasisIdDifferent) {
            toast.error(
                'Les transports doivent avoir la même base tarifaire, veuillez ne pas chevaucher les bases tarifaires selon les dates des transports'
            )
            return true
        }

        if (!isAbleToBeInvoiced) {
            toast.error('Veuillez vérifier les informations des transports')
            return true
        }

        if (checkIfDirectRunAreSepareted(seriesCpamInvoiceForm.runs)) {
            return true
        }

        return false
    }

    const handleSeriesInvoiceForm = async () => {
        if (!checkInvoiceErrors()) {
            const result = await putOrPostSeriesInvoiceForm(
                prescription,
                patientForm,
                series,
                seriesCpamInvoiceForm,
                id
            )
            if (result.success) {
                let url = '/series/' + series.id + '?Series_tab=Facture'
                // reload the page to the url
                window.location.assign(url)
            }
        }
        handleShowModal()
    }

    const handleGetCpamInvoice = async (invoice) => {
        // Add run invoice details to cpamInvoiceForm :
        dispatch({
            type: SERIES_CPAM_INVOICE_FORM_ACTION.SHOW_MODAL,
        })
        dispatch({
            type: SERIES_CPAM_INVOICE_FORM_ACTION.IS_LOADING,
            payload: true,
        })
        invoice = await API.CpamInvoice.get(invoice.id)

        const runInvoiceDetails = getRunInvoiceFormDetailsFromRuns(invoice.runs)

        const getInvoiceFormDetails =
            updateCalculatedPriceFromRunInvoiceDetailsForms(runInvoiceDetails)

        const totals = getTotalsFromInvoiceFormDetails(
            getInvoiceFormDetails,
            prescription.rate.value
        )

        dispatch({
            type: SERIES_CPAM_INVOICE_FORM_ACTION.FETCH_INVOICE_DATA,
            payload: {
                ...invoice,
                date: invoice.date ? new Date(invoice.date) : new Date(),
                runsInvoiceFormsDetails: getInvoiceFormDetails,
                runsInvoiceFormsDetailsHolder: getInvoiceFormDetails,
                totals: totals,
            },
        })
    }

    return (
        <div className="grid gap-8 pb-10 sm:grid-cols-2">
            {doneCount > 0 ? (
                <div className="grid  gap-6 rounded-lg border-2 bg-white p-8 shadow-md sm:grid-cols-2">
                    <h2
                        className={
                            'col-span-2 h-10 border-b text-xl font-semibold'
                        }
                    >
                        Facturer la série de{' '}
                        {patientForm.lastname + ' ' + patientForm.firstname}
                    </h2>
                    <div className={'col-span-2 flex flex-col'}>
                        <p className={'pb-2 font-semibold'}>
                            Période de facturation
                        </p>
                        <div className="flex items-center space-x-2">
                            <CalendarDatePicker
                                openSelectDatePicker={openSelectStartDatePicker}
                                setOpenSelectDatePicker={() =>
                                    setOpenSelectStartDatePicker(
                                        !openSelectStartDatePicker
                                    )
                                }
                                date={startDate}
                                setDate={(date) => setStartDate(date)}
                                icon={false}
                                fontSize="text-lg lg:text-sm"
                                customDisplay="D MMM YYYY"
                            />
                            <ArrowRight />
                            <CalendarDatePicker
                                openSelectDatePicker={openSelectEndDatePicker}
                                setOpenSelectDatePicker={() =>
                                    setOpenSelectEndDatePicker(
                                        !openSelectEndDatePicker
                                    )
                                }
                                date={endDate}
                                fontSize="text-lg lg:text-sm"
                                setDate={(date) => setEndDate(date)}
                                icon={false}
                                customDisplay="D MMM YYYY"
                            />
                        </div>
                    </div>
                    <div className={'col-span-2 grid grid-cols-2 gap-4'}>
                        <div className={'col-span-1'}>
                            <p className={'font-semibold'}>
                                Nb de transport fait pouvant être facturé
                            </p>
                        </div>
                        <div className={'col-span-1 text-right'}>
                            <span className={'rounded-full border-2 p-2'}>
                                {counter} / 16
                            </span>
                        </div>
                        {runsInInterval ? (
                            <div
                                className={'col-span-4 grid grid-cols-2 gap-4'}
                            >
                                {runsInInterval?.map((run) => (
                                    <div
                                        key={run.key}
                                        className={
                                            'col-span-1 flex justify-center rounded-lg border bg-white p-2'
                                        }
                                    >
                                        <p>
                                            {run.returnStatus === 'done' &&
                                            run.status === 'done' &&
                                            !run.runSharedAndGiven &&
                                            !run.returnRunSharedAndGiven
                                                ? run.dateString + ' x2'
                                                : run.dateString}
                                        </p>
                                        {run.isRoundTrip &&
                                        run.status !== 'done' ? (
                                            <i
                                                className={
                                                    'far fa-registered fa-lg ml-2'
                                                }
                                            />
                                        ) : run.isReturnPath ? (
                                            <i
                                                className={
                                                    'far fa-registered fa-lg ml-2'
                                                }
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </div>
                    {runsInInterval.length > 0 ? (
                        <div
                            className={
                                'col-span-2 mt-auto flex justify-end pt-10'
                            }
                        >
                            <PrimaryButton
                                label={`Facturer les transports`}
                                action={handleCreateInvoice}
                            />
                        </div>
                    ) : null}
                </div>
            ) : null}
            <div className={'flex flex-col space-y-4'}>
                <p className={'text-xl font-semibold'}>
                    Statistique globale de la série
                </p>
                <div className={'items-end'}>
                    <div className={'float-right flex flex-col pr-5 sm:pr-0'}>
                        {statsLabels.map((label, index) => (
                            <div className="flex w-32 flex-row items-center">
                                <div className={'flex items-center space-x-2'}>
                                    <div
                                        key={index}
                                        className="h-3 w-3 rounded-full"
                                        style={{
                                            backgroundColor:
                                                backgroundColor[index],
                                        }}
                                    />
                                    <div>{label}</div>
                                </div>
                                <div className={' w-full text-right'}>
                                    <div className="text-right">
                                        {data[index]}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={''}>
                        <DoughnutChart
                            labels={labels}
                            data={data}
                            backgroundColor={backgroundColor}
                            hoverBackgroundColor={hoverBackgroundColor}
                            cutout={cutout}
                            aspectRatio="3.5"
                        />
                    </div>
                </div>
                <div className={'flex flex-col pt-10'}>
                    {cpamInvoices.length > 0 ? (
                        <div className="grid gap-6 rounded-lg border-2 bg-white p-8 shadow-md sm:grid-cols-2">
                            <h2
                                className={
                                    'col-span-2 h-10 border-b text-xl font-semibold'
                                }
                            >
                                Liste des factures
                            </h2>

                            <div
                                className={
                                    'col-span-2 grid gap-4 sm:grid-cols-2'
                                }
                            >
                                {cpamInvoices?.map((invoice) => (
                                    <button
                                        key={cpamInvoices.id}
                                        onClick={(e) =>
                                            handleGetCpamInvoice(invoice)
                                        }
                                        className={`col-span-1 flex w-52 justify-between rounded-lg border-2 ${findBorderByStatusType(
                                            invoice.status
                                        )}  bg-white p-2 hover:scale-105
                                        `}
                                    >
                                        <p className={'text-center'}>
                                            <RenderInvoiceId
                                                invoiceId={
                                                    invoice.cpamInvoiceId
                                                }
                                            />
                                        </p>
                                        <p>
                                            {formatEuro(
                                                invoice.totalInvoiceAmount
                                            )}
                                        </p>
                                    </button>
                                ))}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            {seriesCpamInvoiceForm.showModal ? (
                <ModalSkeleton
                    label={
                        seriesCpamInvoiceForm.id
                            ? 'Série : modifier la facture'
                            : 'Série : ajouter une facture'
                    }
                    componentProps={{
                        invoiceForm: seriesCpamInvoiceForm,
                        dispatch: dispatch,
                        prescription,
                        series,
                        handleCreateInvoice,
                    }}
                    Component={SeriesCpamInvoiceFormModal}
                    handleForm={handleSeriesInvoiceForm}
                    hideSubmitButton={
                        seriesCpamInvoiceForm.status &&
                        seriesCpamInvoiceForm.status?.label !== 'Créée'
                    }
                    buttonLabel={
                        seriesCpamInvoiceForm.id
                            ? 'Modifier la facture'
                            : 'Facturer'
                    }
                    handleCloseModal={handleShowModal}
                />
            ) : null}
        </div>
    )
}

export default SeriesCpamInvoiceForm
