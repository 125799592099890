import React from 'react'
import { ReactComponent as Cpam } from '../../../../files/cpam.svg'
import {
    Check,
    User,
    Cancel,
    Healthcare,
    CheckCircle,
    Hourglass,
} from 'iconoir-react'
import { formatEuro } from '../Manage'
import DeleteButton from '../../../Commons/Buttons/DeleteButton'
import { useNavigate } from 'react-router-dom'
export const navigateToInvoice = (invoice, navigate) => {
    if (invoice.series) {
        navigate(`/series/${invoice.series.id}?Series_tab=Facture`)
    } else {
        navigate(`/transport/${invoice.runs[0].id}?tab=Facture`)
    }
}
const InvoiceDetails = ({ lot, openedCardId, handleShowLotResolvingModal }) => {
    const { validatedInvoices, rejectedInvoices } = lot
    const navigate = useNavigate()

    const countHeathComplementaryNotPaid = validatedInvoices.filter(
        (invoice) => {
            return (
                invoice.healthComplementaryAmount > 0 &&
                !invoice.paidByPayingCenter &&
                invoice.healthComplementaryStatus !== 'Virement reçu'
            )
        }
    ).length

    return (
        openedCardId === lot.id && (
            <div className="mb-4 grid gap-4 border-x-2 p-2 sm:grid-cols-2">
                {validatedInvoices.length > 0 && (
                    <div className="h-auto w-full rounded-lg border-2 bg-white p-4">
                        <div className="flex justify-between border-b pb-2 font-semibold ">
                            <div className="flex w-full items-center justify-between text-xl">
                                <div className={'flex  items-center'}>
                                    <p>Factures acceptées</p>
                                    <Check
                                        className={
                                            'ml-2 hidden text-green-500 sm:block'
                                        }
                                        height={36}
                                        width={36}
                                    />
                                </div>
                                {countHeathComplementaryNotPaid > 0 && (
                                    <span className="ml-2 text-red-500">
                                        {countHeathComplementaryNotPaid} en
                                        reste à payer
                                    </span>
                                )}
                            </div>
                        </div>
                        {validatedInvoices.map((invoice, index) => (
                            <div key={index} className="mb-4 border-b py-2">
                                <div className="mb-2 flex justify-between">
                                    <button
                                        className="flex items-center"
                                        onClick={() =>
                                            navigateToInvoice(invoice, navigate)
                                        }
                                    >
                                        <span className="text-lg font-semibold text-secondary-color underline hover:text-primary-color ">
                                            {invoice.numFacture}
                                        </span>
                                    </button>
                                    <div className="flex items-center truncate">
                                        <User />
                                        <span className="ml-2">
                                            {invoice.patientFullName}
                                        </span>
                                    </div>
                                </div>
                                <div className="grid grid-cols-2  gap-2 md:grid-cols-3">
                                    <div className="flex items-center">
                                        <Cpam height={24} width={24} />
                                        <span className="ml-2">
                                            {formatEuro(
                                                invoice.payingCenterAmount
                                            )}
                                        </span>
                                    </div>
                                    {invoice.healthComplementaryAmount > 0 ? (
                                        <div className="mx-auto flex items-center">
                                            <Healthcare
                                                height={24}
                                                width={24}
                                            />
                                            <span className="ml-2">
                                                {formatEuro(
                                                    invoice.healthComplementaryAmount
                                                )}
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="mx-auto flex items-center">
                                            <span
                                                className={
                                                    'flex w-20 justify-center rounded-full bg-blue-100 text-center font-bold text-blue-800'
                                                }
                                            >
                                                100%
                                            </span>
                                        </div>
                                    )}
                                    {invoice.healthComplementaryAmount ===
                                        'N/A' ||
                                    invoice.healthComplementaryAmount === 0.0 ||
                                    invoice.paidByPayingCenter ||
                                    invoice.healthComplementaryStatus ===
                                        'Virement reçu' ? (
                                        <div className="col-span-2 ml-auto flex items-center rounded-full bg-green-500 p-1 text-white sm:col-auto">
                                            <span className="ml-2 font-semibold">
                                                {formatEuro(
                                                    invoice.totalAmount
                                                )}
                                            </span>
                                            <CheckCircle
                                                className={'ml-2 text-white'}
                                            />
                                        </div>
                                    ) : (
                                        <div className="col-span-2 ml-auto flex items-center rounded-full bg-blue-500 p-1 text-white sm:col-auto">
                                            <span className="ml-2 font-semibold">
                                                {formatEuro(
                                                    invoice.totalAmount
                                                )}
                                            </span>
                                            <Hourglass
                                                className={'ml-2 text-white'}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {rejectedInvoices.length > 0 && (
                    <div className="h-auto w-full rounded-lg border-2 bg-white p-4">
                        <div className="flex justify-between border-b pb-2  font-semibold">
                            <div className="flex items-center text-xl">
                                <p>Factures rejetées</p>
                                <Cancel
                                    className={
                                        'ml-2 hidden text-red-500 sm:block'
                                    }
                                    height={36}
                                    width={36}
                                />
                            </div>
                            <DeleteButton
                                label={'Résoudre les rejets'}
                                action={() =>
                                    handleShowLotResolvingModal(lot.id)
                                }
                            />
                        </div>
                        {rejectedInvoices.map((invoice, index) => (
                            <div key={index} className="mb-4 border-b py-2">
                                <div className="mb-2 flex justify-between">
                                    <button
                                        className="flex items-center"
                                        onClick={() =>
                                            navigateToInvoice(invoice, navigate)
                                        }
                                    >
                                        <span className="text-lg font-semibold text-secondary-color underline hover:text-primary-color">
                                            {invoice.numFacture}
                                        </span>
                                    </button>
                                    <div className="flex items-center truncate">
                                        <User />
                                        <span className="ml-2">
                                            {invoice.patientFullName}
                                        </span>
                                    </div>
                                </div>
                                <div className="grid grid-cols-2  gap-2 pb-2 md:grid-cols-3">
                                    <div className="flex items-center">
                                        <Cpam height={24} width={24} />
                                        <span className="ml-2">
                                            {formatEuro(
                                                invoice.payingCenterAmount
                                            )}
                                        </span>
                                    </div>
                                    {invoice.healthComplementaryAmount > 0 ? (
                                        <div className="mx-auto flex items-center">
                                            <Healthcare
                                                height={24}
                                                width={24}
                                            />
                                            <span className="ml-2">
                                                {formatEuro(
                                                    invoice.healthComplementaryAmount
                                                )}
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="mx-auto flex items-center">
                                            <span
                                                className={
                                                    'flex w-20 justify-center rounded-full bg-blue-100 text-center font-bold text-blue-800'
                                                }
                                            >
                                                100%
                                            </span>
                                        </div>
                                    )}
                                    <div className="col-span-2 ml-auto flex items-center sm:col-auto ">
                                        <span className="ml-2 font-bold">
                                            {formatEuro(invoice.totalAmount)}
                                        </span>
                                        <Cancel
                                            className={'ml-2 text-red-500'}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-2 flex w-full items-center rounded-lg bg-red-100 px-2 py-2 md:col-span-4">
                                    <p>{invoice.rejectionReason}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        )
    )
}

export default InvoiceDetails
