import React from 'react'
import classNames from 'classnames'

const PrimaryButton = ({
    icon,
    action,
    label,
    hiddenLabelOnMobile,
    active,
    title,
    disabled = false,
    mobileSize = false,
    hiddenIconOnPc = false,
    loader = false,
    fullWidth = false,
    customIconClass = '',
}) => {
    return (
        <button
            className={classNames(
                'flex flex-row items-center rounded-lg bg-secondary-color px-4 py-2 font-semibold text-white hover:bg-primary-color',
                {
                    'bg-secondary-color hover:bg-primary-color': !active,
                    'bg-primary-color': active,
                    'cursor-not-allowed': disabled,
                    'h-14 w-full justify-center py-4': mobileSize,
                    'h-10': !mobileSize,
                    'w-full justify-center py-4': fullWidth,
                }
            )}
            onClick={() => {
                if (!loader) action()
            }}
            title={title}
            disabled={disabled}
        >
            <span
                className={classNames('flex', customIconClass, {
                    'flex pr-2 sm:hidden': hiddenIconOnPc,
                })}
            >
                {icon}
            </span>

            <span
                className={classNames('flex items-center', {
                    'hidden sm:flex': hiddenLabelOnMobile && icon,
                })}
            >
                <span className={`${loader && 'pr-2'}`}>{label}</span>
                {loader && (
                    <svg
                        className="h-5 w-5 animate-spin "
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                )}
            </span>
        </button>
    )
}

export default PrimaryButton
