import React, { useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ActionModal from '../../Modal/ActionModal'
import useConfiguratorStore from '../../../stores/Configurator'
import TOOLS from '../../../services/tools'
import { useLongPress } from 'use-long-press'
import MobileOption from '../../Commons/MobileOption'
import DefaultOptions from '../../Commons/DefaultOptions'
import Loader from '../../Commons/Loaders/Loader'
import classNames from 'classnames'

const SeriesList = ({
    rows,
    handleDeleteSeries,
    loading,
    totalItems,
    handleArchivedSeries,
    archived,
}) => {
    let history = useNavigate()
    const [modalOpen, setModalOpen] = useState(false)
    const [modalArchivedOpen, setModalArchivedOpen] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [label, setLabel] = useState('')
    const [labelAction, setLabelAction] = useState('')
    const [actionButton, setActionButton] = useState('')
    const [styleId, setStyleId] = useState(null)
    const [showOptions, setShowOptions] = useState(false)
    const [showMobileOptions, setShowMobileOptions] = useState(false)
    const [selectedRowId, setSelectedRowId] = useState(null)

    const setSeriesFirstRunDate = useConfiguratorStore(
        (state) => state.setSeriesFirstRunDate
    )
    const handleDelete = () => {
        setModalOpen(false)
        handleDeleteSeries(selectedRow.id)
    }
    const handleArchived = () => {
        setModalArchivedOpen(false)
        handleArchivedSeries(selectedRow)
    }

    const handleModalDelete = (rowId) => {
        const series = rows.find((row) => row.id === rowId)
        setSelectedRow(series)
        let label = `Êtes-vous sûr de vouloir supprimer la série contenant ${series.nbRuns} ${series.runObject} pour ${series.patientName} ?`
        setModalOpen(true)
        setLabel(label)
        setActionButton('Supprimer')
        setLabelAction('Supprimer la série')
    }

    const handleModalArchived = (rowId) => {
        const series = rows.find((row) => row.id === rowId)
        setSelectedRow(series)

        if (series.archived) {
            let label = `Êtes-vous sûr de vouloir retirer la série contenant ${series.nbRuns} ${series.runObject} pour ${series.patientName} des archives ?`
            setLabel(label)
            setActionButton('Désarchiver')
            setLabelAction('Désarchiver la série')
        } else {
            let label = `Êtes-vous sûr de vouloir archiver la série contenant ${series.nbRuns} ${series.runObject} pour ${series.patientName} ?`
            setLabel(label)
            setActionButton('Archiver')
            setLabelAction('Archiver la série')
        }
        setModalArchivedOpen(true)
    }

    const handleMobileModal = (rowId) => {
        let row = rows.find((r) => r.id === rowId)
        setSelectedRow(row)
        let label = `Êtes-vous sûr de vouloir supprimer la série contenant ${row.nbRuns} ${row.runObject} pour ${row.patientName} ?`
        setShowMobileOptions(false)
        setLabel(label)
        setActionButton('Supprimer')
        setLabelAction('Supprimer la série')
        setModalOpen(true)
    }

    const historySeriesPush = (rowId) => {
        let series = rows.find((r) => r.id === rowId)
        setSeriesFirstRunDate(new Date(series.firstRunDate))
        history('/transport?series=' + rowId)
    }

    const optionsRef = useRef()
    TOOLS.useOnClickOutside(optionsRef, () => {
        setShowOptions(false)
        setStyleId(null)
    })

    const callback = useCallback((event) => {}, [])

    const bind = useLongPress(callback, {
        onStart: (event) => {
            setSelectedRowId(parseInt(event.currentTarget.id))
            setStyleId(parseInt(event.currentTarget.id))
        },
        onFinish: (event) => setShowMobileOptions(true),
        onCancel: (event) => {
            setSelectedRowId(null)
            setStyleId(null)
            setShowMobileOptions(false)
        },
        onMove: (event) => console.log('Detected mouse or touch movement'),
        threshold: 300,
        captureEvent: true,
        cancelOnMovement: true,
        detect: 'touch',
    })

    const goToSeriesManage = (rowId) => {
        const row = rows.find((r) => r.id === rowId)
        if (row.newSeries) {
            history(`/series/${row.id}`)
        } else {
            history(`/series/${row.id}`)
        }
    }

    return (
        <div className="px-2 pb-5 xl:pt-5">
            <div className="flex py-2">
                <div className="ml-auto text-xs font-bold">
                    {totalItems} Séries
                </div>
            </div>
            {loading ? (
                <Loader />
            ) : (
                <div className="">
                    {rows.map((row) => (
                        <div
                            key={row.id}
                            className={classNames(
                                'parents relative my-2 grid cursor-pointer  select-none rounded bg-white p-2 shadow-mobile lg:select-auto',
                                {
                                    'bg-mid-gray': !row.newSeries,
                                }
                            )}
                            id={row.id}
                            {...bind}
                            onClick={() => goToSeriesManage(row.id)}
                        >
                            <div className="flex flex-wrap text-sm sm:text-base">
                                <div className="w-1/3 lg:w-2/12">
                                    <div className="truncate font-semibold">
                                        {row.patientName}
                                    </div>
                                    <div className="block lg:hidden">
                                        {row.runObject}
                                    </div>
                                </div>
                                <div className="w-1/3 truncate px-2 lg:w-2/12">
                                    {row.label}
                                    <div className="block font-semibold lg:hidden">{`${row.nbRunsChargedOrDone} / ${row.nbRuns} transports`}</div>
                                </div>
                                <div className="block grid w-1/3 grid-cols-2 lg:hidden">
                                    <div className="col-span-1">
                                        <div>Début :</div>
                                        <div>Fin :</div>
                                    </div>
                                    <div className="col-span-1">
                                        <div>
                                            {`${row.firstRunDate.toLocaleDateString(
                                                'fr-FR'
                                            )}`}
                                        </div>
                                        <div>
                                            {`${row.lastRunDate.toLocaleDateString(
                                                'fr-FR'
                                            )}`}
                                        </div>
                                    </div>
                                </div>
                                <div className="hidden w-2/12 lg:block">
                                    {row.runObject}
                                </div>
                                <div className="hidden w-2/12 font-semibold lg:block">{`${row.nbRunsChargedOrDone} / ${row.nbRuns} transports`}</div>
                                <div className="flex hidden w-3/12 space-x-1 text-center lg:block">
                                    <span>
                                        {row.firstRunDate.toLocaleDateString(
                                            'fr-FR'
                                        )}
                                    </span>
                                    <span> au </span>
                                    <span>
                                        {row.lastRunDate.toLocaleDateString(
                                            'fr-FR'
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div
                                className={`absolute right-0 hidden h-10 cursor-pointer rounded pt-2 xl:block   ${
                                    row.id === selectedRowId ? null : 'options '
                                } `}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowOptions(true)
                                    setSelectedRowId(row.id)
                                    setStyleId(row.id)
                                }}
                            >
                                <i
                                    className={`fas fa-ellipsis-h w-20 pr-4 pt-1 text-right ${
                                        showOptions ? 'hidden' : ''
                                    } `}
                                />
                                {row.id === selectedRowId && showOptions ? (
                                    <DefaultOptions
                                        setShowMobileOptions={setShowOptions}
                                        rowId={selectedRowId}
                                        handleModalDelete={handleModalDelete}
                                        handleModalArchived={
                                            handleModalArchived
                                        }
                                        historyPushConsulting={
                                            historySeriesPush
                                        }
                                        historyPush={goToSeriesManage}
                                        historyPushUpdate={goToSeriesManage}
                                        optionsRef={optionsRef}
                                        isArchivedSeries={archived}
                                    />
                                ) : null}
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {showMobileOptions ? (
                <MobileOption
                    setShowMobileOptions={setShowMobileOptions}
                    rowId={selectedRowId}
                    handleModalDelete={handleMobileModal}
                    handleModalArchived={handleModalArchived}
                    historyPushConsulting={historySeriesPush}
                    historyPush={goToSeriesManage}
                    historyPushUpdate={goToSeriesManage}
                    setStyleId={setStyleId}
                    isArchivedSeries={archived}
                />
            ) : null}
            {modalOpen ? (
                <ActionModal
                    setModalOpen={setModalOpen}
                    label={label}
                    action={handleDelete}
                    labelAction={labelAction}
                    actionButton={actionButton}
                />
            ) : null}
            {modalArchivedOpen ? (
                <ActionModal
                    setModalOpen={setModalArchivedOpen}
                    label={label}
                    action={handleArchived}
                    labelAction={labelAction}
                    actionButton={actionButton}
                />
            ) : null}
        </div>
    )
}

export default SeriesList
