import dayjs from 'dayjs'
import { Group, UserSquare } from 'iconoir-react'
import { openMaps } from '../../../services/address'
import React from 'react'

const PatientInfo = ({ runInfo }) => {
    return (
        <div className="mx-5 mb-5 flex w-11/12 shrink-0 snap-center snap-always flex-col space-y-8 overflow-y-scroll rounded-xl bg-white p-5 shadow-mobile">
            <div className="flex w-full items-center  justify-between pb-5 ">
                <p className="font-bold text-gray-600">
                    Fiche {runInfo.patient.isPatient ? 'patient' : 'client'}
                </p>
                <i className="fas fa-user fa-lg pl-4" />
            </div>
            <div className="flex w-full justify-between space-x-2">
                <div className="flex min-w-12 flex-col">
                    <p className="font-bold text-gray-600">Nom</p>
                    <p>{runInfo.patient.lastname}</p>
                </div>

                <div className="flex flex-col ">
                    <p className="font-bold text-gray-600">Prénom</p>
                    <p>{runInfo.patient.firstname}</p>
                </div>
            </div>
            <div className="flex flex-col space-y-1">
                <p className="font-bold text-gray-600">Téléphone</p>
                {runInfo.patient.phoneNumber ? (
                    <div className="flex items-center justify-between">
                        <a href={`tel:${runInfo.patient.phoneNumber}`}>
                            {runInfo.patient.phoneNumber}
                        </a>
                        <i className="fa fa-phone" />
                    </div>
                ) : (
                    <i className="fa fa-times pl-2 pt-2" />
                )}
            </div>
            <div className="flex flex-col space-y-1">
                <p className="font-bold text-gray-600">Date de naissance</p>
                {runInfo.patient.dateOfBirth ? (
                    <div className="flex items-center justify-between">
                        <p>
                            {runInfo.patient.dateOfBirth
                                ? dayjs(
                                      new Date(
                                          runInfo.patient.dateOfBirth.split(
                                              '+'
                                          )[0]
                                      )
                                  ).format('DD/MM/YYYY')
                                : null}
                        </p>
                        <i className="fa fa-calendar-alt" />
                    </div>
                ) : (
                    <i className="fa fa-times pl-2 pt-2" />
                )}
            </div>
            <div className="flex flex-col space-y-1">
                <p className="font-bold text-gray-600">N° de sécu :</p>
                {runInfo.patient.socialNumber ? (
                    <div className="flex items-center justify-between">
                        <p>{runInfo.patient.socialNumber}</p>
                        <UserSquare height={36} width={36} />
                    </div>
                ) : (
                    <i className="fa fa-times pl-2 pt-2" />
                )}
            </div>
            {runInfo.patient.description && (
                <div className="flex flex-col space-y-1">
                    <p className="font-bold text-gray-600">Description</p>
                    <p>{runInfo.patient.description}</p>
                </div>
            )}
            {runInfo.patientsAddresses && (
                <div className="flex w-full flex-col space-y-8">
                    {runInfo.patientsAddresses.map((savedAddress, key) => (
                        <div
                            key={key}
                            onClick={() => openMaps(savedAddress.address)}
                            className="flex flex-col space-y-1"
                        >
                            <p className="font-bold text-gray-600">
                                {savedAddress.label}
                            </p>
                            <p className="cursor-pointer">
                                {savedAddress.address.street
                                    ? savedAddress.address.street + ', '
                                    : ''}
                                {savedAddress.address.secondaryStreet
                                    ? savedAddress.address.secondaryStreet +
                                      ', '
                                    : ''}
                                <span className="underline">
                                    {savedAddress.address.city}
                                </span>
                            </p>
                            {savedAddress.phoneNumber && (
                                <div className="flex items-center justify-between">
                                    <a href={`tel:${savedAddress.phoneNumber}`}>
                                        {savedAddress.phoneNumber}
                                    </a>
                                    <i className="fa fa-phone" />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
            {runInfo.patientsContacts?.length > 0 && (
                <div className="flex w-full flex-col space-y-8">
                    <div className="flex items-center justify-between">
                        <p className="font-semibold text-gray-600">
                            Contacts enregistrés
                        </p>
                        <Group />
                    </div>
                    <div className="flex w-full flex-col space-y-8">
                        {runInfo.patientsContacts.map((contact, key) => (
                            <div key={key} className="flex flex-col space-y-1">
                                <p className="font-semibold text-gray-600">
                                    {contact.label}
                                </p>
                                <p className=""></p>
                                {contact.phoneNumber && (
                                    <div className="flex items-center justify-between">
                                        <a href={`tel:${contact.phoneNumber}`}>
                                            {contact.phoneNumber}
                                        </a>
                                        <i className="fa fa-phone" />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default PatientInfo
