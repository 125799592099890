import React, { useEffect, useState } from 'react'
import SimpleSelectDropDownList from '../../Commons/SimpleSelectDropDownList'
import SelectDateRangePicker from '../../Commons/SelectDateRangePicker'
import { debounce } from '../../../services/debounce'
import API from '../../../services/api'
import dayjs from 'dayjs'
import { INVOICE_FILTER_ACTIONS } from '../../../services/Reducers/InvoiceFilterReducer'
import { Plus } from 'iconoir-react'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'

const formatOptions = (list) => {
    const options = []
    list.forEach((option) => {
        const data = {
            value: option['@id'],
            label: option.label,
        }

        options.push(data)
    })
    return options
}

const FilterPatient = ({
    openFilterModal,
    setOpenFilterModal,
    dispatch,
    invoiceFilter,
    setDateTo,
    setDateFrom,
}) => {
    const [openSelectDatePicker, setOpenSelectDatePicker] = useState(false)

    const searchPatient = debounce((e) => {
        if (e !== '') {
            API.Patients.list(e).then((response) => {
                response.json().then((data) => {
                    const patientList = data['hydra:member'].map((patient) => {
                        return {
                            value: patient['@id'],
                            label: patient.firstname + ' ' + patient.lastname,
                            patient: patient,
                        }
                    })
                    dispatch({
                        type: INVOICE_FILTER_ACTIONS.GET_PATIENT_LIST,
                        payload: patientList,
                    })
                })
            })
        }
    }, 500)

    function fetchObjectList() {
        API.RunObject.list().then((response) => {
            response.json().then((data) => {
                const formatted = formatOptions(data['hydra:member'])
                dispatch({
                    type: INVOICE_FILTER_ACTIONS.GET_OBJECT_LIST,
                    payload: formatted,
                })
            })
        })
    }

    const fetchSeries = () => {
        API.Series.listByPatient(invoiceFilter.patient.patient.id).then(
            (response) => {
                response.json().then((data) => {
                    const seriesList = data['hydra:member'].map((s) => {
                        const runs = s.runs.sort(function (a, b) {
                            return (
                                new Date(a.date) - new Date(b.date) ||
                                new Date(a.departureTime) -
                                    new Date(b.departureTime)
                            )
                        })
                        return {
                            value: s['@id'],
                            label: `${s.label ? s.label + ' - ' : ''}  ${
                                runs[0].runObject.label
                            } - ${dayjs(s.firstRunDate)
                                .local()
                                .format('DD/MM/YYYY')} au ${dayjs(s.lastRunDate)
                                .local()
                                .format('DD/MM/YYYY')}`,
                            object: runs[0].runObject.label,
                        }
                    })
                    dispatch({
                        type: INVOICE_FILTER_ACTIONS.GET_SERIES_LIST,
                        payload: seriesList,
                    })
                })
            }
        )
    }

    useEffect(() => {
        fetchObjectList()
        if (invoiceFilter.patient) {
            fetchSeries()
        }
    }, [invoiceFilter.patient, invoiceFilter.object, invoiceFilter.series])

    return (
        <div className="mx-auto  w-full max-w-md text-gray-800 lg:max-w-lg xl:max-w-3xl ">
            <div className="shadow-modal w-full rounded-xl bg-white">
                <div className="flex flex-col items-start p-4 lg:p-8 ">
                    <div className="flex w-full items-center border-b pb-4 text-xl">
                        <h2 className="flex space-x-4 text-center font-semibold ">
                            Nouvelle facture
                        </h2>
                        <svg
                            className="ml-auto h-6 w-6 cursor-pointer fill-current text-gray-700"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 18 18"
                            onClick={() => {
                                setOpenFilterModal(!openFilterModal)
                            }}
                        >
                            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                        </svg>
                    </div>
                    <div className="flex w-full flex-col space-y-8 p-5">
                        <div className="flex w-full flex-col border-b pb-8">
                            <SimpleSelectDropDownList
                                icon="fa fa-user-injured"
                                options={invoiceFilter.patientList}
                                value={invoiceFilter.patient}
                                isClearable={!!invoiceFilter.patient}
                                handleOptionChange={(e) => {
                                    dispatch({
                                        type: INVOICE_FILTER_ACTIONS.SET_PATIENT,
                                        payload: e,
                                    })
                                }}
                                handleSearchInputPatientChange={searchPatient}
                                showLabel={false}
                                menuPlacement="top"
                                label="Patient ou client"
                            />
                        </div>
                        <div className="flex w-full flex-col ">
                            <SimpleSelectDropDownList
                                options={invoiceFilter.seriesList}
                                value={invoiceFilter.series}
                                handleOptionChange={(e) =>
                                    dispatch({
                                        type: INVOICE_FILTER_ACTIONS.SET_SERIES,
                                        payload: e,
                                    })
                                }
                                isDisabled={
                                    !invoiceFilter.patient ||
                                    invoiceFilter.seriesList.length === 0
                                }
                                isClearable={true}
                                placeholder={
                                    !invoiceFilter.patient
                                        ? "Sélectionnez d'abord un patient"
                                        : invoiceFilter.seriesList.length === 0
                                        ? 'Aucune série disponible pour ce patient'
                                        : 'Sélectionnez une série'
                                }
                                label={'Sélectionner une série'}
                            />
                        </div>
                        <p className="w-full text-center font-bold">Ou</p>
                        <div className="flex w-2/3 flex-col ">
                            <label className="block pb-2 text-sm font-bold uppercase tracking-wide text-gray-700 lg:text-xs">
                                Période de facturation
                            </label>
                            <SelectDateRangePicker
                                openSelectDatePicker={openSelectDatePicker}
                                setOpenSelectDatePicker={
                                    setOpenSelectDatePicker
                                }
                                startDate={invoiceFilter.dateFrom}
                                endDate={invoiceFilter.dateTo}
                                setStartDate={(e) => {
                                    setDateFrom(e)
                                    dispatch({
                                        type: INVOICE_FILTER_ACTIONS.SET_DATE_FROM,
                                        payload: e,
                                    })
                                }}
                                setEndDate={(e) => {
                                    setDateTo(e)
                                    dispatch({
                                        type: INVOICE_FILTER_ACTIONS.SET_DATE_TO,
                                        payload: e,
                                    })
                                }}
                            />
                        </div>
                        <div className="flex w-full  flex-col">
                            <SimpleSelectDropDownList
                                icon="fa fa-bullseye"
                                options={invoiceFilter.objectList}
                                value={invoiceFilter.object}
                                handleOptionChange={(e) => {
                                    dispatch({
                                        type: INVOICE_FILTER_ACTIONS.SET_OBJECT,
                                        payload: e,
                                    })
                                }}
                                isClearable={true}
                                isDisabled={invoiceFilter.series}
                                menuPlacement="top"
                                label="Objet spécifique"
                            />
                        </div>
                    </div>
                    <div className="mt-auto flex w-full justify-between pt-5">
                        <SecondaryButton
                            label="Annuler"
                            title="Annuler"
                            action={() => {
                                setOpenFilterModal(!openFilterModal)
                            }}
                        />
                        <PrimaryButton
                            label="Suivant"
                            title="Suivant"
                            action={(e) =>
                                dispatch({
                                    type: INVOICE_FILTER_ACTIONS.SWITCH_STEP,
                                    payload: 2,
                                })
                            }
                            disabled={!invoiceFilter.patient}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterPatient
