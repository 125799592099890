import React from 'react'

const sortArrayOfSimpleAndGroupedRuns = (array) => {
    return array.sort((a, b) => {
        if (Array.isArray(a)) {
            a = { date: a[0].date, departureTime: a[0].departureTime }
        } else {
            a = { date: a.date, departureTime: a.departureTime }
        }
        if (Array.isArray(b)) {
            b = { date: b[0].date, departureTime: b[0].departureTime }
        } else {
            b = { date: b.date, departureTime: b.departureTime }
        }

        return sortWithNewTimes(a, b)
    })
}

export const sortWithNewTimes = (a, b) => {
    // On compare d'abord les dates
    const dateComparison = new Date(a.date) - new Date(b.date)

    if (dateComparison !== 0) {
        return dateComparison
    }

    // Si les dates sont identiques, on compare les heures de départ
    return new Date(a.departureTime) - new Date(b.departureTime)
}

export const SortRunsByGroupAndDepartureTime = (runsToSort) => {
    //extraire runs non groupés
    const checkIfParentIsInArray = (run, array) => {
        if (run.parent) {
            return array.find((e) => e.id === run.parent.id)
        }
        return false
    }

    // check if the run have a parent and if this parent is in the runToSortArray else remove the departureOrder property
    runsToSort.map((teamRun) => {
        if (teamRun.parent) {
            if (!checkIfParentIsInArray(teamRun, runsToSort)) {
                teamRun.departureOrder = null
            }
        }
        return teamRun
    })

    let runs = runsToSort
        .filter((teamRun) => !teamRun.departureOrder)
        .sort((a, b) => {
            sortWithNewTimes(a, b)
        })

    //tableau de tableaux de runs groupés
    const allGroupedRuns = []

    //extraire runs groupés
    const groupedRuns = runsToSort.filter((teamRun) => teamRun.departureOrder)

    //trier les groupedRuns par groupes distincts
    //récupérer que les parents
    const groupedRunsParents = groupedRuns.filter(
        (groupedRun) => !groupedRun.parent
    )

    groupedRunsParents.forEach((groupedRunsParent) => {
        const runs = runsToSort.filter(
            (teamRun) =>
                teamRun.parent &&
                (teamRun.parent.id === groupedRunsParent.id ||
                    teamRun.parent === groupedRunsParent['@id'])
        )
        const parentAndChilds = [groupedRunsParent]
            .concat(runs)
            .sort((a, b) => a.departureOrder - b.departureOrder)

        allGroupedRuns.push(parentAndChilds)
    })

    let notGroupedRuns = [...groupedRuns, ...groupedRunsParents]

    runs = sortArrayOfSimpleAndGroupedRuns([...runs, ...allGroupedRuns])

    let finalSortedArray = []

    runs.forEach((el) => {
        if (Array.isArray(el)) {
            el.map((e) => {
                finalSortedArray.push(e)
            })
        } else {
            finalSortedArray.push(el)
        }
    })

    if (notGroupedRuns.length > 0) {
        notGroupedRuns.forEach((el) => {
            // check if the run is already in the final array
            if (!finalSortedArray.find((e) => e.id === el.id)) {
                el.departureOrder = null
                finalSortedArray.push(el)
            }
        })
    }

    return finalSortedArray ? finalSortedArray : []
}

export const SortRunsOneAfterAnother = (runsToSort) => {
    const runs = runsToSort.sort(
        (a, b) => new Date(a.departureTime) - new Date(b.departureTime)
    )

    const groupsRunsSorted = []

    while (runs.length) {
        const indexes = []
        const runsSorted = []

        runsSorted.push(runs[0])
        runs.splice(0, 1)

        for (let i = 0; i < runs.length; i++) {
            if (
                runsSorted[runsSorted.length - 1].arrivingTime <
                runs[i].departureTime
            ) {
                runsSorted.push(runs[i])
                indexes.push(i)
            }
        }
        groupsRunsSorted.push(runsSorted)

        indexes
            .sort((a, b) => a - b)
            .reverse()
            .forEach((index) => {
                runs.splice(index, 1)
            })
    }

    return groupsRunsSorted
}
