import ClassicInput from './Inputs/ClassicInput'
import { GoogleAutoComplete } from '../App/GoogleAutoComplete'
import ClassicSwitch from './Inputs/ClassicSwitch'
import React, { useEffect, useReducer, useRef } from 'react'
import {
    AddressAutoCompleteReducer,
    getAddressInitialState,
} from '../../services/Reducers/AddressAutoCompleteReducer'
import { Trash } from 'iconoir-react'

export const AddressItem = ({
    patientAddress,
    dispatch,
    PATIENT_ACTIONS,
    index,
}) => {
    const addressRef = useRef(index)
    const addressItem = patientAddress.address
    const [address, addressDispatch] = useReducer(
        AddressAutoCompleteReducer,
        { addressItem },
        getAddressInitialState
    )

    useEffect(() => {
        dispatch({
            type: PATIENT_ACTIONS.UPDATE_ADDRESS,
            payload: {
                ...patientAddress,
                address,
            },
        })
    }, [address])

    return (
        <div className=" flex w-full flex-col space-y-4">
            <div className="flex w-full flex-col justify-between space-y-6 lg:flex-row lg:items-center lg:space-y-0 ">
                <ClassicInput
                    value={patientAddress.label}
                    label={`Adresse ${index + 1} `}
                    placeholder="Libellé de l'adresse"
                    showLabel={true}
                    className="w-full lg:w-auto"
                    setValue={(e) =>
                        dispatch({
                            type: PATIENT_ACTIONS.UPDATE_ADDRESS_INFO,
                            payload: {
                                ...patientAddress,
                                label: e.target.value,
                            },
                        })
                    }
                />
                <ClassicInput
                    value={patientAddress.phoneNumber}
                    label={`Téléphone`}
                    placeholder="Numéro de contact"
                    showLabel={true}
                    className="w-full lg:w-auto"
                    setValue={(e) =>
                        dispatch({
                            type: PATIENT_ACTIONS.UPDATE_ADDRESS_INFO,
                            payload: {
                                ...patientAddress,
                                phoneNumber: e.target.value,
                            },
                        })
                    }
                />
                <i
                    onClick={() => {
                        dispatch({
                            type: PATIENT_ACTIONS.REMOVE_ADDRESS,
                            payload: patientAddress['@id'],
                        })
                    }}
                    className={`fas fa-minus-circle hidden cursor-pointer pt-5 text-red-600 lg:block  ${
                        patientAddress.id === 'Invoice_Line_1' && 'invisible'
                    } `}
                />
            </div>
            <GoogleAutoComplete
                dispatch={addressDispatch}
                address={address}
                label="Adresse"
                ref={addressRef}
            />
            <div className="flex items-center justify-between">
                <ClassicSwitch
                    label={'Adresse par défaut'}
                    info={true}
                    value={patientAddress.defaultAddress}
                    infoLabel={
                        "L'adresse par défaut est utilisée pour la facturation comme étant le domicile de la personne"
                    }
                    setValue={() =>
                        dispatch({
                            type: PATIENT_ACTIONS.UPDATE_ADDRESS_INFO,
                            payload: {
                                ...patientAddress,
                                defaultAddress: !patientAddress.defaultAddress,
                                defaultHasChanged: true,
                            },
                        })
                    }
                />
                <button
                    className={`block lg:hidden ${
                        patientAddress.id === ' Invoice_Line_1 ' && ' invisible'
                    } `}
                    onClick={() => {
                        dispatch({
                            type: PATIENT_ACTIONS.REMOVE_ADDRESS,
                            payload: patientAddress['@id'],
                        })
                    }}
                >
                    <div className=" rounded bg-red-500 py-2 px-4 font-bold text-white hover:bg-red-700">
                        <Trash />
                    </div>
                </button>
            </div>
        </div>
    )
}
