import { apiClient, withErrorHandling } from '../apiClient'

const PATH_KEY = '/runs'

export const Run = {
    deleteRun: withErrorHandling(async (id) => {
        return await apiClient.delete(`${PATH_KEY}/${id}`)
    }),
    fetchOne: withErrorHandling(async (id) => {
        return await apiClient.get(`${PATH_KEY}/${id}`)
    }),
    downloadSignaturePdf: withErrorHandling(async (id) => {
        const response = await apiClient.get(`${PATH_KEY}/pdf_signature/${id}`)
        console.log('responsdddddddddde', response)
        return response
    }),
}
