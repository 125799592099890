export const ESTABLISHMENT_ACTIONS = {
    GET_ESTABLISHMENT: 'getEstablishment',
    SET_ADDRESS: 'setAddress',
    UPDATE_ADDRESS_LABEL: 'updateAddressLabel',
    SET_LABEL: 'setLabel',
    SET_PHONE_NUMBER: 'setPhoneNumber',
    SET_MAIL: 'setMail',
    DELETE_ADDRESS: 'deleteAddress',
    SET_LOADER: 'setLoader',
    SET_DESCRIPTION: 'setDescription',
}

export const getInitialState = () => {
    return {
        id: null,
        label: null,
        address: null,
        addressLabel: null,
        phoneNumber: null,
        mail: null,
        loading: true,
        description: null,
    }
}

export const EstablishmentReducer = (state, action) => {
    switch (action.type) {
        case ESTABLISHMENT_ACTIONS.GET_ESTABLISHMENT:
            return {
                ...state,
                label: action.payload.label,
                address: action.payload?.address,
                phoneNumber: action.payload.phoneNumber,
                mail: action.payload.mail,
                id: action.payload.id,
                description: action.payload.description,
                loading: false,
            }
        case ESTABLISHMENT_ACTIONS.SET_LABEL:
            return {
                ...state,
                label: action.payload,
            }
        case ESTABLISHMENT_ACTIONS.SET_PHONE_NUMBER:
            return {
                ...state,
                phoneNumber: action.payload
                    ?.replace(/[^\dA-Z]/g, '')
                    .replace(/(.{2})/g, '$1 ')
                    .substring(0, 15)
                    .trim(),
            }
        case ESTABLISHMENT_ACTIONS.SET_MAIL:
            return {
                ...state,
                mail: action.payload,
            }
        case ESTABLISHMENT_ACTIONS.SET_LOADER:
            return {
                ...state,
                loading: action.payload,
            }
        case ESTABLISHMENT_ACTIONS.SET_DESCRIPTION:
            return {
                ...state,
                description: action.payload,
            }
        default:
            throw new Error('Action non reconnue')
    }
}
