import React, { useEffect, useState } from 'react'
import { CommentNotification } from './CommentNotification'
import { StatusNotification } from './StatusNotification'
import API from '../../services/api'
import { useLocation, useNavigate } from 'react-router-dom'
import { MultipleNotification } from './MultipleNotification'

const NotifDropDown = ({
    notifMenuRef,
    setOpenNotifDropDown,
    notifications,
    setReloadNotif,
    reloadNotif,
    loading,
}) => {
    const history = useNavigate()
    const location = useLocation()

    const handleClickOnNotification = (notif) => {
        if (!notif.seen) {
            const data = {
                seen: true,
            }
            API.Notifications.put(notif.id, data).then((response) => {
                response.json().then(() => {
                    setOpenNotifDropDown(false)
                    setReloadNotif(!reloadNotif)
                    if (
                        location.pathname ===
                        '/transport/' + notif.run.id + '?tab=Options'
                    ) {
                        history(0)
                    } else {
                        history('/transport/' + notif.run.id + '?tab=Options')
                    }
                })
            })
        } else {
            setOpenNotifDropDown(false)
            setReloadNotif(!reloadNotif)
            if (
                location.pathname ===
                '/transport/' + notif.run.id + '?tab=Options'
            ) {
                history(0)
            } else {
                history('/transport/' + notif.run.id + '?tab=Options')
            }
        }
    }

    return (
        <div
            className="modal absolute mr-2 mt-12 flex w-80 flex-col rounded-md border bg-white"
            ref={notifMenuRef}
        >
            <div className="max-height-90 flex flex-col overflow-x-auto px-2 text-gray-700">
                <div className="flex w-full justify-between divide-y-2 px-2  py-2">
                    <p className="font-semibold">Notifications</p>
                    <p>{notifications.length}</p>
                </div>
                {!loading && notifications.length > 0 ? (
                    <div>
                        {notifications.map((notif, i) => (
                            <button
                                key={i}
                                className={`cursor-pointer rounded-lg px-2 py-2 hover:bg-gray-200 `}
                                onClick={() => handleClickOnNotification(notif)}
                            >
                                {notif.type.startsWith('both') ? (
                                    <MultipleNotification multiNotif={notif} />
                                ) : notif.type.startsWith('status') ? (
                                    <StatusNotification
                                        statusNotification={notif}
                                    />
                                ) : (
                                    <CommentNotification
                                        commentNotification={notif}
                                    />
                                )}
                            </button>
                        ))}
                    </div>
                ) : loading ? (
                    <div>
                        {Array.from(Array(8), (e, i) => (
                            <div
                                key={i}
                                className="mx-auto w-full max-w-sm rounded-md p-4"
                            >
                                <div className="flex animate-pulse items-center space-x-4">
                                    <div className="h-10 w-10 rounded-full bg-gray-400"></div>
                                    <div className="flex-1 space-y-4 py-1">
                                        <div className="h-2 rounded bg-gray-400"></div>
                                        <div className="space-y-3">
                                            <div className="grid grid-cols-3 gap-4">
                                                <div className="col-span-2 h-2 rounded bg-gray-400"></div>
                                                <div className="col-span-1 h-2 rounded bg-gray-400"></div>
                                            </div>
                                            <div className="h-2 rounded bg-gray-400"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className={'px-2 py-4 text-left text-sm'}>
                        Aucune notifications pour le moment
                    </p>
                )}
            </div>
        </div>
    )
}

export default NotifDropDown
