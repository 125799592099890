import SelectDropDownListV2 from '../../Commons/DropDownLists/SelectDropDownListV2'
import { formatItemForSelect, formatName } from '../../../tools/Utility'
import React from 'react'
import { searchPatient } from '../../../services/patient'
import {
    BENEFICIARY_ACTIONS,
    BeneficiaryFormReducer,
    getBeneficiaryInitialState,
} from '../../../services/Reducers/BeneficiaryReducer'
import DeleteButton from '../../Commons/Buttons/DeleteButton'
import dayjs from 'dayjs'
import { Group, UserSquare } from 'iconoir-react'
import { BeneficiaryLink } from '../../../services/BeneficiaryLink'

const BeneficiaryForm = ({
    beneficiary,
    handleSelectBeneficiaryLink,
    handleAddNewBeneficiary,
    handleRemoveBeneficiary,
}) => {
    const [beneficiaryForm, dispatch] = React.useReducer(
        BeneficiaryFormReducer,
        { beneficiary },
        getBeneficiaryInitialState
    )
    return (
        <div className="flex w-full flex-col rounded-xl bg-white py-10">
            <div className="flex flex-col px-5 pb-10 lg:px-20">
                {beneficiary ? (
                    <>
                        <div className={'ml-auto'}>
                            <DeleteButton
                                label={'Détacher le bénéficiaire'}
                                action={() =>
                                    handleRemoveBeneficiary(beneficiary)
                                }
                            />
                        </div>
                        <div className="flex flex-col space-y-2 rounded-xl  bg-white p-5 text-xl ">
                            <div className="flex w-full justify-between space-x-2">
                                <div className="min-w-12 flex flex-col">
                                    <p className="font-bold text-gray-600">
                                        Nom
                                    </p>
                                    <p>{beneficiary.lastname}</p>
                                </div>

                                <div className="flex flex-col ">
                                    <p className="font-bold text-gray-600">
                                        Prénom
                                    </p>
                                    <p>{beneficiary.firstname}</p>
                                </div>
                            </div>
                            <div className="flex flex-col space-y-1">
                                <p className="font-bold text-gray-600">
                                    Téléphone
                                </p>
                                {beneficiary.phoneNumber ? (
                                    <div className="flex items-center justify-between">
                                        <a
                                            href={`tel:${beneficiary.phoneNumber}`}
                                        >
                                            {beneficiary.phoneNumber}
                                        </a>
                                        <i className="fa fa-phone" />
                                    </div>
                                ) : (
                                    <i className="fa fa-times pl-2 pt-2" />
                                )}
                            </div>
                            <div className="flex flex-col space-y-1">
                                <p className="font-bold text-gray-600">
                                    Date de naissance
                                </p>
                                {beneficiary.dateOfBirth ? (
                                    <div className="flex items-center justify-between">
                                        <p>
                                            {beneficiary.dateOfBirth
                                                ? dayjs(
                                                      new Date(
                                                          beneficiary.dateOfBirth.split(
                                                              '+'
                                                          )[0]
                                                      )
                                                  ).format('DD/MM/YYYY')
                                                : null}
                                        </p>
                                        <i className="fa fa-calendar-alt" />
                                    </div>
                                ) : (
                                    <i className="fa fa-times pl-2 pt-2" />
                                )}
                            </div>
                            <div className="flex flex-col space-y-1">
                                <p className="font-bold text-gray-600">
                                    N° de sécu :
                                </p>
                                {beneficiary.socialNumber ? (
                                    <div className="flex items-center justify-between">
                                        <p>{beneficiary.socialNumber}</p>
                                        <UserSquare height={36} width={36} />
                                    </div>
                                ) : (
                                    <i className="fa fa-times pl-2 pt-2" />
                                )}
                            </div>
                            {beneficiary.description && (
                                <div className="flex flex-col space-y-1">
                                    <p className="font-bold text-gray-600">
                                        Description
                                    </p>
                                    <p>{beneficiary.description}</p>
                                </div>
                            )}
                            {beneficiary.beneficiaryLink && (
                                <div className="flex flex-col space-y-1">
                                    <p className="font-bold text-gray-600">
                                        Lien avec l'assuré
                                    </p>
                                    <p>{beneficiary.beneficiaryLink}</p>
                                </div>
                            )}
                            {beneficiary.patientsAddresses && (
                                <div className="flex w-full flex-col space-y-8">
                                    {beneficiary.patientsAddresses.map(
                                        (savedAddress, key) => (
                                            <div
                                                key={key}
                                                className="flex flex-col space-y-1"
                                            >
                                                <p className="font-bold text-gray-600">
                                                    {savedAddress.label}
                                                </p>
                                                <p className="cursor-pointer">
                                                    {savedAddress.address.street
                                                        ? savedAddress.address
                                                              .street + ', '
                                                        : ''}
                                                    {savedAddress.address
                                                        .secondaryStreet
                                                        ? savedAddress.address
                                                              .secondaryStreet +
                                                          ', '
                                                        : ''}
                                                    <span className="underline">
                                                        {
                                                            savedAddress.address
                                                                .city
                                                        }
                                                    </span>
                                                </p>
                                                {savedAddress.phoneNumber && (
                                                    <div className="flex items-center justify-between">
                                                        <a
                                                            href={`tel:${savedAddress.phoneNumber}`}
                                                        >
                                                            {
                                                                savedAddress.phoneNumber
                                                            }
                                                        </a>
                                                        <i className="fa fa-phone" />
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    )}
                                </div>
                            )}
                            {beneficiary.patientsContacts?.length > 0 && (
                                <div className="flex w-full flex-col space-y-8">
                                    <div className="flex items-center justify-between">
                                        <p className="font-semibold text-gray-600">
                                            Contacts enregistrés
                                        </p>
                                        <Group />
                                    </div>
                                    <div className="flex w-full flex-col space-y-8">
                                        {beneficiary.patientsContacts.map(
                                            (contact, key) => (
                                                <div
                                                    key={key}
                                                    className="flex flex-col space-y-1"
                                                >
                                                    <p className="font-semibold text-gray-600">
                                                        {contact.label}
                                                    </p>
                                                    <p className=""></p>
                                                    {contact.phoneNumber && (
                                                        <div className="flex items-center justify-between">
                                                            <a
                                                                href={`tel:${contact.phoneNumber}`}
                                                            >
                                                                {
                                                                    contact.phoneNumber
                                                                }
                                                            </a>
                                                            <i className="fa fa-phone" />
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <div className={'flex flex-col space-y-2'}>
                        <SelectDropDownListV2
                            label={'Sélectionner le bénéficiaire'}
                            showLabel={true}
                            placeholder={'Sélectionner le bénéficiaire'}
                            icon={'fa-user'}
                            value={formatName(beneficiaryForm)}
                            isSearchable={true}
                            options={beneficiaryForm.beneficiaries}
                            handleOptionChange={(e) =>
                                handleAddNewBeneficiary(e)
                            }
                            handleSearchInputPatientChange={(e) =>
                                searchPatient(
                                    e,
                                    dispatch,
                                    BENEFICIARY_ACTIONS.SET_BENEFICIARY_SEARCH_INPUT
                                )
                            }
                        />
                        <SelectDropDownListV2
                            label={"Lien du bénéficiaire avec l'assuré"}
                            showLabel={true}
                            placeholder={"Lien du bénéficiaire avec l'assuré"}
                            icon={'fa-link'}
                            value={formatItemForSelect(
                                beneficiaryForm.beneficiaryLink
                            )}
                            options={BeneficiaryLink}
                            handleOptionChange={(e) =>
                                handleSelectBeneficiaryLink(e)
                            }
                        />
                    </div>
                )}
            </div>
            {/*{TODO IN APP ADD BENEFICIARY /}
            {/*{beneficiary.showPatientModal && (*/}
            {/*    <ModalSkeleton*/}
            {/*        label={`${*/}
            {/*            patientForm.id ? 'Modifier' : 'Ajouter'*/}
            {/*        } un patient`}*/}
            {/*        componentProps={{*/}
            {/*            patient: patientForm,*/}
            {/*            dispatch,*/}
            {/*            date,*/}
            {/*            dateDispatch,*/}
            {/*            fromModal: true,*/}
            {/*        }}*/}
            {/*        Component={PatientFormContent}*/}
            {/*        handleForm={handlePatientModalForm}*/}
            {/*        handleCloseModal={handleCandelModal}*/}
            {/*        disabledSubmit={disabledSubmit}*/}
            {/*    />*/}
            {/*)}*/}
        </div>
    )
}

export default BeneficiaryForm
